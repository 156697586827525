import {Injectable, Renderer2, RendererFactory2} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppThemeService {
  renderer: Renderer2;
  appTheme: string = '';

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  setAppTheme(theme: string) {
    this.appTheme = theme;
    localStorage.setItem('oph-theme', theme);
  }

  getAppTheme(): string {
    const localStorageColorScheme = localStorage.getItem('oph-theme');
    return localStorageColorScheme || 'light';
  }

  update(theme: string) {
    const previousTheme = this.appTheme;
    this.setAppTheme(theme);
    this.renderer.removeClass(document.body, `${previousTheme}-theme`);
    this.renderer.addClass(document.body, `${theme}-theme`);
  }

  load() {
    this.appTheme = this.getAppTheme();
    this.renderer.addClass(document.body, `${this.appTheme}-theme`);
  }
}
