import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'listViewMenuIcon',
})
export class ListViewMenuIconPipe implements PipeTransform {
  transform(value: string): unknown {
    switch (value) {
      case 'edit':
        return 'edit-brown';
      case 'duplicate':
        return 'duplicate-brown';
      case 'archive':
        return 'archive-brown';
      case 'create template':
        return 'create-template';
      case 'delete':
        return 'delete-brown';
    }
  }
}
