import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {OphButtonModule} from '../../design/oph-button/oph-button.module';
import {OphIconModule} from '../../design/oph-icon/oph-icon.module';
import {OphInputsModule} from '../../design/oph-inputs/oph-inputs.module';
import {OphRadioButtonModule} from '../../design/oph-radio-button/oph-radio-button.module';
import {PipesModule} from '../../pipes/pipes.module';
import {SharedTasksModule} from '../shared-tasks.module';
import {TaskWizardDialogModule} from '../task-wizard-dialog/task-wizard-dialog.module';
import {TaskDialogConfirmationComponent} from './panels/confirmation/task-dialog-confirmation.component';
import {TaskDialogDetailsPanelComponent} from './panels/default/details/task-dialog-details-panel.component';
import {TaskDialogFooterComponent} from './panels/default/footer/task-dialog-footer.component';
import {TaskDialogHeaderComponent} from './panels/default/header/task-dialog-header.component';
import {TaskDialogSchedulePanelComponent} from './panels/default/schedule/task-dialog-schedule-panel.component';
import {TaskDialogSubtasksPanelComponent} from './panels/default/subtasks/task-dialog-subtasks-panel.component';
import {TaskDialogTabsComponent} from './panels/default/tabs/task-dialog-tabs.component';
import {TaskDialogDueByComponent} from './panels/unused/due-by/task-dialog-due-by.component';
import {TaskDialogPanelFooterComponent} from './panels/panel-footer/task-dialog-panel-footer.component';
import {TaskDialogRequirementItemComponent} from './panels/requirements/item/task-dialog-requirement-item.component';
import {TaskDialogRequirementsComponent} from './panels/requirements/task-dialog-requirements.component';
import {TaskDialogSubtaskEditComponent} from './panels/subtask-edit/task-dialog-subtask-edit.component';
import {TaskDialogComponent} from './task-dialog.component';
import {TaskDialogTriggersTabComponent} from './panels/default/triggers/task-dialog-triggers-tab.component';
import {TaskDialogScheduleButtonComponent} from './panels/default/schedule/button/task-dialog-schedule-button.component';
import {TaskDialogIconPickerComponent} from './panels/icon-picker/task-dialog-icon-picker.component';
import {ListViewsModule} from '../../list-views/list-views.module';
import {OphAvatarComponent} from '../../design/oph-avatar/oph-avatar.component';
import {TaskTriggerSelectionComponent} from '../trigger-selection/task-trigger-selection.component';
import {RewardCoinIconPipe} from '../../pipes/reward-coin-icon.pipe';
import {MatIconModule} from '@angular/material/icon';
import {TaskDialogDetailsPanelRewardsComponent} from './panels/default/details/rewards/task-dialog-details-panel-rewards.component';

@NgModule({
  declarations: [
    TaskDialogComponent,
    TaskDialogHeaderComponent,
    TaskDialogFooterComponent,
    TaskDialogTabsComponent,
    TaskDialogDetailsPanelComponent,
    TaskDialogSchedulePanelComponent,
    TaskDialogSubtasksPanelComponent,
    TaskDialogRequirementsComponent,
    TaskDialogDueByComponent,
    TaskDialogSubtaskEditComponent,
    TaskDialogPanelFooterComponent,
    TaskDialogRequirementItemComponent,
    TaskDialogConfirmationComponent,
    TaskDialogTriggersTabComponent,
    TaskDialogScheduleButtonComponent,
    TaskDialogIconPickerComponent,
  ],
  imports: [
    CommonModule,
    SharedTasksModule,
    OphIconModule,
    MatMenuModule,
    OphInputsModule,
    PipesModule,
    ReactiveFormsModule,
    OphButtonModule,
    MatCheckboxModule,
    OphRadioButtonModule,
    TaskWizardDialogModule,
    MatProgressSpinnerModule,
    ListViewsModule,
    OphAvatarComponent,
    TaskTriggerSelectionComponent,
    RewardCoinIconPipe,
    MatIconModule,
    TaskDialogDetailsPanelRewardsComponent,
  ],
  exports: [TaskDialogRequirementItemComponent],
})
export class TaskDialogModule {}
