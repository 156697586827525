import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Store, select} from '@ngrx/store';
import {Observable} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {SetTeamSkedsAction} from 'src/app/core/store/scheduler/scheduler.action';
import {selectTeam} from 'src/app/core/store/teams/teams.selector';
import {selectActiveUser} from '../../../../core/store/active-user/active-user.selector';
import {selectRouterUrl} from '../../../../core/store/router/router.selector';
import {selectTeamSkeds} from '../../../../core/store/scheduler/scheduler.selectors';
import {TimezoneService} from 'src/app/services/timezone.service';

@Component({
  selector: 'oph-top-bar',
  templateUrl: './oph-top-bar.component.html',
  styleUrls: ['./oph-top-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OphTopBarComponent implements OnInit {
  @Output() toggleMenu = new EventEmitter();

  public readonly url$ = this.store$.pipe(select(selectRouterUrl));
  public readonly activeUser$ = this.store$.select(selectActiveUser);
  userTeamName$: Observable<string>;

  public scheduleSelected$: Observable<boolean> = this.store$.pipe(select(selectTeamSkeds));
  currentSkedScreen$: Observable<boolean>;

  constructor(
    private store$: Store,
    private timeZoneService: TimezoneService
  ) {}

  ngOnInit() {
    this.currentSkedScreen$ = this.observeUrl();
    this.userTeamName$ = this.observeUser();
  }

  observeUrl(): Observable<boolean> {
    return this.url$.pipe(
      filter(url => !!url),
      map(url => url.startsWith('/current-sked'))
    );
  }

  observeUser(): Observable<string> {
    return this.activeUser$.pipe(
      filter(user => !!user?.teams?.length),
      switchMap(user => this.store$.pipe(select(selectTeam(user.teams[0])))),
      map(team => team?.name || 'Team name not found')
    );
  }

  onSelectChange($event: boolean) {
    this.store$.dispatch(new SetTeamSkedsAction($event));
  }

  getTimeZone(): string {
    return this.timeZoneService.getTimeZoneAbbr();
  }
}
