import {Component, OnInit} from '@angular/core';
import {IconRegistryService} from './shared/services/icon-registry.service';

@Component({
  selector: 'oph-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'Ophanim';

  constructor(private iconRegistryService: IconRegistryService) {}

  public ngOnInit(): void {
    this.iconRegistryService.registerIcons();
  }
}
