import {HttpErrorResponse} from '@angular/common/http';
import {Action} from '@ngrx/store';
import {Goal, Target} from '../../model/goal';

export enum GoalsActionType {
  GET = '[Goals] Get',
  GET_SUCCESS = '[Goals] Get :: Success',

  GET_SINGLE = '[Goals] Get Single',
  GET_SINGLE_SUCCESS = '[Goals] Get Single :: Success',

  CREATE = '[Goals] Create',
  CREATE_SUCCESS = '[Goals] Create :: Success',

  UPDATE = '[Goals] Update',
  UPDATE_SUCCESS = '[Goals] Update :: Success',

  DELETE = '[Goals] Delete',
  DELETE_SUCCESS = '[Goals] Delete :: Success',

  CONNECT_TARGET = '[Goals] Connect Target',
  CONNECT_TARGET_SUCCESS = '[Goals] Connect Target :: Success',

  DISCONNECT_TARGET = '[Goals] Disconnect Target',
  DISCONNECT_TARGET_SUCCESS = '[Goals] Disconnect Target :: Success',

  TOGGLE_TARGET = '[Goals] Toggle Target',
  TOGGLE_TARGET_SUCCESS = '[Goals] Toggle Target :: Success',
}

export class GetAllGoalsAction implements Action {
  public readonly type = GoalsActionType.GET;

  public constructor(
    public payload: {
      userId?: string;
      onSuccess?: (goals: Goal[]) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetAllGoalsSuccessAction implements Action {
  public readonly type = GoalsActionType.GET_SUCCESS;

  public constructor(public payload: {goals: Goal[]}) {}
}

// ------------------------------------------------------------

export class GetSingleGoalAction implements Action {
  public readonly type = GoalsActionType.GET_SINGLE;

  public constructor(
    public payload: {
      id: string;
      onSuccess?: (goals: Goal) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetSingleGoalSuccessAction implements Action {
  public readonly type = GoalsActionType.GET_SINGLE_SUCCESS;

  public constructor(public payload: {goal: Goal}) {}
}

// ------------------------------------------------------------

export class CreateGoalAction implements Action {
  public readonly type = GoalsActionType.CREATE;

  public constructor(
    public payload: {
      goal: Goal;
      onSuccess?: (goal: Goal) => void;
      onFailure?: (error: string) => void;
    }
  ) {}
}

export class CreateGoalSuccessAction implements Action {
  public readonly type = GoalsActionType.CREATE_SUCCESS;

  public constructor(public payload: {goal: Goal}) {}
}

// ------------------------------------------------------------

export class UpdateGoalAction implements Action {
  public readonly type = GoalsActionType.UPDATE;

  public constructor(
    public payload: {
      goal: Goal;
      onSuccess?: (goal: Goal) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class UpdateGoalSuccessAction implements Action {
  public readonly type = GoalsActionType.UPDATE_SUCCESS;

  public constructor(public payload: {goal: Goal}) {}
}

// ------------------------------------------------------------

export class DeleteGoalAction implements Action {
  public readonly type = GoalsActionType.DELETE;

  public constructor(
    public payload: {
      id: string;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class DeleteGoalSuccessAction implements Action {
  public readonly type = GoalsActionType.DELETE_SUCCESS;
}

// ------------------------------------------------------------

export class ConnectTargetAction implements Action {
  public readonly type = GoalsActionType.CONNECT_TARGET;

  public constructor(
    public payload: {
      goalId: string;
      objectiveId: string;
      target: Partial<Target>;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class ConnectTargetSuccessAction implements Action {
  public readonly type = GoalsActionType.CONNECT_TARGET_SUCCESS;

  public constructor(public payload: {goal: Goal}) {}
}

// ------------------------------------------------------------

export class DisconnectTargetAction implements Action {
  public readonly type = GoalsActionType.DISCONNECT_TARGET;

  public constructor(
    public payload: {
      goalId: string;
      objectiveId: string;
      target: Partial<Target>;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class DisconnectTargetSuccessAction implements Action {
  public readonly type = GoalsActionType.DISCONNECT_TARGET_SUCCESS;

  public constructor(public payload: {goal: Goal}) {}
}

// ------------------------------------------------------------

export class ToggleTargetAction implements Action {
  public readonly type = GoalsActionType.TOGGLE_TARGET;

  public constructor(
    public payload: {
      goalId: string;
      objectiveId: string;
      targetId: string;
      onSuccess?: (goal: Goal) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class ToggleTargetSuccessAction implements Action {
  public readonly type = GoalsActionType.TOGGLE_TARGET_SUCCESS;

  public constructor(public payload: {goal: Goal}) {}
}

// ------------------------------------------------------------

export type GoalsAction =
  | GetAllGoalsAction
  | GetAllGoalsSuccessAction
  | GetSingleGoalAction
  | GetSingleGoalSuccessAction
  | CreateGoalAction
  | CreateGoalSuccessAction
  | UpdateGoalAction
  | UpdateGoalSuccessAction
  | DeleteGoalAction
  | DeleteGoalSuccessAction
  | ConnectTargetAction
  | ConnectTargetSuccessAction
  | DisconnectTargetAction
  | DisconnectTargetSuccessAction
  | ToggleTargetAction
  | ToggleTargetSuccessAction;
