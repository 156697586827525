@if (
  {
    manageRewardsCoinExpandedMap: manageRewardsCoinExpandedMap$ | async,
    manageRewardsRewardExpandedMap: manageRewardsRewardExpandedMap$ | async,
    manageRewards: manageRewards$ | async,
    usersMap: usersMap$ | async
  };
  as state
) {
  @if ((initialLoading$ | async) && (getError$ | async) === false) {
    <div class="loading-container">
      <oph-loading></oph-loading>
    </div>
  } @else {
    @if (!state.manageRewards?.length) {
      <div class="empty">There are no rewards to display.</div>
    } @else {
      <div class="coin-types-container">
        @for (rewardCoin of manageRewards$ | async; track reward) {
          <rewards-coin-expansion-panel
            [rewardCoin]="rewardCoin"
            [expandedMap]="manageRewardsCoinExpandedMap$ | async"
            [expanded]="state.manageRewardsCoinExpandedMap[rewardCoin.name]"
            [disabled]="rewardCoin.rewards?.length === 0"
            (expandedChange)="onCoinExpandedMapChange(rewardCoin.name, $event)"
          >
            <div class="coin-types-content">
              @for (reward of rewardCoin.rewards; track $index; let i = $index) {
                <mat-expansion-panel
                  hideToggle
                  [expanded]="state.manageRewardsRewardExpandedMap[rewardCoin.name + i]"
                  [disabled]="!reward.assignedTo?.length"
                  (opened)="onRewardExpandedMapChange(rewardCoin.name, i, true)"
                  (closed)="onRewardExpandedMapChange(rewardCoin.name, i, false)"
                >
                  <mat-expansion-panel-header>
                    <rewards-reward-header-item [name]="reward.name" [description]="reward.description">
                      <span class="expired">{{ reward.expirationDate | isExpired: true }}</span>
                      <rewards-user-count-chevron
                        [userCount]="reward.assignedTo | rewardsActiveUserCount"
                        [expanded]="state.manageRewardsRewardExpandedMap[rewardCoin.name + i]"
                        [class.orange-border]="reward.assignedTo | rewardsCheckIfAvailableFulfillment"
                      ></rewards-user-count-chevron>
                      <rewards-reward-cost
                        [coinName]="rewardCoin.name"
                        [rewardCost]="reward.rewardCost"
                      ></rewards-reward-cost>
                      <list-view-menu
                        [options]="['edit', 'delete']"
                        (action)="onMenuAction($event, reward)"
                      ></list-view-menu
                    ></rewards-reward-header-item>
                  </mat-expansion-panel-header>
                  <!-- ----------------------------------------MANAGE EXPANSION PANEL CONTENT---------------------------------------- -->
                  <div class="reward-content">
                    @for (u of reward.assignedTo; track $index; let uIndex = $index) {
                      @if (!u.inactive || u.status === 'claimed') {
                        <reward-user-item [user]="state.usersMap?.[u.userId]" [inactiveUser]="u.inactive">
                          <div class="reward-content-item-rc">
                            @if (u.status === 'unclaimed') {
                              <rewards-progress-bar
                                [percentage]="
                                  state.usersMap?.[u.userId] | rewardManageProgress: rewardCoin._id : reward.rewardCost
                                "
                              ></rewards-progress-bar>
                            }
                            @if (u.status === 'claimed') {
                              <span class="claimed">claimed {{ u.claimedAt | moment: 'MMMDD YY' }}</span>
                            }
                            <div class="button-container">
                              @if (u.status === 'claimed') {
                                <oph-button
                                  color="orange"
                                  [style]="{
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    letterSpacing: '0.7px',
                                    birderRadius: '6px'
                                  }"
                                  [class.disabled]="fulfillLoadingIndex >= 0"
                                  [loading]="fulfillLoadingIndex === uIndex"
                                  (buttonClick)="onFulfill(reward._id, u._id, uIndex)"
                                  >FULFILL</oph-button
                                >
                              }
                              @if (u.status === 'fulfilled') {
                                <span class="fulfilled">FULFILLED</span>
                              }
                            </div>
                          </div>
                        </reward-user-item>
                      }
                    }
                  </div>
                </mat-expansion-panel>
              }
            </div>
          </rewards-coin-expansion-panel>
        }
      </div>
    }
  }
}
