@if ({params: params$ | async, trackerData: trackerData$ | async, loading: loading$ | async}; as state) {
  <list-view-header
    name="Trackers"
    icon="tracker"
    iconColor="#F57B4E"
    createButtonText="New Tracker"
    [searchValue]="state.params?.search || ''"
    [loadingCreateNew]="loadingNewTracker$ | async"
    [hideSearch]="state.loading"
    (viewChange)="onViewChange($event)"
    (search)="onSearch($event)"
    (createNew)="onNewTracker()"
  ></list-view-header>

  @if (state.loading) {
    <div class="loading-container">
      <oph-loading></oph-loading>
    </div>
  } @else {
    @if (!state.trackerData?.trackers?.length && !state.params.search && !loadingParams) {
      <create-first type="tracker" (buttonClick)="onNewTracker()"></create-first>
    } @else {
      <oph-table
        [columns]="columns"
        [data]="formattedTrackers$ | async"
        defaultSortField="name"
        (rowClick)="onTracker($event)"
      ></oph-table>

      <div class="grid-sort">
        <label>Sort by</label>
        <button [matMenuTriggerFor]="menu">
          {{ (state.params?.sortField | trackerSortName) || 'name' | titlecase }}
          <mat-icon svgIcon="chevron-down"></mat-icon>
        </button>
        <mat-menu #menu="matMenu" class="oph-select-menu">
          @for (column of columns; track column) {
            @if (column.sortName) {
              <button class="oph-select-menu-item" (click)="onSort(column.sortName)">
                {{ column.name | titlecase }}
              </button>
            }
          }
        </mat-menu>
      </div>

      @if (state.trackerData?.trackers?.length) {
        <div class="mobile-list">
          @for (tracker of state.trackerData?.trackers; track tracker; let i = $index) {
            <trackers-list-grid-item [tracker]="tracker" (trackerClick)="onTracker(i)"></trackers-list-grid-item>
          }
        </div>
      }

      @if (state.trackerData?.pagination?.totalPages > 1) {
        <oph-paginator
          [page]="state.trackerData?.pagination?.page"
          [totalPages]="state.trackerData?.pagination?.totalPages"
        ></oph-paginator>
      }
    }
  }
}
