@if (
  {
    selectedTab: selectedTab$ | async,
    scheduleType: scheduleType$ | async,
    coverSheetData: coverSheetData$ | async,
    repeatTimeInvalid: repeatTimeInvalid$ | async
  };
  as state
) {
  <div class="main-container">
    <div class="tabs-container">
      @for (tab of tabs; track tab) {
        <button [class.selected-tab]="state.selectedTab === tab" class="tab-button" (click)="onTab(tab)">
          {{ tab | titlecase }}
        </button>
      }
    </div>
    <div class="body">
      <div>
        <div class="time-rows-container">
          <!-- -----------------------TASK------------------------- -->
          <div class="time-row" [class.visible]="state.selectedTab === TaskDialogScheduleTabs.Task">
            <div class="task-item-container">
              <span>Activate</span>
              <task-dialog-schedule-button
                [showClear]="scheduleOnDateControl.value !== 'asap'"
                (buttonClick)="onCalendarOpen('scheduleOnDate')"
                (clear)="onScheduleOnDateClear($event)"
                >{{
                  scheduleOnDateControl.value === 'asap'
                    ? 'ASAP'
                    : (scheduleOnDateControl.value | convertUtcToTimezone: dateButtonFormat)
                }}</task-dialog-schedule-button
              >
            </div>
            @if (state.scheduleType === 'doNotRepeat' && !triggerCount) {
              <div class="task-item-container">
                <span>Due by</span>
                <!-- -----------------------IF NO REPEAT, NO TRIGGERS------------------------- -->
                <task-dialog-schedule-button
                  [showClear]="targetCompletionTypeControl.value === 'date'"
                  (buttonClick)="onCalendarOpen('targetCompletionDate')"
                  (clear)="onTargetCompletionDateClear($event)"
                  >{{
                    targetCompletionTypeControl.value === 'endOfProject'
                      ? 'Project End'
                      : (targetCompletionDateControl.value | convertUtcToTimezone: dateButtonFormat)
                  }}</task-dialog-schedule-button
                >
              </div>
              <div class="task-item-container">
                <span>Expires</span>
                <task-dialog-schedule-button
                  [showClear]="expirationTypeControl.value === 'date'"
                  (buttonClick)="onCalendarOpen('expirationDate')"
                  (clear)="onExpirationDateClear($event)"
                  >{{
                    expirationTypeControl.value === 'endOfProject'
                      ? 'Project End'
                      : (expirationDateControl.value | convertUtcToTimezone: dateButtonFormat)
                  }}</task-dialog-schedule-button
                >
              </div>
            }
            <!-- -----------------------IF REPEAT OR TRIGGERS------------------------- -->
            @if (state.scheduleType === 'repeats' || triggerCount) {
              <div class="task-item-container">
                <span>Due by</span>
                <task-dialog-schedule-button
                  iconType="time"
                  [showClear]="targetCompletionTypeControl.value === 'amountOfTime'"
                  (buttonClick)="onTimeSelectorOpen('targetCompletion')"
                  (clear)="onTargetCompletionDateClear($event)"
                  >{{
                    targetCompletionTypeControl.value !== 'amountOfTime'
                      ? (state.scheduleType === 'repeats' && 'On Repeat') || 'Project End'
                      : targetCompletionValueControl.value + ' ' + targetCompletionValueTypeControl.value
                  }}</task-dialog-schedule-button
                >
              </div>
              <div class="task-item-container">
                <span>Expires</span>
                <task-dialog-schedule-button
                  iconType="time"
                  [showClear]="expirationTypeControl.value === 'amountOfTime'"
                  (buttonClick)="onTimeSelectorOpen('expiration')"
                  (clear)="onExpirationDateClear($event)"
                  >{{
                    expirationTypeControl.value !== 'amountOfTime'
                      ? (state.scheduleType === 'repeats' && 'On Repeat') || 'Project End'
                      : expirationValueControl.value + ' ' + expirationValueTypeControl.value
                  }}</task-dialog-schedule-button
                >
              </div>
            }
          </div>
          <!-- -----------------------EVENT------------------------- -->
          <div class="time-row" [class.visible]="state.selectedTab === TaskDialogScheduleTabs.Event">
            <div class="event-item-container starts">
              <span>Starts</span>
              <task-dialog-schedule-button (buttonClick)="onCalendarOpen('scheduleOnDate')" class="date-button-mw">{{
                scheduleOnDateControl.value === 'asap'
                  ? ''
                  : (scheduleOnDateControl.value | convertUtcToTimezone: dateButtonFormat)
              }}</task-dialog-schedule-button>
            </div>
            <div class="event-item-container">
              <span>at</span>
              <!-- START -->
              <div #eventStartTime class="timepicker-container" [class.disabled]="false">
                <img src="assets/img/icons/clock-brown.svg" />
                <input
                  placeholder="Start time"
                  readonly
                  type="text"
                  class="time-input"
                  [class.error-border]="validationErrors.eventStart"
                  [value]="eventStartControl.value | uppercase"
                />
              </div>
            </div>
            <div class="relative-container">
              @if (!eventTimesControls.valid && !!eventStartControl.value && !!eventEndControl.value) {
                <error class="to error" class="time-error">Start time should be before stop time.</error>
              }
            </div>
            <div class="event-item-container">
              <span>until</span>
              <!-- END -->
              <div #eventEndTime class="timepicker-container" [class.disabled]="false">
                <img src="assets/img/icons/clock-brown.svg" />
                <input
                  placeholder="End time"
                  readonly
                  type="text"
                  class="time-input"
                  [class.error-border]="validationErrors.eventEnd"
                  [value]="eventEndControl.value | uppercase"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- -----------------------REPEAT------------------------- -->
        <div class="repeat-container">
          <div class="radio-buttons-container">
            <button class="radio-button" (click)="onRepeatType('doNotRepeat')">
              <oph-radio-button [selected]="state.scheduleType === 'doNotRepeat'"></oph-radio-button>
              No Repeat
            </button>
            <button class="radio-button" (click)="onRepeatType('repeats')">
              <oph-radio-button [selected]="state.scheduleType === 'repeats'"></oph-radio-button>
              Repeats
            </button>
          </div>
          <task-repetition
            [scheduleFormGroup]="scheduleControls"
            [disabledDays]="disabledDays$ | async"
            [noDaySelected]="validationErrors.repeatDays"
            (showCalendar)="onCalendarOpen('endsDate')"
          ></task-repetition>
        </div>
      </div>
    </div>
    <div class="empty-space"></div>
    <!-- This covers the current dialog with a selector - calendar or amount of time -->
    @if (showCoverSelector$ | async) {
      <div class="dialog-cover-selector">
        <button class="close-button" (click)="onCloseCoverSelector()">
          <img src="assets/img/icons/x-brown.svg" />
        </button>
        @if (state.coverSheetData?.type === 'calendar') {
          <task-calendar
            [control]="state.coverSheetData?.dateControl"
            (dateChange)="onDateChange()"
            hideBorder="true"
          ></task-calendar>
        }
        @if (state.coverSheetData?.type === 'time') {
          <div class="time-select-container">
            <div class="time-select-inner-container">
              <task-number-input
                [min]="1"
                [max]="10000"
                [control]="state.coverSheetData.valueControl"
                [fontSize]="20"
              ></task-number-input>
              <button class="type-button" [matMenuTriggerFor]="typeMenu" #trigger="matMenuTrigger">
                {{ state.coverSheetData.valueTypeControl.value | titlecase }}
                <img src="assets/img/icons/chevron-down-brown.svg" />
              </button>
              <mat-menu #typeMenu="matMenu" class="oph-select-menu">
                @for (type of repeatTypesArr; track type) {
                  <button class="oph-select-menu-item" (click)="$event.stopPropagation(); onTimeType(type)">
                    <span>{{ type | titlecase }}</span>
                  </button>
                }
              </mat-menu>
              <oph-button color="green" (buttonClick)="onOk()" height="62" borderRadius="6" fontSize="20"
                >OK</oph-button
              >
              @if (state.repeatTimeInvalid) {
                <div class="time-sheet-error-container">
                  <error>Expiration time must be less than time in between repeated tasks.</error>
                  @if (state.repeatTimeInvalid.timeBeforeNextTaskDisplay) {
                    <span>Max time allowed: {{ state.repeatTimeInvalid.timeBeforeNextTaskDisplay }}</span>
                  }
                </div>
              }
            </div>
          </div>
        }
      </div>
    }
  </div>
}

<div class="task-dialog-timepicker"></div>
