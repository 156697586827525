<header>
  <h2>Select Sked</h2>
  <oph-x-button (buttonClick)="onClose()"></oph-x-button>
</header>

<!-- <oph-checkbox [textStyle]="{fontSize: '15px', color: 'var(--off-black)'}">Add for today only</oph-checkbox> -->

<div class="sked-container">
  @for (sked of autoSkeds$ | async; track sked; let i = $index) {
    <button class="sked-button" (click)="onSked(sked, i)">
      <span class="sked-label">{{ sked.label }}</span>
      <span class="sked-time">{{ sked | autoskedTimeDisplay }}</span>
      @if ((skedLoadingIndex$ | async) === i) {
        <mat-spinner diameter="22"></mat-spinner>
      } @else if ((autoSkedCheckedMap$ | async)[sked._id]) {
        <mat-icon class="check-icon" svgIcon="check"></mat-icon>
      } @else {
        <mat-icon class="plus-icon" svgIcon="plus"></mat-icon>
      }
    </button>
  }
</div>
