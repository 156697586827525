import {AsyncPipe, SlicePipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {MatExpansionModule} from '@angular/material/expansion';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import * as moment from 'moment-timezone';
import {BehaviorSubject, Observable} from 'rxjs';
import {ProjectView, ProjectViewRoutine, SelectedRoutine} from 'src/app/core/model/project';
import {CurrentSkedTask} from 'src/app/core/model/task';
import {Tracker} from 'src/app/core/model/tracker';
import {User} from 'src/app/core/model/user';
import {GetProjectViewAction, UpdateProjectViewTaskId} from 'src/app/core/store/projects/projects.action';
import {selectProjectViewTaskId} from 'src/app/core/store/projects/projects.selector';
import {CreateNewTrackerAction, UpdateTempTrackerProjectIdAction} from 'src/app/core/store/trackers/trackers.action';
import {CurrentSkedTaskDetailModule} from 'src/app/current-sked/task-detail/current-sked-task-detail.module';
import {MessageService} from 'src/app/services/message.service';
import {TimezoneService} from 'src/app/services/timezone.service';
import {OphNewButtonComponent} from 'src/app/shared/design/oph-new-button/oph-new-button.component';
import {ProjectViewActivityItemComponent} from './activity-item/project-view-activity-item.component';
import {ProjectViewItemHeaderComponent} from './item-header/project-view-item-header.component';
import {ProjectViewLineItemComponent} from './line-item/project-view-line-item.component';
import {ProjectViewMilestoneComponent} from './milestone/project-view-milestone.component';
import {ProjectViewActivityIconPipe} from './pipes/project-view-activity-icon.pipe';
import {ProjectViewEmptyMessagePipe} from './pipes/project-view-empty-message.pipe';
import {ProjectViewRoutineStatusColorPipe} from './pipes/project-view-routine-status-color.pipe';
import {ProjectViewProgressComponent} from './progress/project-view-progress.component';
import {ProjectViewRoutineComponent} from './routine/project-view-routine.component';
import {ProjectViewTaskComponent} from './task/project-view-task.component';
import {ProjectViewTaskRoutineComponent} from './task/task-routine/project-view-task-routine.component';
import {ProjectViewTrackerComponent} from './tracker/project-view-tracker.component';

interface FormattedTracker {
  mostRecentlyUpdatedMetricIndex: number;
  tracker: Tracker;
}

@Component({
  selector: 'project-view',
  standalone: true,
  imports: [
    ProjectViewItemHeaderComponent,
    ProjectViewProgressComponent,
    AsyncPipe,
    ProjectViewLineItemComponent,
    ProjectViewMilestoneComponent,
    ProjectViewTaskComponent,
    ProjectViewActivityItemComponent,
    ProjectViewRoutineStatusColorPipe,
    ProjectViewActivityIconPipe,
    ProjectViewEmptyMessagePipe,
    CurrentSkedTaskDetailModule,
    ProjectViewTaskRoutineComponent,
    ProjectViewRoutineComponent,
    ProjectViewTrackerComponent,
    MatExpansionModule,
    SlicePipe,
    OphNewButtonComponent,
  ],
  templateUrl: './project-view.component.html',
  styleUrl: './project-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectViewComponent implements OnChanges {
  @Input() project: ProjectView;
  @Input() usersMap: Record<string, User>;
  @Input() selectedRoutine: SelectedRoutine;

  @Output() selectedRoutineChange = new EventEmitter<SelectedRoutine>();

  taskId$: Observable<string> = this.store$.pipe(select(selectProjectViewTaskId));

  timeLeft$ = new BehaviorSubject<{percentage: number; display: string}>(null);
  progressText$ = new BehaviorSubject<string>('');
  formattedTrackers$ = new BehaviorSubject<FormattedTracker[]>(null);
  addNewButtonLoading$ = new BehaviorSubject<boolean>(false);
  showMoreButtonMap$ = new BehaviorSubject<Record<string, boolean>>({});

  taskTypesArray = [
    {label: 'Routines', name: 'routines', color: 'green'},
    {label: 'To Do', name: 'todoTasks', color: 'blue-2'},
    {label: 'In-progress', name: 'inProgressTasks', color: 'blue'},
    {label: 'On Hold', name: 'onHoldTasks', color: 'orange-2'},
    {label: 'Expired', name: 'expiredTasks', color: 'purple'},
    {label: 'Cancelled', name: 'cancelledTasks', color: 'yellow-2'},
    {label: 'Completed', name: 'completedTasks', color: 'gray'},
  ];
  numberOfTasksShown = 12;

  constructor(
    private timezoneService: TimezoneService,
    private store$: Store,
    private router: Router,
    private messageService: MessageService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.project && this.project) {
      this.initialize();
    }
  }

  initialize() {
    this.timeLeft$.next(this.getTimeLeft(this.project));
    this.progressText$.next(this.getProgressText(this.project));
    this.formattedTrackers$.next(this.formatTrackers());
    this.showMoreButtonMap$.next(this.getShowMoreButtonMap(this.project));
  }

  getShowMoreButtonMap(project: ProjectView): Record<string, boolean> {
    const showMoreButtonMap = {};
    this.taskTypesArray.forEach(taskType => {
      showMoreButtonMap[taskType.name] = true;
    });
    return showMoreButtonMap;
  }

  formatTrackers(): FormattedTracker[] {
    if (!this.project.trackers?.length) {
      return [];
    }

    return this.project.trackers.map(tracker => {
      // Find the most recently updated metric for display purposes
      const mostRecentlyUpdatedMetricIndex = tracker.metrics.reduce((latestIndex, metric, index, metrics) => {
        const currentMoment = moment(metric.lastUpdatedAt);
        const latestMoment = moment(metrics[latestIndex].lastUpdatedAt);
        return currentMoment.isAfter(latestMoment) ? index : latestIndex;
      }, 0);

      return {
        mostRecentlyUpdatedMetricIndex,
        tracker,
      };
    });
  }

  getTimeLeft(project: ProjectView): {percentage: number; display: string} {
    if (project.status === 'inactive') {
      return {percentage: 0, display: 'Project is in draft mode'};
    }
    const timezone = this.timezoneService.getTimeZone();
    const startTime = moment.tz(project.startDateTime, timezone);
    const endTime = moment.tz(project.endDateTime, timezone);
    const currentTime = moment.tz(timezone);

    const totalDuration = moment.duration(endTime.diff(startTime));
    const elapsedTime = moment.duration(currentTime.diff(startTime));
    const remainingTime = moment.duration(endTime.diff(currentTime));

    let percentage = 100 - Math.round((elapsedTime.asMilliseconds() / totalDuration.asMilliseconds()) * 100);
    if (percentage < 0) {
      percentage = 0;
    }
    if (percentage > 100) {
      percentage = 100;
    }
    const expiredDisplay = percentage === 0 ? 'Expired' : 'Expires';
    const display = `${expiredDisplay} ${remainingTime.humanize(true)}`;

    return {percentage, display};
  }

  getProgressText(project: ProjectView): string {
    if (!project.progress?.tasksRemaining) {
      return 'This project has no tasks';
    }

    if (project.progress.tasksRemaining === 1) {
      return '1 Task remains';
    } else {
      return `${project.progress.tasksRemaining} Tasks remain`;
    }
  }

  onTaskClick(task: CurrentSkedTask, taskTypeName: string) {
    // Task is a routine if it has a batchId
    if (task.batchId) {
      this.selectedRoutineChange.emit({
        label: task.label,
        icon: task.icon,
        taskInstanceId: task.taskInstanceId,
        priority: task.priority,
        status: taskTypeName,
      });
      return;
    }
    this.store$.dispatch(new UpdateProjectViewTaskId({id: task._id}));
  }

  onTaskBack() {
    this.getProjectView(this.project._id);
    // if (this.selectedRoutine) {
    //   this.scrollService.setScrollPosition(0);
    // } else {
    //   this.scrollPosition$.pipe(take(1)).subscribe(position => {
    //     timer(0).subscribe(() => {
    //       this.scrollService.setScrollPosition(position);
    //     });
    //   });
    // }
    this.store$.dispatch(new UpdateProjectViewTaskId({id: null}));
  }

  getProjectView(projectId: string) {
    this.store$.dispatch(
      new GetProjectViewAction({
        projectId,
      })
    );
  }

  onRoutineClick(routine: ProjectViewRoutine) {
    // this.scrollPosition$.next(this.scrollService.getCurrentScrollPosition());
    this.selectedRoutineChange.emit({
      label: routine.task.label,
      icon: routine.task.icon,
      taskInstanceId: routine.task.taskInstanceId,
      priority: routine.task.priority,
    });
  }

  onRoutineBack() {
    // this.scrollPosition$.pipe(take(1)).subscribe(position => {
    //   timer(0).subscribe(() => {
    //     this.scrollService.setScrollPosition(position);
    //   });
    // });
    this.selectedRoutineChange.emit(null);
  }

  onAddTracker() {
    this.addNewButtonLoading$.next(true);
    this.store$.dispatch(
      new CreateNewTrackerAction({
        onSuccess: tracker => this.onCreateTrackerSuccess(tracker),
        onFailure: err => this.onCreateTrackerFailure(err),
      })
    );
  }

  onCreateTrackerSuccess(tracker: Tracker) {
    if (tracker?._id) {
      this.store$.dispatch(new UpdateTempTrackerProjectIdAction({projectId: this.project._id}));
      this.router.navigate(['trackers', tracker._id, {returnTo: this.router.url}]);
    }
    this.addNewButtonLoading$.next(false);
  }

  onCreateTrackerFailure(err: Error) {
    this.addNewButtonLoading$.next(false);
    this.messageService.add(err.message || 'There was a problem creating the tracker');
  }

  onToggleShowMore(name: string) {
    const showMoreMap = {...this.showMoreButtonMap$.value, [name]: !this.showMoreButtonMap$.value[name]};
    this.showMoreButtonMap$.next(showMoreMap);
  }
}
