@if ({sortField: sortField$ | async, loadingIndex: loadingIndex$ | async}; as state) {
  @if (data?.length) {
    <div class="table">
      <!-- ----------------------------HEADER-------------------------- -->
      <div class="table-header">
        @for (column of columns; track column; let i = $index) {
          <div class="header-cell" [style.width]="column.width">
            <button
              (click)="onSort(column.sortName || column.name, i)"
              [disabled]="state.loadingIndex === i || !column.sortName"
            >
              {{ column.name }}
              @if (column.sortName) {
                <div class="sort-img-container">
                  @if (state.loadingIndex === i) {
                    <oph-loading diameter="17"></oph-loading>
                  } @else if (
                    (state.sortField && state.sortField === column.sortName) || state.sortField === column.name
                  ) {
                    <img src="assets/img/icons/caret-down-green.svg" />
                  } @else {
                    <img src="assets/img/icons/caret-down-brown.svg" />
                  }
                </div>
              }
            </button>
          </div>
        }
      </div>

      <!-- ----------------------------ROWS-------------------------- -->
      @for (row of data; track data; let index = $index) {
        <div class="row" (click)="onRow(index)">
          @for (column of columns; track column) {
            <div
              class="cell"
              [style.width]="column.width"
              [class.bold]="column.bold"
              [class.no-right-padding]="column?.type === 'projects' || column?.type === 'menu'"
            >
              <div class="cell-content">
                <!-- ----------DATE CELL----------- -->
                @if (column?.type === 'date') {
                  {{ row[column.name] | convertUtcToTimezone: 'MMM DD YYYY' }}&nbsp;&nbsp;&nbsp;
                  {{ row[column.name] | convertUtcToTimezone: 'hh:mm a' }}
                } @else if (column?.type === 'projects') {
                  <!-- ----------PROJECTS CELL----------- -->
                  <list-views-project-display [projects]="row[column.name]"></list-views-project-display>
                } @else if (column?.type === 'menu') {
                  <!-- ----------MENU CELL----------- -->
                  <list-view-menu [options]="menuOptions" (action)="menuAction.emit($event)"></list-view-menu>
                } @else {
                  {{ row[column.name] }}
                }
              </div>
            </div>
          }
        </div>
      }
    </div>
  } @else {
    <div class="empty">No results were found.</div>
  }
}
