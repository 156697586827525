import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'twd-task-or-event',
  templateUrl: './twd-task-or-event.component.html',
  styleUrls: ['./twd-task-or-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwdTaskOrEventComponent {
  @Input() control: FormControl;

  onClick(value: boolean) {
    this.control.setValue(value);
  }
}
