<div class="container" [class.focused]="focused" [style.border]="hideBorder ? 'none' : '1px solid #e5e3dc'">
  <mat-calendar
    class="task-calendar"
    [headerComponent]="headerComponent"
    [startAt]="control.value || today"
    [selected]="control.value"
    [class.disabled]="disabled"
    (selectedChange)="onDateChange($event)"
  ></mat-calendar>
</div>
