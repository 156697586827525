<button
  [matMenuTriggerFor]="typeMenu"
  class="select-button"
  (click)="onMenu(true)"
  [class.focused]="menuOpen"
  [class.disabled]="disabled"
  [ngStyle]="buttonStyle"
  [disabled]="disabled"
  [class.center-button-content]="!!loadingStatus"
>
  @if (loadingStatus === 'loading') {
    <mat-spinner class="custom-color-brown" diameter="20"></mat-spinner>
  } @else if (loadingStatus === 'success') {
    <mat-icon svgIcon="check"></mat-icon>
  } @else {
    <span>{{ buttonText }}</span>
    <oph-icon name="caret-down-brown" size="10"></oph-icon>
  }
</button>
<mat-menu #typeMenu="matMenu" class="oph-select-menu" (closed)="onMenu(false)">
  @if (options?.length) {
    @for (option of options; track option) {
      <button class="oph-select-menu-item" (click)="optionChange.emit(option)">{{ option }}</button>
    }
  } @else {
    <div class="no-options">There are no options</div>
  }
</mat-menu>
