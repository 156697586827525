import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {RewardCoin} from 'src/app/core/model/reward';
import {NumberAbbreviationPipe} from 'src/app/shared/pipes/number-abbreviation.pipe';
import {PipesModule} from 'src/app/shared/pipes/pipes.module';
import {RewardCoinIconPipe} from 'src/app/shared/pipes/reward-coin-icon.pipe';

@Component({
  selector: 'rewards-coin-expansion-panel',
  standalone: true,
  imports: [MatExpansionModule, MatIconModule, PipesModule, RewardCoinIconPipe, NumberAbbreviationPipe],
  templateUrl: './rewards-coin-expansion-panel.component.html',
  styleUrl: './rewards-coin-expansion-panel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RewardsCoinExpansionPanelComponent {
  @Input() rewardCoin: RewardCoin;
  @Input() expanded: boolean;
  @Input() userCoinCount: number;
  @Input() disabled: boolean;

  @Output() expandedChange = new EventEmitter<boolean>();

  onTypeToggle(open: boolean) {
    this.expandedChange.emit(open);
  }
}
