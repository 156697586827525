@if (
  {
    selectedTracker: selectedTracker$ | async,
    selectedMetric: selectedMetric$ | async,
    filteredMetrics: filteredMetrics$ | async
  };
  as state
) {
  <div
    class="tracker-container"
    [class.x-button-width]="state.selectedTracker"
    [class.disabled]="disabled"
    [matTooltip]="disabled ? 'You cannot add a Tracker to a template.' : null"
  >
    @if (!state.selectedTracker) {
      <oph-autocomplete
        placeholder="Connect a tracker"
        [options]="autocompleteOptions$ | async"
        [style]="{border: 'none', background: 'transparent'}"
        [inputDisabled]="disabled"
        (optionChange)="onTracker($event)"
      ></oph-autocomplete>
    } @else {
      <div class="tracker-name-container">
        <span>{{ state.selectedTracker.name }}</span>
      </div>
    }
  </div>

  @if (state.selectedTracker) {
    <button class="select-button" [matMenuTriggerFor]="metricMenu" [class.x-button-width]="state.selectedTracker">
      <span>{{ state.selectedMetric?.name || 'Select metric' }}</span>
      <img src="assets/img/icons/chevron-down-brown.svg" />
    </button>
    <mat-menu #metricMenu="matMenu" class="oph-select-menu">
      @for (metric of state.filteredMetrics; track metric) {
        @if (metric.name) {
          <button (click)="onMetric(metric)" class="oph-select-menu-item">
            <span>{{ metric.name || '' }}</span>
          </button>
        }
      }
      @if (!state.filteredMetrics?.length) {
        <div class="empty">This tracker has no metrics.</div>
      }
    </mat-menu>

    <div class="x-button-container">
      <oph-x-button (click)="onClearObject()"></oph-x-button>
    </div>
  }
}
