import {UsersAction, UsersActionType} from './users.action';
import {UsersState, initialUsersState} from './users.state';

export function usersReducer(state: UsersState = initialUsersState, action: UsersAction): UsersState {
  switch (action.type) {
    case UsersActionType.GET_ALL_SUCCESS:
      return {...state, users: action.payload.users};
    case UsersActionType.CLEAR:
      return {...state, users: null};
    default:
      return state;
  }
}
