<mat-expansion-panel
  hideToggle
  (opened)="onTracker(true)"
  (closed)="onTracker(false)"
  [disabled]="!tracker.metrics?.length"
>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <div class="header-container">
        <div class="title-container">
          <project-view-icon-container icon="tracker-orange"></project-view-icon-container>
          <span class="tracker-name ellipsis">{{ tracker.name }}</span>
        </div>
        <div class="header-item header-label-container" [class.hidden]="expanded">
          <span class="header-value-label">{{ tracker.metrics[mostRecentlyUpdatedMetricIndex]?.name }}</span>
          @if (tracker.metrics?.length) {
            <span>
              @if (tracker.metrics[mostRecentlyUpdatedMetricIndex]?.type === trackerMetricTypes.Boolean) {
                <span class="text bold-black">{{
                  tracker.metrics[mostRecentlyUpdatedMetricIndex]?.value | numberToBoolean
                }}</span>
              } @else {
                <span class="text bold-black">{{
                  tracker.metrics[mostRecentlyUpdatedMetricIndex]?.value || '(No value)'
                }}</span>
              }
              <span class="uom black">{{ tracker.metrics[mostRecentlyUpdatedMetricIndex]?.uom || '' }}</span>
            </span>
          }
        </div>
        <div class="header-item updated-container">
          @if (tracker.metrics[mostRecentlyUpdatedMetricIndex]) {
            <span class="subtext">upd</span>
            <span class="text bold-brown">{{
              tracker.metrics[mostRecentlyUpdatedMetricIndex]?.lastUpdatedAt | convertUtcToTimezone: 'MMM D  h:mma'
            }}</span>
          }
        </div>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="tracker-metrics-container">
    @for (metric of tracker.metrics; track metric) {
      <div class="metric" [class.even]="evenMetricCount" [class.odd]="!evenMetricCount">
        <oph-icon name="caret-right-orange" size="8"></oph-icon>
        <span class="black ellipsis metric-name-container">{{ metric.name }}</span>
        <span class="value-container ellipsis">
          @if (metric.type === trackerMetricTypes.Boolean) {
            <span class="bold-black">{{ metric.value | numberToBoolean }}</span>
          } @else {
            <span class="bold-black">{{ metric.value }}</span>
          }

          <span class="black uom">{{ metric.uom || '' }}</span>
        </span>
      </div>
    }
  </div>
</mat-expansion-panel>
