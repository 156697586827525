import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Store, select} from '@ngrx/store';
import {Observable} from 'rxjs';
import {selectRouterUrl} from '../../../core/store/router/router.selector';

@Component({
  selector: 'desktop-layout',
  templateUrl: './desktop-layout.component.html',
  styleUrls: ['./desktop-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesktopLayoutComponent implements OnInit {
  routerUrl$: Observable<string>;

  public username: string;

  constructor(private store$: Store) {}

  ngOnInit() {
    this.routerUrl$ = this.store$.pipe(select(selectRouterUrl));
    this.username = JSON.parse(localStorage.getItem('currentUser')).username;
  }
}
