<oph-input
  fontSize="16"
  [control]="controls?.get('title')"
  placeholder="Subtask title*"
  height="40"
  autoFocusInput="true"
></oph-input>

<div class="editor-container" #editorContainer>
  <quill-editor
    #editor
    placeholder="Instructions"
    [modules]="quillConfig"
    [bounds]="'self'"
    [styles]="quillStyles"
    [formControl]="controls?.get('body')"
    (onEditorCreated)="onEditorCreated($event)"
    (click)="onQuillClick($event)"
    scrollingContainer="true"
    [class.task-dialog-quill-editor]="taskDialog"
    [class.task-wizard-quill-editor]="!taskDialog"
  >
    <div quill-editor-toolbar class="quill-toolbar">
      <button class="ql-header h1-icon" value="1"></button>
      <button class="ql-header h2-icon" value="2"></button>
      <button class="ql-bold bold-icon"></button>
      <button class="ql-italic italic-icon"></button>
      <button class="ql-underline underline-icon"></button>
      <button class="ql-list list-icon" value="bullet"></button>
      <button class="ql-link link-icon"></button>
      <button class="ql-image image-icon"></button>
      <button class="ql-clean clean-icon"></button>
    </div>
  </quill-editor>

  <div class="input-container" [ngStyle]="inputContainerStyle$ | async" #inputContainer>
    <input type="text" placeholder="Enter caption" #captionInput />
    <button class="input-save-button" (click)="onCaptionSave()">Save</button>
  </div>

  <!-- <subtask-edit-attribute-select
    [controls]="controls"
    [taskDialog]="taskDialog"
    (isSubtaskComplete)="isSubtaskComplete.emit($event)"
  ></subtask-edit-attribute-select> -->

  <subtask-edit-metric-selection
    [subtaskFormControl]="controls"
    [disabled]="fromTemplate"
    (isSubtaskComplete)="isSubtaskComplete.emit($event)"
  ></subtask-edit-metric-selection>
</div>
