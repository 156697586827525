import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'projectViewActivityAction',
  standalone: true,
})
export class ProjectViewActivityActionPipe implements PipeTransform {
  transform(value: string): string {
    if (value === 'task put on hold') {
      return 'task on hold';
    }
    return value;
  }
}
