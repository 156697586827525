import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OphRadioButtonComponent} from './oph-radio-button.component';

@NgModule({
  declarations: [OphRadioButtonComponent],
  imports: [CommonModule],
  exports: [OphRadioButtonComponent],
})
export class OphRadioButtonModule {}
