import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'create-first',
  templateUrl: './create-first.component.html',
  styleUrls: ['./create-first.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateFirstComponent {
  @Input() type: string;

  @Output() buttonClick = new EventEmitter();
}
