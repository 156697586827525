import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Store, select} from '@ngrx/store';
import {BehaviorSubject, Observable} from 'rxjs';
import {first} from 'rxjs/operators';
import {UserApiService} from 'src/app/core/api/user-api.service';
import {SetActiveUser} from 'src/app/core/store/active-user/active-user.action';
import {TitleService} from '../../core/page/title.service';
import {AuthenticationService} from '../auth.service';
import {selectRouterQueryParam} from 'src/app/core/store/router/router.selector';
import {emailOrAdminValidator} from './email-or-admin-validator';
import {MatDialog} from '@angular/material/dialog';
import {PrivacyPolicyDialogComponent} from 'src/app/shared/dialog/privacy-policy-dialog/privacy-policy-dialog.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  errorMsg: string;
  emailSentMessage: string;

  view$: Observable<'forgotPassword' | 'emailSent'>;

  public passwordInputType$ = new BehaviorSubject<string>('password');

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: TitleService,
    private userApiService: UserApiService,
    private store$: Store,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.titleService.setPageTitle('Login');
    this.view$ = this.store$.pipe(select(selectRouterQueryParam('view')));

    this.loginForm = this.formBuilder.group({
      email: ['', emailOrAdminValidator()],
      password: [''],
      requestEmail: ['', Validators.email],
    });
    // reset login status
    this.authenticationService.logout(true);
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/current-sked';
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  handleKeyDown(event: KeyboardEvent, view: string) {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (!view) {
        this.onGo(view);
      } else if (view === 'forgotPassword') {
        this.onRequestPasswordReset(view);
      } else {
        this.changeView(null);
      }
    }
  }
  changeView(view: null | 'forgotPassword' | 'emailSent') {
    this.errorMsg = '';
    this.loading = false;
    this.router.navigate([], {queryParams: {view}, relativeTo: this.activatedRoute});
  }

  onGo(view: string | null) {
    this.submitted = true;
    if (!view && !this.f.email.invalid) {
      this.errorMsg = 'Valid email is required';
    }
    // stop here if form is invalid
    if (!view && (!this.f.email.value || !this.f.password.value)) {
      this.errorMsg = 'Email and password must be filled in';
      return;
    }

    this.errorMsg = '';
    this.loading = true;
    this.authenticationService
      .login(this.f.email.value.toLowerCase(), this.f.password.value)
      .pipe(first())
      .subscribe({
        next: data => {
          this.userApiService.getAll().subscribe(users => {
            const user = users.find(u => u.email === data.email);
            if (user) {
              this.store$.dispatch(new SetActiveUser({user}));
              this.router.navigateByUrl(this.returnUrl);
            }
          });
        },
        error: error => {
          if (error === 'Connection to the Database lost') {
            this.errorMsg = error;
          } else {
            this.errorMsg = 'Incorrect email/password. Please try again.';
          }
          this.loading = false;
        },
      });
  }

  public onTogglePassword(event: MouseEvent) {
    event.preventDefault();
    const type = this.passwordInputType$.value === 'password' ? 'text' : 'password';
    this.passwordInputType$.next(type);
  }

  onRequestPasswordReset(view: string) {
    if (view === 'forgotPassword' && (!this.f.requestEmail.value || this.f.requestEmail.invalid)) {
      this.errorMsg = 'You must enter a valid email address';
      return;
    }
    this.errorMsg = '';
    this.loading = true;
    const url = `${window.location.protocol}//${window.location.host}/reset-password`;
    this.authenticationService
      .requestPasswordReset(this.f.requestEmail.value, url)
      .pipe(first())
      .subscribe({
        next: data => {
          this.loginForm.patchValue({requestEmail: ''});
          this.emailSentMessage = data;
          this.router.navigate([], {queryParams: {view: 'emailSent'}, relativeTo: this.activatedRoute});
        },
        error: error => {
          this.errorMsg = error || 'There was a problem.';
          this.loading = false;
        },
      });
  }

  onPrivacyPolicy() {
    this.dialog.open(PrivacyPolicyDialogComponent);
  }
}
