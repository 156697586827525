@if ({durationDisplay: durationDisplay$ | async}; as state) {
  <div class="desktop">
    @if (!durationDisplayType) {
      <button [matMenuTriggerFor]="menu" class="duration-button">
        {{ state.durationDisplay }}
      </button>
    }
    @if (durationDisplayType === 'manualTask') {
      <button
        [matMenuTriggerFor]="menu"
        [class.white-background]="menuOpen"
        class="duration-button manual-task-duration-button"
        (click)="menuOpen = true"
      >
        <span class="label">Duration</span>
        {{ state.durationDisplay }}
      </button>
    }
    @if (durationDisplayType === 'taskWizard') {
      <button [matMenuTriggerFor]="menu" class="duration-button task-wizard-button">
        {{ state.durationDisplay }}
      </button>
    }
  </div>
  <div class="mobile">
    <button [matMenuTriggerFor]="menu" class="duration-button mobile-button">
      <span class="label">Duration</span>
      {{ state.durationDisplay }}
    </button>
  </div>
  <mat-menu #menu="matMenu" xPosition="before" class="task-slider-menu" (closed)="menuOpen = false">
    <div class="slider-container">
      <mat-slider min="0" [max]="durationOptions$.value.length - 1" step="1" showTickMarks>
        <input (input)="onSliderChange($event.target.value)" matSliderThumb [value]="sliderValue$ | async" />
      </mat-slider>
    </div>
  </mat-menu>
}
