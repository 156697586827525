import {Pipe, PipeTransform} from '@angular/core';
import {ProjectViewActivityAction} from '../project-view-activity-action';

@Pipe({
  name: 'projectViewActivityIcon',
  standalone: true,
})
export class ProjectViewActivityIconPipe implements PipeTransform {
  transform(action: ProjectViewActivityAction | string): string {
    switch (action) {
      case ProjectViewActivityAction.Start.toLowerCase():
        return 'play-circle-blue';
      case ProjectViewActivityAction.OnHold.toLowerCase():
      case 'task put on hold':
        return 'pause-circle-orange';
      case ProjectViewActivityAction.Completed.toLowerCase():
        return 'check-square-green';
      case ProjectViewActivityAction.Cancelled.toLowerCase():
        return 'slash-yellow';
      // case ProjectViewActivityAction.AttributeUpdated:
      //   return 'bolt-outline-orange';
      case ProjectViewActivityAction.TaskTriggered.toLowerCase():
        return 'bolt-outline-orange';
      case ProjectViewActivityAction.Expired.toLowerCase():
        return 'hour-glass-purple';
      default:
        return 'square-outline-gray';
    }
  }
}
