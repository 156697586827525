import {initialSkillsState, SkillsState} from './skills.state';
import {SkillsAction, SkillsActionType} from './skills.action';
import {SkillModel} from '../../model/skill';

export function skillsReducer(state: SkillsState = initialSkillsState, action: SkillsAction): SkillsState {
  switch (action.type) {
    case SkillsActionType.ADD_EMPTY_SKILL: {
      const emptySkill: SkillModel = {
        name: null,
        icon: null,
        description: null,
        type: null,
        _id: 'new',
      };

      return {
        ...state,
        skillsList: [emptySkill, ...state.skillsList],
        selectedSkill: emptySkill,
      };
    }
    case SkillsActionType.FETCH_SKILLS_SUCCESS:
      return {
        ...state,
        skillsList: action.payload.skills,
      };
    case SkillsActionType.GET_SINGLE_SKILL_SUCCESS:
      return {
        ...state,
        selectedSkill: action.payload.skill,
      };
    case SkillsActionType.RESET_SKILL_DETAILS:
      return {
        ...state,
        selectedSkill: null,
        skillsList: [],
      };
    default:
      return state;
  }
}
