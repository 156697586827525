<div class="back-button-container">
  <button class="back-button" (click)="back.emit()">
    <img src="assets/img/icons/chevron-left-brown-2.svg" />
    BACK
  </button>
</div>

<div class="subtask-edit-container">
  <subtask-edit [controls]="formArray.at(subtaskIndex)" (imageAdded)="imageAdded.emit($event)"> </subtask-edit>
</div>
