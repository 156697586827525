import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'rewardCoinIcon',
  standalone: true,
})
export class RewardCoinIconPipe implements PipeTransform {
  transform(coinName: string): string {
    if (!coinName) {
      coinName = 'silver';
    }
    return 'coin-' + coinName.toLowerCase().split(' ')[0];
  }
}
