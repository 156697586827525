export const TIME_PICKER_OPTIONS = {
  incrementMinutes: 5,
  cancelLabel: 'Cancel',
  animation: false,
  backdrop: false,
  // appendModalSelector: '.task-timepicker',
  delayHandler: 1,
  amLabel: 'am',
  pmLabel: 'pm',
  enableScrollbar: true,
};

export const TASK_REPEAT_TYPE_OPTIONS = ['hours', 'days', 'weeks'];
