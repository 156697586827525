<div class="border-container">
  <div class="progress-container">
    <mat-progress-spinner
      class="progress"
      color="#fff"
      mode="determinate"
      [value]="percentComplete"
      [diameter]="diameter"
      [strokeWidth]="strokeWidth"
    >
    </mat-progress-spinner>
    <span class="percent-complete">{{ percentComplete }}%</span>
    <div
      class="inner-circle"
      [style.width.px]="diameter - strokeWidth - 7"
      [style.height.px]="diameter - strokeWidth - 7"
    ></div>
  </div>
</div>
