import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Tracker} from 'src/app/core/model/tracker';
import {PipesModule} from 'src/app/shared/pipes/pipes.module';
import {ListViewsModule} from '../../../shared/list-views/list-views.module';
import {ListViewsProjectDisplayComponent} from 'src/app/shared/list-views/shared/project-display/list-views-project-display.component';

@Component({
  selector: 'trackers-list-grid-item',
  standalone: true,
  imports: [PipesModule, ListViewsModule, ListViewsProjectDisplayComponent],
  templateUrl: './trackers-list-grid-item.component.html',
  styleUrl: './trackers-list-grid-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackersListGridItemComponent {
  @Input() tracker: Tracker;

  @Output() trackerClick = new EventEmitter();
}
