import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {timer} from 'rxjs';

@Component({
  selector: 'oph-input-inside-label',
  templateUrl: './oph-input-inside-label.component.html',
  styleUrls: ['./oph-input-inside-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OphInputInsideLabelComponent implements AfterViewInit {
  @Input() set value(value: string) {
    this.formInput.setValue(value);
  }
  @Input() set control(control: FormControl | null) {
    this.internalControl = control || this.formInput;
  }
  @Input() invalid: boolean;
  @Input() label: string;
  @Input() inputType: string;
  @Input() fontSize: number;
  @Input() width: number;
  @Input() paddingRight: number;
  @Input() defaultFocus: boolean;
  @Input() borderColorHex: string;
  @Input() labelColorHex: string;
  @Input() valueColorHex: string;
  @Input() backgroundColorHex: string;
  @Input() disabled: boolean = false;

  @Output() valueChange = new EventEmitter<string>();
  @Output() inputFocus = new EventEmitter<boolean>();

  @ViewChild('input') input: ElementRef;

  formInput = new FormControl('');
  inputFocusClass: boolean;
  internalControl = this.formInput;

  ngAfterViewInit() {
    if (this.defaultFocus) {
      timer(500).subscribe(() => this.input.nativeElement.focus());
    }
  }

  onInput(value: string) {
    this.valueChange.emit(value);
  }

  onFocus() {
    this.inputFocusClass = true;
    this.inputFocus.emit(true);
  }
}
