import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'task-dialog-footer',
  templateUrl: './task-dialog-footer.component.html',
  styleUrls: ['./task-dialog-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskDialogFooterComponent {
  @Input() loading: boolean;
  @Input() hideMenu: boolean;
  @Input() template: boolean;

  @Output() cancel = new EventEmitter();
  @Output() save = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() duplicate = new EventEmitter();
  @Output() createTemplate = new EventEmitter();

  menuOptions = ['create template', 'duplicate', 'delete'];

  ngOnChanges(changes: SimpleChanges) {
    if (changes.template) {
      this.menuOptions = this.template ? ['delete'] : ['create template', 'duplicate', 'delete'];
    }
  }

  onMenu(type: string) {
    if (type === 'delete') {
      this.delete.emit();
    }
    if (type === 'create template') {
      this.createTemplate.emit();
    }
    if (type === 'duplicate') {
      this.duplicate.emit();
    }
  }
}
