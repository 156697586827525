import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';
import {BehaviorSubject, Observable, debounceTime, map} from 'rxjs';
import {TASK_ICON_PICKER_ICONS, TaskIcon, TaskIconItem} from './task-icon-picker-icons';

@Component({
  selector: 'task-icon-picker',
  templateUrl: './task-icon-picker.component.html',
  styleUrls: ['./task-icon-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskIconPickerComponent {
  @Output() iconChange = new EventEmitter<string>();

  @ViewChild('input') input: ElementRef;

  searchValue$ = new BehaviorSubject<string>('');
  noIconsFound$ = new BehaviorSubject<boolean>(false);

  filteredIcons$: Observable<Record<string, TaskIcon>>;

  iconItems: TaskIconItem[] = TASK_ICON_PICKER_ICONS;
  keywordMap = {};
  displayedCategories: string[];

  ngOnInit() {
    this.preprocessData();
    this.filteredIcons$ = this.observeSearch();
    this.displayedCategories = this.iconItems.map(item => item.name);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.input?.nativeElement.focus();
    }, 1);
  }

  preprocessData() {
    this.iconItems.forEach((category, categoryIndex) => {
      category.icons.forEach(icon => {
        icon.keywords.forEach(keyword => {
          keyword = keyword.toLowerCase();
          const formattedIcon = {...icon, categoryIndex, categoryName: category.name};
          if (!this.keywordMap[keyword]) {
            this.keywordMap[keyword] = [formattedIcon];
          } else {
            this.keywordMap[keyword].push(formattedIcon);
          }
        });
      });
    });
  }

  observeSearch(): Observable<Record<string, TaskIcon>> {
    return this.searchValue$.pipe(
      debounceTime(100),
      map(search => {
        search = search.toLowerCase();
        const obj = {};
        Object.keys(this.keywordMap).forEach(key => {
          if (key.startsWith(search)) {
            const icons: TaskIcon[] = this.keywordMap[key];
            icons.forEach(icon => {
              if (obj[icon.categoryName]) {
                if (obj[icon.categoryName].icons.some(i => icon.name === i.name)) {
                  return;
                }
                obj[icon.categoryName].icons.push(icon);
              } else {
                obj[icon.categoryName] = {name: icon.categoryName, icons: [icon]};
              }
            });
          }
        });
        this.noIconsFound$.next(!Object.keys(obj).length);
        return obj;
      })
    );
  }

  onSearch(value: string) {
    this.searchValue$.next(value);
  }

  onSelectIcon(name: string) {
    this.iconChange.emit(name);
  }

  trackByCategory(index: number, category: string): any {
    return category;
  }

  trackByIcon(index: number, icon: TaskIcon): any {
    return icon.name;
  }
}
