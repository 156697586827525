<div class="text-container">
  <span class="text-overflow">
    <strong>{{ activityItem.action | projectViewActivityAction | titlecase }}</strong>
    @if (user?.firstName && user.lastName) {
      <span> by {{ user.firstName | titlecase }} {{ user.lastName | titlecase }}</span>
    }
  </span>
  <span class="subtext text-overflow">{{ activityItem.details.taskName }}</span>
</div>

<span class="time-display">{{ activityItem.timestamp | timeAgo }}</span>
