import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OphColorPickerComponent} from './oph-color-picker.component';
import {MatIconModule} from '@angular/material/icon';
import {DialogLayoutModule} from '../../dialog/layout/dialog-layout.module';

@NgModule({
  declarations: [OphColorPickerComponent],
  imports: [CommonModule, MatIconModule, DialogLayoutModule],
  exports: [OphColorPickerComponent],
})
export class OphColorPickerModule {}
