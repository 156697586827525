import {Pipe, PipeTransform} from '@angular/core';
import {TimezoneService} from 'src/app/services/timezone.service';

@Pipe({
  name: 'daysUntilExpiration',
  standalone: true,
})
export class DaysUntilExpirationPipe implements PipeTransform {
  constructor(private timezoneService: TimezoneService) {}

  transform(dateString: string): number {
    return this.timezoneService.getDaysUntilExpiration(dateString);
  }
}
