<div
  [style.width.px]="size || defaultSize"
  [style.height.px]="size || defaultSize"
  [style.background]="user?.avatarUrl ? 'transparent' : background || '#7b61ff'"
  class="container"
>
  @if (user?.avatarUrl || imgUrl) {
    <img
      [src]="user?.avatarUrl || imgUrl"
      [style.width.px]="size || defaultSize"
      [style.height.px]="size || defaultSize"
    />
  } @else if (user?.firstName && user?.lastName) {
    <span>{{ user.firstName | userAvatar: 1 }}{{ user.lastName | userAvatar: 1 }}</span>
  } @else if (empty) {
    <span></span>
  } @else {
    <span>?</span>
  }
</div>
