<div class="desktop">
  <!-- This will eventually be the supertask option -->
  <div class="container"></div>

  <div class="container">
    @if (!hideMenu) {
      <list-view-menu [options]="menuOptions" (action)="onMenu($event)"></list-view-menu>
    }
    <oph-button
      color="brown"
      appearance="outline"
      [style]="{'border-width': '0.5px', 'border-radius': '8px'}"
      (click)="cancel.emit()"
      >Cancel</oph-button
    >
    <oph-button
      color="green"
      [style]="{'border-radius': '8px'}"
      [loading]="loading"
      [disabled]="loading"
      (click)="save.emit()"
      >Save</oph-button
    >
  </div>
</div>

<div class="mobile">
  @if (!hideMenu) {
    <list-view-menu [options]="menuOptions" (action)="onMenu($event)"></list-view-menu>
  }
  <button class="mobile-button cancel-button" (click)="cancel.emit()">Cancel</button>
  <button class="mobile-button save-button" (click)="save.emit()">
    <div class="save-button-inner-container">
      @if (loading) {
        <div class="oph-white-spinner">
          <mat-spinner [diameter]="24"></mat-spinner>
        </div>
      } @else {
        Save
      }
    </div>
  </button>
</div>
