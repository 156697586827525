<div class="scheduler-toolbar">
  <div class="toggle-btn">
    <div
      class="option"
      [class.selected-auto]="toggleOption === toggleOptions.Auto"
      (click)="changeOption(toggleOptions.Auto)"
    >
      <span>Auto</span>
    </div>
    <div
      class="option"
      [class.selected]="toggleOption === toggleOptions.Calendar"
      (click)="changeOption(toggleOptions.Calendar)"
    >
      <span>Calendar</span>
    </div>
  </div>
  @if (toggleOption === toggleOptions.Calendar) {
    <div class="toggle-btn">
      @if (toggleOption === toggleOptions.Calendar) {
        <div class="week-month-selector">
          <mat-icon svgIcon="chevron-left" (click)="subtract()"></mat-icon>
          <span class="title">{{ displayStartDate() }}</span>
          <mat-icon svgIcon="chevron-right" (click)="add()"></mat-icon>
        </div>
      }
      <div
        class="option"
        [class.selected]="weekMonthOption === weekMonthOptions.Week"
        (click)="changeWeekMonth(weekMonthOptions.Week)"
      >
        <span>Week</span>
      </div>
      <div
        class="option"
        [class.selected]="weekMonthOption === weekMonthOptions.Month"
        (click)="changeWeekMonth(weekMonthOptions.Month)"
      >
        <span>Month</span>
      </div>
    </div>
  }

  <!-- This will be added back later -->
  <!-- <div class="user-team-select" [matMenuTriggerFor]="userTeamMenu">
  <div class="user-team-selected">
    <mat-icon svgIcon="user"></mat-icon>
    <span>{{ userTeamOption.label }}</span>
  </div>
  <mat-icon svgIcon="chevron-down"></mat-icon>
</div>
<mat-menu #userTeamMenu="matMenu" class="schedule-menu">
  <button mat-menu-item *ngFor="let menuOption of userTeamList" (click)="changeUserTeam(menuOption)">
    <span>{{ menuOption.label }}</span>
  </button>
</mat-menu> -->
</div>
