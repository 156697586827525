<button class="time-container-button date-button" (click)="buttonClick.emit()">
  <div class="button-inner">
    <div class="img-container">
      @if (iconType === 'date') {
        <img src="assets/img/icons/calendar-brown-2.svg" />
      }
      @if (iconType === 'time') {
        <img src="assets/img/icons/clock-brown.svg" />
      }
    </div>
    <ng-content></ng-content>
  </div>
  @if (showClear) {
    <button class="x-button" (click)="clear.emit($event)">
      <img class="red-x-icon" src="assets/img/icons/x-red.svg" />
    </button>
  }
</button>
