@if (
  {
    adminHistory: adminHistory$ | async,
    rewardHistoryExpandedMap: rewardHistoryExpandedMap$ | async,
    usersMap: usersMap$ | async,
    coinTypesMap: coinTypesMap$ | async,
    userHistory: userHistory$ | async,
    pagination: pagination$ | async
  };
  as state
) {
  @if (initialLoading$ | async) {
    <div class="loading-container">
      <oph-loading> </oph-loading>
    </div>
  } @else {
    @if ((userRole$ | async) === 'admin') {
      @if (!state.adminHistory?.length) {
        <div class="empty">There are no rewards to display.</div>
      } @else {
        <div class="coin-types-content oph-expansion-panel rewards-expansion-panel">
          @for (reward of state.adminHistory; track reward) {
            <mat-expansion-panel
              hideToggle
              [expanded]="state.rewardHistoryExpandedMap[reward.name]"
              [disabled]="!reward.assignedTo?.length"
              (opened)="onRewardHistoryExpandedMapChange(reward.name, true)"
              (closed)="onRewardHistoryExpandedMapChange(reward.name, false)"
            >
              <mat-expansion-panel-header>
                <rewards-reward-header-item [name]="reward.name" [description]="reward.description">
                  <div class="expired-container">
                    <span class="expired">Expired</span>
                    <span class="expired-date">{{ reward.expirationDate | moment: 'DDMMMYYYY' }}</span>
                  </div>

                  <rewards-user-count-chevron
                    class="ml-35"
                    [userCount]="reward.assignedTo?.length || 0"
                    [expanded]="state.rewardHistoryExpandedMap[reward.name]"
                  ></rewards-user-count-chevron>
                  <rewards-reward-cost
                    [coinName]="state.coinTypesMap[reward.coinType]?.name"
                    [rewardCost]="reward.rewardCost"
                  ></rewards-reward-cost>
                  <list-view-menu
                    [options]="['edit', 'delete']"
                    (action)="onMenuAction($event, reward)"
                  ></list-view-menu
                ></rewards-reward-header-item>
              </mat-expansion-panel-header>
              <!-- ----------------------------------------MANAGE EXPANSION PANEL CONTENT---------------------------------------- -->
              <div class="reward-content">
                @for (u of reward.assignedTo; track user) {
                  <reward-user-item [user]="state.usersMap[u.userId]">
                    @if (u.status === 'claimed' || u.status === 'fulfilled') {
                      <div class="reward-content-item-rc">
                        <span class="status-text mr-8">{{ u.status === 'fulfilled' ? 'FULFILLED' : 'CLAIMED' }}</span>
                        <span class="status-text">{{ reward.expirationDate | moment: 'DDMMMYYYY' }}</span>
                      </div>
                    }
                  </reward-user-item>
                }
              </div>
            </mat-expansion-panel>
          }
        </div>
      }
    } @else {
      @if (!state.userHistory?.length) {
        <div class="empty">There are no rewards to display.</div>
      } @else {
        @for (reward of state.userHistory; track reward) {
          <div class="user-history-item">
            <rewards-reward-header-item [name]="reward.name" [description]="reward.description">
              <div class="expired-container">
                <span class="expired">{{ reward.status | titlecase }}</span>
                @if (reward.status === 'fulfilled') {
                  <span class="expired-date no-mr">{{ reward.fulfilledAt | moment: 'DDMMMYYYY' }}</span>
                }
                @if (reward.status === 'unclaimed') {
                  <span class="expired-date no-mr">{{ reward.expirationDate | moment: 'DDMMMYYYY' }}</span>
                }
              </div>
              <rewards-reward-cost
                [coinName]="state.coinTypesMap[reward.coinType]?.name"
                [rewardCost]="reward.rewardCost"
                class="negative-ml-15"
              ></rewards-reward-cost>
            </rewards-reward-header-item>
          </div>
        }
      }
    }

    @if (state.pagination?.totalPages > 1) {
      <oph-paginator [page]="+state.pagination?.page" [totalPages]="state.pagination?.totalPages"></oph-paginator>
    }
  }
}
