import {Pipe, PipeTransform} from '@angular/core';
import {TimezoneService} from 'src/app/services/timezone.service';

@Pipe({
  name: 'isExpired',
  standalone: true,
})
export class IsExpiredPipe implements PipeTransform {
  constructor(private timezoneService: TimezoneService) {}

  transform(value: string, returnExpiredDate?: boolean): string | boolean {
    const expired = this.timezoneService.isDateExpired(value);

    if (returnExpiredDate) {
      return expired ? `exp. ${this.timezoneService.convertUtcToTimezone(value, 'MMM DD YYYY')}` : '';
    }
    return expired;
  }
}
