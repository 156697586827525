import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {User} from '../model/user';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  private url = `${environment.apiUrl}/users`;
  private imgUrl = `${environment.apiUrl}/imageUpload`;

  constructor(private http: HttpClient) {}

  getAll(): Observable<User[]> {
    return this.http.get<User[]>(this.url);
  }

  getActiveUser(email: string): Observable<User> {
    return this.http.get<User>(`${this.url}/userFromEmail/${encodeURIComponent(email)}`);
  }

  getUser(id: string): Observable<User[]> {
    // returns one user in array
    return this.http.get<User[]>(`${this.url}/${id}`);
  }

  createNewUser(user: User): Observable<unknown> {
    return this.http.post(`${this.url}/register`, user);
  }

  updateUser(user: User): Observable<unknown> {
    return this.http.put(`${this.url}/${user._id}`, user);
  }

  deleteUser(id: string): Observable<unknown> {
    return this.http.delete(`${this.url}/${id}`);
  }

  uploadImg(image: Object): Observable<string> {
    return this.http.post<string>(`${this.imgUrl}`, image);
  }

  deleteImg(id: string): Observable<unknown> {
    return this.http.delete(`${this.imgUrl}/${id}`);
  }
}
