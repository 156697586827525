import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {TeamDto} from '../../teams/shared/dto/team.dto';
import {Team} from '../../teams/shared/model/team';

@Injectable({
  providedIn: 'root',
})
export class TeamsApiService {
  public url = `${environment.apiUrl}/teams`;

  constructor(private http: HttpClient) {}

  public get(): Observable<TeamDto[]> {
    return this.http.get<TeamDto[]>(this.url);
  }

  public create(team: Partial<Team>): Observable<Team> {
    return this.http.post<Team>(`${this.url}`, team);
  }

  public update(team: Team): Observable<Team> {
    return this.http.put<Team>(`${this.url}/${team.id}`, team);
  }

  public delete(id: string): Observable<Object> {
    return this.http.delete(`${this.url}/${id}`);
  }
}
