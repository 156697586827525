import {CommonModule} from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {MatExpansionModule, MatExpansionPanel} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {BehaviorSubject} from 'rxjs';
import {CurrentSkedTask, Subtask} from 'src/app/core/model/task';
import {TrackerMetric} from 'src/app/core/model/tracker';
import {SharedCurrentSkedModule} from 'src/app/shared/current-sked/shared-current-sked.module';
import {PipesModule} from 'src/app/shared/pipes/pipes.module';
import {TrackerMetricValueUpdateComponent} from 'src/app/shared/trackers/tracker-metric-value-update/tracker-metric-value-update.component';
import {SubtaskStatusChange} from '../../shared/model/status-change';
import {TaskLoadingState} from '../../shared/model/task-loading-state';
import {TaskStatusActions} from '../../shared/task-status-actions';
import {TaskStatuses} from '../../shared/task-statuses';
import {CurrentSkedTaskDetailValueDisplayComponent} from '../value-display/current-sked-task-detail-value-display.component';
import {TrackerMetricTypes} from './../../../trackers/shared/tracker-metric-types';

@Component({
  selector: 'current-sked-task-detail-subtask',
  standalone: true,
  imports: [
    CommonModule,
    SharedCurrentSkedModule,
    ReactiveFormsModule,
    PipesModule,
    MatIconModule,
    TrackerMetricValueUpdateComponent,
    CurrentSkedTaskDetailValueDisplayComponent,
    MatExpansionModule,
  ],
  templateUrl: './current-sked-task-detail-subtask.component.html',
  styleUrl: './current-sked-task-detail-subtask.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentSkedTaskDetailSubtaskComponent implements OnChanges, AfterViewInit {
  @Input() task: CurrentSkedTask;
  @Input() subtask: Subtask;
  @Input() subtaskIndex: number;
  @Input() expandedSubtaskIndex: number;
  @Input() loading: TaskLoadingState;
  @Input() ableToCompleteSubtaskIndex: number;

  @Output() expandedIndexChange = new EventEmitter();

  @Output() subtaskClick = new EventEmitter();
  @Output() updateSubtaskStatus = new EventEmitter<boolean>();
  @Output() updateMetricValue = new EventEmitter<string>();
  @Output() updateTaskStatus = new EventEmitter<TaskStatusActions>();

  @ViewChild('panel') panel: MatExpansionPanel;

  metric$ = new BehaviorSubject<TrackerMetric>(null);
  pendingSubtaskUpdateData$ = new BehaviorSubject<SubtaskStatusChange>(null);

  TrackerMetricTypes = TrackerMetricTypes;
  TaskStatuses = TaskStatuses;
  subtaskValueControl = new FormControl('');

  ngOnChanges(changes: SimpleChanges) {
    if (changes.subtask && this.subtask) {
      this.metric$.next(this.subtask.tracker?.metric || null);
      this.subtaskValueControl.setValue(
        String(this.subtask.tracker?.metric?.value || this.subtask.tracker?.metric?.defaultValue || '')
      );
    }

    if (changes.expandedSubtaskIndex || changes.subtaskIndex) {
      this.checkPanels();
    }
  }

  ngAfterViewInit() {
    this.checkPanels();
  }

  checkPanels() {
    if (this.subtaskIndex === this.expandedSubtaskIndex) {
      this.panel?.open();
    } else {
      this.panel?.close();
    }
  }

  onSubtask(event: PointerEvent): void {
    event.stopPropagation();
    this.subtaskClick.emit();
  }

  onCheckboxClick(checked: boolean): void {
    this.updateSubtaskStatus.emit(checked);
  }

  onMetricToggle(event: PointerEvent): void {
    event.stopPropagation();
    this.expandedIndexChange.emit();
  }

  onUpdateMetricValue(value: string) {
    this.updateMetricValue.emit(value);
  }
}
