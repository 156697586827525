@if ({scheduleType: scheduleType$ | async}; as state) {
  @if (scheduleFormGroup) {
    <div class="schedule-container" [class.disabled]="scheduleTypeControl.value === 'doNotRepeat'">
      <div class="schedule-row">
        <div class="schedule-row-sub-row">
          <span class="text mr-20">Every</span>
          <button class="menu-button number-menu-button text" [matMenuTriggerFor]="numberMenu">
            {{ repeatEveryControl.value }}
          </button>
          <mat-menu #numberMenu="matMenu" class="oph-select-menu">
            @for (n of numberOptionsArr; track n; let i = $index) {
              <button class="oph-select-menu-item text" (click)="onNumber(i + 1)">
                {{ i + 1 }}
              </button>
            }
          </mat-menu>
          <button class="menu-button type-menu-button text" [matMenuTriggerFor]="typeMenu">
            {{ repeatTypeControl.value | titlecase }}
            <img src="assets/img/icons/chevron-down-brown.svg" />
          </button>
          <mat-menu #typeMenu="matMenu" class="oph-select-menu">
            @for (type of repeatTypesArr; track type) {
              <button class="oph-select-menu-item" (click)="onType(type)">
                <span>{{ type | titlecase }}</span>
              </button>
            }
          </mat-menu>
          @if (repeatTypeControl.value === 'week') {
            <span class="text mr-20">on</span>
          }
        </div>
        @if (repeatTypeControl.value === 'week') {
          <oph-week-selector
            [control]="repeatDaysControl"
            [disabledIndexArr]="disabledDays"
            [allInvalid]="noDaySelected"
            (valueChange)="clearExpiration()"
          ></oph-week-selector>
        }
      </div>
      <div class="schedule-row">
        <div class="schedule-row-sub-row">
          <span class="text desktop">Ends</span>
          <oph-radio-button
            (radioClick)="onRadioButton('never')"
            [selected]="endsTypeControl.value === 'date' || endsTypeControl.value === 'never'"
            class="mr-15 ml-15"
          ></oph-radio-button>
          <span class="text mobile mr-15">Ends on</span>
          <button
            class="date-button"
            (click)="onEndsDate()"
            [class.disabled]="endsTypeControl.value === 'occurrences' && scheduleTypeControl.value !== 'doNotRepeat'"
          >
            <div class="button-inner">
              <img src="assets/img/icons/calendar-brown-2.svg" />
              <span class="text">{{
                endsTypeControl.value !== 'date' ? 'Never' : (endsDate$.value | convertUtcToTimezone: 'M/DD/YY')
              }}</span>
            </div>
            @if (endsTypeControl.value === 'date') {
              <button class="x-button" (click)="onClear($event)">
                <img class="red-x-icon" src="assets/img/icons/x-red.svg" />
              </button>
            }
          </button>
        </div>
        <div class="schedule-row-sub-row mobile-ml-15">
          <oph-radio-button
            (radioClick)="onRadioButton('occurrences')"
            [selected]="endsTypeControl.value === 'occurrences'"
            class="mr-15"
          ></oph-radio-button>
          <div
            class="occurrences-container"
            [class.disabled]="endsTypeControl.value !== 'occurrences' && scheduleTypeControl.value !== 'doNotRepeat'"
          >
            <span class="text desktop">After</span>
            <span class="text mobile">Ends After</span>
            <input
              class="occurrences-input"
              type="number"
              min="0"
              (blur)="onOccurrencesBlur($event.target.value)"
              [formControl]="endsOccurrencesControl"
            />
            <span class="text">Occurrences</span>
          </div>
        </div>
      </div>
    </div>
  }
}
