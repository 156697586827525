import {DEFAULT_MY_REWARDS_EXPANDED_MAP} from 'src/app/rewards/shared/rewards-constants';
import {Pagination} from '../../model/pagination';
import {Reward, RewardAssignment, RewardCoin} from '../../model/reward';

export interface RewardsState {
  manageRewards: RewardCoin[];
  myRewards: RewardCoin[];
  adminHistory: Reward[];
  singleReward: Reward;
  userHistory: RewardAssignment[];
  coinTypes: RewardCoin[];
  coinTypesMap: Record<string, RewardCoin>;
  manageRewardsCoinExpandedMap: Record<string, boolean>;
  manageRewardsRewardExpandedMap: Record<string, boolean>;
  myRewardsExpandedMap: Record<string, boolean>;
  rewardHistoryExpandedMap: Record<string, boolean>;
  historyPagination: Pagination;
  headerLoading: boolean;
}

export const initialRewardsState: RewardsState = {
  manageRewards: null,
  myRewards: null,
  adminHistory: null,
  userHistory: null,
  singleReward: null,
  coinTypes: null,
  coinTypesMap: {},
  manageRewardsCoinExpandedMap: DEFAULT_MY_REWARDS_EXPANDED_MAP,
  manageRewardsRewardExpandedMap: {},
  myRewardsExpandedMap: DEFAULT_MY_REWARDS_EXPANDED_MAP,
  rewardHistoryExpandedMap: {},
  historyPagination: null,
  headerLoading: false,
};
