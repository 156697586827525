<div class="main-container" [class.light-background]="lightBackground">
  @for (priority of priorities; track priority; let i = $index) {
    <button
      [class]="'priority-button ' + priority"
      [class.selected]="control?.value === i + 1"
      (click)="onPriority(i + 1)"
    >
      {{ priority | titlecase }}
    </button>
  }
</div>
