import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormArray} from '@angular/forms';

@Component({
  selector: 'twd-subtask-edit',
  templateUrl: './twd-subtask-edit.component.html',
  styleUrls: ['./twd-subtask-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwdSubtaskEditComponent {
  @Input() formArray: FormArray;
  @Input() subtaskIndex: number;

  @Output() hideHeaderAndBorder = new EventEmitter<boolean>();
  @Output() back = new EventEmitter();
  @Output() imageAdded = new EventEmitter<string>();
}
