import {Injectable} from '@angular/core';
import * as moment from 'moment-timezone';
import {Observable, Subject} from 'rxjs';
import {CurrentSkedTask} from 'src/app/core/model/task';
import {User} from 'src/app/core/model/user';
import {LensSettings} from 'src/app/lenses/lens-settings';
import {TimezoneService} from 'src/app/services/timezone.service';
import {CurrentSkedTabOptions} from '../task-list/shared/current-sked-tab-options';
import {AvailableTaskActions, AvailableTaskActionsItem} from './model/available-task-actions';
import {Sked} from './model/sked';
import {TaskStatuses} from './task-statuses';

@Injectable({
  providedIn: 'root',
})
export class CurrentSkedService {
  failsUserReqsMessage = 'You do not satisfy the necessary task requirements.';
  failsTaskOwnerMessage = 'You are not the owner of this task.';
  subtaskLensMessage = 'You must complete all the subtasks.';

  private notify = new Subject<number>();

  public notifyObservable$: Observable<number> = this.notify.asObservable();

  constructor(private timezoneService: TimezoneService) {}

  public dubaiThemeCardFlipIndex(subtaskIndex: number) {
    if (subtaskIndex || subtaskIndex === 0) {
      this.notify.next(subtaskIndex);
    }
  }

  getAvailableTaskActions(
    task: CurrentSkedTask,
    user: User,
    lensMap: Record<LensSettings, string>,
    skedTimeStatus: string
  ): AvailableTaskActions {
    return {
      [TaskStatuses.Unclaimed]: {disabled: !task.authorizationObject.isAuthorized},
      [TaskStatuses.InProgress]: {disabled: !task.authorizationObject.isAuthorized},
      [TaskStatuses.OnHold]: {disabled: !task.authorizationObject.isAuthorized},
      [TaskStatuses.Cancelled]: {disabled: !task.authorizationObject.isAuthorized},
      [TaskStatuses.Completed]: this.getCompleteTaskActionItem(user, task, lensMap),
    };
  }

  getInProgressTaskActionItem(task: CurrentSkedTask, user: User, skedTimeStatus: string): AvailableTaskActionsItem {
    const passesUserReqs = this.passesUserReqs(task, user);
    // const passesStatus = task.status === TaskStatuses.Unclaimed;

    if (!passesUserReqs) {
      return {disabled: true, message: this.failsUserReqsMessage};
    }

    if (passesUserReqs) {
      const timezone = this.timezoneService.getTimeZone();
      const passesTimeReqs = moment(task.startDateTime).tz(timezone).isBefore(moment().tz(timezone));
      const disabled = !passesTimeReqs || skedTimeStatus === 'future';
      return {
        disabled,
        message: disabled ? `Your ${(task.event && 'event') || 'task'}  is not ready to start yet.` : '',
      };
    }
  }

  passesUserReqs(task: CurrentSkedTask, user: User): boolean {
    return user.requirements.some(r => task.requirements?.includes(r)) || task.requirements?.length === 0;
  }

  getCancelActionItem(task: CurrentSkedTask, user: User): AvailableTaskActionsItem {
    const passesUserReqs = this.passesUserReqs(task, user);
    const message = passesUserReqs ? '' : this.failsTaskOwnerMessage;

    return {
      disabled: (task.ownerId && user._id !== task.ownerId) || !passesUserReqs,
      message,
    };
  }

  getCompleteTaskActionItem(
    user: User,
    task: CurrentSkedTask,
    lensMap: Record<LensSettings, string>
  ): AvailableTaskActionsItem {
    const passesLensReqs =
      lensMap[LensSettings.CanCompleteTaskWithoutCompletingSubtasks] === 'true' ||
      task?.subtasks?.every(subtask => subtask.complete);
    let message = '';
    if (!passesLensReqs) {
      message = this.subtaskLensMessage;
    }

    return {
      disabled: !passesLensReqs || !task.authorizationObject.isAuthorized,
      message,
    };
  }

  formatSkeds(skeds: Sked[]): Record<CurrentSkedTabOptions, Sked[]> {
    const formattedSkeds = {
      [CurrentSkedTabOptions.Sked]: [],
      [CurrentSkedTabOptions.Scheduled]: [],
      [CurrentSkedTabOptions.Completed]: [],
      [CurrentSkedTabOptions.Loose]: [],
    };
    //This is to make sure the completed array is unique
    const completedTaskIds = {};
    skeds.forEach(sked => {
      const formattedTasks = {
        [CurrentSkedTabOptions.Sked]: [],
        [CurrentSkedTabOptions.Scheduled]: [],
        [CurrentSkedTabOptions.Completed]: [],
        [CurrentSkedTabOptions.Loose]: [],
      };

      sked.tasks.forEach(task => {
        if (task.status !== TaskStatuses.Completed && task.status !== TaskStatuses.Cancelled) {
          formattedTasks[CurrentSkedTabOptions.Sked].push(task);
        }
        if (task.event) {
          formattedTasks[CurrentSkedTabOptions.Scheduled].push(task);
        }
        if (
          (task.status === TaskStatuses.Completed || task.status === TaskStatuses.Cancelled) &&
          this.timezoneService.isDateToday(task.completedTime) &&
          !completedTaskIds[task._id]
        ) {
          completedTaskIds[task._id] = true;
          formattedTasks[CurrentSkedTabOptions.Completed].push(task);
        }
      });
      formattedSkeds[CurrentSkedTabOptions.Sked].push({...sked, tasks: formattedTasks[CurrentSkedTabOptions.Sked]});
      formattedSkeds[CurrentSkedTabOptions.Scheduled].push({
        ...sked,
        tasks: formattedTasks[CurrentSkedTabOptions.Scheduled],
      });
      formattedSkeds[CurrentSkedTabOptions.Completed].push({
        ...sked,
        tasks: formattedTasks[CurrentSkedTabOptions.Completed],
      });
      formattedSkeds[CurrentSkedTabOptions.Loose].push({...sked, tasks: formattedTasks[CurrentSkedTabOptions.Loose]});
    });

    return formattedSkeds;
  }
}
