import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {TrackerMetricTypeDisplayPipe} from 'src/app/trackers/shared/tracker-metric-type-display.pipe';
import {OphButtonModule} from '../design/oph-button/oph-button.module';
import {OphIconModule} from '../design/oph-icon/oph-icon.module';
import {ListViewsDetailBackComponent} from './detail/back/list-views-detail-back.component';
import {ListViewsDetailHeaderComponent} from './detail/header/list-views-detail-header.component';
import {ListViewsDetailComponent} from './detail/list-views-detail.component';
import {ListViewEmptyComponent} from './shared/empty/list-view-empty.component';
import {ListViewMenuIconPipe} from './shared/list-view-menu-icon.pipe';
import {ListViewMenuComponent} from './shared/menu/list-view-menu.component';
import {ListViewWarningDialogComponent} from './shared/warning-dialog/list-view-warning-dialog.component';
import {ListViewsGridCardItemComponent} from './summary/grid/card/item/list-views-grid-card-item.component';
import {ListViewsGridCardComponent} from './summary/grid/card/list-views-grid-card.component';
import {ListViewsGridComponent} from './summary/grid/list-views-grid.component';
import {ListViewHeaderComponent} from './summary/header/list-view-header.component';
import {OphXButtonComponent} from '../design/oph-x-button/oph-x-button.component';

@NgModule({
  declarations: [
    ListViewHeaderComponent,
    ListViewEmptyComponent,
    ListViewMenuComponent,
    ListViewWarningDialogComponent,
    ListViewMenuIconPipe,
    ListViewsGridComponent,
    ListViewsGridCardComponent,
    ListViewsGridCardItemComponent,
    ListViewsDetailComponent,
    ListViewsDetailHeaderComponent,
    ListViewsDetailBackComponent,
  ],
  imports: [
    CommonModule,
    OphIconModule,
    MatButtonModule,
    MatMenuModule,
    OphButtonModule,
    MatIconModule,
    TrackerMetricTypeDisplayPipe,
    OphXButtonComponent,
  ],
  exports: [
    ListViewHeaderComponent,
    ListViewEmptyComponent,
    ListViewMenuComponent,
    ListViewMenuIconPipe,
    ListViewsGridComponent,
    ListViewsGridCardComponent,
    ListViewsGridCardItemComponent,
    ListViewsDetailHeaderComponent,
    ListViewsDetailBackComponent,
  ],
})
export class ListViewsModule {}
