import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {convertReportingObjectEntryDtoToModel} from '../../../reporting/objects/shared/converters/convert-reporting-object-entry-dto-to-model';
import {ReportingObjectEntryDto} from '../../../reporting/objects/shared/dto/reporting-object-entry.dto';
import {ReportingApiService} from '../../api/reporting-api.service';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {
  GetObjectAttributeReportAction,
  GetObjectInstanceReportAction,
  GetObjectParentReportAction,
  GetObjectsReportAction,
  GetObjectsReportSuccessAction,
  ReportingActionType,
} from './reporting.action';
import {convertReportingObjectsParamsModelToDto} from '../../../reporting/objects/shared/converters/convert-reporting-objects-params-model-to-dto';

@Injectable()
export class ReportingEffects {
  public get$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetObjectsReportAction>(ReportingActionType.GET_OBJECTS_REPORT),
      mergeMap(action => {
        const {params, onSuccess, onFailure} = action.payload;
        const paramsDto = convertReportingObjectsParamsModelToDto(params);

        return this.reportingApiService.getReportingRecentAttributeUpdates(paramsDto).pipe(
          map((dtos: ReportingObjectEntryDto[]) => dtos.map(dto => convertReportingObjectEntryDtoToModel(dto))),
          mergeMap(reportingObjectEntries => [
            new GetObjectsReportSuccessAction({reportingObjectEntries}),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getParent$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetObjectParentReportAction>(ReportingActionType.GET_OBJECT_PARENT_REPORT),
      mergeMap(action => {
        const {parentId, params, onSuccess, onFailure} = action.payload;
        const paramsDto = convertReportingObjectsParamsModelToDto(params);

        return this.reportingApiService.getReportingObjectParent(parentId, paramsDto).pipe(
          map((dtos: ReportingObjectEntryDto[]) => dtos.map(dto => convertReportingObjectEntryDtoToModel(dto))),
          mergeMap(reportingObjectEntries => [
            new GetObjectsReportSuccessAction({reportingObjectEntries}),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getInstance$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetObjectInstanceReportAction>(ReportingActionType.GET_OBJECT_INSTANCE_REPORT),
      mergeMap(action => {
        const {instanceId, params, onSuccess, onFailure} = action.payload;
        const paramsDto = convertReportingObjectsParamsModelToDto(params);

        return this.reportingApiService.getReportingObjectInstance(instanceId, paramsDto).pipe(
          map((dtos: ReportingObjectEntryDto[]) => dtos.map(dto => convertReportingObjectEntryDtoToModel(dto))),
          mergeMap(reportingObjectEntries => [
            new GetObjectsReportSuccessAction({reportingObjectEntries}),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getAttribute$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetObjectAttributeReportAction>(ReportingActionType.GET_OBJECT_ATTRIBUTE_REPORT),
      mergeMap(action => {
        const {instanceId, attributeId, params, onSuccess, onFailure} = action.payload;
        const paramsDto = convertReportingObjectsParamsModelToDto(params);

        return this.reportingApiService.getReportingObjectAttribute(instanceId, attributeId, paramsDto).pipe(
          map((dtos: ReportingObjectEntryDto[]) => dtos.map(dto => convertReportingObjectEntryDtoToModel(dto))),
          mergeMap(reportingObjectEntries => [
            new GetObjectsReportSuccessAction({reportingObjectEntries}),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private reportingApiService: ReportingApiService
  ) {}
}
