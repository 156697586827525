import {Goal} from '../../model/goal';

export interface GoalsState {
  goals: Goal[];
  goal: Goal;
}

export const initialGoalsState: GoalsState = {
  goals: null,
  goal: null,
};
