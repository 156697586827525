import {CdkDragDrop, CdkDropList, DragDropModule, moveItemInArray} from '@angular/cdk/drag-drop';
import {AsyncPipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, inject, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {select, Store} from '@ngrx/store';
import * as _ from 'lodash';
import {BehaviorSubject, debounceTime, Observable, Subscription} from 'rxjs';
import {Project} from 'src/app/core/model/project';
import {RewardCoin} from 'src/app/core/model/reward';
import {SkillModel} from 'src/app/core/model/skill';
import {ProjectTask, TaskTemplate} from 'src/app/core/model/task';
import {selectLocationsMap} from 'src/app/core/store/locations/locations.selector';
import {
  GetTaskTemplatesAction,
  UpdateProjectAction,
  UpdateProjectTasksAction,
} from 'src/app/core/store/projects/projects.action';
import {selectSkillsList, selectSkillsMap} from 'src/app/core/store/skills/skills.selector';
import {Location} from 'src/app/locations/shared/model/location';
import {MessageService} from 'src/app/services/message.service';
import {
  OphButtonGroupComponent,
  OphButtonGroupOption,
} from 'src/app/shared/design/oph-button-group/oph-button-group.component';
import {OphSearchInputComponent} from 'src/app/shared/design/oph-inputs/oph-search-input/oph-search-input.component';
import {OphNewButtonComponent} from 'src/app/shared/design/oph-new-button/oph-new-button.component';
import {ProjectsService} from 'src/app/shared/services/projects.service';
import {TASK_DIALOG_SETTINGS} from 'src/app/shared/tasks/task-dialog/shared/task-dialog-constants';
import {TaskDialogComponent} from 'src/app/shared/tasks/task-dialog/task-dialog.component';
import {TaskWizardDialogComponent} from 'src/app/shared/tasks/task-wizard-dialog/task-wizard-dialog.component';
import {formatSelectedDay} from 'src/app/shared/tasks/utils/format-selected-day';
import {ProjectsEditTaskComponent} from './task/projects-edit-task.component';

@Component({
  selector: 'project-edit',
  standalone: true,
  imports: [
    DragDropModule,
    MatIconModule,
    ProjectsEditTaskComponent,
    AsyncPipe,
    OphButtonGroupComponent,
    OphSearchInputComponent,
    OphNewButtonComponent,
    CdkDropList,
  ],
  templateUrl: './project-edit.component.html',
  styleUrl: './project-edit.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectEditComponent implements OnInit, OnDestroy {
  @Input() project: Project;
  @Input() taskTemplates: TaskTemplate[];
  @Input() coinTypes: RewardCoin[];

  requirements$: Observable<SkillModel[]> = this.store$.pipe(select(selectSkillsList));
  requirementsMap$: Observable<Record<string, SkillModel>> = this.store$.pipe(select(selectSkillsMap));
  locationsMap$: Observable<Record<string, Location>> = this.store$.pipe(select(selectLocationsMap));

  selectedTab$ = new BehaviorSubject<string>('tasks');
  loadingTaskIndex$ = new BehaviorSubject<number>(null);

  readonly dialog = inject(MatDialog);
  tabOptions: OphButtonGroupOption[] = [{name: 'tasks'}, {name: 'milestones', disabled: true}];
  search: FormControl = new FormControl('');
  searchSub: Subscription;

  constructor(
    private store$: Store,
    private projectsService: ProjectsService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.searchSub = this.search.valueChanges.pipe(debounceTime(300)).subscribe(search => {
      this.store$.dispatch(new GetTaskTemplatesAction({search}));
    });
  }

  ngOnDestroy() {
    this.searchSub.unsubscribe();
  }

  taskDrop(event: CdkDragDrop<ProjectTask[]>) {
    // Check if item is being moved between different containers
    if (event.previousContainer !== event.container) {
      // Handle dropping template into task list
      const task = _.cloneDeep(event.previousContainer.data[event.previousIndex]);
      if (task?.schedule) {
        // Change default dates to today
        const today = formatSelectedDay(new Date());
        task.schedule.dueWithinDate = today;
        task.schedule.endsDate = today;
      }
      // Add to list to show loading
      event.container.data.splice(event.currentIndex, 0, task);
      this.loadingTaskIndex$.next(event.currentIndex);
      const projectPartial = this.projectsService.getProjectPartial(this.project);
      this.store$.dispatch(
        new UpdateProjectTasksAction({
          id: this.project._id,
          projectPartial,
          task,
          index: event.currentIndex,
          onSuccess: () => this.onUpdateSuccess(),
          onFailure: err => this.onUpdateFailure(err),
        })
      );
    } else {
      // Handle reordering tasks within the same container
      // Add to list to show loading
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.loadingTaskIndex$.next(event.currentIndex);
      this.store$.dispatch(
        new UpdateProjectAction({
          project: this.project,
          onSuccess: () => this.onUpdateSuccess(),
          onFailure: err => this.onUpdateFailure(err),
        })
      );
    }
  }

  onUpdateSuccess() {
    this.loadingTaskIndex$.next(null);
  }

  onUpdateFailure(err: Error) {
    this.loadingTaskIndex$.next(null);
    this.messageService.add(err.message || 'There was a problem updating the project.');
  }

  onCreateTaskForProject(project: Project) {
    const dialogRef = this.dialog.open(TaskWizardDialogComponent, {
      data: {project, coinTypes: this.coinTypes},
      panelClass: 'task-wizard-dialog',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((manualEntry: boolean) => {
      if (manualEntry) {
        this.openManualProjectTaskModal();
      }
    });
  }

  openManualProjectTaskModal(taskIndex?: number) {
    if (taskIndex && taskIndex === this.loadingTaskIndex$.value) {
      return;
    }
    this.dialog.open(TaskDialogComponent, {
      data: {task: this.project.tasks[taskIndex] || null, project: this.project, coinTypes: this.coinTypes},
      panelClass: 'task-dialog',
      disableClose: true,
    });
  }

  onTabChange(tab: string) {
    this.selectedTab$.next(tab);
  }

  openTaskTemplateDialog(task?: TaskTemplate) {
    this.dialog.open(TaskDialogComponent, {
      data: {task, template: true, project: this.project},
      ...TASK_DIALOG_SETTINGS,
    });
  }

  alwaysAllowDrag(): boolean {
    return true; // This always allows the drag action to start
  }
}
