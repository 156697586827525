import {HttpErrorResponse} from '@angular/common/http';
import {Action} from '@ngrx/store';
import {Location} from '../../../locations/shared/model/location';

export enum LocationsActionType {
  GET = '[Locations] Get',
  GET_SUCCESS = '[Locations] Get :: Success',

  CREATE = '[Locations] Create',
  CREATE_SUCCESS = '[Locations] Create :: Success',

  UPDATE = '[Locations] Update',
  UPDATE_SUCCESS = '[Locations] Update :: Success',

  DELETE = '[Locations] Delete',
  DELETE_SUCCESS = '[Locations] Delete :: Success',

  CLEAR = '[Locations] Clear',
}

export class GetLocationsAction implements Action {
  public readonly type = LocationsActionType.GET;

  public constructor(
    public payload: {
      onSuccess?: (locations: Location[]) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetLocationsSuccessAction implements Action {
  public readonly type = LocationsActionType.GET_SUCCESS;

  public constructor(public payload: {locations: Location[]}) {}
}

export class CreateLocationAction implements Action {
  public readonly type = LocationsActionType.CREATE;

  public constructor(
    public payload: {
      location: Location;
      onSuccess?: (location: Location) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class CreateLocationSuccessAction implements Action {
  public readonly type = LocationsActionType.CREATE_SUCCESS;

  public constructor(public payload: {location: Location}) {}
}

export class UpdateLocationAction implements Action {
  public readonly type = LocationsActionType.UPDATE;

  public constructor(
    public payload: {
      location: Location;
      onSuccess?: (location: Location) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class UpdateLocationSuccessAction implements Action {
  public readonly type = LocationsActionType.UPDATE_SUCCESS;

  public constructor(public payload: {location: Location}) {}
}

export class DeleteLocationAction implements Action {
  public readonly type = LocationsActionType.DELETE;

  public constructor(
    public payload: {
      id: string;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class DeleteLocationSuccessAction implements Action {
  public readonly type = LocationsActionType.DELETE_SUCCESS;
}

export type LocationsAction =
  | GetLocationsAction
  | GetLocationsSuccessAction
  | CreateLocationAction
  | CreateLocationSuccessAction
  | UpdateLocationAction
  | UpdateLocationSuccessAction
  | DeleteLocationAction
  | DeleteLocationSuccessAction;
