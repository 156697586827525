export enum TaskStatuses {
  Unclaimed = 'unclaimed',
  Claimed = 'claimed',
  InProgress = 'inProgress',
  Paused = 'paused',
  Snoozed = 'snoozed',
  Completed = 'completed',
  Cancelled = 'cancelled',
  OnHold = 'onHold',
  Expired = 'expired',
}
