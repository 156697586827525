import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {catchError, mergeMap, withLatestFrom} from 'rxjs/operators';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {
  FetchSkillsAction,
  FetchSkillsSuccessAction,
  GetSingleSkillAction,
  GetSingleSkillSuccessAction,
  RemoveSkillAction,
  SaveCurrentSkillAction,
  SkillsActionType,
} from './skills.action';
import {SkillsApiService} from '../../../services/skills-api.service';
import {selectSelectedSkill, selectSkillsList} from './skills.selector';
import {selectRouterParam} from '../router/router.selector';
import {SkillModel} from '../../model/skill';

@Injectable()
export class SkillsEffects {
  public fetchSkills$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchSkillsAction>(SkillsActionType.FETCH_SKILLS),
      withLatestFrom(this.store$.select(selectRouterParam('id'))),
      mergeMap(([action, id]) => {
        const {search, onSuccess, onFailure} = action.payload;
        return this.skillsApiService.fetchAll(search).pipe(
          mergeMap(skills => {
            const emptySkill: SkillModel[] =
              id === 'new'
                ? [
                    {
                      name: null,
                      icon: null,
                      description: null,
                      type: null,
                      _id: 'new',
                    },
                  ]
                : [];
            return [
              new FetchSkillsSuccessAction({
                skills: [...emptySkill, ...skills],
              }),
              ...createCallbackActions(onSuccess, [...emptySkill, ...skills]),
            ];
          }),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getSingleSkill$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetSingleSkillAction>(SkillsActionType.GET_SINGLE_SKILL),
      withLatestFrom(this.store$.select(selectRouterParam('id')), this.store$.select(selectSkillsList)),
      mergeMap(([action, routerId, skills]) => {
        const {id, onSuccess, onFailure} = action.payload;

        if (id === 'new' && id === routerId) {
          const emptySkill = skills.find(s => s._id === 'new');
          return [
            new GetSingleSkillSuccessAction({
              skill: emptySkill,
            }),
            ...createCallbackActions(onSuccess, emptySkill),
          ];
        }

        return this.skillsApiService.getSingleSkill(id).pipe(
          mergeMap(skill => [new GetSingleSkillSuccessAction({skill}), ...createCallbackActions(onSuccess, skill)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public saveCurrentSkill$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveCurrentSkillAction>(SkillsActionType.SAVE_CURRENT_SKILL),
      withLatestFrom(this.store$.select(selectSelectedSkill)),
      mergeMap(([action, skill]) => {
        const {onSuccess, onFailure} = action.payload;
        return this.skillsApiService.saveSkill(skill).pipe(
          mergeMap(res => [...createCallbackActions(onSuccess, res)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public removeSkill$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<RemoveSkillAction>(SkillsActionType.REMOVE_SKILL),
      mergeMap(action => {
        const {id, onSuccess, onFailure} = action.payload;
        return this.skillsApiService.removeSkill(id).pipe(
          mergeMap(res => [...createCallbackActions(onSuccess, res)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private skillsApiService: SkillsApiService,
    private store$: Store
  ) {}
}
