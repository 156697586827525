import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';
import {formatSelectedDay} from '../../../utils/format-selected-day';

@Component({
  selector: 'twd-event-date',
  templateUrl: './twd-event-date.component.html',
  styleUrls: ['./twd-event-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwdEventDateComponent {
  @Input() control: FormControl;

  onDateChange(date: Date) {
    this.control.setValue(formatSelectedDay(date));
  }
}
