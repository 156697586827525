import {createSelector} from '@ngrx/store';
import {AppState} from '../app-state';
import {selectRouterQueryParam} from '../router/router.selector';

export const selectRewardsState = (state: AppState) => state.rewards;

export const selectManageRewards = createSelector(selectRewardsState, state => state.manageRewards);

export const selectMyRewards = createSelector(selectRewardsState, state => state.myRewards);

export const selectSingleReward = createSelector(selectRewardsState, state => state.singleReward);

export const selectCoinTypes = createSelector(selectRewardsState, state => state.coinTypes);

export const selectCoinTypesMap = createSelector(selectRewardsState, state => state.coinTypesMap);

export const selectManageRewardsCoinExpandedMap = createSelector(
  selectRewardsState,
  state => state.manageRewardsCoinExpandedMap
);

export const selectManageRewardsRewardExpandedMap = createSelector(
  selectRewardsState,
  state => state.manageRewardsRewardExpandedMap
);

export const selectMyRewardsExpandedMap = createSelector(selectRewardsState, state => state.myRewardsExpandedMap);

export const selectRewardHistoryExpandedMap = createSelector(
  selectRewardsState,
  state => state.rewardHistoryExpandedMap
);

export const selectRewardsUserHistory = createSelector(selectRewardsState, state => state.userHistory);

export const selectRewardsAdminHistory = createSelector(selectRewardsState, state => state.adminHistory);

export const selectRewardsAdminHistoryParams = createSelector(
  selectRouterQueryParam('search'),
  selectRouterQueryParam('page'),
  (search, page) => ({
    search,
    page: page ? Number(page) : 1,
  })
);

export const selectRewardsHistoryPagination = createSelector(selectRewardsState, state => state.historyPagination);

export const selectRewardsHeaderLoading = createSelector(selectRewardsState, state => state.headerLoading);
