import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'trackerMetricTypeDisplay',
  standalone: true,
})
export class TrackerMetricTypeDisplayPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'numeric':
        return 'Number';
      case 'select':
        return 'List';
      case 'boolean':
        return 'Yes / No';
    }
  }
}
