import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OphButtonComponent} from './oph-button.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRippleModule} from '@angular/material/core';

@NgModule({
  declarations: [OphButtonComponent],
  imports: [CommonModule, MatProgressSpinnerModule, MatRippleModule],
  exports: [OphButtonComponent],
})
export class OphButtonModule {}
