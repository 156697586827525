@if ({loadingIndex: loadingIndex$ | async}; as state) {
  <button (click)="onBack()">
    <mat-icon svgIcon="chevron-left"></mat-icon>
  </button>

  @for (item of countArray; track item; let i = $index) {
    <button [class.selected]="page === item" (click)="onPage(item)" [disabled]="state.loadingIndex === i">
      @if (state.loadingIndex === i) {
        <oph-loading diameter="20"></oph-loading>
      } @else {
        {{ item }}
      }
    </button>
  }

  <button (click)="onForward()">
    <mat-icon svgIcon="chevron-right"></mat-icon>
  </button>
}
