import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {Lens} from 'src/app/lenses/shared/model/lens';
import {LensSetting} from 'src/app/lenses/shared/model/lens-setting';
import {LensesApiService} from '../../api/lenses-api.service';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {getLensSettingsMap} from './get-lens-settings-map';
import {
  CreateLensSuccessAction,
  CreateNewLensAction,
  DeleteLensAction,
  DeleteLensSuccessAction,
  GetLensSettingsAction,
  GetLensSettingsSuccessAction,
  GetLensesAction,
  GetLensesSuccessAction,
  GetSingleLensAction,
  GetSingleLensSuccessAction,
  GetUserLensAction,
  GetUserLensSuccessAction,
  LensesActionType,
  UpdateLensAction,
  UpdateLensSuccessAction,
} from './lenses.action';

@Injectable()
export class LensesEffects {
  public create$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateNewLensAction>(LensesActionType.CREATE),
      mergeMap(action => {
        const {lens, onSuccess, onFailure} = action.payload;

        return this.lensApiService.create(lens).pipe(
          mergeMap(l => [new CreateLensSuccessAction({lens: l}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public update$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateLensAction>(LensesActionType.UPDATE),
      mergeMap(action => {
        const {lens, onSuccess, onFailure} = action.payload;

        return this.lensApiService.update(lens).pipe(
          mergeMap(l => [new UpdateLensSuccessAction({lens: l}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public get$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetLensesAction>(LensesActionType.GET),
      mergeMap(action => {
        const {onSuccess, onFailure} = action.payload;

        return this.lensApiService.get().pipe(
          mergeMap((lenses: Lens[]) => [new GetLensesSuccessAction({lenses}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getSingle$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetSingleLensAction>(LensesActionType.GET_SINGLE),
      mergeMap(action => {
        const {id, onSuccess, onFailure} = action.payload;

        return this.lensApiService.getSingle(id).pipe(
          mergeMap((lens: Lens[]) => [
            new GetSingleLensSuccessAction({
              lens: lens?.length ? lens[0] : ({} as Lens),
            }),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getUserLens$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetUserLensAction>(LensesActionType.GET_USER_LENS),
      mergeMap(action => {
        const {id, onSuccess, onFailure} = action.payload;
        return this.lensApiService.getSingle(id).pipe(
          mergeMap((lens: Lens[]) => [
            new GetUserLensSuccessAction({
              userLens: lens?.length ? lens[0] : ({} as Lens),
              userLensSettingsMap: lens?.length ? getLensSettingsMap(lens[0]?.settings) : {},
            }),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getSettings$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetLensSettingsAction>(LensesActionType.GET_SETTINGS),
      mergeMap(action => {
        const {onSuccess, onFailure} = action.payload;

        return this.lensApiService.getSettings().pipe(
          mergeMap((settings: LensSetting[]) => [
            new GetLensSettingsSuccessAction({settings, settingsMap: getLensSettingsMap(settings)}),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public delete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteLensAction>(LensesActionType.DELETE),
      mergeMap(action => {
        const {id, onSuccess, onFailure} = action.payload;

        return this.lensApiService.delete(id).pipe(
          mergeMap(() => [new DeleteLensSuccessAction(), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private lensApiService: LensesApiService
  ) {}
}
