import {LensSetting} from 'src/app/lenses/shared/model/lens-setting';

export function getLensSettingsMap(settings: LensSetting[]): Record<string, LensSetting> {
  return (
    settings?.reduce(
      (lensSettingsMap: Record<string, LensSetting>, obj: LensSetting) => (
        (lensSettingsMap[obj.title] = obj), lensSettingsMap
      ),
      {}
    ) || {}
  );
}
