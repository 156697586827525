import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'numberToBoolean',
})
export class NumberToBooleanPipe implements PipeTransform {
  transform(value: string): string {
    return (value === '1' && 'Yes') || 'No';
  }
}
