import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import {FormArray, FormBuilder} from '@angular/forms';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Subtask} from 'src/app/core/model/task';
import * as uuid from 'uuid';

@Component({
  selector: 'subtask-list',
  templateUrl: './subtask-list.component.html',
  styleUrls: ['./subtask-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubtaskListComponent {
  @Input() formArray: FormArray;
  @Input() itemBackground: 'light' | 'dark' = 'dark';

  @Output() subtaskIndexChange = new EventEmitter<number>();

  hoverIndex$ = new BehaviorSubject<number>(null);
  menuIndex$ = new BehaviorSubject<number>(null);
  subtasks$ = new BehaviorSubject<Subtask[]>([]);

  subscription: Subscription;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.subscription = this.formArray.valueChanges.subscribe(subtasks => {
      this.subtasks$.next(subtasks);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.formArray && this.formArray) {
      this.subtasks$.next(this.formArray.getRawValue());
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDrop(event: CdkDragDrop<any[]>) {
    this.menuIndex$.next(null);
    const subtasks = [...this.formArray.getRawValue()];
    moveItemInArray(subtasks, event.previousIndex, event.currentIndex);
    this.formArray.setValue(subtasks);
  }

  onMouseEnter(index: number) {
    this.hoverIndex$.next(index);
  }

  onMouseLeave(index: number) {
    this.hoverIndex$.next(null);
  }

  onShowActions(index: number) {
    this.menuIndex$.next(index);
  }

  onHideActions(index: number) {
    this.menuIndex$.next(null);
  }

  handleEditButtonClick(action: string, i: number): void {
    switch (action) {
      case 'delete':
        this.onDelete(i);
        break;
      case 'edit':
        this.onEdit(i);
        break;
      case 'duplicate':
        this.onDuplicate(i);
        break;
    }
  }

  onDuplicate(index: number) {
    const subtasks = this.formArray.getRawValue();
    const duplicateValue = {...subtasks[index], _id: uuid.v4()};
    const duplicateFormGroup = this.fb.group({
      ...duplicateValue,
    });
    this.formArray.insert(index, duplicateFormGroup);
    this.menuIndex$.next(null);
  }

  onDelete(index: number) {
    this.formArray.removeAt(index);
    this.menuIndex$.next(null);
  }

  onEdit(index: number) {
    this.subtaskIndexChange.next(index);
  }
}
