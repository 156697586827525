import {ProgramsAction, ProgramsActionType} from './programs.action';
import {initialProgramsState, ProgramsState} from './programs.state';

export function programsReducer(state: ProgramsState = initialProgramsState, action: ProgramsAction): ProgramsState {
  switch (action.type) {
    case ProgramsActionType.GET_SUCCESS:
      return {...state, programs: action.payload.programs};
    default:
      return state;
  }
}
