import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'oph-input',
  templateUrl: './oph-input.component.html',
  styleUrls: ['./oph-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OphInputComponent implements AfterViewInit, OnChanges {
  @Input() width: number;
  @Input() height: number;
  @Input() fontSize: number;
  @Input() control: FormControl;
  @Input() inputType: string;
  @Input() placeholder: string;
  @Input() autoFocusInput: boolean;

  @Output() valueChange = new EventEmitter<string>();

  @ViewChild('input') input: ElementRef;

  inputFocusClass: boolean;
  hovered: boolean;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.autoFocusInput && this.autoFocusInput) {
      this.inputFocusClass = true;
    }
  }

  ngAfterViewInit() {
    if (this.autoFocusInput) {
      this.input.nativeElement.focus();
    }
  }

  onInput(value: string) {
    this.valueChange.emit(value);
  }

  onMouseEnter() {
    this.hovered = true;
  }

  onMouseLeave() {
    this.hovered = false;
  }
}
