import {Pipe, PipeTransform} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {RewardCoin} from 'src/app/core/model/reward';
import {User} from 'src/app/core/model/user';
import {selectCoinTypesMap} from 'src/app/core/store/rewards/rewards.selector';

@Pipe({
  name: 'rewardManageProgress',
  standalone: true,
})
export class RewardManageProgressPipe implements PipeTransform {
  coinTypesMap$: Observable<Record<string, RewardCoin>> = this.store$.pipe(select(selectCoinTypesMap));

  constructor(private store$: Store) {}

  transform(user: User, coinId: string, rewardCost: number): number {
    const availableCoins = user.coins.find(r => r.coinType === coinId)?.coinCount || 0;
    if (availableCoins === 0) {
      return 0;
    }

    const percentage = (availableCoins / rewardCost) * 100;
    if (percentage > 100) {
      return 100;
    } else {
      return percentage;
    }
  }
}
