import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function minMaxValidator(min: number, max: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    // If the value is empty, skip validation (not required)
    if (value === null || value === undefined || value === '') {
      return null;
    }

    // Validate the value if it's present
    if (value < min) {
      return {min: {required: min, actual: value}};
    }
    if (value > max) {
      return {max: {required: max, actual: value}};
    }
    return null; // Valid if within range
  };
}
