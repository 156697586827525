import {Pipe, PipeTransform} from '@angular/core';
import {RewardUser} from 'src/app/core/model/reward';

@Pipe({
  name: 'rewardsCheckIfAvailableFulfillment',
  standalone: true,
})
export class RewardsCheckIfAvailableFulfillmentPipe implements PipeTransform {
  transform(assignedTo: RewardUser[]): boolean {
    return assignedTo.some(a => a.status === 'claimed');
  }
}
