import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TrackerMetric} from 'src/app/core/model/tracker';
import {PipesModule} from 'src/app/shared/pipes/pipes.module';
import {TrackerMetricTypes} from 'src/app/trackers/shared/tracker-metric-types';

@Component({
  selector: 'current-sked-task-detail-value-display',
  standalone: true,
  imports: [PipesModule],
  templateUrl: './current-sked-task-detail-value-display.component.html',
  styleUrl: './current-sked-task-detail-value-display.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentSkedTaskDetailValueDisplayComponent {
  @Input() metric: TrackerMetric;

  TrackerMetricTypes = TrackerMetricTypes;
}
