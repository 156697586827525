import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {NativeDateAdapter} from '@angular/material/core';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  constructor(@Inject(LOCALE_ID) public locale: string) {
    super(locale);
  }

  getFirstDayOfWeek(): number {
    return 1; // Monday as the first day of the week
  }
}
