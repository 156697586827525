import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {User} from 'src/app/core/model/user';
import {PipesModule} from '../../pipes/pipes.module';

@Component({
  selector: 'oph-avatar',
  standalone: true,
  imports: [PipesModule],
  templateUrl: './oph-avatar.component.html',
  styleUrl: './oph-avatar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OphAvatarComponent {
  @Input() user: User;
  @Input() size: number;
  @Input() background: string;
  @Input() imgUrl: string;
  @Input() empty: boolean;

  defaultSize = 26; //px
}
