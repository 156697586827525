import {TIME_PICKER_OPTIONS} from './../../../task-constants';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {TimepickerUI} from 'timepicker-ui';
import {} from '../../shared/task-wizard-constants';
import {AbstractControl, FormGroup} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'twd-event-time',
  templateUrl: './twd-event-time.component.html',
  styleUrls: ['./twd-event-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwdEventTimeComponent implements AfterViewInit {
  @Input() scheduleControls: FormGroup;

  @Output() hideHeaderAndFooter = new EventEmitter<boolean>();

  @ViewChild('startTime') startTime: ElementRef;
  @ViewChild('endTime') endTime: ElementRef;

  showTimepicker$ = new BehaviorSubject<boolean>(false);

  timePickerOptions = {...TIME_PICKER_OPTIONS, appendModalSelector: '.task-wizard-timepicker'};

  ngAfterViewInit(): void {
    const startTimeEl = new TimepickerUI(this.startTime.nativeElement, this.timePickerOptions);
    const endTimeEl = new TimepickerUI(this.endTime.nativeElement, this.timePickerOptions);
    startTimeEl.create();
    endTimeEl.create();

    this.startTime.nativeElement.addEventListener('accept', res => this.setStartTime(res.detail));
    this.startTime.nativeElement.addEventListener('show', () => this.onTimepicker(true));
    this.startTime.nativeElement.addEventListener('cancel', () => this.onTimepicker(false));

    this.endTime.nativeElement.addEventListener('accept', res => this.setEndTime(res.detail));
    this.endTime.nativeElement.addEventListener('show', () => this.onTimepicker(true));
    this.endTime.nativeElement.addEventListener('cancel', () => this.onTimepicker(false));
  }

  onTimepicker(open: boolean) {
    this.hideHeaderAndFooter.emit(open);
    this.showTimepicker$.next(open);
  }

  setStartTime(value: Record<string, string>): void {
    this.eventStartControl.setValue(`${value.hour}:${value.minutes} ${value.type}`);
    this.onTimepicker(false);
  }

  setEndTime(value: Record<string, string>): void {
    this.eventEndControl.setValue(`${value.hour}:${value.minutes} ${value.type}`);
    this.onTimepicker(false);
  }

  get scheduleOnDateControl(): AbstractControl {
    return this.scheduleControls.get('scheduleOnDate');
  }

  get eventTimesControls(): AbstractControl {
    return this.scheduleControls.get('eventTimes');
  }

  get eventStartControl(): AbstractControl {
    return this.eventTimesControls.get('eventStart');
  }

  get eventEndControl(): AbstractControl {
    return this.eventTimesControls.get('eventEnd');
  }
}
