import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {
  SeasonModel,
  SkedTemplateModel,
  WeekModel,
  WeekSkedTemplateModel,
} from '../core/store/scheduler/scheduler-dummy-data';
import {TeamSchedule} from '../team-schedule/shared/model/team-schedule';
import {TeamScheduleSearchParams} from '../team-schedule/shared/model/team-schedule-search-params';

@Injectable({
  providedIn: 'root',
})
export class SchedulerService {
  constructor(private http: HttpClient) {}

  fetchSeason(id: string = null): Observable<SeasonModel[]> {
    return this.http.get<SeasonModel[]>(`${environment.apiUrl}/seasons${id ? '/' + id : ''}`);
  }

  updateSeason(month: SeasonModel): Observable<SeasonModel> {
    return this.http.put<SeasonModel>(`${environment.apiUrl}/seasons/${month._id}`, month);
  }

  createWeek(week: WeekModel, teamSkeds: boolean): Observable<WeekModel> {
    return this.http.post<WeekModel>(`${environment.apiUrl}/weeks?team=${String(teamSkeds)}`, week);
  }

  createSkedTemplate(template: WeekSkedTemplateModel): Observable<WeekSkedTemplateModel> {
    return this.http.post<WeekSkedTemplateModel>(`${environment.apiUrl}/skeds/templates`, template);
  }

  fetchSkedTemplates(search: string): Observable<SkedTemplateModel[]> {
    const params:
      | HttpParams
      | {
          [param: string]: string | string[];
        } = {};

    if (search) {
      params.label = search;
    }

    return this.http.get<SkedTemplateModel[]>(`${environment.apiUrl}/skeds/templates`, {params});
  }

  fetchWeek(id: string): Observable<WeekModel[]> {
    return this.http.get<WeekModel[]>(`${environment.apiUrl}/weeks/${id}`);
  }

  fetchWeeks({
    startDate,
    teamSkeds = false,
  }: {
    startDate: string;
    numberOfWeeks: number;
    teamSkeds: boolean;
  }): Observable<WeekModel[]> {
    return this.http.get<WeekModel[]>(
      `${environment.apiUrl}/weeks?startDate=${startDate}&teamSkeds=${String(teamSkeds)}`
    );
  }

  updateWeek(week: WeekModel): Observable<WeekModel> {
    return this.http.put<WeekModel>(`${environment.apiUrl}/weeks/${week._id}`, week);
  }

  duplicateWeek(weekId: string): Observable<WeekModel> {
    return this.http.post<WeekModel>(`${environment.apiUrl}/weeks/${weekId}`, null);
  }

  removeWeek(weekId: string): Observable<WeekModel> {
    return this.http.delete<WeekModel>(`${environment.apiUrl}/weeks/${weekId}`);
  }

  deleteSkedTemplate(id: string): Observable<WeekModel> {
    return this.http.delete<WeekModel>(`${environment.apiUrl}/skeds/templates/${id}`);
  }

  updateSkedTemplate(sked: SkedTemplateModel): Observable<SkedTemplateModel> {
    return this.http.put<SkedTemplateModel>(`${environment.apiUrl}/skeds/templates/${sked._id}`, sked);
  }

  getTeamSchedule(teamId: string, params: TeamScheduleSearchParams): Observable<TeamSchedule> {
    return this.http.get<TeamSchedule>(`${environment.apiUrl}/swimlane/${teamId}`, {params: {...params}});
  }
}
