import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';

@Component({
  selector: 'oph-checkbox',
  standalone: true,
  imports: [MatIconModule, CommonModule],
  templateUrl: './oph-checkbox.component.html',
  styleUrl: './oph-checkbox.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OphCheckboxComponent {
  @Input() width: number = 20;
  @Input() color: 'brown' | 'green' = 'brown';
  @Input() control: FormControl;
  @Input() checked: boolean;
  @Input() textStyle: Object;

  @Output() valueChange = new EventEmitter<boolean>();

  onCheckbox() {
    if (this.control) {
      this.control.setValue(!this.control.value);
    }
    this.valueChange.emit(!this.checked);
  }
}
