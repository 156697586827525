import {AsyncPipe} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, ReactiveFormsModule, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {Store} from '@ngrx/store';
import {BehaviorSubject} from 'rxjs';
import {ListSearchParams} from 'src/app/core/model/list-search-params';
import {Reward, RewardCoin} from 'src/app/core/model/reward';
import {User} from 'src/app/core/model/user';
import {
  CreateRewardAction,
  GetManageRewardsAction,
  GetRewardsAdminHistoryAction,
  GetSingleRewardAction,
  UpdateRewardAction,
} from 'src/app/core/store/rewards/rewards.action';
import {MessageService} from 'src/app/services/message.service';
import {OphAvatarComponent} from 'src/app/shared/design/oph-avatar/oph-avatar.component';
import {OphButtonModule} from 'src/app/shared/design/oph-button/oph-button.module';
import {OphCheckboxComponent} from 'src/app/shared/design/oph-checkbox/oph-checkbox.component';
import {OphInputOrangeComponent} from 'src/app/shared/design/oph-inputs/oph-input-orange/oph-input-orange.component';
import {OphLoadingModule} from 'src/app/shared/design/oph-loading/oph-loading.module';
import {OphXButtonComponent} from 'src/app/shared/design/oph-x-button/oph-x-button.component';
import {PipesModule} from 'src/app/shared/pipes/pipes.module';
import {RewardCoinIconPipe} from 'src/app/shared/pipes/reward-coin-icon.pipe';
import {SharedTasksModule} from 'src/app/shared/tasks/shared-tasks.module';
import {TaskDialogModule} from 'src/app/shared/tasks/task-dialog/task-dialog.module';
import {
  REWARD_COST_ERROR,
  REWARD_COST_MAX,
  REWARD_COST_MIN,
  REWARD_LIMIT_ERROR,
  REWARD_LIMIT_MAX,
  REWARD_LIMIT_MIN,
} from '../rewards-constants';
import {IsAssignedUserSelectedPipe} from './is-assigned-user-selected.pipe';
import {minMaxValidator} from './min-max-validator';

interface DialogData {
  reward: Reward | null;
  coinTypes: RewardCoin[];
  users: User[];
  usersMap: Record<string, User>;
  page: string;
  params: ListSearchParams;
}

@Component({
  selector: 'rewards-dialog',
  standalone: true,
  imports: [
    OphXButtonComponent,
    OphButtonModule,
    OphInputOrangeComponent,
    ReactiveFormsModule,
    MatMenuModule,
    MatIconModule,
    PipesModule,
    RewardCoinIconPipe,
    SharedTasksModule,
    OphCheckboxComponent,
    TaskDialogModule,
    OphAvatarComponent,
    OphLoadingModule,
    AsyncPipe,
    IsAssignedUserSelectedPipe,
  ],
  templateUrl: './rewards-dialog.component.html',
  styleUrl: './rewards-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RewardsDialogComponent implements OnInit {
  readonly dialogRef = inject(MatDialogRef<RewardsDialogComponent>);
  readonly data = inject<DialogData>(MAT_DIALOG_DATA);
  readonly reward = this.data.reward;
  readonly coinTypes = this.data.coinTypes;
  users = this.data.users;
  usersMap = this.data.usersMap;
  page = this.data.page;
  params = this.data.params;
  coinTypesMap: Record<string, RewardCoin> = {};
  view = 'form';
  inputStyle = {height: '40px', borderRadius: '6px', fontSize: '16px', fontWeight: '400px'};
  loadingSave = false;
  coinTypeMenuOpen = false;
  rewardCostMin = REWARD_COST_MIN;
  rewardCostMax = REWARD_COST_MAX;
  rewardCostError = REWARD_COST_ERROR;
  rewardLimitMin = REWARD_LIMIT_MIN;
  rewardLimitMax = REWARD_LIMIT_MAX;
  rewardLimitError = REWARD_LIMIT_ERROR;

  loading$ = new BehaviorSubject<boolean>(false);

  form = this.fb.group<{
    name: FormControl<string | null>;
    description: FormControl<string | null>;
    coinType: FormControl<string | null>;
    rewardCost: FormControl<number | null>;
    expirationDate: FormControl<string | null>;
    claimLimit: FormControl<number>;
    claimCount: FormControl<number>;
    unlimited: FormControl<boolean>;
    assignedTo: FormArray;
  }>({
    name: this.fb.control('', Validators.required),
    description: this.fb.control('', Validators.required),
    coinType: this.fb.control('', Validators.required),
    rewardCost: this.fb.control(null, [Validators.required, minMaxValidator(this.rewardCostMin, this.rewardCostMax)]),
    expirationDate: this.fb.control('', Validators.required),
    claimLimit: this.fb.control(1, [Validators.required, minMaxValidator(this.rewardLimitMin, this.rewardLimitMax)]),
    claimCount: this.fb.control(0),
    unlimited: this.fb.control(false),
    assignedTo: this.fb.array([]),
  });

  constructor(
    private fb: FormBuilder,
    private store$: Store,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    if (this.reward) {
      this.loading$.next(true);
      this.getReward();
    } else {
      this.fillForm(null);
    }
    this.coinTypes.forEach(coinType => {
      this.coinTypesMap[coinType._id] = coinType;
    });
  }

  getReward() {
    this.store$.dispatch(
      new GetSingleRewardAction({
        rewardId: this.reward._id,
        onSuccess: (reward: Reward) => {
          this.fillForm(reward);
          this.loading$.next(false);
        },
      })
    );
  }

  fillForm(reward: Reward) {
    this.form.setValue({
      name: reward?.name || '',
      description: reward?.description || '',
      coinType: reward?.coinType || '',
      rewardCost: reward?.rewardCost || null,
      expirationDate: reward?.expirationDate || '',
      claimLimit: reward?.claimLimit || 1,
      claimCount: reward?.claimCount || 0,
      unlimited: reward?.unlimited || false,
      assignedTo: [],
    });

    this.assignedToFormArray.clear();

    reward?.assignedTo.forEach(user => {
      this.assignedToFormArray.push(new FormControl({...user}));
    });
  }

  onCoinType(coin: RewardCoin) {
    this.form.patchValue({coinType: coin._id});
  }

  onCoinTypeMenuClosed() {
    this.coinTypeMenuOpen = false;
    this.coinTypeControl.markAsTouched();
  }

  onDateChange() {
    this.expirationDateControl.markAsTouched();
    this.view = 'form';
  }

  onAddUser() {
    this.view = 'user';
  }

  onUser(userId: string) {
    // See if user exists already
    const index = this.assignedToFormArray.controls.findIndex(control => control.value.userId === userId);

    // If exists
    if (index !== -1) {
      const existingUser = this.assignedToFormArray.at(index).value;
      this.assignedToFormArray
        .at(index)
        .setValue({...existingUser, inactive: !this.assignedToFormArray.at(index).value.inactive});
    } else {
      this.assignedToFormArray.push(new FormControl({userId, inactive: false}));
    }
  }

  removeAssignedToUser(userId: string) {
    const index = this.assignedToFormArray.controls.findIndex(control => control.value.userId === userId);
    if (index !== -1) {
      this.assignedToFormArray.removeAt(index);
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSave() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.loadingSave = true;

    if (this.reward) {
      this.updateReward();
    } else {
      this.createReward();
    }
  }

  createReward() {
    const reward = this.getRewardFromForm();
    this.store$.dispatch(
      new CreateRewardAction({
        reward,
        onSuccess: () => this.onSaveSuccess(),
        onFailure: err => this.onSaveFailure(err),
      })
    );
  }

  getRewardFromForm(): Reward {
    return {
      ...this.form.value,
      claimLimit: +this.form.value.claimLimit,
    } as Reward;
  }

  onSaveSuccess() {
    if (this.page === 'manage') {
      this.getManageRewards();
    }

    if (this.page === 'history') {
      this.getAdminHistory();
    }
  }

  getManageRewards() {
    this.store$.dispatch(
      new GetManageRewardsAction({
        onSuccess: () => this.onSuccess(),
        onFailure: err => this.onFailure(err),
      })
    );
  }

  getAdminHistory() {
    this.store$.dispatch(
      new GetRewardsAdminHistoryAction({
        params: this.params,
        onSuccess: () => this.onSuccess(),
        onFailure: err => this.onFailure(err),
      })
    );
  }

  onSuccess() {
    this.dialogRef.close();
    this.loadingSave = false;
  }

  onFailure(err: HttpErrorResponse) {
    this.loadingSave = false;
    this.messageService.add(err.error || 'There was a problem retrieving rewards');
  }

  onSaveFailure(err: HttpErrorResponse) {
    this.loadingSave = false;
    this.messageService.add(err.error || 'Failed to save reward');
  }

  updateReward() {
    const reward = this.getRewardFromForm();
    this.store$.dispatch(
      new UpdateRewardAction({
        rewardId: this.reward._id,
        reward,
        onSuccess: () => this.onSaveSuccess(),
        onFailure: err => this.onSaveFailure(err),
      })
    );
  }

  onRemoveDecimals(value: string, control: AbstractControl) {
    control.setValue(value.replace(/\.\d*$/, ''));
  }

  get nameControl(): AbstractControl {
    return this.form.get('name');
  }

  get descriptionControl(): AbstractControl {
    return this.form.get('description');
  }

  get coinTypeControl(): AbstractControl {
    return this.form.get('coinType');
  }

  get rewardCostControl(): AbstractControl {
    return this.form.get('rewardCost');
  }

  get expirationDateControl(): AbstractControl {
    return this.form.get('expirationDate');
  }

  get claimLimitControl(): AbstractControl {
    return this.form.get('claimLimit');
  }

  get assignedToFormArray(): FormArray {
    return this.form.get('assignedTo') as FormArray;
  }
}
