import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Store, select} from '@ngrx/store';
import {Observable} from 'rxjs';
import {UpdateSelectedSkedTypeAction} from 'src/app/core/store/current-sked/current-sked.action';
import {selectSelectedSkedType} from 'src/app/core/store/current-sked/current-sked.selector';

@Component({
  selector: 'current-sked-selector',
  templateUrl: './current-sked-selector.component.html',
  styleUrls: ['./current-sked-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentSkedSelectorComponent implements OnInit {
  selectedSkedType$: Observable<'current' | 'all'>;
  skedOptions = ['current', 'all'];

  constructor(private store$: Store) {}

  ngOnInit() {
    this.selectedSkedType$ = this.store$.pipe(select(selectSelectedSkedType));
  }

  onSkedType(selectedSkedType: 'current' | 'all') {
    this.store$.dispatch(new UpdateSelectedSkedTypeAction({selectedSkedType}));
  }
}
