import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {Store, select} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {filter, throttleTime} from 'rxjs/operators';
import {selectActiveUser} from 'src/app/core/store/active-user/active-user.selector';
import {
  GetLensSettingsAction,
  GetUserLensAction,
  GetUserLensSuccessAction,
} from 'src/app/core/store/lenses/lenses.action';
import {AuthenticationService} from '../../auth/auth.service';
import {MobileService} from '../../core/page/mobile.service';
import {User} from 'src/app/core/model/user';

@Component({
  selector: 'page-wrapper',
  templateUrl: './page-wrapper.component.html',
  styleUrls: ['./page-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageWrapperComponent implements OnInit, OnDestroy {
  subscription = new Subscription();

  title = 'Ophanim';
  user$: Observable<User>;
  public authenticated$: Observable<boolean>;
  public mobile$: Observable<boolean>;

  constructor(
    private authenticationService: AuthenticationService,
    private mobileService: MobileService,
    private store$: Store
  ) {}

  public ngOnInit() {
    this.user$ = this.store$.pipe(select(selectActiveUser));
    this.authenticated$ = this.authenticationService.isLoggedIn;
    this.mobile$ = this.mobileService.observeMobile().pipe(throttleTime(500));
    this.subscription.add(this.subscribeToUser());
    this.subscription.add(this.subscribeToAuthenticated());
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  subscribeToAuthenticated(): Subscription {
    return this.authenticated$.subscribe(auth => {
      if (auth) {
        this.store$.dispatch(new GetLensSettingsAction({}));
      }
    });
  }

  subscribeToUser(): Subscription {
    return this.user$.pipe(filter(user => !!user)).subscribe(user => {
      //don't make the call if legacy user lens is present
      if (user.lens && user.lens !== 'Default' && user.lens !== 'Focused' && user.lens !== 'Advanced') {
        this.store$.dispatch(new GetUserLensAction({id: user.lens}));
      } else {
        this.store$.dispatch(new GetUserLensSuccessAction({userLens: null, userLensSettingsMap: {}}));
      }
    });
  }
}
