import {Action} from '@ngrx/store';
import {AutoSked} from 'src/app/schedule/shared/model/autosked';
import {ScheduleQueryParams} from 'src/app/schedule/shared/model/schedule-query-params';
import {SkedParams} from '../../model/sked-params';
import {Sked} from 'src/app/current-sked/shared/model/sked';

export enum ScheduleActionType {
  UPDATE_QUERY_PARAMS = '[Schedule] Update Query Params',

  GET_AUTOSKEDS = '[Schedule] Get AutoSkeds',
  GET_AUTOSKEDS_SUCCESS = '[Schedule] Get AutoSkeds :: Success',

  GET_AUTOSKED = '[Schedule] Get AutoSked',
  GET_AUTOSKED_SUCCESS = '[Schedule] Get AutoSked :: Success',

  CREATE_AUTOSKED = '[Schedule] Create AutoSked',
  CREATE_AUTOSKED_SUCCESS = '[Schedule] Create AutoSked :: Success',

  UPDATE_AUTOSKED = '[Schedule] Update AutoSked',
  UPDATE_AUTOSKED_SUCCESS = '[Schedule] Update AutoSked :: Success',

  DELETE_AUTOSKED = '[Schedule] Delete AutoSked',
  DELETE_AUTOSKED_SUCCESS = '[Schedule] Delete AutoSked :: Success',

  GET_SKEDS = '[Schedule] Get Skeds',
  GET_SKEDS_SUCCESS = '[Schedule] Get Skeds :: Success',
}

export class UpdateScheduleQueryParams implements Action {
  public readonly type = ScheduleActionType.UPDATE_QUERY_PARAMS;

  public constructor(public payload: {params: ScheduleQueryParams}) {}
}

export class GetAutoSkeds implements Action {
  public readonly type = ScheduleActionType.GET_AUTOSKEDS;

  constructor(public payload: {onSuccess?: (autoSkeds: AutoSked[]) => void}) {}
}

export class GetAutoSkedsSuccess implements Action {
  public readonly type = ScheduleActionType.GET_AUTOSKEDS_SUCCESS;

  constructor(public payload: {autoSkeds: AutoSked[]}) {}
}

export class GetAutoSked implements Action {
  public readonly type = ScheduleActionType.GET_AUTOSKED;

  constructor(public payload: {autoSked: AutoSked; onSuccess?: (autoSked: AutoSked) => void}) {}
}

export class GetAutoSkedSuccess implements Action {
  public readonly type = ScheduleActionType.GET_AUTOSKED_SUCCESS;

  constructor(public payload: {autoSked: AutoSked}) {}
}

export class CreateAutoSked implements Action {
  public readonly type = ScheduleActionType.CREATE_AUTOSKED;

  constructor(public payload: {autoSked: AutoSked; onSuccess?: (autoSked: AutoSked) => void}) {}
}

export class CreateAutoSkedSuccess implements Action {
  public readonly type = ScheduleActionType.CREATE_AUTOSKED_SUCCESS;

  constructor(public payload: {autoSked: AutoSked}) {}
}

export class UpdateAutoSked implements Action {
  public readonly type = ScheduleActionType.UPDATE_AUTOSKED;

  constructor(
    public payload: {autoSked: AutoSked; onSuccess?: (autoSked: AutoSked) => void; onFailure?: (err: Error) => void}
  ) {}
}

export class UpdateAutoSkedSuccess implements Action {
  public readonly type = ScheduleActionType.UPDATE_AUTOSKED_SUCCESS;

  constructor(public payload: {autoSked: AutoSked}) {}
}

export class DeleteAutoSked implements Action {
  public readonly type = ScheduleActionType.DELETE_AUTOSKED;

  constructor(public payload: {autoSked: AutoSked; onSuccess?: (autoSked: AutoSked) => void}) {}
}

export class DeleteAutoSkedSuccess implements Action {
  public readonly type = ScheduleActionType.DELETE_AUTOSKED_SUCCESS;

  constructor(public payload: {autoSked: AutoSked}) {}
}

export class GetSkedsAction implements Action {
  public readonly type = ScheduleActionType.GET_SKEDS;

  constructor(public payload: {params: SkedParams; onSuccess?: () => void}) {}
}

export class GetSkedsSuccessAction implements Action {
  public readonly type = ScheduleActionType.GET_SKEDS_SUCCESS;

  constructor(public payload: {skeds: Sked[]}) {}
}

export type ScheduleAction =
  | UpdateScheduleQueryParams
  | GetAutoSkeds
  | GetAutoSkedsSuccess
  | GetAutoSked
  | GetAutoSkedSuccess
  | CreateAutoSked
  | CreateAutoSkedSuccess
  | UpdateAutoSked
  | UpdateAutoSkedSuccess
  | DeleteAutoSked
  | DeleteAutoSkedSuccess
  | GetSkedsAction
  | GetSkedsSuccessAction;
