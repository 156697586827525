<button class="sked-selector-button" [matMenuTriggerFor]="skedMenu">
  <span class="button-text">{{ (selectedSkedType$ | async) === 'current' ? 'Current sked' : 'All day' }}</span>
  <oph-icon name="chevron-down-orange" size="24"></oph-icon>
</button>
<mat-menu #skedMenu="matMenu" class="oph-selector-menu">
  @for (option of skedOptions; track option) {
    <button class="menu-button" mat-menu-item [value]="sked" (click)="onSkedType(option)">
      {{ option === 'current' ? 'Current sked' : 'All day' }}
    </button>
  }
</mat-menu>
