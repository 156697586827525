import {createSelector} from '@ngrx/store';
import {AppState} from '../app-state';

export const selectScheduleState = (state: AppState) => state.schedule;

export const selectScheduleQueryParams = createSelector(selectScheduleState, state => state.queryParams);

export const selectScheduleAutoSkeds = createSelector(selectScheduleState, state => state.autoSkeds);

export const selectScheduleSkeds = createSelector(selectScheduleState, state => state.skeds);
