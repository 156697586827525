import {TitleCasePipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ProjectViewActivity} from 'src/app/core/model/project';
import {User} from 'src/app/core/model/user';
import {ProjectViewActivityActionPipe} from '../pipes/project-view-activity-action.pipe';
import {PipesModule} from 'src/app/shared/pipes/pipes.module';

@Component({
  selector: 'project-view-activity-item',
  standalone: true,
  imports: [TitleCasePipe, ProjectViewActivityActionPipe, PipesModule],
  templateUrl: './project-view-activity-item.component.html',
  styleUrl: './project-view-activity-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectViewActivityItemComponent {
  @Input() activityItem: ProjectViewActivity;
  @Input() user: User;
}
