import {Injectable} from '@angular/core';
import {AbstractControl, FormGroup, NonNullableFormBuilder} from '@angular/forms';
import {Tracker, TrackerMetric} from 'src/app/core/model/tracker';
import {Project} from 'src/app/core/model/project';

@Injectable({
  providedIn: 'root',
})
export class TrackersService {
  constructor(private formBuilder: NonNullableFormBuilder) {}

  getTrackerForm(tracker?: Tracker): FormGroup {
    return this.formBuilder.group({
      name: [tracker?.name || ''],
      projectIds: this.formBuilder.array(
        tracker?.projectIds ? tracker.projectIds.map(projectId => this.formBuilder.control(projectId)) : []
      ),
    });
  }

  getProjectsFormGroup(project?: Partial<Project>): AbstractControl {
    return this.formBuilder.group({
      _id: [project?._id || ''],
      name: [project?.label || ''],
      color: [project?.color || ''],
    });
  }

  getMetricsFormGroup(metric?: TrackerMetric): FormGroup {
    return this.formBuilder.group({
      name: [metric?.name || ''],
      type: [metric?.type || 'numeric'],
      uom: [metric?.uom || ''],
      min: [metric?.min || ''],
      max: [metric?.max || ''],
      options: this.formBuilder.array(
        metric?.options
          ? metric.options.map(option => this.getOptionsFormGroup(metric?.defaultValue, option))
          : [
              this.formBuilder.group({
                value: [''],
                default: [false],
              }),
            ]
      ),
      updateable: [metric?.updateable === undefined ? true : metric?.updateable],
      defaultValue: [metric?.defaultValue || ''],
      value: [metric?.value || ''],
    });
  }

  getOptionsFormGroup(defaultValue: string, option?: string) {
    return this.formBuilder.group({
      value: [option || ''],
      default: [option ? option === defaultValue : false],
    });
  }
}
