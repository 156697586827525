@if (!teamsControl?.value.length && !usersControl?.value.length && !requirementsControl?.value.length) {
  <button class="add-requirement-button" (click)="onAddRequirement()">
    <img src="assets/img/icons/plus-brown-filled.svg" />
    Add provision
  </button>
} @else {
  <button class="small-add-requirement-button" (click)="onAddRequirement()">
    <img src="assets/img/icons/plus-brown-filled.svg" />
  </button>
}
@for (team of teamsControl?.value; track team) {
  <div class="chip">
    <div class="avatar">{{ teamsMap[team]?.name | userAvatar | uppercase }}</div>
    <span>{{ teamsMap[team]?.name }}</span>
    <button class="remove-requirement-button" (click)="onRemoveTeam(team)">
      <img src="assets/img/icons/x-gray-filled.svg" />
    </button>
  </div>
}
@for (user of usersControl?.value; track user) {
  <div class="chip">
    <div class="avatar">{{ usersMap[user]?.username | userAvatar | uppercase }}</div>
    <span>{{ usersMap[user]?.username }}</span>
    <button class="remove-requirement-button" (click)="onRemoveUser(user)">
      <img src="assets/img/icons/x-gray-filled.svg" />
    </button>
  </div>
}
@for (requirement of requirementsControl?.value; track requirement) {
  <div class="chip">
    <img class="star-icon" src="assets/img/icons/star-dark-brown.svg" />
    <span>{{ requirementsMap[requirement]?.name }}</span>
    <button class="remove-requirement-button" (click)="onRemoveRequirement(requirement)">
      <img src="assets/img/icons/x-gray-filled.svg" />
    </button>
  </div>
}
