import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'oph-slide-toggle',
  standalone: true,
  imports: [],
  templateUrl: './oph-slide-toggle.component.html',
  styleUrl: './oph-slide-toggle.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OphSlideToggleComponent {
  @Input() checked: boolean;

  @Output() toggle = new EventEmitter<boolean>();

  onToggle() {
    this.toggle.emit(!this.checked);
  }
}
