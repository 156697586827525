import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {FormArray} from '@angular/forms';
import {TasksService} from 'src/app/shared/services/tasks.service';
import {TaskDialogPanels} from '../../shared/task-dialog-constants';
import {BehaviorSubject} from 'rxjs';
import {Subtask} from 'src/app/core/model/task';

@Component({
  selector: 'task-dialog-subtask-edit',
  templateUrl: './task-dialog-subtask-edit.component.html',
  styleUrls: ['./task-dialog-subtask-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskDialogSubtaskEditComponent implements OnChanges {
  @Input() formArray: FormArray;
  @Input() fromTemplate: boolean;

  @Output() panelChange = new EventEmitter<TaskDialogPanels>();
  @Output() imagesToDeleteChange = new EventEmitter<string[]>();

  isSubtaskComplete$ = new BehaviorSubject<boolean>(false);

  storedSubtask: Subtask;
  TaskDialogPanels = TaskDialogPanels;
  subtaskIndex: number;
  // These arrays will be compared to indicate images that need to be removed from the s3 bucket
  imagesArray: string[] = [];

  constructor(private tasksService: TasksService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.formArray && this.formArray) {
      this.subtaskIndex = this.tasksService.getSubtaskEditIndex();
      this.storedSubtask = {...this.formArray.at(this.subtaskIndex).value};
      // This finds all existing uploaded images - when an img is removed from editor, this array is used to compare which imgs still exist
      this.imagesArray = this.tasksService.getImgArrayFromHTMLString(
        this.formArray.at(this.subtaskIndex).value?.body || ''
      );
    }
  }

  onCancel() {
    this.removeImages(true);

    if (this.tasksService.getEditingSubtask()) {
      this.formArray.at(this.subtaskIndex).setValue(this.storedSubtask);
    } else {
      this.formArray.removeAt(this.subtaskIndex);
    }
    this.panelChange.emit(TaskDialogPanels.Default);
  }

  onOk() {
    this.removeImages(false);
    this.panelChange.emit(TaskDialogPanels.Default);
  }

  onIsSubtaskComplete(complete: boolean) {
    this.isSubtaskComplete$.next(complete);
  }

  onImageAdded(image: string) {
    this.imagesArray.push(image);
  }

  removeImages(cancel: boolean) {
    let subtaskBody: string;
    if (cancel) {
      subtaskBody = this.storedSubtask?.body || '';
    } else {
      subtaskBody = this.formArray.at(this.subtaskIndex).value?.body || '';
    }
    const currentImageArray = this.tasksService.getImgArrayFromHTMLString(subtaskBody);
    const imagesToDelete = this.imagesArray.filter(img => !currentImageArray.includes(img));
    this.imagesToDeleteChange.emit(imagesToDelete);
  }
}
