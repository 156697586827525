import {TaskDialogTabs} from './../../../shared/task-dialog-constants';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'task-dialog-tabs',
  templateUrl: './task-dialog-tabs.component.html',
  styleUrls: ['./task-dialog-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskDialogTabsComponent {
  @Input() selectedTab: string;
  @Input() triggerCount: number;
  @Input() subtaskCount: number;
  @Input() template: boolean;

  @Output() tabChange = new EventEmitter<string>();

  tabs: string[];
  TaskDialogTabs = TaskDialogTabs;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.template) {
      this.tabs = this.template ? ['details', 'subtasks'] : ['details', 'schedule', 'triggers', 'subtasks'];
    }
  }
}
