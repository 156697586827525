import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DeleteDialogModule} from './delete/delete-dialog.module';
import {DialogLayoutModule} from './layout/dialog-layout.module';
import {RenameDialogModule} from './rename/rename-dialog.module';
import {OphGeneralDialogModule} from './oph-general-dialog/oph-general-dialog.module';
import {SuccessDialogComponent} from './success-dialog/success-dialog.component';
import {MatIconModule} from '@angular/material/icon';
import {OphButtonModule} from '../design/oph-button/oph-button.module';
import {InfoConfirmDialogComponent} from './info-confirm-dialog/info-confirm-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    DeleteDialogModule,
    DialogLayoutModule,
    RenameDialogModule,
    OphGeneralDialogModule,
    MatIconModule,
    OphButtonModule,
  ],
  declarations: [SuccessDialogComponent, InfoConfirmDialogComponent],
})
export class SharedDialogModule {}
