import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ListSearchParams} from '../model/list-search-params';
import {Reward, RewardCoin, RewardHistory, RewardPagination} from '../model/reward';

@Injectable({
  providedIn: 'root',
})
export class RewardsApiService {
  public url = `${environment.apiUrl}/rewards`;

  constructor(private http: HttpClient) {}

  public getManageRewards(): Observable<RewardCoin[]> {
    return this.http.get<RewardCoin[]>(`${this.url}/admin/all`);
  }

  public getMyRewards(): Observable<RewardCoin[]> {
    return this.http.get<RewardCoin[]>(`${this.url}/myRewards`);
  }

  public getUserHistory(params: ListSearchParams): Observable<RewardHistory> {
    return this.http.get<RewardHistory>(`${this.url}/history`, {params: {...params}});
  }

  public getAdminHistory(params: ListSearchParams): Observable<RewardPagination> {
    return this.http.get<RewardPagination>(`${this.url}/admin/history`, {params: {...params}});
  }

  public getSingleReward(rewardId: string): Observable<Reward> {
    return this.http.get<Reward>(`${this.url}/${rewardId}`);
  }

  public create(reward: Reward): Observable<Reward> {
    return this.http.post<Reward>(`${this.url}/addreward`, reward);
  }

  public update(rewardId: string, reward: Reward): Observable<Reward> {
    return this.http.put<Reward>(`${this.url}/${rewardId}`, reward);
  }

  public delete(id: string): Observable<Object> {
    return this.http.delete(`${this.url}/${id}`);
  }

  public getCoinTypes(): Observable<RewardCoin[]> {
    return this.http.get<RewardCoin[]>(`${environment.apiUrl}/coins`);
  }

  public fulfillReward(rewardId: string, assignedToArrayId: string): Observable<unknown> {
    return this.http.post<unknown>(`${this.url}/fulfill/${rewardId}/${assignedToArrayId}`, {});
  }

  public claimReward(rewardId: string, assignedToArrayId: string): Observable<unknown> {
    return this.http.post<unknown>(`${this.url}/claim/${rewardId}/${assignedToArrayId}`, null);
  }
}
