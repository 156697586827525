import {Sked} from 'src/app/current-sked/shared/model/sked';
import {createEntityAdapter} from '@ngrx/entity';
import {AutoSked} from 'src/app/schedule/shared/model/autosked';
import {ScheduleQueryParams} from 'src/app/schedule/shared/model/schedule-query-params';

export interface ScheduleState {
  queryParams: ScheduleQueryParams;
  autoSkeds: AutoSked[];
  currentAutoSked: AutoSked;
  skeds: Sked[];
}

export const scheduleAdapter = createEntityAdapter<ScheduleState>();

export const initialScheduleState: ScheduleState = scheduleAdapter.getInitialState({
  queryParams: {} as ScheduleQueryParams,
  autoSkeds: [],
  currentAutoSked: null,
  skeds: [],
});
