import {BreakpointObserver} from '@angular/cdk/layout';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {Store, select} from '@ngrx/store';
import {BehaviorSubject, Observable} from 'rxjs';
import {SkillModel} from 'src/app/core/model/skill';
import {User} from 'src/app/core/model/user';
import {selectSkillsMap} from 'src/app/core/store/skills/skills.selector';
import {selectTeamsMap} from 'src/app/core/store/teams/teams.selector';
import {selectUsersMap} from 'src/app/core/store/users/users.selector';
import {Team} from 'src/app/teams/shared/model/team';
import {TaskDialogPanels} from '../../../shared/task-dialog-constants';

@Component({
  selector: 'task-dialog-details-panel',
  templateUrl: './task-dialog-details-panel.component.html',
  styleUrls: ['./task-dialog-details-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskDialogDetailsPanelComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() template: boolean;

  @Output() panelChange = new EventEmitter<TaskDialogPanels>();

  teamsMap$: Observable<Record<string, Team>>;
  usersMap$: Observable<Record<string, User>>;
  requirementsMap$: Observable<Record<string, SkillModel>>;

  isMobile$ = new BehaviorSubject<boolean>(false);

  constructor(
    private store$: Store,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit() {
    this.teamsMap$ = this.store$.pipe(select(selectTeamsMap));
    this.usersMap$ = this.store$.pipe(select(selectUsersMap));
    this.requirementsMap$ = this.store$.pipe(select(selectSkillsMap));

    this.breakpointObserver.observe(['(max-width: 722px)']).subscribe(result => {
      this.isMobile$.next(result.matches);
    });
  }

  onPriority(priority: number) {
    this.priorityControl.setValue(priority);
  }

  onRemoveTeam(teamId: string) {
    const teams = this.teamsControl.value.filter(team => team !== teamId);
    this.teamsControl.setValue(teams);
  }

  onRemoveUser(userId: string) {
    const users = this.usersControl.value.filter(user => user !== userId);
    this.usersControl.setValue(users);
  }

  onRemoveRequirement(requirementId: string) {
    const requirements = this.requirementsControl.value.filter(requirement => requirement !== requirementId);
    this.requirementsControl.setValue(requirements);
  }

  get priorityControl(): AbstractControl {
    return this.form.get('priority');
  }

  get locationControl(): AbstractControl {
    return this.form.get('location');
  }

  get descriptionControl(): AbstractControl {
    return this.form.get('description');
  }

  get teamsControl(): AbstractControl {
    return this.form.get('teams');
  }

  get usersControl(): AbstractControl {
    return this.form.get('users');
  }

  get requirementsControl(): AbstractControl {
    return this.form.get('requirements');
  }
}
