import {APP_INITIALIZER, NgModule} from '@angular/core';
import {DateAdapter} from '@angular/material/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthModule} from './auth/auth.module';
import {CoreModule} from './core/core.module';
import {AppInitService} from './services/app-init.service';
import {CustomDateAdapter} from './services/custom-date-adapter.service';
import {SharedModule} from './shared/shared.module';
import {MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import {provideCharts, withDefaultRegisterables} from 'ng2-charts';

export function initializeApp(appInitService: AppInitService) {
  // eslint-disable-next-line
  return (): Promise<any> => {
    return appInitService.initApp(window.location.pathname);
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AuthModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    MatDialogModule,
  ],
  providers: [
    DatePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitService],
      multi: true,
    },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
    },
    {
      provide: MatDialogRef,
      useValue: {},
    },
    provideCharts(withDefaultRegisterables()),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
