import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {ProjectsApiService} from '../../api/projects-api.service';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {
  CreateProjectAction,
  CreateProjectSuccessAction,
  CreateTaskTemplateAction,
  DeleteProjectAction,
  DeleteProjectSuccessAction,
  DeleteTaskTemplateAction,
  DuplicateProjectAction,
  DuplicateProjectSuccessAction,
  GetProjectsAction,
  GetProjectsSuccessAction,
  GetProjectViewAction,
  GetProjectViewRoutineDetailAction,
  GetProjectViewRoutineDetailSuccessAction,
  GetProjectViewSuccessAction,
  GetTaskTemplatesAction,
  GetTaskTemplatesSuccessAction,
  ProjectsActionType,
  UpdateProjectAction,
  UpdateProjectSuccessAction,
  UpdateProjectTasksAction,
  UpdateTaskTemplateAction,
} from './projects.action';

@Injectable()
export class ProjectsEffects {
  public get$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetProjectsAction>(ProjectsActionType.GET),
      mergeMap(action => {
        const {onSuccess, onFailure} = action.payload;

        return this.projectsApiService.get().pipe(
          mergeMap(projects => [new GetProjectsSuccessAction({projects}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public create$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateProjectAction>(ProjectsActionType.CREATE),
      mergeMap(action => {
        const {project, onSuccess, onFailure} = action.payload;

        return this.projectsApiService.create(project).pipe(
          mergeMap(project => [
            new CreateProjectSuccessAction({project}),
            ...createCallbackActions(onSuccess, project),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public update$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateProjectAction>(ProjectsActionType.UPDATE),
      mergeMap(action => {
        const {project, onSuccess, onFailure} = action.payload;

        return this.projectsApiService.update(project).pipe(
          mergeMap(p => [new UpdateProjectSuccessAction({project: p}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  duplicate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DuplicateProjectAction>(ProjectsActionType.DUPLICATE),
      mergeMap(action => {
        const {id, onSuccess, onFailure} = action.payload;

        return this.projectsApiService.duplicate(id).pipe(
          mergeMap(project => [
            new DuplicateProjectSuccessAction({project}),
            ...createCallbackActions(onSuccess, project),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public delete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteProjectAction>(ProjectsActionType.DELETE),
      mergeMap(action => {
        const {id, onSuccess, onFailure} = action.payload;

        return this.projectsApiService.delete(id).pipe(
          mergeMap(() => [new DeleteProjectSuccessAction(), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public updateProjectTasks$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateProjectTasksAction>(ProjectsActionType.UPDATE_PROJECT_TASKS),
      mergeMap(action => {
        const {id, projectPartial, index, task, taskDeleted, onSuccess, onFailure} = action.payload;
        return this.projectsApiService.updateProjectTasks(id, projectPartial, index, task, taskDeleted).pipe(
          mergeMap(project => [new UpdateProjectSuccessAction({project}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getProjectView$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetProjectViewAction>(ProjectsActionType.GET_PROJECT_VIEW),
      mergeMap(action => {
        const {projectId, onSuccess, onFailure} = action.payload;

        return this.projectsApiService.getProjectView(projectId).pipe(
          mergeMap(projectView => [
            new GetProjectViewSuccessAction({projectView}),
            ...createCallbackActions(onSuccess, projectView),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getRoutineDetail$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetProjectViewRoutineDetailAction>(ProjectsActionType.GET_ROUTINE_DETAIL),
      mergeMap(action => {
        const {taskInstanceId, params, onSuccess, onFailure} = action.payload;

        return this.projectsApiService.getRoutineDetail(taskInstanceId, params).pipe(
          mergeMap(routineDetail => [
            new GetProjectViewRoutineDetailSuccessAction({routineDetail, taskInstanceId}),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getTaskTemplates$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetTaskTemplatesAction>(ProjectsActionType.GET_TASK_TEMPLATES),
      mergeMap(action => {
        const {search, onSuccess, onFailure} = action.payload;

        return this.projectsApiService.getTaskTemplates(search).pipe(
          mergeMap(taskTemplates => [
            new GetTaskTemplatesSuccessAction({taskTemplates}),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public createTaskTemplate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateTaskTemplateAction>(ProjectsActionType.CREATE_TASK_TEMPLATE),
      mergeMap(action => {
        const {task, onSuccess, onFailure} = action.payload;

        return this.projectsApiService.createTaskTemplate(task).pipe(
          mergeMap(task => [...createCallbackActions(onSuccess, task)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  updateTaskTemplate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateTaskTemplateAction>(ProjectsActionType.UPDATE_TASK_TEMPLATE),
      mergeMap(action => {
        return this.projectsApiService
          .updateTaskTemplate(action.payload.task)
          .pipe(mergeMap(() => [...createCallbackActions(action.payload.onSuccess)]));
      })
    )
  );

  deleteTaskTemplate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteTaskTemplateAction>(ProjectsActionType.DELETE_TASK_TEMPLATE),
      mergeMap(action => {
        const {id, onSuccess, onFailure} = action.payload;

        return this.projectsApiService.deleteTaskTemplate(id).pipe(
          mergeMap(() => [...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private projectsApiService: ProjectsApiService
  ) {}
}
