import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {OphIconModule} from 'src/app/shared/design/oph-icon/oph-icon.module';
import {SharedDubaiThemeModule} from '../../shared/dubai-theme/shared-dubai-theme.module';
import {CurrentSkedTaskDetailDubaiThemeComponent} from './current-sked-task-detail-dubai-theme.component';
import {CurrentSkedTaskDetailDubaiThemeHeaderComponent} from './header/current-sked-task-detail-dubai-theme-header.component';
import {CurrentSkedTaskDetailDubaiThemeInfoContainerComponent} from './info-container/current-sked-task-detail-dubai-theme-info-container.component';
import {CurrentSkedTaskListDubaiThemeSubtaskCardComponent} from './subtask-card/current-sked-task-list-dubai-theme-subtask-card.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatRippleModule} from '@angular/material/core';
import {PipesModule} from 'src/app/shared/pipes/pipes.module';
import {OphLoadingModule} from 'src/app/shared/design/oph-loading/oph-loading.module';
import {QuillModule} from 'ngx-quill';

@NgModule({
  declarations: [
    CurrentSkedTaskDetailDubaiThemeComponent,
    CurrentSkedTaskDetailDubaiThemeHeaderComponent,
    CurrentSkedTaskDetailDubaiThemeInfoContainerComponent,
    CurrentSkedTaskListDubaiThemeSubtaskCardComponent,
  ],
  imports: [
    CommonModule,
    SharedDubaiThemeModule,
    OphIconModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatRippleModule,
    PipesModule,
    MatProgressSpinnerModule,
    OphLoadingModule,
    QuillModule.forRoot(),
  ],
  exports: [CurrentSkedTaskDetailDubaiThemeComponent],
})
export class CurrentSkedTaskDetailDubaiThemeModule {}
