import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';

@Component({
  selector: 'oph-loading',
  templateUrl: './oph-loading.component.html',
  styleUrls: ['./oph-loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OphLoadingComponent implements OnChanges {
  @Input() text: string;
  @Input() diameter: number;
  @Input() color: string;
  @Input() strokeWidth: number;

  colorClass = 'oph-orange-spinner';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.color) {
      this.colorClass = this.getColorClass();
    }
  }

  getColorClass(): string {
    return `oph-${this.color}-spinner`;
  }
}
