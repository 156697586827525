@if ({existingSubtasks: existingSubtasks$ | async}; as state) {
  <button
    class="add-subtask-button"
    [class.large]="!state.existingSubtasks"
    [class.small]="state.existingSubtasks"
    (click)="onAddSubtask()"
  >
    <img src="assets/img/icons/plus-circle-orange.svg" />
    Add subtask
  </button>
  @if (state.existingSubtasks) {
    <subtask-list
      [formArray]="formArray"
      itemBackground="light"
      (subtaskIndexChange)="onEditSubtask($event)"
    ></subtask-list>
  }
}
