import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Location} from '../../locations/shared/model/location';
import {LocationDto} from '../../locations/shared/dto/location.dto';

@Injectable({
  providedIn: 'root',
})
export class LocationsApiService {
  public url = `${environment.apiUrl}/locations`;

  constructor(private http: HttpClient) {}

  public get(): Observable<LocationDto[]> {
    return this.http.get<LocationDto[]>(this.url);
  }

  public create(location: Partial<Location>): Observable<Location> {
    return this.http.post<Location>(`${this.url}`, location);
  }

  public update(location: Location): Observable<Location> {
    return this.http.put<Location>(`${this.url}/${location.id}`, location);
  }

  public delete(id: string): Observable<Object> {
    return this.http.delete(`${this.url}/${id}`);
  }
}
