import {AsyncPipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, HostBinding, inject, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, debounceTime, filter, Observable, Subscription, take, withLatestFrom} from 'rxjs';
import {Project} from 'src/app/core/model/project';
import {Tracker} from 'src/app/core/model/tracker';
import {User} from 'src/app/core/model/user';
import {GetProjectsAction} from 'src/app/core/store/projects/projects.action';
import {selectAllProjects, selectProjectsMap} from 'src/app/core/store/projects/projects.selector';
import {selectRouterParam} from 'src/app/core/store/router/router.selector';
import {
  DeleteTrackerAction,
  GetSingleTrackerAction,
  UpdateTempTrackerProjectIdAction,
  UpdateTrackerAction,
} from 'src/app/core/store/trackers/trackers.action';
import {selectSingleTracker, selectTempTrackerProjectId} from 'src/app/core/store/trackers/trackers.selector';
import {GetAllUsersAction} from 'src/app/core/store/users/users.action';
import {selectUsersMap} from 'src/app/core/store/users/users.selector';
import {MessageService} from 'src/app/services/message.service';
import {OphBackButtonComponent} from 'src/app/shared/design/oph-back-button/oph-back-button.component';
import {OphButtonModule} from 'src/app/shared/design/oph-button/oph-button.module';
import {OphInputOrangeComponent} from 'src/app/shared/design/oph-inputs/oph-input-orange/oph-input-orange.component';
import {OphLoadingModule} from 'src/app/shared/design/oph-loading/oph-loading.module';
import {ListViewsModule} from 'src/app/shared/list-views/list-views.module';
import {ListViewWarningDialogComponent} from 'src/app/shared/list-views/shared/warning-dialog/list-view-warning-dialog.component';
import {PipesModule} from 'src/app/shared/pipes/pipes.module';
import {TrackersService} from 'src/app/shared/services/trackers.service';
import {DuplicateTrackerAction} from './../../core/store/trackers/trackers.action';
import {TrackerDetailMetricsComponent} from './metrics/tracker-detail-metrics.component';
import {TrackerDetailProjectSelectionComponent} from './project-selection/tracker-detail-project-selection.component';

@Component({
  selector: 'tracker-detail',
  standalone: true,
  imports: [
    OphBackButtonComponent,
    MatIconModule,
    OphInputOrangeComponent,
    ListViewsModule,
    PipesModule,
    AsyncPipe,
    OphLoadingModule,
    OphButtonModule,
    TrackerDetailProjectSelectionComponent,
    TrackerDetailMetricsComponent,
  ],
  templateUrl: './tracker-detail.component.html',
  styleUrl: './tracker-detail.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackerDetailComponent implements OnInit, OnDestroy {
  @HostBinding('class') hostClasses = 'oph-feature-layout oph-feature-padding';

  readonly dialog = inject(MatDialog);

  subscriptions = new Subscription();

  returnTo$: Observable<string> = this.store$.pipe(select(selectRouterParam('returnTo')));
  trackerId$: Observable<string> = this.store$.pipe(select(selectRouterParam('id')));
  tracker$: Observable<Tracker> = this.store$.pipe(select(selectSingleTracker));
  projects$: Observable<Project[]> = this.store$.pipe(select(selectAllProjects));
  projectsMap$: Observable<Record<string, Project>> = this.store$.pipe(select(selectProjectsMap));
  usersMap$: Observable<Record<string, User>> = this.store$.pipe(select(selectUsersMap));
  tempTrackerProjectId$: Observable<string> = this.store$.pipe(select(selectTempTrackerProjectId));

  loadingTracker$ = new BehaviorSubject<boolean>(true);
  mobileActiveTab$ = new BehaviorSubject<'metrics' | 'projects'>('metrics');

  form: FormGroup;
  menuOptions = ['duplicate', 'delete'];
  mobileTabs = ['metrics', 'projects'];

  constructor(
    private router: Router,
    private store$: Store,
    private trackersService: TrackersService,
    private messageService: MessageService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.store$.dispatch(new GetProjectsAction({}));
    this.store$.dispatch(new GetAllUsersAction({}));

    this.subscriptions.add(this.subscribeToTrackerId());
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  subscribeToTrackerId(): Subscription {
    return this.trackerId$.pipe(filter(id => !!id)).subscribe(id => {
      this.store$.dispatch(
        new GetSingleTrackerAction({
          id,
          onSuccess: trackerDetailData => this.onGetSingleTrackerSuccess(trackerDetailData?.tracker),
          onFailure: err => this.onGetSingleTrackerFailure(err),
        })
      );
    });
  }

  onGetSingleTrackerSuccess(tracker: Tracker) {
    this.form = this.trackersService.getTrackerForm(tracker);
    this.loadingTracker$.next(false);
    this.subscriptions.add(this.subscribeToFormChanges());
    this.checkToTempTrackerProjectId();
  }

  onGetSingleTrackerFailure(err: Error) {
    this.messageService.add(err.message || 'There was a problem getting the Tracker.');
    this.loadingTracker$.next(false);
  }

  subscribeToFormChanges(): Subscription {
    return this.form.valueChanges
      .pipe(debounceTime(400), withLatestFrom(this.tracker$))
      .subscribe(([form, tracker]) => {
        if (!tracker || !form?.name) {
          return;
        }
        this.store$.dispatch(new UpdateTrackerAction({tracker: {...form, _id: tracker._id}}));
      });
  }

  checkToTempTrackerProjectId() {
    this.tempTrackerProjectId$
      .pipe(
        filter(id => !!id),
        take(1)
      )
      .subscribe(id => {
        if (id) {
          const projectIdsFormArray = this.form.get('projectIds') as FormArray;
          if (projectIdsFormArray.value.some(projectId => projectId === id)) {
            return;
          }
          projectIdsFormArray.push(this.fb.control(id));
          this.store$.dispatch(new UpdateTempTrackerProjectIdAction({projectId: ''}));
        }
      });
  }

  onBack() {
    this.returnTo$.pipe(take(1)).subscribe(returnTo => {
      if (returnTo) {
        this.router.navigateByUrl(returnTo);
      } else {
        this.router.navigate(['trackers']);
      }
    });
  }

  onMenuAction(action: string) {
    if (action === 'duplicate') {
      this.duplicateTracker();
    }
    if (action === 'delete') {
      this.openDeleteConfirmation();
    }
  }

  duplicateTracker() {
    this.tracker$.pipe(take(1)).subscribe(tracker =>
      this.store$.dispatch(
        new DuplicateTrackerAction({
          trackerId: tracker._id,
          onSuccess: tracker => this.onDuplicateTrackerSuccess(tracker),
          onFailure: err => this.onDuplicateTrackerFailure(err),
        })
      )
    );
  }

  onDuplicateTrackerSuccess(tracker: Tracker) {
    if (tracker) {
      this.router.navigate(['trackers', tracker._id]);
    }
  }

  onDuplicateTrackerFailure(err: Error) {
    this.messageService.add(err.message || 'There was a problem duplicating the Tracker.');
  }

  openDeleteConfirmation() {
    this.tracker$.pipe(take(1)).subscribe(tracker => {
      const dialog = this.dialog.open(ListViewWarningDialogComponent, {
        backdropClass: 'oph-backdrop',
        panelClass: 'list-views-warning-dialog',
        data: {
          type: 'tracker',
          tracker,
          customClose: true,
          callback: () => this.deleteTracker(tracker, dialog),
        },
      });
    });
  }

  deleteTracker(tracker: Tracker, dialog: MatDialogRef<ListViewWarningDialogComponent>) {
    this.store$.dispatch(
      new DeleteTrackerAction({
        trackerId: tracker._id,
        onSuccess: () => this.onDeleteTrackerSuccess(dialog),
        onFailure: err => this.onDeleteTrackerFailure(err),
      })
    );
  }

  onDeleteTrackerSuccess(dialog: MatDialogRef<ListViewWarningDialogComponent>) {
    dialog.close();
    this.onBack();
  }

  onDeleteTrackerFailure(err: Error) {
    this.loadingTracker$.next(false);
    this.messageService.add(err.message || 'There was a problem deleting the Tracker.');
  }

  onMobileTab(tab: 'metrics' | 'projects') {
    this.mobileActiveTab$.next(tab);
  }

  get nameControl(): AbstractControl {
    return this.form.get('name');
  }
}
