<div class="container" [style.height.px]="height || 60"></div>
<input
  class="number-input"
  type="number"
  [placeholder]="placeholder || ''"
  [min]="min"
  [max]="max"
  [formControl]="control"
  [style.font-size.px]="fontSize || 16"
  [style.width.px]="inputWidth || 105"
  (blur)="onInputBlur($event.target.value)"
/>
<div class="arrows-container">
  <button class="arrow-button" (click)="onArrowUp()">
    <img src="assets/img/icons/chevron-small-up-brown.svg" />
  </button>
  <div class="divider"></div>
  <button class="arrow-button" (click)="onArrowDown()">
    <img src="assets/img/icons/chevron-small-down-brown.svg" />
  </button>
</div>
