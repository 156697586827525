import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TaskWizardDialogComponent} from './task-wizard-dialog.component';
import {TaskWizardDialogPanelItemComponent} from './panel-item/task-wizard-dialog-panel-item.component';
import {TaskWizardDialogCheckButtonComponent} from './check-button/task-wizard-dialog-check-button.component';
import {TwdTaskOrEventComponent} from './panels/twd-task-or-event/twd-task-or-event.component';
import {TwdBasicInfoComponent} from './panels/twd-basic-info/twd-basic-info.component';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedTasksModule} from '../shared-tasks.module';
import {TaskWizardDialogNumberInputComponent} from './shared/number-input/task-wizard-dialog-number-input.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {TwdEventDateComponent} from './panels/twd-event-date/twd-event-date.component';
import {TwdCalendarComponent} from './shared/calendar/twd-calendar.component';
import {TwdCalendarHeaderComponent} from './shared/calendar/header/twd-calendar-header.component';
import {PipesModule} from '../../pipes/pipes.module';
import {TwdEventTimeComponent} from './panels/twd-event-time/twd-event-time.component';
import {TwdTaskDateComponent} from './panels/twd-task-date/twd-task-date.component';
import {TwdTaskTimeComponent} from './panels/unused/twd-task-time/twd-task-time.component';
import {TwdTriggerComponent} from './panels/twd-trigger/twd-trigger.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {TwdTaskDueByComponent} from './panels/twd-task-due-by/twd-task-due-by.component';
import {TwdRepeatComponent} from './panels/twd-repeat/twd-repeat.component';
import {OphInputsModule} from '../../design/oph-inputs/oph-inputs.module';
import {OphRadioButtonModule} from '../../design/oph-radio-button/oph-radio-button.module';
import {TwdSubtasksComponent} from './panels/twd-subtasks/twd-subtasks.component';
import {TwdSubtaskEditComponent} from './panels/twd-subtask-edit/twd-subtask-edit.component';
import {TwdFinishComponent} from './panels/twd-finish/twd-finish.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {TwdCompletionComponent} from './panels/twd-completion/twd-completion.component';
import {OphButtonModule} from '../../design/oph-button/oph-button.module';
import {TaskTriggerSelectionComponent} from '../trigger-selection/task-trigger-selection.component';
import {TaskDialogDetailsPanelRewardsComponent} from '../task-dialog/panels/default/details/rewards/task-dialog-details-panel-rewards.component';

@NgModule({
  declarations: [
    TaskWizardDialogComponent,
    TaskWizardDialogPanelItemComponent,
    TaskWizardDialogCheckButtonComponent,
    TwdTaskOrEventComponent,
    TwdBasicInfoComponent,
    TaskWizardDialogNumberInputComponent,
    TwdEventDateComponent,
    TwdCalendarComponent,
    TwdCalendarHeaderComponent,
    TwdEventTimeComponent,
    TwdTaskDateComponent,
    TwdTaskTimeComponent,
    TwdTriggerComponent,
    TwdTaskDueByComponent,
    TwdRepeatComponent,
    TwdSubtasksComponent,
    TwdSubtaskEditComponent,
    TwdFinishComponent,
    TwdCompletionComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedTasksModule,
    MatDatepickerModule,
    PipesModule,
    MatMenuModule,
    MatCheckboxModule,
    OphInputsModule,
    OphRadioButtonModule,
    MatProgressSpinnerModule,
    OphButtonModule,
    TaskTriggerSelectionComponent,
    TaskDialogDetailsPanelRewardsComponent,
  ],
  exports: [TaskWizardDialogComponent, TaskWizardDialogCheckButtonComponent],
})
export class TaskWizardDialogModule {}
