import {SkillModel} from '../../model/skill';
import {AppState} from '../app-state';
import {createSelector} from '@ngrx/store';

export const selectSkillsState = (state: AppState) => state.skills;

export const selectSkillsList = createSelector(selectSkillsState, state => state.skillsList);

export const selectSkillsMap = createSelector(selectSkillsList, skills =>
  skills.reduce((skillMap: Record<string, SkillModel>, obj: SkillModel) => ((skillMap[obj._id] = obj), skillMap), {})
);

export const selectSelectedSkill = createSelector(selectSkillsState, state => state.selectedSkill);
