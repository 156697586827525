import {createSelector} from '@ngrx/store';
import {AppState} from '../app-state';
import {Location} from 'src/app/locations/shared/model/location';

export const selectLocationsState = (state: AppState) => state.locations;

export const selectAllLocations = createSelector(selectLocationsState, state => state.locations);

export const selectLocationsMap = createSelector(selectAllLocations, locations =>
  locations.reduce(
    (locationsMap: Record<string, Location>, obj: Location) => ((locationsMap[obj.id] = obj), locationsMap),
    {}
  )
);
