import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CreateFirstComponent} from './create-first.component';

@NgModule({
  declarations: [CreateFirstComponent],
  imports: [CommonModule],
  exports: [CreateFirstComponent],
})
export class CreateFirstModule {}
