import {AsyncPipe, CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {Store} from '@ngrx/store';
import * as moment from 'moment';
import {BehaviorSubject, Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {colors} from 'src/app/app.constants';
import {Project} from 'src/app/core/model/project';
import {CreateProgramAction, GetProgramsAction} from 'src/app/core/store/programs/programs.action';
import {Program} from 'src/app/programs/shared/model/program';
import {MessageService} from 'src/app/services/message.service';
import {OphButtonModule} from 'src/app/shared/design/oph-button/oph-button.module';
import {OphInputsModule} from 'src/app/shared/design/oph-inputs/oph-inputs.module';
import {PipesModule} from 'src/app/shared/pipes/pipes.module';
import {CustomHeaderComponent} from '../builder-calendar/builder-calendar.component';
import {OphInputOrangeComponent} from '../../../shared/design/oph-inputs/oph-input-orange/oph-input-orange.component';
import {FormControl} from '@angular/forms';

enum Options {
  QuickStart = 'quickStart',
  Schedule = 'schedule',
  Draft = 'draft',
}

enum Pane {
  Start = 'start',
  Calendar = 'calendar',
  Color = 'color',
  Program = 'program',
  CreateProgram = 'createProgram',
}

@Component({
  selector: 'project-wizard-dialog',
  standalone: true,
  imports: [
    MatIconModule,
    OphInputsModule,
    MatDatepickerModule,
    MatCheckboxModule,
    OphButtonModule,
    AsyncPipe,
    PipesModule,
    CommonModule,
    OphInputOrangeComponent,
  ],
  templateUrl: './project-wizard-dialog.component.html',
  styleUrls: ['./project-wizard-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
  ],
})
export class ProjectWizardDialogComponent implements OnInit {
  public readonly customHeader = CustomHeaderComponent;
  public readonly colors: string[] = colors;

  programs$: Observable<Program[]>;

  options = Options;
  pane = Pane;

  selectedPane$ = new BehaviorSubject<string>(this.pane.Start);
  selectedOption: string = this.options.QuickStart;
  selectedDateRange: DateRange<Date>;
  previousSelectedDateRange: DateRange<Date>;
  calendarStartDateDisplay: string;
  calendarEndDateDisplay: string;
  calendarText: string;
  project: Project;
  initialPrograms: string[];

  newProgramName = new FormControl('');

  constructor(
    @Inject(MAT_DIALOG_DATA) data: {project: Project; programs$: Observable<Program[]>},
    private dialogRef: MatDialogRef<ProjectWizardDialogComponent>,
    private store$: Store,
    private messageService: MessageService
  ) {
    this.programs$ = data.programs$;
    this.project = data.project;
  }

  ngOnInit(): void {
    this.initialPrograms = [...this.project.program];
    if (this.project.status !== 'inactive' && this.project.startDateTime && this.project.endDateTime) {
      this.selectedDateRange = new DateRange(new Date(this.project.startDateTime), new Date(this.project.endDateTime));
      this.selectedOption = this.options.Schedule;
    } else {
      this.selectedOption = this.options.Draft;
    }
  }

  onProjectNameChange(name: string) {
    this.project.label = name;
  }

  onOptionSelect(option: string): void {
    this.selectedOption = option;
    if (option === this.options.Schedule) {
      this.previousSelectedDateRange = this.selectedDateRange;
      this.selectedPane$.next(this.pane.Calendar);
    }
  }

  modeText(): string {
    if (this.selectedOption === this.options.Draft) {
      return 'Draft Mode';
    } else {
      return '';
    }
  }

  helpText(): string {
    if (this.selectedOption === this.options.Draft) {
      return 'Project is not active.';
    } else {
      return '';
    }
  }

  dateText(): string {
    if (this.selectedOption === this.options.Schedule && this.dateRangeComplete()) {
      return `${this.getSelectedStartDate()} - ${this.getSelectedEndDate()}`;
    } else {
      return '';
    }
  }

  getSelectedStartDate() {
    return this.selectedDateRange.start.toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  }

  getSelectedEndDate() {
    return this.selectedDateRange.end.toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  }

  nextPane(pane: string): void {
    if (pane === this.pane.Start) {
      this.selectedPane$.next(this.pane.Calendar);
    } else {
      this.selectedPane$.next(this.pane.Start);
    }
  }

  onColorSelect(color: string): void {
    this.project.color = color;
    this.selectedPane$.next(this.pane.Start);
  }

  toggleProgram(program: Program): void {
    if (this.project.program.find((p: string) => p === program.id)) {
      this.project.program = this.project.program.filter((p: string) => p !== program.id);
    } else {
      this.project.program.push(program.id);
    }
    this.selectedPane$.next(this.pane.Start);
  }

  getProgramName(id: string): string {
    let programName = '';
    this.programs$.pipe(take(1)).subscribe(programs => {
      const program = programs.find(p => p.id === id);
      if (program) {
        programName = program.name || '';
      } else {
        this.project.program = this.project.program.filter(p => p !== id);
      }
    });
    return programName;
  }

  hasProgram(id: string): boolean {
    return !!this.project.program.find((p: string) => p === id);
  }

  changeToPane(pane: string): void {
    this.selectedPane$.next(pane);
  }

  toStart(): void {
    this.selectedDateRange = this.previousSelectedDateRange;
    if (!this.selectedDateRange) {
      this.selectedOption = this.options.Draft;
    }
    this.selectedPane$.next(this.pane.Start);
  }

  onSelectedChange(date: Date): void {
    if (
      this.selectedDateRange &&
      this.selectedDateRange.start &&
      date > this.selectedDateRange.start &&
      !this.selectedDateRange.end
    ) {
      this.selectedDateRange = new DateRange(this.selectedDateRange.start, date);
    } else {
      this.selectedDateRange = new DateRange(date, null);
    }
  }

  dateRangeComplete(): boolean {
    return !!(this.selectedDateRange && this.selectedDateRange.start && this.selectedDateRange.end);
  }

  displayBottomContainer(pane: string): boolean {
    return !(pane === this.pane.Color || pane === this.pane.Program || pane === this.pane.CreateProgram);
  }

  showNextButton(pane: string): boolean {
    if (this.selectedOption === this.options.Schedule) {
      if (pane === this.pane.Start && this.dateRangeComplete()) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  disableNextButton(pane: string): boolean {
    if (this.selectedOption === this.options.Schedule) {
      if (pane === this.pane.Start) {
        return false;
      } else if (pane === this.pane.Calendar && this.dateRangeComplete()) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  disableNewProgramButton(): boolean {
    if (this.newProgramName) {
      return false;
    } else {
      return true;
    }
  }

  showFinishButton(pane: string): boolean {
    if (this.selectedOption === this.options.Schedule) {
      if (pane === this.pane.Start && this.dateRangeComplete()) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  saveProgram() {
    const program = {name: this.newProgramName.value};
    this.store$.dispatch(
      new CreateProgramAction({
        program,
        onSuccess: res => {
          this.project.program.push(res._id);
          this.store$.dispatch(
            new GetProgramsAction({
              onSuccess: () => {
                this.selectedPane$.next(this.pane.Start);
              },
              onFailure: () => null,
            })
          );
        },
        onFailure: () => {
          this.messageService.add('There was a problem creating this program.');
        },
      })
    );
  }

  setStatus(): void {
    if (this.selectedOption === this.options.Draft) {
      this.project.status = 'inactive';
    } else {
      this.project.status = 'active';
    }
  }

  setStartEndDates(): void {
    if (this.selectedOption === this.options.Schedule && this.dateRangeComplete()) {
      this.project.startDateTime = moment(this.selectedDateRange.start).tz('America/New_York').startOf('day').format();
      this.project.endDateTime = moment(this.selectedDateRange.end).tz('America/New_York').endOf('day').format();
    } else {
      this.project.startDateTime = moment(new Date()).tz('America/New_York').startOf('day').format();
      this.project.endDateTime = moment(new Date()).tz('America/New_York').endOf('day').add(1, 'years').format();
    }
  }

  onSave(): void {
    this.setStatus();
    this.setStartEndDates();
    this.dialogRef.close(this.project);
  }

  onClose(): void {
    this.project.program = this.initialPrograms;
    this.dialogRef.close();
  }
}
