import {NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {AuthRoutingModule} from './auth-routing.module';
import {LoginComponent} from './login/login.component';
import {OphInputsModule} from '../shared/design/oph-inputs/oph-inputs.module';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {OphLoadingModule} from '../shared/design/oph-loading/oph-loading.module';

@NgModule({
  imports: [SharedModule, AuthRoutingModule, OphInputsModule, OphLoadingModule],
  declarations: [LoginComponent, ResetPasswordComponent],
})
export class AuthModule {}
