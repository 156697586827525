import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {OphIconModule} from 'src/app/shared/design/oph-icon/oph-icon.module';
import {OphLoadingModule} from 'src/app/shared/design/oph-loading/oph-loading.module';
import {PipesModule} from 'src/app/shared/pipes/pipes.module';
import {SharedDubaiThemeModule} from '../../shared/dubai-theme/shared-dubai-theme.module';
import {CurrentSkedTaskListDubaiThemeGridCardComponent} from './card/current-sked-task-list-dubai-theme-grid-card.component';
import {CurrentSkedTaskListDubaiThemeComponent} from './current-sked-task-list-dubai-theme.component';
import {CurrentSkedTaskListDubaiThemeHeaderComponent} from './header/current-sked-task-list-dubai-theme-header.component';
import {CurrentSkedTaskListDubaiThemeTopBarComponent} from './top-bar/current-sked-task-list-dubai-theme-top-bar.component';

@NgModule({
  declarations: [
    CurrentSkedTaskListDubaiThemeComponent,
    CurrentSkedTaskListDubaiThemeGridCardComponent,
    CurrentSkedTaskListDubaiThemeHeaderComponent,
    CurrentSkedTaskListDubaiThemeTopBarComponent,
  ],
  imports: [
    CommonModule,
    OphIconModule,
    MatMenuModule,
    OphLoadingModule,
    PipesModule,
    SharedDubaiThemeModule,
    MatProgressSpinnerModule,
  ],
  exports: [CurrentSkedTaskListDubaiThemeComponent],
})
export class CurrentSkedTaskListDubaiThemeModule {}
