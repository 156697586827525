<div class="desktop">
  @for (tab of tabs; track tab; let i = $index) {
    <button
      class="desktop-tab-button"
      [class.desktop-tab-selected]="selectedTab === tab"
      [class.border-right]="
        (i === 1 || i === 2) && (selectedTab === TaskDialogTabs.Details || selectedTab === TaskDialogTabs.Schedule)
      "
      [class.border-left]="
        (i === 1 || i === 2) && (selectedTab === TaskDialogTabs.Subtasks || selectedTab === TaskDialogTabs.Triggers)
      "
      (click)="tabChange.emit(tab)"
    >
      {{ tab | titlecase }}
      {{ tab === TaskDialogTabs.Subtasks ? '(' + (subtaskCount || 0) + ')' : '' }}
      {{ tab === TaskDialogTabs.Triggers ? '(' + (triggerCount || 0) + ')' : '' }}
    </button>
  }
</div>

<div class="mobile">
  @for (tab of tabs; track tab) {
    <button class="mobile-tab-button" [class.mobile-tab-selected]="selectedTab === tab" (click)="tabChange.emit(tab)">
      {{ tab | titlecase }}
    </button>
  }
</div>
