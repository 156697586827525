import {CurrentSkedAction, CurrentSkedActionType} from './current-sked.action';
import {CurrentSkedState, initialCurrentSkedState} from './current-sked.state';

export function currentSkedReducer(
  state: CurrentSkedState = initialCurrentSkedState,
  action: CurrentSkedAction
): CurrentSkedState {
  switch (action.type) {
    case CurrentSkedActionType.GET_SUCCESS:
      return {...state, currentSked: action.payload.currentSked};
    case CurrentSkedActionType.GET_ALL_SKEDS_SUCCESS:
      return {...state, skeds: action.payload.skeds};
    case CurrentSkedActionType.GET_TASK_SUCCESS:
    case CurrentSkedActionType.CHANGE_TASK_STATUS_SUCCESS:
    case CurrentSkedActionType.CHANGE_SUBTASK_STATUS_SUCCESS:
    case CurrentSkedActionType.UPDATE_SUBTASK_METRIC_SUCCESS:
      return {...state, task: action.payload.task};
    case CurrentSkedActionType.UPDATE_ALL_SKEDS:
      return {...state, skeds: action.payload.allSkeds};
    case CurrentSkedActionType.UPDATE_ACTIVE_TAB:
      return {...state, activeTab: action.payload.activeTab};
    case CurrentSkedActionType.UPDATE_SELECTED_SKED_TYPE:
      return {...state, selectedSkedType: action.payload.selectedSkedType};
    case CurrentSkedActionType.UPDATE_VALUE_CHOSEN:
      return {
        ...state,
        metricUpdateValueChosenMap: {
          ...state.metricUpdateValueChosenMap,
          [action.payload.id]: true,
        },
      };
    case CurrentSkedActionType.CLEAR_VALUE_CHOSEN:
      return {...state, metricUpdateValueChosenMap: {}};
    case CurrentSkedActionType.CLEAR_TASK:
      return {...state, task: null};
    default:
      return state;
  }
}
