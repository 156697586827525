import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {SharedDubaiThemeModule} from 'src/app/current-sked/shared/dubai-theme/shared-dubai-theme.module';
import {SharedLayoutModule} from '../shared/shared-layout.module';
import {MobileCreateButtonComponent} from './legacy-bottom-nav/create-button/mobile-create-button.component';
import {MobileBottomNavComponent} from './legacy-bottom-nav/mobile-bottom-nav.component';
import {MobileNavButtonComponent} from './legacy-bottom-nav/nav-button/mobile-nav-button.component';
import {CreateButtonUrlPipe} from './legacy-bottom-nav/pipes/create-button-url.pipe';
import {MoreButtonActivePipe} from './legacy-bottom-nav/pipes/more-button-active.pipe';
import {MobileLayoutComponent} from './mobile-layout.component';
import {MobileNavbarComponent} from './mobile-navbar/mobile-navbar.component';
import {MobileSearchClearButtonComponent} from './search-bar/clear-button/mobile-search-clear-button.component';
import {MobileSearchInputComponent} from './search-bar/input/mobile-search-input.component';
import {MobileSearchBarComponent} from './search-bar/mobile-search-bar.component';

@NgModule({
  imports: [SharedLayoutModule, CommonModule, MatButtonModule, MatSidenavModule, SharedDubaiThemeModule],
  declarations: [
    MobileLayoutComponent,
    MobileSearchBarComponent,
    MobileSearchInputComponent,
    MobileBottomNavComponent,
    MobileNavButtonComponent,
    MobileCreateButtonComponent,
    MoreButtonActivePipe,
    MobileSearchClearButtonComponent,
    CreateButtonUrlPipe,
    MobileNavbarComponent,
  ],
  exports: [MobileLayoutComponent, MobileSearchBarComponent, MobileBottomNavComponent],
})
export class MobileLayoutModule {}
