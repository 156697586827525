import {Pipe, PipeTransform} from '@angular/core';
import {RewardUser} from 'src/app/core/model/reward';

@Pipe({
  name: 'rewardsActiveUserCount',
  standalone: true,
})
export class RewardsActiveUserCountPipe implements PipeTransform {
  transform(assignedToArray: RewardUser[]): number {
    const obj = {};
    assignedToArray.forEach(user => {
      if (!user.inactive || user.status === 'claimed') {
        obj[user.userId] = true;
      }
    });
    return Object.keys(obj)?.length || 0;
  }
}
