<ng-template #content>
  <ng-content></ng-content>
</ng-template>

@if ({authenticated: authenticated$ | async, mobile: mobile$ | async}; as state) {
  @if (state.authenticated && state.mobile) {
    <mobile-layout>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </mobile-layout>
  }
  @if (state.authenticated && !state.mobile) {
    <desktop-layout>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </desktop-layout>
  }
  @if (!state.authenticated) {
    <ng-container *ngTemplateOutlet="content"></ng-container>
  }
}
