import {Pipe, PipeTransform} from '@angular/core';
import {LEGACY_TASK_ICONS} from 'src/app/current-sked/task-list/dubai-theme/shared/current-sked-dubai-theme-constants';

@Pipe({
  name: 'legacyTaskIcon',
})
export class LegacyTaskIconPipe implements PipeTransform {
  transform(value: string): string {
    if (LEGACY_TASK_ICONS.includes(value)) {
      return 'clock-time-watch';
    }
    return value;
  }
}
