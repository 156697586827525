import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {convertActionDtoToModel} from '../../../actions/shared/converters/convert-action-dto-to-model';
import {ActionDto} from '../../../actions/shared/dto/action.dto';
import {ActionsApiService} from '../../api/actions-api.service';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {
  ActionsActionType,
  CreateActionAction,
  CreateActionSuccessAction,
  DeleteActionAction,
  DeleteActionSuccessAction,
  GetActionsAction,
  GetActionsSuccessAction,
  UpdateActionAction,
  UpdateActionSuccessAction,
} from './actions.action';

@Injectable()
export class ActionsEffects {
  public get$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetActionsAction>(ActionsActionType.GET),
      mergeMap(action => {
        const {onSuccess, onFailure} = action.payload;

        return this.actionsApiService.get().pipe(
          map((dtos: ActionDto[]) => dtos.map(dto => convertActionDtoToModel(dto))),
          mergeMap(actions => [new GetActionsSuccessAction({actions}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public create$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateActionAction>(ActionsActionType.CREATE),
      mergeMap(ngrxAction => {
        const {action, onSuccess, onFailure} = ngrxAction.payload;

        return this.actionsApiService.create(action).pipe(
          mergeMap(a => [new CreateActionSuccessAction({action: a}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public update$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateActionAction>(ActionsActionType.UPDATE),
      mergeMap(ngrxAction => {
        const {action, onSuccess, onFailure} = ngrxAction.payload;

        return this.actionsApiService.update(action).pipe(
          mergeMap(a => [new UpdateActionSuccessAction({action: a}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public delete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteActionAction>(ActionsActionType.DELETE),
      mergeMap(action => {
        const {id, onSuccess, onFailure} = action.payload;

        return this.actionsApiService.delete(id).pipe(
          mergeMap(programId => [new DeleteActionSuccessAction(), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private actionsApiService: ActionsApiService // private store$: Store<{}>
  ) {}
}
