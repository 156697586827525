import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DesignModule} from '../../design/design.module';
import {SharedLayoutModule} from '../shared/shared-layout.module';
import {DesktopLayoutComponent} from './desktop-layout.component';
import {DesktopSidebarComponent} from './sidebar/desktop-sidebar.component';
import {SidebarGroupItemComponent} from './sidebar/group/item/sidebar-group-item.component';
import {SidebarGroupComponent} from './sidebar/group/sidebar-group.component';
import {SidebarItemComponent} from './sidebar/item/sidebar-item.component';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatSelectModule} from '@angular/material/select';
import {CurrentSkedTaskListDubaiThemeModule} from 'src/app/current-sked/task-list/dubai-theme/current-sked-task-list-dubai-theme.module';
import {SharedDubaiThemeModule} from 'src/app/current-sked/shared/dubai-theme/shared-dubai-theme.module';
import {ScheduleSharedModule} from 'src/app/schedule/shared/schedule-shared.module';
import {SharedCurrentSkedModule} from '../../current-sked/shared-current-sked.module';

@NgModule({
  imports: [
    SharedLayoutModule,
    DesignModule,
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatButtonToggleModule,
    MatSelectModule,
    CurrentSkedTaskListDubaiThemeModule,
    SharedDubaiThemeModule,
    ScheduleSharedModule,
    SharedCurrentSkedModule,
  ],
  declarations: [
    DesktopLayoutComponent,
    DesktopSidebarComponent,
    SidebarItemComponent,
    SidebarGroupComponent,
    SidebarGroupItemComponent,
  ],
  exports: [DesktopLayoutComponent, DesktopSidebarComponent],
})
export class DesktopLayoutModule {}
