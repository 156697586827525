import {Action} from '@ngrx/store';
import {ReportingObjectEntry} from './../../../reporting/objects/shared/model/reporting-object-entry';
import {ReportingObjectsParams} from './../../../reporting/objects/shared/model/reporting-objects-params';

export enum ReportingActionType {
  GET_OBJECTS_REPORT = '[Reporting] Get Objects Report',
  GET_OBJECTS_REPORT_SUCCESS = '[Reporting] Get Objects Report :: Success',

  GET_OBJECT_PARENT_REPORT = '[Reporting] Get Object Parent Report',
  GET_OBJECT_PARENT_REPORT_SUCCESS = '[Reporting] Get Object Parent Report :: Success',

  GET_OBJECT_INSTANCE_REPORT = '[Reporting] Get Object Instance Report',
  GET_OBJECT_INSTANCE_REPORT_SUCCESS = '[Reporting] Get Object Instance Report :: Success',

  GET_OBJECT_ATTRIBUTE_REPORT = '[Reporting] Get Object Attribute Report',
  GET_OBJECT_ATTRIBUTE_REPORT_SUCCESS = '[Reporting] Get Object Attribute Report :: Success',
}

export class GetObjectsReportAction implements Action {
  public readonly type = ReportingActionType.GET_OBJECTS_REPORT;

  public constructor(
    public payload: {
      params: ReportingObjectsParams;
      onSuccess?: () => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class GetObjectsReportSuccessAction implements Action {
  public readonly type = ReportingActionType.GET_OBJECTS_REPORT_SUCCESS;

  public constructor(public payload: {reportingObjectEntries: ReportingObjectEntry[]}) {}
}

export class GetObjectParentReportAction implements Action {
  public readonly type = ReportingActionType.GET_OBJECT_PARENT_REPORT;

  public constructor(
    public payload: {
      parentId: string;
      params: ReportingObjectsParams;
      onSuccess?: () => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class GetObjectParentReportSuccessAction implements Action {
  public readonly type = ReportingActionType.GET_OBJECT_PARENT_REPORT_SUCCESS;

  public constructor(public payload: {reportingObjectEntries: ReportingObjectEntry[]}) {}
}

export class GetObjectInstanceReportAction implements Action {
  public readonly type = ReportingActionType.GET_OBJECT_INSTANCE_REPORT;

  public constructor(
    public payload: {
      instanceId: string;
      params: ReportingObjectsParams;
      onSuccess?: () => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class GetObjectInstanceReportSuccessAction implements Action {
  public readonly type = ReportingActionType.GET_OBJECT_INSTANCE_REPORT_SUCCESS;

  public constructor(public payload: {reportingObjectEntries: ReportingObjectEntry[]}) {}
}

export class GetObjectAttributeReportAction implements Action {
  public readonly type = ReportingActionType.GET_OBJECT_ATTRIBUTE_REPORT;

  public constructor(
    public payload: {
      instanceId: string;
      attributeId: string;
      params: ReportingObjectsParams;
      onSuccess?: () => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class GetObjectAttributeReportSuccessAction implements Action {
  public readonly type = ReportingActionType.GET_OBJECT_ATTRIBUTE_REPORT_SUCCESS;

  public constructor(public payload: {reportingObjectEntries: ReportingObjectEntry[]}) {}
}

export type ReportingAction =
  | GetObjectsReportAction
  | GetObjectsReportSuccessAction
  | GetObjectParentReportAction
  | GetObjectParentReportSuccessAction
  | GetObjectInstanceReportAction
  | GetObjectInstanceReportSuccessAction
  | GetObjectAttributeReportAction
  | GetObjectAttributeReportSuccessAction;
