@if (task) {
  <div class="container" [class.clickable]="true" (click)="onTask()">
    <div class="f-c left-container">
      <oph-icon
        class="task-icon"
        [name]="'color-icons/' + task.icon"
        size="32"
        imageErrorName="color-icons/clock-time-watch"
      ></oph-icon>
      <span class="label">{{ task.label || '' }}</span>
      @if (task.batchId) {
        <oph-icon name="repeat-green" size="18"></oph-icon>
      }
    </div>

    <div class="right-container f-c">
      <div class="info-item">
        <label>Estimated</label>
        <span>{{ task.duration || 0 | minutesToHoursAndMinutes }}</span>
      </div>
      <div class="divider"></div>
      <div class="info-item">
        <label>Subtasks</label>
        <span>{{ completeSubtaskCount }}/{{ task.subtasks.length }}</span>
      </div>
      <div class="divider"></div>
      <div class="info-item">
        @if (type === 'expiredTasks') {
          <label class="time-label">{{
            task.endDateTime ? (task.endDateTime | convertUtcToTimezone: 'h:mma') : ''
          }}</label>
          <span>{{ task.endDateTime ? (task.endDateTime | convertUtcToTimezone: 'DDMMM') : '' }}</span>
        } @else {
          <label class="time-label">{{ task.updatedAt ? (task.updatedAt | convertUtcToTimezone: 'h:mma') : '' }}</label>
          <span>{{ task.updatedAt ? (task.updatedAt | convertUtcToTimezone: 'DDMMM') : '' }}</span>
        }
      </div>
      <project-task-user [user]="user" [action]="userSubtext"></project-task-user>
    </div>
  </div>
}
