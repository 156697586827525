import {initialSchedulerState, SchedulerState} from './scheduler.state';
import {SchedulerAction, SchedulerActionType} from './scheduler.action';

export function schedulerReducer(
  state: SchedulerState = initialSchedulerState,
  action: SchedulerAction
): SchedulerState {
  switch (action.type) {
    case SchedulerActionType.CREATE_WEEK_SUCCESS:
      return {
        ...state,
        currentWeek: {
          ...action.payload.week,
        },
        saveInProgress: false,
      };
    case SchedulerActionType.FETCH_CURRENT_WEEK_SUCCESS:
      return {
        ...state,
        currentWeek: {
          ...action.week,
        },
        saveInProgress: false,
      };
    case SchedulerActionType.FETCH_CURRENT_SEASON_SUCCESS:
      return {
        ...state,
        currentSeason: {
          ...action.payload.season,
        },
        saveInProgress: false,
      };
    case SchedulerActionType.FETCH_SKED_TEMPLATES_SUCCESS:
      return {
        ...state,
        skedTemplates: action.skedTemplates,
        saveInProgress: false,
      };
    case SchedulerActionType.FETCH_WEEKS_SUCCESS:
      return {
        ...state,
        seasonWeeks: action.payload.seasonWeeks,
      };
    case SchedulerActionType.RESET_SEASON:
      return {
        ...state,
        currentSeason: null,
        seasonWeeks: null,
      };
    case SchedulerActionType.RESET_WEEK:
      return {
        ...state,
        currentWeek: null,
      };
    case SchedulerActionType.SAVE_CURRENT_WEEK:
      return {
        ...state,
        saveInProgress: true,
      };
    case SchedulerActionType.SAVE_CURRENT_WEEK_SUCCESS:
      return {
        ...state,
        currentWeek: action.week,
        saveInProgress: false,
      };
    case SchedulerActionType.SET_SAVE_IN_PROGRESS:
      return {
        ...state,
        saveInProgress: action.state,
      };
    case SchedulerActionType.SET_HAS_UNSAVED_CHANGES:
      return {
        ...state,
        hasUnsavedChanges: action.state,
      };
    case SchedulerActionType.UPDATE_CURRENT_WEEK_SKEDS_SUCCESS:
      return {
        ...state,
        currentWeek: {
          ...action.week,
        },
        saveInProgress: false,
      };
    case SchedulerActionType.UPDATE_WEEK_SUCCESS:
      return {
        ...state,
        currentWeek: {
          ...action.week,
        },
        saveInProgress: false,
      };
    case SchedulerActionType.SET_TEAM_SKEDS:
      return {
        ...state,
        teamSkeds: action.state,
      };
    case SchedulerActionType.GET_TEAM_SCHEDULE_SUCCESS:
      return {
        ...state,
        teamSchedule: action.teamSchedule,
      };
    default:
      return state;
  }
}
