import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {MatMenuTrigger} from '@angular/material/menu';
import * as moment from 'moment';
import {BehaviorSubject, Subscription} from 'rxjs';
import {TasksService} from 'src/app/shared/services/tasks.service';
import {TaskRepeatTimeValidityData} from 'src/app/shared/tasks/model/task-repeat-time-validity-data';
import {formatSelectedDay} from 'src/app/shared/tasks/utils/format-selected-day';
import {TimepickerUI} from 'timepicker-ui';
import {TASK_REPEAT_TYPE_OPTIONS, TIME_PICKER_OPTIONS} from './../../../../task-constants';
import {TaskDialogPanels, TaskDialogScheduleTabs} from './../../../shared/task-dialog-constants';
@Component({
  selector: 'task-dialog-schedule-panel',
  templateUrl: './task-dialog-schedule-panel.component.html',
  styleUrls: ['./task-dialog-schedule-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskDialogSchedulePanelComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  subscriptions = new Subscription();

  @Input() form: FormGroup;
  @Input() validationErrors: Record<string, boolean>;
  @Input() triggerCount: number;

  @ViewChild('eventStartTime') eventStartTime: ElementRef;
  @ViewChild('eventEndTime') eventEndTime: ElementRef;
  @ViewChild('trigger') trigger: MatMenuTrigger;

  selectedTab$ = new BehaviorSubject<TaskDialogScheduleTabs>(TaskDialogScheduleTabs.Task);
  scheduleType$ = new BehaviorSubject<string>('');
  coverSheetData$ = new BehaviorSubject<{
    name: string;
    type: string;
    dateControl?: AbstractControl;
    valueControl?: AbstractControl;
    valueTypeControl?: AbstractControl;
  }>(null);
  disabledDays$ = new BehaviorSubject<number[]>([]);
  repeatTimeInvalid$ = new BehaviorSubject<TaskRepeatTimeValidityData>(null);
  showCoverSelector$ = new BehaviorSubject<boolean>(false);

  tabs = [TaskDialogScheduleTabs.Task, TaskDialogScheduleTabs.Event];
  TaskDialogScheduleTabs = TaskDialogScheduleTabs;
  TaskDialogPanels = TaskDialogPanels;
  timePickerOptions = {...TIME_PICKER_OPTIONS, appendModalSelector: '.task-dialog-timepicker'};
  dateButtonFormat = 'M/DD/YY';
  storedTaskScheduleOnDate: string;
  storedEventScheduleOnDate: string;
  storedTime: {valueType: string; value: number; type: string};
  repeatTypesArr = TASK_REPEAT_TYPE_OPTIONS;

  eventStartTimeListener: (event: any) => void;
  eventEndTimeListener: (event: any) => void;

  constructor(private taskService: TasksService) {}

  ngOnInit() {
    this.subscriptions.add(this.subscribeToScheduleOnChanges());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.form && this.form) {
      this.selectedTab$.next((this.eventControl.value && TaskDialogScheduleTabs.Event) || TaskDialogScheduleTabs.Task);
      this.scheduleType$.next(this.scheduleTypeControl.value);
      if (this.selectedTab$.value === TaskDialogScheduleTabs.Event) {
        const date = this.getScheduleOnDate();
        this.formatEvent(date);
        this.storedEventScheduleOnDate = this.scheduleOnDateControl.value;
      } else {
        this.storedTaskScheduleOnDate = this.scheduleOnDateControl.value;
      }
    }
  }

  ngAfterViewInit(): void {
    const eventStartTimeEl = new TimepickerUI(this.eventStartTime.nativeElement, this.timePickerOptions);
    const eventEndTimeEl = new TimepickerUI(this.eventEndTime.nativeElement, this.timePickerOptions);
    eventStartTimeEl.create();
    eventEndTimeEl.create();

    this.eventStartTimeListener = res => this.setTime(res.detail, 'eventStart');
    this.eventEndTimeListener = res => this.setTime(res.detail, 'eventEnd');

    this.eventStartTime.nativeElement.addEventListener('accept', this.eventStartTimeListener);
    this.eventEndTime.nativeElement.addEventListener('accept', this.eventEndTimeListener);
  }

  ngOnDestroy() {
    if (this.eventStartTime?.nativeElement && this.eventStartTimeListener) {
      this.eventStartTime.nativeElement.removeEventListener('accept', this.eventStartTimeListener);
    }
    if (this.eventEndTime?.nativeElement && this.eventEndTimeListener) {
      this.eventEndTime.nativeElement.removeEventListener('accept', this.eventEndTimeListener);
    }
    this.subscriptions.unsubscribe();
  }

  subscribeToScheduleOnChanges(): Subscription {
    return this.scheduleOnDateControl.valueChanges.subscribe(scheduleOnDate => {
      if (this.selectedTab$.value === TaskDialogScheduleTabs.Event) {
        const date = this.getScheduleOnDate();
        this.formatEvent(date);
      }
    });
  }

  setTime(value: Record<string, string>, type: string): void {
    const selectedTime = `${value.hour}:${value.minutes} ${value.type.toLowerCase()}`;
    if (type === 'eventStart') {
      this.eventStartControl.setValue(selectedTime);
    }
    if (type === 'eventEnd') {
      this.eventEndControl.setValue(selectedTime);
    }
  }

  onTab(tab: TaskDialogScheduleTabs) {
    if (tab === TaskDialogScheduleTabs.Event) {
      this.eventControl.setValue(true);
      this.autoResetControl.setValue(false);
      const date = this.getScheduleOnDate();
      this.scheduleOnDateControl.setValue(formatSelectedDay(date));
      this.formatEvent(date);
      this.selectedTab$.next(tab);
    } else {
      this.eventControl.setValue(false);
      this.selectedTab$.next(tab);
      this.scheduleOnDateControl.setValue('asap');
      this.disabledDays$.next([]);
    }
  }

  getScheduleOnDate() {
    return this.scheduleOnDateControl.value === 'asap' ? new Date() : this.scheduleOnDateControl.value;
  }

  formatEvent(date: Date) {
    const momentIndex = moment(date).day();
    let dayIndex = momentIndex === 0 ? 6 : momentIndex - 1; // oph day indexes aren't the same as moments
    this.disabledDays$.next([dayIndex]);
    if (!this.repeatDaysControl.value.includes(dayIndex)) {
      this.repeatDaysControl.setValue([...this.repeatDaysControl.value, dayIndex]);
    }
  }

  onRepeatType(type: string) {
    this.scheduleType$.next(type);
    const controlValue = type === 'repeats' ? 'onRepeat' : 'endOfProject';
    this.form.patchValue({
      autoReset: false,
      schedule: {
        scheduleType: type,
        targetCompletionType: controlValue,
        expirationType: controlValue,
      },
    });
  }

  onDateChange() {
    if (this.coverSheetData$.value.name === 'targetCompletionDate') {
      this.targetCompletionTypeControl.setValue('date');
    }
    if (this.coverSheetData$.value.name === 'expirationDate') {
      this.expirationTypeControl.setValue('date');
    }
    if (this.coverSheetData$.value.name === 'endsDate') {
      this.endsTypeControl.setValue('date');
    }
    setTimeout(() => {
      this.showCoverSelector$.next(false);
    }, 100);
  }

  closeCoverSelector() {
    this.showCoverSelector$.next(false);
  }

  onCalendarOpen(name: string) {
    const type = 'calendar';
    if (name === 'scheduleOnDate') {
      this.coverSheetData$.next({name, type, dateControl: this.scheduleOnDateControl});
    }
    if (name === 'targetCompletionDate') {
      this.coverSheetData$.next({
        name,
        type,
        dateControl: this.targetCompletionDateControl,
      });
    }
    if (name === 'expirationDate') {
      this.coverSheetData$.next({
        name,
        type,
        dateControl: this.expirationDateControl,
      });
    }
    if (name === 'endsDate') {
      this.coverSheetData$.next({
        name,
        type,
        dateControl: this.endsDateControl,
      });
    }
    this.showCoverSelector$.next(true);
  }

  onScheduleOnDateClear(event: PointerEvent) {
    event.stopPropagation();
    this.scheduleOnDateControl.setValue('asap');
  }

  onTargetCompletionDateClear(event: PointerEvent) {
    event.stopPropagation();
    this.targetCompletionTypeControl.setValue(
      this.scheduleTypeControl.value === 'repeats' ? 'onRepeat' : 'endOfProject'
    );
  }

  onExpirationDateClear(event: PointerEvent) {
    event.stopPropagation();
    this.expirationTypeControl.setValue(this.scheduleTypeControl.value === 'repeats' ? 'onRepeat' : 'endOfProject');
  }

  onTimeSelectorOpen(name: 'targetCompletion' | 'expiration') {
    const type = 'time';
    if (name === 'targetCompletion') {
      this.coverSheetData$.next({
        name,
        type,
        valueControl: this.targetCompletionValueControl,
        valueTypeControl: this.targetCompletionValueTypeControl,
      });
      this.storedTime = {
        value: this.targetCompletionValueControl.value,
        valueType: this.targetCompletionValueTypeControl.value,
        type: this.targetCompletionTypeControl.value,
      };
    }
    if (name === 'expiration') {
      this.coverSheetData$.next({
        name,
        type,
        valueControl: this.expirationValueControl,
        valueTypeControl: this.expirationValueTypeControl,
      });
      this.storedTime = {
        value: this.expirationValueControl.value,
        valueType: this.expirationValueTypeControl.value,
        type: this.expirationTypeControl.value,
      };
    }
    this.showCoverSelector$.next(true);
  }

  onTimeType(type: string) {
    this.coverSheetData$.value.valueTypeControl.setValue(type);
    this.trigger.closeMenu();
  }

  onOk() {
    let repeatData;
    if (this.coverSheetData$.value.name === 'targetCompletion') {
      this.targetCompletionTypeControl.setValue('amountOfTime');
      repeatData = this.taskService.getRepeatTimeValidityData(
        this.scheduleControls,
        this.targetCompletionValueTypeControl.value,
        this.targetCompletionValueControl.value
      );
    }
    if (this.coverSheetData$.value.name === 'expiration') {
      this.expirationTypeControl.setValue('amountOfTime');
      repeatData = this.taskService.getRepeatTimeValidityData(
        this.scheduleControls,
        this.expirationValueTypeControl.value,
        this.expirationValueControl.value
      );
    }
    if (!repeatData.valid) {
      this.repeatTimeInvalid$.next(repeatData);
      return;
    }
    this.repeatTimeInvalid$.next(null);
    this.closeCoverSelector();
  }

  onCloseCoverSelector() {
    // if time selection is cancelled, revert to previous values
    const coverSheetData = this.coverSheetData$.value;
    if (coverSheetData.type === 'time' && coverSheetData.name === 'targetCompletion') {
      this.scheduleControls.patchValue({
        targetCompletionValue: this.storedTime.value,
        targetCompletionValueType: this.storedTime.valueType,
        targetCompletionType: this.storedTime.type,
      });
    }
    if (coverSheetData.type === 'time' && coverSheetData.name === 'expiration') {
      this.scheduleControls.patchValue({
        expirationValue: this.storedTime.value,
        expirationValueType: this.storedTime.valueType,
        expirationType: this.storedTime.type,
      });
    }
    this.repeatTimeInvalid$.next(null);
    this.closeCoverSelector();
  }

  get autoResetControl(): AbstractControl {
    return this.form.get('autoReset');
  }

  get eventControl(): AbstractControl {
    return this.form.get('event');
  }

  get scheduleControls(): AbstractControl {
    return this.form.get('schedule');
  }

  get dueWithinControl(): AbstractControl {
    return this.scheduleControls.get('dueWithin');
  }

  get dueWithinDateControl(): AbstractControl {
    return this.scheduleControls.get('dueWithinDate');
  }

  get dueWithinIntervalTypeControl(): AbstractControl {
    return this.scheduleControls.get('dueWithinIntervalType');
  }

  get scheduleOnDateControl(): AbstractControl {
    return this.scheduleControls.get('scheduleOnDate');
  }

  get scheduleTypeControl(): AbstractControl {
    return this.scheduleControls.get('scheduleType');
  }

  get endsDateControl(): AbstractControl {
    return this.scheduleControls.get('endsDate');
  }

  get eventTimesControls(): AbstractControl {
    return this.scheduleControls.get('eventTimes');
  }

  get eventStartControl(): AbstractControl {
    return this.eventTimesControls.get('eventStart');
  }

  get eventEndControl(): AbstractControl {
    return this.eventTimesControls.get('eventEnd');
  }

  get repeatDaysControl(): AbstractControl {
    return this.scheduleControls.get('repeatDays');
  }

  get targetCompletionTypeControl(): AbstractControl {
    return this.scheduleControls.get('targetCompletionType');
  }

  get targetCompletionDateControl(): AbstractControl {
    return this.scheduleControls.get('targetCompletionDate');
  }

  get targetCompletionValueControl(): AbstractControl {
    return this.scheduleControls.get('targetCompletionValue');
  }

  get targetCompletionValueTypeControl(): AbstractControl {
    return this.scheduleControls.get('targetCompletionValueType');
  }

  get expirationTypeControl(): AbstractControl {
    return this.scheduleControls.get('expirationType');
  }

  get expirationDateControl(): AbstractControl {
    return this.scheduleControls.get('expirationDate');
  }

  get expirationValueControl(): AbstractControl {
    return this.scheduleControls.get('expirationValue');
  }

  get expirationValueTypeControl(): AbstractControl {
    return this.scheduleControls.get('expirationValueType');
  }

  get endsTypeControl(): AbstractControl {
    return this.scheduleControls.get('endsType');
  }
}
