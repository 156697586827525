<div class="container" [class.focused]="focused">
  <mat-calendar
    class="twd-calendar"
    [headerComponent]="headerComponent"
    [startAt]="control.value || today"
    [selected]="control.value"
    [class.disabled]="disabled"
    (selectedChange)="dateChange.emit($event)"
  ></mat-calendar>
</div>
