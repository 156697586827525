import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {TwdCalendarHeaderComponent} from './header/twd-calendar-header.component';

@Component({
  selector: 'twd-calendar',
  templateUrl: './twd-calendar.component.html',
  styleUrls: ['./twd-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwdCalendarComponent {
  @Input() control: FormControl;
  @Input() disabled: boolean;
  @Input() focused: boolean;

  @Output() dateChange = new EventEmitter<Date>();

  today = new Date();
  headerComponent = TwdCalendarHeaderComponent;
}
