import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  constructor(private title: Title) {}

  public setPageTitle(...parts: string[]) {
    const title = parts.concat('fullsked').join('  |  ');
    this.title.setTitle(title);
  }
}
