import {Pipe, PipeTransform} from '@angular/core';
import {RewardUser} from 'src/app/core/model/reward';

@Pipe({
  name: 'isAssignedUserSelected',
  standalone: true,
})
export class IsAssignedUserSelectedPipe implements PipeTransform {
  transform(userId: string, assignedToArray: RewardUser[]): boolean {
    return assignedToArray.some((assigned: RewardUser) => assigned.userId === userId && !assigned.inactive);
  }
}
