import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormArray} from '@angular/forms';
import {TasksService} from 'src/app/shared/services/tasks.service';
import {TaskDialogPanels} from '../../../shared/task-dialog-constants';

@Component({
  selector: 'task-dialog-subtasks-panel',
  templateUrl: './task-dialog-subtasks-panel.component.html',
  styleUrls: ['./task-dialog-subtasks-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskDialogSubtasksPanelComponent {
  @Input() formArray: FormArray;

  @Output() panelChange = new EventEmitter<TaskDialogPanels>();

  constructor(private taskService: TasksService) {}

  onAddSubtask() {
    this.formArray.push(this.taskService.getSubtaskFormGroup());
    this.taskService.setSubtaskEditIndex(this.formArray.length === 0 ? 0 : this.formArray.length - 1, false);
    this.panelChange.next(TaskDialogPanels.SubtaskEdit);
  }

  onEditSubtask(index: number) {
    this.taskService.setSubtaskEditIndex(index, true);
    this.panelChange.next(TaskDialogPanels.SubtaskEdit);
  }
}
