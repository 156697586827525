import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {CurrentSkedTask} from 'src/app/core/model/task';
import {User} from 'src/app/core/model/user';
import {TaskStatuses} from 'src/app/current-sked/shared/task-statuses';
import {ProjectTaskUserComponent} from 'src/app/projects/shared/project-task-user/project-task-user.component';
import {SharedCurrentSkedModule} from 'src/app/shared/current-sked/shared-current-sked.module';
import {PipesModule} from 'src/app/shared/pipes/pipes.module';

@Component({
  selector: 'project-view-routine-item',
  standalone: true,
  imports: [SharedCurrentSkedModule, ProjectTaskUserComponent, PipesModule, CommonModule, ProjectTaskUserComponent],
  templateUrl: './project-view-routine-item.component.html',
  styleUrl: './project-view-routine-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectViewRoutineItemComponent implements OnChanges {
  @Input() routine: CurrentSkedTask;
  @Input() usersMap: Record<string, User>;

  @Output() routineClick = new EventEmitter();
  @Output() checkboxClick = new EventEmitter<boolean>();

  statusDisplay: string;
  statusBackgroundColor: string;
  statusBorderColor: string;
  updatedAt: string;
  taskStatuses = TaskStatuses;
  loading: boolean;
  action: string;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.routine && this.routine) {
      this.updatedAt = this.routine?.activity ? this.routine.activity[this.routine.activity.length - 1]?.updatedAt : '';
      switch (this.routine.status) {
        case TaskStatuses.Unclaimed:
          this.statusDisplay = 'To do';
          this.statusBackgroundColor = '#DDE7F6';
          this.statusBorderColor = '#5081CA';
          this.action = 'Assigned to';
          break;
        case TaskStatuses.InProgress:
          this.statusDisplay = 'In Progress';
          this.statusBackgroundColor = '#D8FFFA';
          this.statusBorderColor = '#29AA9B';
          this.action = 'In-progress by';
          break;
        case TaskStatuses.OnHold:
          this.statusDisplay = 'On hold';
          this.statusBackgroundColor = '#FFE1E1';
          this.statusBorderColor = '#E35C49';
          this.action = 'On hold by';
          break;
        case TaskStatuses.Expired:
          this.statusDisplay = 'Expired';
          this.statusBackgroundColor = '#E3E1FF';
          this.statusBorderColor = '#6960CB';
          this.action = 'Assigned to';
          break;
        case TaskStatuses.Completed:
          this.statusDisplay = 'Completed';
          this.statusBackgroundColor = '#E5E3DD';
          this.statusBorderColor = '#7A746F';
          this.action = 'Completed by';
          break;
        case TaskStatuses.Cancelled:
          this.statusDisplay = 'Cancelled';
          this.statusBackgroundColor = '#FFEAC0';
          this.statusBorderColor = '#CC921F';
          this.action = 'Cancelled by';
          break;
        default:
          this.statusDisplay = 'Unknown';
          this.statusBackgroundColor = 'var(--light)';
          this.statusBorderColor = 'var(--border)';
          break;
      }
    }
    this.loading = false;
  }

  onCheckbox(checked: boolean) {
    this.loading = true;
    this.checkboxClick.emit(checked);
  }
}
