import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {User} from 'src/app/core/model/user';
import {OphAvatarComponent} from 'src/app/shared/design/oph-avatar/oph-avatar.component';

@Component({
  selector: 'reward-user-item',
  standalone: true,
  imports: [MatIconModule, OphAvatarComponent],
  templateUrl: './reward-user-item.component.html',
  styleUrl: './reward-user-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RewardUserItemComponent {
  @Input() user: User;
  @Input() inactiveUser: boolean;
}
