import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {SkillModel} from '../../model/skill';

export interface SkillsState extends EntityState<SkillModel> {
  skillsList: SkillModel[];
  skillsListLoadingProcess: boolean;
  selectedSkill: SkillModel;
  skillDetailsLoadingProcess: boolean;
}

export const skillsAdapter = createEntityAdapter<SkillModel>();

export const initialSkillsState: SkillsState = skillsAdapter.getInitialState({
  loaded: false,
  skillsList: [],
  skillsListLoadingProcess: false,
  selectedSkill: null,
  skillDetailsLoadingProcess: false,
});
