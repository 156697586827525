import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {AbstractControl, FormArray, FormControl} from '@angular/forms';
import {BehaviorSubject, Subscription} from 'rxjs';
import {TASK_REPEAT_TYPE_OPTIONS} from '../../../task-constants';
import {TaskRepeatTimeValidityData} from '../../../model/task-repeat-time-validity-data';
import {TasksService} from 'src/app/shared/services/tasks.service';

@Component({
  selector: 'twd-completion',
  templateUrl: './twd-completion.component.html',
  styleUrls: ['./twd-completion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwdCompletionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() scheduleControls: FormControl;
  @Input() triggersFormArray: FormArray;
  @Input() triggerCount: number;

  @Output() hideHeaderAndFooter = new EventEmitter<boolean>();

  showCompletionCalendar$ = new BehaviorSubject<boolean>(false);
  showExpirationCalendar$ = new BehaviorSubject<boolean>(false);
  targetCompletionRepeatTimeInvalid$ = new BehaviorSubject<TaskRepeatTimeValidityData>(null);
  expirationRepeatTimeInvalid$ = new BehaviorSubject<TaskRepeatTimeValidityData>(null);

  subscriptions = new Subscription();

  previousCompletionDate: Date;
  previousExpirationDate: Date;
  repeatTypesArr = TASK_REPEAT_TYPE_OPTIONS;

  constructor(private tasksService: TasksService) {}

  ngOnInit() {
    this.subscriptions.add(this.subscribeToScheduleValueChanges());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.triggersFormArray && this.triggersFormArray) {
      if (this.scheduleTypeControl.value === 'repeats') {
        this.scheduleControls.patchValue({targetCompletionType: 'onRepeat', expirationType: 'onRepeat'});
      } else {
        this.scheduleControls.patchValue({targetCompletionType: 'endOfProject', expirationType: 'endOfProject'});
      }
      if (this.triggerCount) {
        this.scheduleControls.patchValue({targetCompletionType: 'endOfProject', expirationType: 'endOfProject'});
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  subscribeToScheduleValueChanges(): Subscription {
    return this.scheduleControls.valueChanges.subscribe(schedule => {
      const targetCompletionRepeatData = this.tasksService.getRepeatTimeValidityData(
        this.scheduleControls,
        this.targetCompletionValueTypeControl.value,
        this.targetCompletionValueControl.value
      );
      this.targetCompletionRepeatTimeInvalid$.next(
        targetCompletionRepeatData.valid ? null : targetCompletionRepeatData
      );
      const expirationRepeatData = this.tasksService.getRepeatTimeValidityData(
        this.scheduleControls,
        this.expirationValueTypeControl.value,
        this.expirationValueControl.value
      );
      this.expirationRepeatTimeInvalid$.next(expirationRepeatData.valid ? null : expirationRepeatData);
    });
  }

  onCompletion(type: string) {
    this.targetCompletionTypeControl.setValue(type);
    const showCalendar = type === 'date';
    this.showCompletionCalendar$.next(showCalendar);
    this.hideHeaderAndFooter.emit(showCalendar);
    this.previousCompletionDate = this.targetCompletionDateControl.value;
    if (type === 'endOfProject') {
      this.targetCompletionDateControl.setValue('');
    }
  }

  onCompletionCalendarButton(cancel: boolean) {
    if (cancel) {
      if (!this.previousCompletionDate) {
        this.targetCompletionDateControl.setValue('');
        this.targetCompletionTypeControl.setValue('endOfProject');
      } else {
        this.targetCompletionDateControl.setValue(this.previousCompletionDate);
      }
    }
    this.showCompletionCalendar$.next(false);
    this.hideHeaderAndFooter.emit(false);
  }

  onCompletionDateChange(date: Date) {
    this.targetCompletionDateControl.setValue(date);
  }

  onCompletionType(type: string) {
    this.targetCompletionValueTypeControl.setValue(type);
  }

  onExpiration(type: string) {
    this.expirationTypeControl.setValue(type);
    const showCalendar = type === 'date';
    this.showExpirationCalendar$.next(showCalendar);
    this.hideHeaderAndFooter.emit(showCalendar);
    this.previousExpirationDate = this.expirationDateControl.value;
    if (type === 'endOfProject') {
      this.expirationDateControl.setValue('');
    }
  }

  onExpirationCalendarButton(cancel: boolean) {
    if (cancel) {
      if (!this.previousExpirationDate) {
        this.expirationDateControl.setValue('');
        this.expirationTypeControl.setValue('endOfProject');
      } else {
        this.expirationDateControl.setValue(this.previousExpirationDate);
      }
    }
    this.showExpirationCalendar$.next(false);
    this.hideHeaderAndFooter.emit(false);
  }

  onExpirationDateChange(date: Date) {
    this.expirationDateControl.setValue(date);
  }

  onExpirationType(type: string) {
    this.expirationValueTypeControl.setValue(type);
  }

  get scheduleTypeControl(): AbstractControl {
    return this.scheduleControls.get('scheduleType');
  }

  get targetCompletionTypeControl(): AbstractControl {
    return this.scheduleControls.get('targetCompletionType');
  }

  get targetCompletionDateControl(): AbstractControl {
    return this.scheduleControls.get('targetCompletionDate');
  }

  get targetCompletionValueControl(): AbstractControl {
    return this.scheduleControls.get('targetCompletionValue');
  }

  get targetCompletionValueTypeControl(): AbstractControl {
    return this.scheduleControls.get('targetCompletionValueType');
  }

  get expirationTypeControl(): AbstractControl {
    return this.scheduleControls.get('expirationType');
  }

  get expirationDateControl(): AbstractControl {
    return this.scheduleControls.get('expirationDate');
  }

  get expirationValueControl(): AbstractControl {
    return this.scheduleControls.get('expirationValue');
  }

  get expirationValueTypeControl(): AbstractControl {
    return this.scheduleControls.get('expirationValueType');
  }
}
