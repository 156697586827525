import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Lens, LensDto} from 'src/app/lenses/shared/model/lens';
import {LensSetting} from 'src/app/lenses/shared/model/lens-setting';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LensesApiService {
  public url = `${environment.apiUrl}/lens`;

  constructor(private http: HttpClient) {}

  public get(): Observable<Lens[]> {
    return this.http.get<Lens[]>(this.url);
  }

  public getSingle(id: string): Observable<Lens[]> {
    return this.http.get<Lens[]>(`${this.url}/${id}`);
  }

  public getSettings(): Observable<LensSetting[]> {
    return this.http.get<LensSetting[]>(`${this.url}/setting`);
  }

  public create(lens: LensDto): Observable<Lens> {
    return this.http.post<Lens>(`${this.url}`, lens);
  }

  public update(lens: LensDto): Observable<Lens> {
    return this.http.put<Lens>(`${this.url}/${lens._id}`, lens);
  }

  public delete(id: string): Observable<Object> {
    return this.http.delete<Lens[]>(`${this.url}/${id}`);
  }
}
