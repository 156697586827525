import {ReportingAction, ReportingActionType} from './reporting.action';
import {ReportingState, initialReportingState} from './reporting.state';

export function reportingReducer(
  state: ReportingState = initialReportingState,
  action: ReportingAction
): ReportingState {
  switch (action.type) {
    case ReportingActionType.GET_OBJECTS_REPORT_SUCCESS:
      return {...state, reportingObjectEntries: action.payload.reportingObjectEntries};
    default:
      return state;
  }
}
