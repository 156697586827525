import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {RewardsApiService} from '../../api/rewards-api.service';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {
  ClaimRewardAction,
  CreateRewardAction,
  CreateRewardSuccessAction,
  DeleteRewardAction,
  DeleteRewardSuccessAction,
  FulfillRewardAction,
  FulfillRewardSuccessAction,
  GetCoinTypesAction,
  GetCoinTypesSuccessAction,
  GetManageRewardsAction,
  GetManageRewardsSuccessAction,
  GetMyRewardsAction,
  GetMyRewardsSuccessAction,
  GetRewardsAdminHistoryAction,
  GetRewardsAdminHistorySuccessAction,
  GetRewardsUserHistoryAction,
  GetRewardsUserHistorySuccessAction,
  GetSingleRewardAction,
  GetSingleRewardSuccessAction,
  RewardsActionType,
  UpdateRewardAction,
  UpdateRewardSuccessAction,
} from './rewards.action';

@Injectable()
export class RewardsEffects {
  public getManageRewards$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetManageRewardsAction>(RewardsActionType.GET_MANAGE),
      mergeMap(action => {
        const {onSuccess, onFailure} = action.payload;

        return this.rewardsApiService.getManageRewards().pipe(
          mergeMap(manageRewards => [
            new GetManageRewardsSuccessAction({manageRewards}),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getMyRewards$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetMyRewardsAction>(RewardsActionType.GET_MY_REWARDS),
      mergeMap(action => {
        const {onSuccess, onFailure} = action.payload;

        return this.rewardsApiService.getMyRewards().pipe(
          mergeMap(myRewards => [new GetMyRewardsSuccessAction({myRewards}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getUserHistory$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetRewardsUserHistoryAction>(RewardsActionType.GET_USER_HISTORY),
      mergeMap(action => {
        const {params, onSuccess, onFailure} = action.payload;

        return this.rewardsApiService.getUserHistory(params).pipe(
          mergeMap(userHistory => [
            new GetRewardsUserHistorySuccessAction({userHistory}),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getAdminHistory$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetRewardsAdminHistoryAction>(RewardsActionType.GET_ADMIN_HISTORY),
      mergeMap(action => {
        const {params, onSuccess, onFailure} = action.payload;

        return this.rewardsApiService.getAdminHistory(params).pipe(
          mergeMap(adminHistory => [
            new GetRewardsAdminHistorySuccessAction({adminHistory}),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getSingleReward$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetSingleRewardAction>(RewardsActionType.GET_SINGLE_REWARD),
      mergeMap(action => {
        const {rewardId, onSuccess, onFailure} = action.payload;

        return this.rewardsApiService.getSingleReward(rewardId).pipe(
          mergeMap(reward => [new GetSingleRewardSuccessAction({reward}), ...createCallbackActions(onSuccess, reward)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public create$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateRewardAction>(RewardsActionType.CREATE),
      mergeMap(action => {
        const {reward, onSuccess, onFailure} = action.payload;

        return this.rewardsApiService.create(reward).pipe(
          mergeMap(r => [new CreateRewardSuccessAction({reward: r}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public update$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateRewardAction>(RewardsActionType.UPDATE),
      mergeMap(action => {
        const {rewardId, reward, onSuccess, onFailure} = action.payload;

        return this.rewardsApiService.update(rewardId, reward).pipe(
          mergeMap(r => [new UpdateRewardSuccessAction({reward: r}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public delete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteRewardAction>(RewardsActionType.DELETE),
      mergeMap(action => {
        const {id, onSuccess, onFailure} = action.payload;

        return this.rewardsApiService.delete(id).pipe(
          mergeMap(rewardId => [new DeleteRewardSuccessAction(), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getCoinTypes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetCoinTypesAction>(RewardsActionType.GET_COIN_TYPES),
      mergeMap(action => {
        const {onSuccess, onFailure} = action.payload;

        return this.rewardsApiService.getCoinTypes().pipe(
          mergeMap(coinTypes => [new GetCoinTypesSuccessAction({coinTypes}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public fulfillReward$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<FulfillRewardAction>(RewardsActionType.FULFILL_REWARD),
      mergeMap(action => {
        const {rewardId, assignedToArrayId, onSuccess, onFailure} = action.payload;

        return this.rewardsApiService.fulfillReward(rewardId, assignedToArrayId).pipe(
          mergeMap(reward => [new FulfillRewardSuccessAction({reward}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public claimReward$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<ClaimRewardAction>(RewardsActionType.CLAIM),
      mergeMap(action => {
        const {rewardId, assignedToArrayId, onSuccess, onFailure} = action.payload;

        return this.rewardsApiService.claimReward(rewardId, assignedToArrayId).pipe(
          mergeMap(() => [...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private rewardsApiService: RewardsApiService
  ) {}
}
