import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'userAvatar',
})
export class UserAvatarPipe implements PipeTransform {
  transform(value: string, characters?: number): string {
    if (!value) {
      return '';
    }
    const nameArr = value.split(' ');
    if (characters === 1 || nameArr.length === 1) {
      return nameArr[0][0] || '';
    }
    return (nameArr[0][0] || '') + (nameArr[1][0] || '');
  }
}
