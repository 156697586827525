import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Tracker, TrackerData, TrackerDetailData, TrackerMetric} from '../model/tracker';

@Injectable({
  providedIn: 'root',
})
export class TrackersApiService {
  private url = `${environment.apiUrl}/trackers`;

  constructor(private http: HttpClient) {}

  public getAll(params): Observable<TrackerData> {
    return this.http.get<TrackerData>(this.url, {params});
  }

  public getSingle(id: string): Observable<TrackerDetailData> {
    return this.http.get<TrackerDetailData>(`${this.url}/${id}`);
  }

  public createNew(): Observable<Tracker> {
    return this.http.post<Tracker>(`${this.url}`, {});
  }

  public update(tracker: Tracker): Observable<Tracker> {
    return this.http.put<Tracker>(`${this.url}/${tracker._id}`, {...tracker});
  }

  public duplicate(trackerId: string): Observable<Tracker> {
    return this.http.post<Tracker>(`${this.url}/${trackerId}`, {});
  }

  public delete(trackerId: string) {
    return this.http.delete(`${this.url}/${trackerId}`);
  }

  public createNewMetric(): Observable<Tracker> {
    return this.http.post<Tracker>(`${this.url}`, {});
  }

  public addMetricToTracker(trackerId: string, metric: TrackerMetric): Observable<Tracker> {
    return this.http.post<Tracker>(`${this.url}/${trackerId}/metric`, {...metric});
  }

  public updateMetric(trackerId: string, metric: TrackerMetric): Observable<Tracker> {
    return this.http.patch<Tracker>(`${this.url}/${trackerId}/metric/${metric._id}`, {...metric});
  }

  public updateMetricValue(trackerId: string, metricId: string, value: string): Observable<Tracker> {
    return this.http.patch<Tracker>(`${this.url}/${trackerId}/metric/${metricId}/updateValue`, {value});
  }

  public duplicateMetric(trackerId: string, metricId: string) {
    return this.http.post(`${this.url}/${trackerId}/metric/${metricId}`, {});
  }

  public deleteMetric(trackerId: string, metricId: string) {
    return this.http.delete(`${this.url}/${trackerId}/metric/${metricId}`);
  }
}
