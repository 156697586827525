import {AbstractControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';

// Custom validator
export function emailOrAdminValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value === 'admin') {
      return null; // If the value is 'admin', it's valid
    }

    // If not 'admin', check for valid email
    const emailValidationResult = Validators.email(control);

    // If the email validation fails and it's not 'admin', return an error
    if (emailValidationResult) {
      return {emailOrAdmin: 'Value must be a valid email or "admin"'};
    }

    return null; // If it passes the email validation, return null (no error)
  };
}
