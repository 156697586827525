import {TaskPanelItem} from '../model/task-panel-item';

export enum TaskWizardPanels {
  TaskOrEvent = 'taskOrEvent',
  BasicInfo = 'basicInfo',
  EventDate = 'eventDate',
  EventTime = 'eventTime',
  EventTrigger = 'eventTrigger',
  TaskTrigger = 'taskTrigger',
  TaskDate = 'taskDate',
  TaskRepeat = 'taskRepeat',
  EventRepeat = 'eventRepeat',
  Completion = 'completion',
  SubtaskList = 'subtaskList',
  SubtaskEdit = 'subtaskEdit',
  Finish = 'finish',
}

export const TASK_PANEL_ARRAY: TaskWizardPanels[] = [
  TaskWizardPanels.TaskOrEvent,
  TaskWizardPanels.BasicInfo,
  TaskWizardPanels.TaskDate,
  TaskWizardPanels.TaskRepeat,
  TaskWizardPanels.TaskTrigger,
  TaskWizardPanels.Completion,
  TaskWizardPanels.SubtaskList,
  TaskWizardPanels.SubtaskEdit,
  TaskWizardPanels.Finish,
];

export const EVENT_PANEL_ARRAY: TaskWizardPanels[] = [
  TaskWizardPanels.TaskOrEvent,
  TaskWizardPanels.BasicInfo,
  TaskWizardPanels.EventDate,
  TaskWizardPanels.EventTime,
  TaskWizardPanels.EventTrigger,
  TaskWizardPanels.EventRepeat,
  TaskWizardPanels.SubtaskList,
  TaskWizardPanels.SubtaskEdit,
  TaskWizardPanels.Finish,
];

export const PANEL_DATA: Record<TaskWizardPanels, TaskPanelItem> = {
  [TaskWizardPanels.TaskOrEvent]: {title: 'Is this a task or event?'},
  [TaskWizardPanels.BasicInfo]: {title: 'Add some basic information.'},
  [TaskWizardPanels.EventDate]: {title: 'Select the date.'},
  [TaskWizardPanels.EventTime]: {title: 'Select a start and stop time.'},
  [TaskWizardPanels.EventTrigger]: {title: 'Does this event have a trigger?'},
  [TaskWizardPanels.TaskTrigger]: {title: 'Does this task have a trigger?'},
  [TaskWizardPanels.TaskDate]: {title: 'When can the task be started?'},
  [TaskWizardPanels.TaskRepeat]: {title: 'Does the task repeat?'},
  [TaskWizardPanels.EventRepeat]: {title: 'Does the event repeat?'},
  [TaskWizardPanels.Completion]: {title: 'When is the target completion?'},
  [TaskWizardPanels.SubtaskList]: {title: 'Create subtasks'},
  [TaskWizardPanels.SubtaskEdit]: {title: ''},
  [TaskWizardPanels.Finish]: {title: 'Success!'},
};
