import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';

@Component({
  selector: 'rewards-user-count-chevron',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './rewards-user-count-chevron.component.html',
  styleUrl: './rewards-user-count-chevron.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RewardsUserCountChevronComponent {
  @Input() userCount: number;
  @Input() expanded: boolean;
}
