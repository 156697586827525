<img class="fullsked-logo" src="assets/img/fullsked-logo.svg" />

@if (
  {
    passwordInputType: passwordInputType$ | async,
    passwordConfirmInputType: passwordConfirmInputType$ | async,
    loadingValid: loadingValid$ | async,
    errorMessage: errorMessage$ | async,
    view: view$ | async
  };
  as state
) {
  <form [formGroup]="form">
    @if (state.loadingValid) {
      <oph-loading></oph-loading>
    } @else {
      @if (state.view === 'valid') {
        <h3>Create a new password.</h3>
        <div class="password-input-container mb-20">
          <input
            formControlName="password"
            placeholder="New password"
            [type]="state.passwordInputType"
            class="password-input"
          />
          <button class="show-password-button">
            <oph-icon
              [name]="state.passwordInputType === 'text' ? 'show-password' : 'hide-password'"
              class="show-password-icon"
              (click)="onTogglePassword($event)"
              matSuffix
            ></oph-icon>
          </button>
        </div>
        <div class="password-input-container mb-20">
          <input
            formControlName="confirmPassword"
            placeholder="Confirm password"
            [type]="state.passwordConfirmInputType"
            class="password-input"
          />
          <button class="show-password-button">
            <oph-icon
              [name]="state.passwordConfirmInputType === 'text' ? 'show-password' : 'hide-password'"
              class="show-password-icon"
              (click)="onToggleConfirmPassword($event)"
              matSuffix
            ></oph-icon>
          </button>
        </div>
        <div class="button-container">
          <button class="go-button" (click)="onGo()">
            @if (loadingReset) {
              <div class="oph-white-spinner">
                <mat-spinner [diameter]="24"></mat-spinner>
              </div>
            } @else {
              Go
            }
          </button>
        </div>
        <div class="error-msg">
          <small data-test="login-form-error">{{ state.errorMessage }}</small>
        </div>
      }
      @if (state.view === 'invalid') {
        <h3>Reset Link Not Valid</h3>
        @if (state.errorMessage) {
          <p>
            {{ state.errorMessage }}
          </p>
        }
        @if (!state.errorMessage) {
          <p>
            The password reset link you tried to use is no longer <br />
            valid. It may have expired or been used already.
          </p>
        }
        <div class="invalid-button-container">
          <button class="button request" (click)="onRequestNewLink()">Request New Link</button>
          <button class="button back" (click)="onBack($event)">Back to Login</button>
        </div>
      }
      @if (state.view === 'passwordChanged') {
        <h3>Your password has been successfully changed!</h3>
        <div class="button-container center">
          <button class="button back" (click)="onBack($event)">Back to Login</button>
        </div>
      }
    }
  </form>
}
