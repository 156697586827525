<div class="left-container">
  <h3>{{ text }}</h3>
  @if (color) {
    <div [class]="'color-bar ' + color"></div>
  }
</div>

@if (addNewButton) {
  <oph-button
    color="gray"
    appearance="outline"
    size="small"
    [style]="buttonStyle"
    [loading]="addNewButtonLoading"
    (buttonClick)="buttonClick.emit()"
    >Add new</oph-button
  >
}

@if (taskCount) {
  <div class="task-count">{{ taskCount }} {{ taskCount === 1 ? 'Task' : 'Tasks' }}</div>
}
