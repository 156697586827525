export enum ProjectViewActivityAction {
  Todo = 'Task Todo',
  Start = 'Task Started',
  OnHold = 'Task On Hold',
  Completed = 'Task Completed',
  Cancelled = 'Task Cancelled',
  AttributeUpdated = 'Attribute Updated',
  TaskTriggered = 'Task Triggered',
  Expired = 'Task Expired',
}
