import {Pipe, PipeTransform} from '@angular/core';
import {minutesToHoursAndMinutes} from '../utils/minutes-to-hours-and-mintes';

@Pipe({
  name: 'minutesToHoursAndMinutes',
})
export class MinutesToHoursAndMinutesPipe implements PipeTransform {
  transform(minutes: number): string {
    return minutesToHoursAndMinutes(minutes);
  }
}
