<button [class.checked]="checked" [class.disabled-button]="disabled" [disabled]="disabled" (click)="onButton($event)">
  @if (loading) {
    <div [class]="checked ? 'oph-white-spinner' : 'oph-green-spinner'">
      <mat-spinner [diameter]="16"></mat-spinner>
    </div>
  } @else {
    <div class="hover-background"></div>
    <img [src]="checkImgSrc" />
  }
</button>
