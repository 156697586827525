import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {DateAdapter, MAT_DATE_FORMATS, MatDateFormats} from '@angular/material/core';
import {MatCalendar, MatDatepickerModule} from '@angular/material/datepicker';
import {MatIconModule} from '@angular/material/icon';
import * as moment from 'moment';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'builder-calendar',
  standalone: true,
  imports: [MatCardModule, MatDatepickerModule],
  templateUrl: './builder-calendar.component.html',
  styleUrls: ['./builder-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuilderCalendarComponent {
  @Input() isDisabled: boolean;
  @Input() selectedDate: Date | string | null;
  @Input() weekSelector: boolean;
  @Output() calendarDateChange = new EventEmitter();

  public readonly customHeader = CustomHeaderComponent;

  dateFilter: (date: moment.Moment | null) => boolean = (date: moment.Moment | null) => {
    if (!date) {
      return false;
    }
    const day = date.day();
    return day === 1; // 1 means monday, 0 means sunday, etc.
  };

  onDateChange(date: Date | null) {
    this.calendarDateChange.emit(date);
  }
}

@Component({
  standalone: true,
  imports: [MatIconModule, MatCardModule, MatDatepickerModule, MatButtonModule],
  styles: [
    `
      .custom-header {
        display: flex;
        align-items: center;
        padding: 0 0.5em 0.5em;
      }

      .custom-header .mat-icon {
        cursor: pointer;
        color: #5d4037;
      }

      .custom-header-label {
        flex: 1;
        font-weight: 500;
        text-align: center;
        color: #5d4037;
      }
    `,
  ],
  template: `
    <div class="custom-header">
      <button mat-icon-button (click)="previousClicked('month')">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <span class="custom-header-label">{{ periodLabel }}</span>
      <button mat-icon-button (click)="nextClicked('month')">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomHeaderComponent<D> implements OnDestroy {
  private destroyed = new Subject<void>();

  constructor(
    private calendar: MatCalendar<D>,
    private dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef
  ) {
    calendar.stateChanges.pipe(takeUntil(this.destroyed)).subscribe(() => cdr.markForCheck());
    this.dateAdapter.setLocale('en-US');
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  get periodLabel() {
    return this.dateAdapter
      .format(this.calendar.activeDate, this.dateFormats.display.monthYearA11yLabel)
      .toLocaleUpperCase();
  }

  previousClicked(mode: 'month' | 'year') {
    this.calendar.activeDate =
      mode === 'month'
        ? this.dateAdapter.addCalendarMonths(this.calendar.activeDate, -1)
        : this.dateAdapter.addCalendarYears(this.calendar.activeDate, -1);
  }

  nextClicked(mode: 'month' | 'year') {
    this.calendar.activeDate =
      mode === 'month'
        ? this.dateAdapter.addCalendarMonths(this.calendar.activeDate, 1)
        : this.dateAdapter.addCalendarYears(this.calendar.activeDate, 1);
  }
}
