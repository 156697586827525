import {GoalsAction, GoalsActionType} from './goals.action';
import {GoalsState, initialGoalsState} from './goals.state';

export function goalsReducer(state: GoalsState = initialGoalsState, action: GoalsAction): GoalsState {
  switch (action.type) {
    case GoalsActionType.GET_SUCCESS:
      return {...state, goals: action.payload.goals};
    case GoalsActionType.GET_SINGLE_SUCCESS:
      return {...state, goal: action.payload.goal};
    case GoalsActionType.UPDATE_SUCCESS:
      return {...state, goal: action.payload.goal};
    case GoalsActionType.DISCONNECT_TARGET_SUCCESS:
    case GoalsActionType.CONNECT_TARGET_SUCCESS:
    case GoalsActionType.TOGGLE_TARGET_SUCCESS:
      return {...state, goal: action.payload.goal};
    default:
      return state;
  }
}
