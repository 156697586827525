import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'rewards-reward-header-item',
  standalone: true,
  imports: [],
  templateUrl: './rewards-reward-header-item.component.html',
  styleUrl: './rewards-reward-header-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RewardsRewardHeaderItemComponent {
  @Input() name: string;
  @Input() description: string;
}
