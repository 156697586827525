import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ProjectViewIconContainerComponent} from '../icon-container/project-view-icon-container.component';

@Component({
  selector: 'project-view-line-item',
  standalone: true,
  imports: [ProjectViewIconContainerComponent],
  templateUrl: './project-view-line-item.component.html',
  styleUrl: './project-view-line-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectViewLineItemComponent {
  @Input() icon: string;
  @Input() padding: string = '10px 10px';
}
