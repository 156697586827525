import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {OphIconModule} from '../oph-icon/oph-icon.module';

@Component({
  selector: 'oph-x-button',
  standalone: true,
  imports: [OphIconModule],
  templateUrl: './oph-x-button.component.html',
  styleUrl: './oph-x-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OphXButtonComponent {
  @Input() width: number = 24;
  @Output() buttonClick = new EventEmitter();
}
