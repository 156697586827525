import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {convertTeamDtoToModel} from '../../../teams/shared/converters/convert-team-dto-to-model';
import {TeamDto} from '../../../teams/shared/dto/team.dto';
import {TeamsApiService} from '../../api/teams-api.service';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {
  CreateTeamAction,
  CreateTeamSuccessAction,
  DeleteTeamAction,
  DeleteTeamSuccessAction,
  GetTeamsAction,
  GetTeamsSuccessAction,
  TeamsActionType,
  UpdateTeamAction,
  UpdateTeamSuccessAction,
} from './teams.action';

@Injectable()
export class TeamsEffects {
  public get$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetTeamsAction>(TeamsActionType.GET),
      mergeMap(action => {
        const {onSuccess, onFailure} = action.payload;

        return this.teamsApiService.get().pipe(
          map((dtos: TeamDto[]) => dtos.map(dto => convertTeamDtoToModel(dto))),
          mergeMap(teams => [new GetTeamsSuccessAction({teams}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public create$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateTeamAction>(TeamsActionType.CREATE),
      mergeMap(action => {
        const {team, onSuccess, onFailure} = action.payload;

        return this.teamsApiService.create(team).pipe(
          mergeMap(p => [new CreateTeamSuccessAction({team: p}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public update$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateTeamAction>(TeamsActionType.UPDATE),
      mergeMap(action => {
        const {team, onSuccess, onFailure} = action.payload;

        return this.teamsApiService.update(team).pipe(
          mergeMap(p => [new UpdateTeamSuccessAction({team: p}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public delete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteTeamAction>(TeamsActionType.DELETE),
      mergeMap(action => {
        const {id, onSuccess, onFailure} = action.payload;

        return this.teamsApiService.delete(id).pipe(
          mergeMap(() => [new DeleteTeamSuccessAction(), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private teamsApiService: TeamsApiService // private store$: Store<{}>
  ) {}
}
