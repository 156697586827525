import {AsyncPipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, HostBinding, inject, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {Router, RouterOutlet} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {combineLatest, map, Observable, Subscription, take, withLatestFrom} from 'rxjs';
import {Reward, RewardCoin} from '../core/model/reward';
import {User} from '../core/model/user';
import {TitleService} from '../core/page/title.service';
import {selectActiveUserRole} from '../core/store/active-user/active-user.selector';
import {GetCoinTypesAction} from '../core/store/rewards/rewards.action';
import {selectCoinTypes, selectRewardsHeaderLoading} from '../core/store/rewards/rewards.selector';
import {selectRouterQueryParam, selectRouterUrl} from '../core/store/router/router.selector';
import {GetAllUsersAction} from '../core/store/users/users.action';
import {selectAllUsers, selectUsersMap} from '../core/store/users/users.selector';
import {OphAvatarComponent} from '../shared/design/oph-avatar/oph-avatar.component';
import {
  OphButtonGroupComponent,
  OphButtonGroupOption,
} from '../shared/design/oph-button-group/oph-button-group.component';
import {OphNewButtonComponent} from '../shared/design/oph-new-button/oph-new-button.component';
import {
  REWARDS_ADMIN_OPTIONS,
  REWARDS_USER_OPTIONS,
  rewardsDialogConfig,
  RewardsTabOption,
} from './shared/rewards-constants';
import {RewardsDialogComponent} from './shared/rewards-dialog/rewards-dialog.component';

@Component({
  selector: 'rewards',
  standalone: true,
  imports: [
    AsyncPipe,
    MatIconModule,
    RouterOutlet,
    OphButtonGroupComponent,
    OphNewButtonComponent,
    MatMenuModule,
    OphAvatarComponent,
    MatProgressSpinnerModule,
  ],
  templateUrl: './rewards.component.html',
  styleUrl: './rewards.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RewardsComponent implements OnInit, OnDestroy {
  @HostBinding('class') hostClasses = 'oph-feature-layout';

  subscriptions = new Subscription();

  userRole$: Observable<string> = this.store$.pipe(select(selectActiveUserRole)).pipe(map(role => role?.toLowerCase()));
  routerUrl$: Observable<string> = this.store$.pipe(select(selectRouterUrl));
  selectedUserId$: Observable<string> = this.store$.pipe(select(selectRouterQueryParam('search')));
  coinTypes$: Observable<RewardCoin[]> = this.store$.pipe(select(selectCoinTypes));
  users$: Observable<User[]> = this.store$.pipe(select(selectAllUsers));
  usersMap$: Observable<Record<string, User>> = this.store$.pipe(select(selectUsersMap));
  headerLoading$: Observable<boolean> = this.store$.pipe(select(selectRewardsHeaderLoading));

  tabOptions$: Observable<RewardsTabOption[]>;
  buttonGroupOptions$: Observable<OphButtonGroupOption[]>;
  selectedTabOption$: Observable<RewardsTabOption>;

  adminOptions = REWARDS_ADMIN_OPTIONS;
  userOptions = REWARDS_USER_OPTIONS;
  readonly dialog = inject(MatDialog);

  constructor(
    private store$: Store,
    private titleService: TitleService,
    private router: Router
  ) {}

  ngOnInit() {
    this.titleService.setPageTitle('Rewards');

    this.store$.dispatch(new GetCoinTypesAction({}));
    this.store$.dispatch(new GetAllUsersAction({}));

    this.tabOptions$ = this.observeUserRole();
    this.buttonGroupOptions$ = this.observeTabOptions();
    this.selectedTabOption$ = this.observeRouterUrl();

    this.subscriptions.add(this.subscribeToUserRole());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  subscribeToUserRole(): Subscription {
    return this.userRole$.subscribe(role => {
      if (role === 'admin') {
        this.router.navigate(['/rewards/manage']);
      } else {
        this.router.navigate(['/rewards/my-rewards']);
      }
    });
  }

  observeUserRole(): Observable<RewardsTabOption[]> {
    return this.userRole$.pipe(
      map(role => {
        return role?.toLowerCase() === 'admin' ? this.adminOptions : this.userOptions;
      })
    );
  }

  observeTabOptions(): Observable<OphButtonGroupOption[]> {
    return this.tabOptions$.pipe(
      map(options => {
        return options.map(option => ({name: option.tabName}));
      })
    );
  }

  observeRouterUrl(): Observable<RewardsTabOption> {
    return this.routerUrl$.pipe(
      withLatestFrom(this.tabOptions$),
      map(([url, options]) => {
        // Parse the URL using Angular's Router
        const urlTree = this.router.parseUrl(url);
        const path = urlTree.root.children['primary']?.segments.map(segment => segment.path).pop(); // Get the last segment

        return options.find(option => option.url === path);
      })
    );
  }

  onTabOption(tab: string) {
    this.tabOptions$.pipe(take(1)).subscribe(options => {
      const url = options.find(option => option.tabName === tab)?.url;
      this.router.navigate(['/rewards', url]);
    });
  }

  openRewardDialog(reward: Reward): void {
    combineLatest([this.coinTypes$, this.users$, this.usersMap$, this.selectedTabOption$])
      .pipe(take(1))
      .subscribe(([coinTypes, users, usersMap, tab]) => {
        this.dialog.open(RewardsDialogComponent, {
          ...rewardsDialogConfig,
          data: {reward, coinTypes, users, usersMap, page: tab.url},
        });
      });
  }

  onUser(userId: string | null) {
    this.router.navigate(['/rewards/history'], {queryParams: {search: userId, page: 1}});
  }
}
