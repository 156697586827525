import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OphInlineEditMenuComponent} from './oph-inline-edit-menu.component';
import {OphIconModule} from '../oph-icon/oph-icon.module';

@NgModule({
  declarations: [OphInlineEditMenuComponent],
  imports: [CommonModule, OphIconModule],
  exports: [OphInlineEditMenuComponent],
})
export class OphInlineEditMenuModule {}
