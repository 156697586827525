import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Project} from 'src/app/core/model/project';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  // ---------------------------------------------------------------------------
  // Methods were copy/pasted from project-builder.component.ts (now deleted) to be used globally
  // Originally authored by Stanislav Kromaschenkov and Ian Sear
  private subject = new Subject<void>();

  getProjectPartial(project: Project): Partial<Project> {
    return {
      startDateTime: project.startDateTime,
      endDateTime: project.endDateTime,
      length: project.length,
      status: project.status,
      private: project.private,
      authorNotes: project.authorNotes,
    };
  }

  getProjectFields(project: Project): Partial<Project> {
    return {
      authorNotes: project.authorNotes,
      endDateTime: project.endDateTime,
      length: project.length,
      private: project.private,
      startDateTime: project.startDateTime,
      status: project.status,
    };
  }

  // --------------------------------------------------------------------------

  sendClickEvent() {
    this.subject.next();
  }

  getClickEvent(): Observable<unknown> {
    return this.subject.asObservable();
  }
}
