<div class="header">
  <h1 class="desktop">Subtasks ({{ formArray?.length || 0 }})</h1>
  <div class="button-container">
    <button class="subtask-button desktop" (click)="onAddSubtask()">
      <img src="assets/img/icons/plus-circle-white.svg" />
      Subtask
    </button>

    <button class="subtask-button-mobile mobile" (click)="onAddSubtask()">
      <img src="assets/img/icons/plus-circle-orange.svg" />
      Subtask
    </button>

    <!-- This will get added in later -->
    <!-- <button class="condition-button">
      <img src="assets/img/icons/bolt-outline-orange.svg" />
      Condition
    </button> -->
  </div>
</div>

<subtask-list [formArray]="formArray" (subtaskIndexChange)="onEditSubtask($event)"></subtask-list>
