import {createSelector} from '@ngrx/store';
import {Sked} from 'src/app/current-sked/shared/model/sked';
import {AppState} from '../app-state';

export const selectCurrentSkedState = (state: AppState) => state.currentSked;

export const selectCurrentSkedSkeds = createSelector(selectCurrentSkedState, state => state.skeds);

export const selectCurrentSked = createSelector(
  selectCurrentSkedSkeds,
  skeds => skeds.find(sked => sked.current) || ({} as Sked)
);

export const selectCurrentSkedDetailTask = createSelector(selectCurrentSkedState, state => state.task);

export const selectCurrentSkedActiveTab = createSelector(selectCurrentSkedState, state => state.activeTab);

export const selectSelectedSkedType = createSelector(selectCurrentSkedState, state => state.selectedSkedType);

export const selectUpdatedTask = createSelector(selectCurrentSkedState, state => state.updatedTask);

export const selectCurrentSkedValueChosenMap = createSelector(
  selectCurrentSkedState,
  state => state.metricUpdateValueChosenMap
);
