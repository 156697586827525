export interface TaskIconItem {
  name: string;
  icons: TaskIcon[];
}

export interface TaskIcon {
  name: string;
  keywords: string[];
  categoryName?: string;
  categoryIndex?: number;
}

export const TASK_ICON_PICKER_ICONS: TaskIconItem[] = [
  {
    name: 'miscellaneous',
    icons: [
      {name: 'male-sign', keywords: ['miscellaneous', 'male', 'sign', 'gender', 'identity', 'symbol']},
      {name: 'female-sign', keywords: ['miscellaneous', 'female', 'sign', 'gender', 'identity', 'symbol']},
      {name: 'ban-sign', keywords: ['miscellaneous', 'ban', 'sign', 'restriction', 'prohibition', 'forbidden']},
      {name: 'aquarius', keywords: ['miscellaneous', 'aquarius', 'zodiac', 'astrology']},
      {name: 'yarn-ball', keywords: ['miscellaneous', 'yarn', 'ball', 'knitting', 'crafts']},
      {name: 'basket', keywords: ['miscellaneous', 'basket', 'storage', 'wicker', 'container']},
      {name: 'book', keywords: ['miscellaneous', 'book', 'reading', 'knowledge', 'education', 'literature']},
      {name: 'brain', keywords: ['miscellaneous', 'brain', 'mind', 'intelligence']},
      {
        name: 'capricorn',
        keywords: ['miscellaneous', 'capricorn', 'zodiac', 'astrology'],
      },
      {name: 'cards', keywords: ['miscellaneous', 'cards', 'payment', 'transactions', 'finance']},
      {name: 'credit-card', keywords: ['miscellaneous', 'credit', 'card', 'finance', 'money']},
      {
        name: 'shopping-cart',
        keywords: ['miscellaneous', 'shopping', 'cart', 'supermarket'],
      },
      {name: 'cash-bill', keywords: ['miscellaneous', 'cash', 'bill', 'money', 'finance']},
      {name: 'cash-coupons', keywords: ['miscellaneous', 'cash', 'coupons', 'savings', 'discounts', 'deals']},
      {
        name: 'caution-electricity',
        keywords: ['miscellaneous', 'caution', 'electricity', 'safety', 'hazard', 'warning'],
      },
      {name: 'christmas-star', keywords: ['miscellaneous', 'christmas', 'star', 'holiday', 'celebration', 'decor']},
      {name: 'clock-time-watch', keywords: ['miscellaneous', 'clock', 'time', 'watch', 'measurement', 'schedule']},
      {name: 'coins-1', keywords: ['miscellaneous', 'coins', 'currency', 'money', 'metal', 'finance']},
      {name: 'coins-2', keywords: ['miscellaneous', 'coins', 'currency', 'savings', 'metallic', 'value']},
      {name: 'coins-3', keywords: ['miscellaneous', 'coins', 'currency', 'finance', 'exchange', 'trade']},
      {name: 'collar', keywords: ['miscellaneous', 'collar', 'fashion', 'style', 'clothing', 'apparel']},
      {name: 'desk-bell', keywords: ['miscellaneous', 'desk', 'bell', 'alert', 'service', 'attention']},
      {name: 'ear', keywords: ['miscellaneous', 'ear', 'hearing', 'sense', 'sound', 'body']},
      {name: 'eye', keywords: ['miscellaneous', 'eye', 'vision', 'sight', 'look', 'see']},
      {name: 'flag', keywords: ['miscellaneous', 'flag', 'nation', 'symbol', 'patriotism', 'identity']},
      {name: 'flexed-biceps', keywords: ['miscellaneous', 'flexed', 'biceps', 'strength', 'muscle', 'power']},
      {name: 'general-caution', keywords: ['miscellaneous', 'general', 'caution', 'warning', 'alert', 'safety']},
      {name: 'insecticide', keywords: ['miscellaneous', 'insecticide', 'pest', 'control', 'bug', 'chemical']},
      {name: 'leg', keywords: ['miscellaneous', 'leg', 'limb', 'walking', 'mobility', 'anatomy']},
      {
        name: 'letter-postcard',
        keywords: ['miscellaneous', 'letter', 'postcard', 'mail', 'correspondence', 'communication'],
      },
      {
        name: 'letter-postcard-1',
        keywords: ['miscellaneous', 'letter', 'postcard', 'greeting', 'sending', 'communication'],
      },
      {name: 'libra', keywords: ['miscellaneous', 'libra', 'zodiac', 'balance', 'justice', 'astrology']},
      {name: 'lipstick', keywords: ['miscellaneous', 'lipstick', 'makeup', 'beauty', 'cosmetics', 'fashion']},
      {
        name: 'litter-in-bin-sign',
        keywords: ['miscellaneous', 'litter', 'bin', 'sign', 'recycling', 'environment'],
      },
      {
        name: 'lock-directory',
        keywords: ['miscellaneous', 'lock', 'directory', 'security', 'privacy', 'protection'],
      },
      {name: 'manicure', keywords: ['miscellaneous', 'manicure', 'nails', 'beauty', 'care', 'cosmetics']},
      {
        name: 'maps-and-flags-road-sign',
        keywords: ['miscellaneous', 'maps', 'flags', 'road', 'sign', 'navigation'],
      },
      {name: 'mouth', keywords: ['miscellaneous', 'mouth', 'speech', 'expression', 'taste', 'communication']},
      {name: 'music', keywords: ['miscellaneous', 'music', 'melody', 'harmony', 'rhythm', 'audio']},
      {name: 'music-1', keywords: ['miscellaneous', 'music', 'melody', 'harmony', 'composition', 'audio']},
      {name: 'music-play', keywords: ['miscellaneous', 'music', 'play', 'audio', 'listen', 'entertainment']},
      {name: 'music-stop', keywords: ['miscellaneous', 'music', 'stop', 'pause', 'silence', 'halt']},
      {name: 'nail-polish', keywords: ['miscellaneous', 'nail', 'polish', 'manicure', 'style', 'beauty']},
      {name: 'nail-polish-1', keywords: ['miscellaneous', 'nail', 'polish', 'cosmetic', 'art', 'beauty']},
      {name: 'nose', keywords: ['miscellaneous', 'nose', 'smell', 'sensory', 'breathing', 'face']},
      {
        name: 'ophiuchus',
        keywords: ['miscellaneous', 'ophiuchus', 'zodiac', 'mystery', 'astrology', 'constellation'],
      },
      {name: 'paper-bag', keywords: ['miscellaneous', 'paper', 'bag', 'shopping', 'recycle', 'eco-friendly']},
      {name: 'pisces', keywords: ['miscellaneous', 'pisces', 'zodiac', 'water', 'intuitive', 'astrology']},
      {name: 'red-heart', keywords: ['miscellaneous', 'red', 'heart', 'love', 'emotion', 'affection']},
      {name: 'scorpio', keywords: ['miscellaneous', 'scorpio', 'zodiac', 'intense', 'mysterious', 'astrology']},
      {name: 'shopping-bag', keywords: ['miscellaneous', 'shopping', 'bag', 'retail', 'consumer', 'purchase']},
      {name: 'sign-add', keywords: ['miscellaneous', 'sign', 'add', 'plus', 'increase', 'addition']},
      {name: 'sign-check', keywords: ['miscellaneous', 'sign', 'check', 'confirm', 'approval', 'ok']},
      {name: 'sign-delete', keywords: ['miscellaneous', 'sign', 'delete', 'remove', 'erase', 'clear']},
      {name: 'sign-down', keywords: ['miscellaneous', 'sign', 'down', 'decrease', 'lower', 'reduce']},
      {name: 'sign-error', keywords: ['miscellaneous', 'sign', 'error', 'mistake', 'problem', 'fault']},
      {name: 'sign-info', keywords: ['miscellaneous', 'sign', 'info', 'information', 'help', 'details']},
      {name: 'sign-left', keywords: ['miscellaneous', 'sign', 'left', 'direction', 'arrow', 'navigation']},
      {name: 'sign-question', keywords: ['miscellaneous', 'sign', 'question', 'inquiry', 'doubt', 'query']},
      {name: 'sign-right', keywords: ['miscellaneous', 'sign', 'right', 'direction', 'arrow', 'forward']},
      {name: 'sign-sync', keywords: ['miscellaneous', 'sign', 'sync', 'update', 'refresh', 'cycle']},
      {name: 'sign-up', keywords: ['miscellaneous', 'sign', 'up', 'increase', 'raise', 'promote']},
      {name: 'spray-bottle', keywords: ['miscellaneous', 'spray', 'bottle', 'cleaner', 'liquid', 'dispense']},
      {name: 'street-map-gps', keywords: ['miscellaneous', 'street', 'map', 'gps', 'navigation', 'location']},
      {name: 'street-sign', keywords: ['miscellaneous', 'street', 'sign', 'direction', 'navigation', 'guide']},
      {name: 'suitcase-travel', keywords: ['miscellaneous', 'suitcase', 'travel', 'luggage', 'trip', 'tourism']},
      {name: 'supermarket-gift', keywords: ['miscellaneous', 'supermarket', 'gift', 'shopping', 'store', 'offer']},
      {
        name: 'table-of-contents',
        keywords: ['miscellaneous', 'table', 'contents', 'index', 'organize', 'document'],
      },
      {name: 'tank', keywords: ['miscellaneous', 'tank', 'military', 'armor', 'war', 'battle']},
      {name: 'test-tube-chemical', keywords: ['miscellaneous', 'test', 'tube', 'chemical', 'lab', 'science']},
      {name: 'tissues-paper', keywords: ['miscellaneous', 'tissues', 'paper', 'hygiene', 'clean', 'wipe']},
      {name: 'toilet-paper', keywords: ['miscellaneous', 'toilet', 'paper', 'bathroom', 'hygiene', 'tissue']},
      {name: 'train-ticket', keywords: ['miscellaneous', 'train', 'ticket', 'travel', 'transport', 'journey']},
      {name: 'wallet', keywords: ['miscellaneous', 'wallet', 'money', 'finance', 'cash', 'storage']},
      {name: 'water-bottle', keywords: ['miscellaneous', 'water', 'bottle', 'drink', 'hydration', 'portable']},
      {name: 'water-fee', keywords: ['miscellaneous', 'water', 'fee', 'bill', 'utility', 'charge']},
      {name: 'water-gun', keywords: ['miscellaneous', 'water', 'gun', 'toy', 'play', 'summer']},
      {name: 'water-tank', keywords: ['miscellaneous', 'water', 'tank', 'storage', 'reserve', 'supply']},
      {name: 'world-map', keywords: ['miscellaneous', 'world', 'map', 'globe', 'geography', 'global']},
    ],
  },
  {
    name: 'faces',
    icons: [
      {name: 'astonished-emoji', keywords: ['faces', 'astonished', 'emoji', 'surprised', 'shocked', 'expression']},
      {name: 'astonished-emoji-1', keywords: ['faces', 'astonished', 'emoji', 'amazed', 'stunned', 'expression']},
      {
        name: 'claus-santa-christmas',
        keywords: ['faces', 'claus', 'santa', 'christmas', 'holiday', 'festive', 'gifts'],
      },
      {name: 'cool-emoji', keywords: ['faces', 'cool', 'emoji', 'sunglasses', 'stylish', 'chill']},
      {name: 'danger', keywords: ['faces', 'danger', 'warning', 'hazard', 'risk', 'caution']},
      {name: 'dizzy-emoji', keywords: ['faces', 'dizzy', 'emoji', 'confused', 'spinning', 'sick']},
      {name: 'flushed-emoji', keywords: ['faces', 'flushed', 'emoji', 'embarrassed', 'red', 'blushing']},
      {name: 'happy-emoji', keywords: ['faces', 'happy', 'emoji', 'joyful', 'smiling', 'cheerful']},
      {name: 'happy-emoji-1', keywords: ['faces', 'happy', 'emoji', 'smile', 'joy', 'delighted']},
      {name: 'in-love-emoji', keywords: ['faces', 'in love', 'emoji', 'heart eyes', 'affectionate', 'romantic']},
      {name: 'old-man', keywords: ['faces', 'old', 'man', 'elderly', 'wise', 'grandfather']},
      {name: 'pumpkin', keywords: ['faces', 'pumpkin', 'halloween', 'autumn', 'jack-o-lantern', 'october']},
      {name: 'shocked-emoji', keywords: ['faces', 'shocked', 'emoji', 'astonished', 'amazed', 'surprise']},
      {name: 'sleeping-emoji', keywords: ['faces', 'sleeping', 'emoji', 'rest', 'nap', 'tired']},
      {name: 'smiling-emoji', keywords: ['faces', 'smiling', 'emoji', 'happy', 'joyous', 'grinning']},
      {name: 'smiling-emoji-1', keywords: ['faces', 'smiling', 'emoji', 'happy', 'joyful', 'grin']},
      {name: 'smirking-emoji', keywords: ['faces', 'smirking', 'emoji', 'sly', 'smug', 'mischievous']},
      {name: 'surprised-emoji', keywords: ['faces', 'surprised', 'emoji', 'astonished', 'amazed', 'startled']},
      {name: 'thinking-emoji', keywords: ['faces', 'thinking', 'emoji', 'thoughtful', 'pondering', 'reflective']},
      {name: 'tongue-emoji', keywords: ['faces', 'tongue', 'emoji', 'playful', 'cheeky', 'silly']},
      {name: 'unamused-emoji', keywords: ['faces', 'unamused', 'emoji', 'skeptical', 'displeased', 'indifferent']},
      {name: 'vomiting-emoji', keywords: ['faces', 'vomiting', 'emoji', 'sick', 'nausea', 'disgust']},
    ],
  },
  {
    name: 'animals',
    icons: [
      {name: 'bird', keywords: ['animals', 'bird', 'avian', 'feathered', 'flying', 'songbird']},
      {name: 'bull', keywords: ['animals', 'bull', 'bovine', 'cattle', 'strength', 'farm']},
      {name: 'cat-face', keywords: ['animals', 'cat', 'face', 'feline', 'pet', 'whiskers']},
      {name: 'chicken', keywords: ['animals', 'chicken', 'poultry', 'farm', 'bird', 'hen']},
      {name: 'cow', keywords: ['animals', 'cow', 'bovine', 'milk', 'farm', 'cattle']},
      {name: 'dinosaur', keywords: ['animals', 'dinosaur', 'prehistoric', 'jurassic', 'reptile', 'fossil']},
      {name: 'dinosaur-1', keywords: ['animals', 'dinosaur', 'extinct', 'cretaceous', 'paleontology', 'giant']},
      {name: 'dog-face', keywords: ['animals', 'dog', 'face', 'pet', 'canine', 'puppy']},
      {name: 'fish-1', keywords: ['animals', 'fish', 'aquatic', 'swimming', 'sea', 'marine']},
      {name: 'fish-2', keywords: ['animals', 'fish', 'seafood', 'swimming', 'aquatic', 'marine']},
      {name: 'giraffe', keywords: ['animals', 'giraffe', 'tall', 'safari', 'wildlife', 'spots']},
      {name: 'goat', keywords: ['animals', 'goat', 'farm', 'caprine', 'milk', 'mountain']},
      {name: 'gorilla', keywords: ['animals', 'gorilla', 'ape', 'primate', 'jungle', 'strong']},
      {name: 'hippo', keywords: ['animals', 'hippo', 'hippopotamus', 'water', 'river', 'large']},
      {name: 'horse', keywords: ['animals', 'horse', 'equine', 'ride', 'stallion', 'mane']},
      {name: 'lion-face', keywords: ['animals', 'lion', 'face', 'king of the jungle', 'cat', 'roar']},
      {name: 'monkey-face', keywords: ['animals', 'monkey', 'face', 'primate', 'wild', 'climb']},
      {name: 'panda', keywords: ['animals', 'panda', 'bear', 'bamboo', 'China', 'endangered']},
      {name: 'pig', keywords: ['animals', 'pig', 'swine', 'farm', 'oink', 'mud']},
      {name: 'rabbit-face', keywords: ['animals', 'rabbit', 'face', 'bunny', 'hops', 'ears']},
      {name: 'sheep', keywords: ['animals', 'sheep', 'wool', 'farm', 'lamb', 'flock']},
      {name: 'sheep-1', keywords: ['animals', 'sheep', 'wool', 'lamb', 'pasture', 'herd']},
      {name: 'sheep-2', keywords: ['animals', 'sheep', 'ram', 'woolly', 'livestock', 'grazing']},
      {name: 'tiger', keywords: ['animals', 'tiger', 'big cat', 'stripes', 'predator', 'jungle']},
      {name: 'tropical-fish', keywords: ['animals', 'tropical fish', 'colorful', 'aquarium', 'reef', 'marine']},
      {name: 'walrus', keywords: ['animals', 'walrus', 'tusk', 'marine', 'arctic', 'mammal']},
    ],
  },
  {
    name: 'technology',
    icons: [
      {name: 'arcade', keywords: ['technology', 'arcade', 'games', 'video', 'entertainment', 'coin-operated']},
      {name: 'cassette', keywords: ['technology', 'cassette', 'tape', 'audio', 'music', 'retro']},
      {name: 'cd', keywords: ['technology', 'cd', 'disc', 'compact disc', 'audio', 'music']},
      {name: 'computer-mouse', keywords: ['technology', 'computer', 'mouse', 'input', 'click', 'navigation']},
      {
        name: 'computer-speaker-device',
        keywords: ['technology', 'computer', 'speaker', 'audio', 'sound', 'amplify'],
      },
      {name: 'computer', keywords: ['technology', 'computer', 'hardware', 'computing', 'electronics', 'device']},
      {name: 'game-console', keywords: ['technology', 'game', 'console', 'video games', 'entertainment', 'play']},
      {name: 'game-controller', keywords: ['technology', 'game', 'controller', 'gaming', 'joystick', 'play']},
      {name: 'game-controller-2', keywords: ['technology', 'game', 'controller', 'gaming', 'joystick', 'play']},
      {name: 'headphones', keywords: ['technology', 'headphone', 'audio', 'sound', 'music', 'listening']},
      {name: 'headset', keywords: ['technology', 'headset', 'audio', 'microphone', 'communication', 'sound']},
      {name: 'ipod-nano', keywords: ['technology', 'ipod', 'nano', 'music', 'mp3', 'portable']},
      {name: 'microphone', keywords: ['technology', 'microphone', 'audio', 'sound', 'recording', 'voice']},
      {name: 'personal-console', keywords: ['technology', 'personal', 'console', 'gaming', 'handheld', 'portable']},
      {
        name: 'outlet',
        keywords: ['technology', 'outlet', 'power', 'electric', 'connection', 'energy'],
      },
      {name: 'power-outlet-plug', keywords: ['technology', 'power', 'outlet', 'plug', 'electricity', 'connect']},
      {name: 'usb-memory', keywords: ['technology', 'usb', 'memory', 'storage', 'flash drive', 'data']},
      {name: 'vhs-tape', keywords: ['technology', 'vhs', 'tape', 'video', 'cassette', 'recording']},
    ],
  },
  {
    name: 'furniture',
    icons: [
      {name: 'adornment-furniture', keywords: ['furniture', 'adornment', 'decorative', 'home', 'design', 'style']},
      {name: 'adornment-rug', keywords: ['furniture', 'adornment', 'rug', 'carpet', 'floor', 'decor']},
      {name: 'antique-furniture', keywords: ['furniture', 'antique', 'vintage', 'classic', 'wood', 'decor']},
      {name: 'antique-seat', keywords: ['furniture', 'antique', 'seat', 'chair', 'vintage', 'wooden']},
      {name: 'armchair-chair', keywords: ['furniture', 'armchair', 'chair', 'seating', 'comfort', 'living room']},
      {name: 'armchair-chair-1', keywords: ['furniture', 'armchair', 'chair', 'comfortable', 'lounge', 'design']},
      {name: 'armchair', keywords: ['furniture', 'armchair', 'seat', 'comfort', 'lounge', 'stylish']},
      {
        name: 'bed-side-desk',
        keywords: ['furniture', 'bed-side desk', 'bedroom', 'nightstand', 'storage', 'table'],
      },
      {name: 'bedroom-drawer', keywords: ['furniture', 'bedroom', 'drawer', 'storage', 'chest', 'organize']},
      {name: 'bedroom-stage', keywords: ['furniture', 'bedroom', 'stage', 'platform', 'bed', 'foundation']},
      {name: 'bedroom-table', keywords: ['furniture', 'bedroom', 'table', 'nightstand', 'side table', 'storage']},
      {name: 'bedroom-table-1', keywords: ['furniture', 'bedroom', 'table', 'nightstand', 'storage', 'decor']},
      {
        name: 'bedside-table-drawer',
        keywords: ['furniture', 'bedside', 'table', 'drawer', 'nightstand', 'bedroom'],
      },
      {name: 'bookcase-bookshelf', keywords: ['furniture', 'bookcase', 'bookshelf', 'storage', 'books', 'shelves']},
      {name: 'chairs-room', keywords: ['furniture', 'chairs', 'room', 'seating', 'comfort', 'living space']},
      {
        name: 'drawer-and-document',
        keywords: ['furniture', 'drawer', 'document', 'storage', 'office', 'organizer'],
      },
      {
        name: 'dressing-table-dresser',
        keywords: ['furniture', 'dressing table', 'dresser', 'bedroom', 'storage', 'mirror'],
      },
      {
        name: 'dressing-table-dresser-1',
        keywords: ['furniture', 'dressing table', 'dresser', 'elegant', 'bedroom', 'vanity'],
      },
      {name: 'lamp', keywords: ['furniture', 'lamp', 'lighting', 'illumination', 'decor', 'table lamp']},
      {name: 'lamp-1', keywords: ['furniture', 'lamp', 'light', 'desk', 'illumination', 'decorative']},
      {
        name: 'livingroom-chair',
        keywords: ['furniture', 'livingroom chair', 'seating', 'comfort', 'lounge', 'decor'],
      },
      {
        name: 'livingroom-furniture',
        keywords: ['furniture', 'livingroom', 'sofa', 'couch', 'comfort', 'home decor'],
      },
      {
        name: 'livingroom',
        keywords: ['furniture', 'livingroom', 'home', 'comfortable', 'lounge area', 'living space'],
      },
      {name: 'nightstand', keywords: ['furniture', 'nightstand', 'bedside', 'bedroom', 'storage', 'table']},
      {
        name: 'nightstand-1',
        keywords: ['furniture', 'nightstand', 'bedroom', 'storage', 'furniture', 'bedside storage'],
      },
      {name: 'office-chair', keywords: ['furniture', 'office chair', 'seating', 'comfort', 'swivel', 'ergonomic']},
      {
        name: 'office-chair-1',
        keywords: ['furniture', 'office chair', 'comfortable', 'adjustable', 'desk chair', 'ergonomic'],
      },
      {
        name: 'red-paper-lantern',
        keywords: ['furniture', 'red paper lantern', 'lighting', 'decorative', 'festival', 'Asian decor'],
      },
      {name: 'stool', keywords: ['furniture', 'seatting chair', 'comfort', 'lounge', 'armchair', 'seating']},
      {
        name: 'snow-globe',
        keywords: ['furniture', 'snow globe', 'decorative', 'miniature', 'souvenir', 'winter scene'],
      },
      {
        name: 'street-light',
        keywords: ['furniture', 'street light', 'outdoor', 'lighting', 'illumination', 'lamp post'],
      },
      {
        name: 'table-desk',
        keywords: ['furniture', 'table desk', 'workspace', 'office', 'study', 'writing surface'],
      },
      {
        name: 'television-furniture',
        keywords: ['furniture', 'television', 'TV stand', 'entertainment center', 'media console', 'living room'],
      },
      {name: 'vase', keywords: ['furniture', 'vase', 'decor', 'container', 'flower vase', 'ceramic']},
      {name: 'vase-1', keywords: ['furniture', 'vase', 'decorative', 'floral', 'ceramic', 'pottery']},
      {
        name: 'wardrobe-locker',
        keywords: ['furniture', 'wardrobe locker', 'clothing storage', 'bedroom', 'secure', 'cabinet'],
      },
    ],
  },
  {
    name: 'tools',
    icons: [
      {name: 'boots-boot', keywords: ['tools', 'boots boot', 'footwear', 'outdoor', 'protection', 'leather']},
      {name: 'brush-1', keywords: ['tools', 'brush', 'cleaning', 'painting', 'bristles', 'application']},
      {name: 'brush-shave', keywords: ['tools', 'brush shave', 'barber', 'grooming', 'beard', 'styling']},
      {name: 'brush', keywords: ['tools', 'brush', 'paintbrush', 'art', 'application', 'decorating']},
      {name: 'cellophane-tape', keywords: ['tools', 'cellophane tape', 'adhesive', 'packaging', 'seal', 'office']},
      {
        name: 'cleaning-mop',
        keywords: ['tools', 'cleaning mop', 'housekeeping', 'wet', 'floor cleaning', 'maintenance'],
      },
      {
        name: 'dumbbell-gym-1',
        keywords: ['tools', 'dumbbell gym', 'fitness', 'weight training', 'exercise', 'strength'],
      },
      {
        name: 'dumbbell-gym',
        keywords: ['tools', 'dumbbell gym', 'bodybuilding', 'exercise', 'fitness equipment', 'training'],
      },
      {name: 'fence', keywords: ['tools', 'fence', 'security', 'property boundary', 'wooden', 'metal']},
      {
        name: 'garden-work-shovel',
        keywords: ['tools', 'garden work shovel', 'digging', 'gardening', 'soil work', 'spade'],
      },
      {
        name: 'gardener-shovel',
        keywords: ['tools', 'gardener shovel', 'gardening', 'planting', 'digging tool', 'spade'],
      },
      {
        name: 'gardening-tools-rake',
        keywords: ['tools', 'gardening tools rake', 'yard cleanup', 'leaves', 'garden maintenance', 'raking'],
      },
      {name: 'gun', keywords: ['tools', 'gun', 'firearm', 'shooting', 'safety', 'defense']},
      {
        name: 'hair-clip-tool',
        keywords: ['tools', 'hair clip tool', 'salon accessory', 'styling', 'hair management', 'beauty tool'],
      },
      {name: 'hammer', keywords: ['tools', 'hammer', 'construction', 'woodwork', 'carpentry tool', 'nailing']},
      {
        name: 'lamp-oil-lamp',
        keywords: ['tools', 'lamp oil lamp', 'vintage lighting', 'flame', 'decorative piece', 'light source'],
      },
      {
        name: 'measuring-tape',
        keywords: [
          'tools',
          'measuring tape',
          'tailoring',
          'length measurement',
          'construction tool',
          'measurement accuracy',
        ],
      },
      {
        name: 'metronome',
        keywords: ['tools', 'metronome', 'music practice', 'beat keeper', 'rhythm training', 'musical timing'],
      },
      {
        name: 'mic',
        keywords: ['tools', 'mic', 'audio recording', 'sound equipment', 'broadcast tool', 'communication'],
      },
      {
        name: 'nail-gun',
        keywords: ['tools', 'nail gun', 'fastening', 'power tool', 'carpentry aid', 'construction equipment'],
      },
      {
        name: 'nail-trimmer',
        keywords: ['tools', 'nail trimmer', 'manicure tool', 'grooming', 'personal care', 'pedicure'],
      },
      {
        name: 'nail',
        keywords: ['tools', 'nail', 'fastener', 'construction', 'building support', 'carpentry fastening'],
      },
      {
        name: 'packs-cart',
        keywords: ['tools', 'packs cart', 'hauling', 'load transport', 'heavy carrying', 'utility cart'],
      },
      {name: 'pen', keywords: ['tools', 'pen', 'writing instrument', 'office supplies', 'ink tool', 'stationery']},
      {name: 'pencil', keywords: ['tools', 'pencil', 'sketching', 'school supplies', 'graphite tool', 'drawing']},
      {
        name: 'pruners-garden',
        keywords: ['tools', 'pruners garden', 'trimming plants', 'gardening shears', 'branch cutter', 'landscape tool'],
      },
      {
        name: 'rake-gardening',
        keywords: ['tools', 'rake gardening', 'leaf cleanup', 'garden tool', 'yard work', 'soil preparation'],
      },
      {
        name: 'ruler',
        keywords: ['tools', 'ruler', 'measuring stick', 'geometry tool', 'drafting', 'linear measurement'],
      },
      {
        name: 'safety-glasses',
        keywords: ['tools', 'safety glasses', 'eye protection', 'work safety gear', 'protective eyewear', 'lab safety'],
      },
      {
        name: 'safety-pin',
        keywords: ['tools', 'safety pin', 'secure fastening', 'temporary fix', 'fabric binder', 'clothing adjustment'],
      },
      {
        name: 'safety-vest',
        keywords: [
          'tools',
          'safety vest',
          'visibility clothing',
          'protective gear',
          'construction safety',
          'reflective wear',
        ],
      },
      {
        name: 'secateurs-scissors',
        keywords: [
          'tools',
          'secateurs scissors',
          'plant cutting',
          'garden pruner',
          'bush trimming',
          'gardening essential',
        ],
      },
      {
        name: 'sign-roadblock',
        keywords: [
          'tools',
          'sign roadblock',
          'traffic control',
          'safety warning',
          'road work indicator',
          'barrier notification',
        ],
      },
      {
        name: 'spray-bottle-sprayer',
        keywords: [
          'tools',
          'spray bottle sprayer',
          'liquid dispensing',
          'cleaning aid',
          'water sprayer',
          'chemical application',
        ],
      },
      {
        name: 'tape-line',
        keywords: [
          'tools',
          'tape line',
          'guideline setting',
          'safety marking',
          'construction line',
          'visible boundary',
        ],
      },
      {
        name: 'tape',
        keywords: ['tools', 'tape', 'adhesive strip', 'binding material', 'repair aid', 'sealing solution'],
      },
      {
        name: 'toothbrush',
        keywords: ['tools', 'toothbrush', 'oral hygiene', 'dental care', 'teeth cleaning', 'mouth health'],
      },
      {
        name: 'trash',
        keywords: ['tools', 'trash', 'waste management', 'garbage collection', 'rubbish handling', 'disposal unit'],
      },
      {
        name: 'umbrella-1',
        keywords: ['tools', 'umbrella', 'weather protection', 'rain shield', 'portable shelter', 'waterproof cover'],
      },
      {
        name: 'umbrella',
        keywords: ['tools', 'umbrella', 'rain protection', 'weather guard', 'sun shade', 'outdoor cover'],
      },
      {
        name: 'vacuum-cleaner',
        keywords: [
          'tools',
          'vacuum cleaner',
          'dirt removal',
          'household appliance',
          'cleaning device',
          'suction machine',
        ],
      },
      {
        name: 'watering-can',
        keywords: [
          'tools',
          'watering can',
          'plant watering',
          'gardening equipment',
          'water dispenser',
          'irrigation tool',
        ],
      },
    ],
  },

  {
    name: 'food',
    icons: [
      {name: 'apple', keywords: ['food', 'apple', 'fruit', 'orchard', 'crisp', 'pie']},
      {name: 'avocado', keywords: ['food', 'avocado', 'toast', 'superfood', 'creamy', 'nutritious']},
      {
        name: 'grapes',
        keywords: ['food', 'fruit', 'vine', 'wine', 'snack'],
      },
      {name: 'cherries', keywords: ['food', 'cherries', 'fruit', 'pie', 'stone', 'snack']},
      {name: 'cocktail', keywords: ['food', 'cocktail', 'drink', 'party', 'spirits', 'celebration']},
      {
        name: 'coconut-young',
        keywords: ['food', 'young coconut', 'refreshing', 'tropical', 'natural', 'hydration'],
      },
      {name: 'coconut', keywords: ['food', 'coconut', 'tropical', 'coconut milk', 'health', 'palm']},
      {name: 'cookie', keywords: ['food', 'cookie', 'snack', 'sweet', 'bake', 'treat']},
      {name: 'dragon-fruit', keywords: ['food', 'dragon fruit', 'pitaya', 'exotic', 'vibrant', 'superfood']},
      {name: 'soda', keywords: ['food', 'cola', 'soda', 'refreshment', 'caffeine', 'drink']},
      {name: 'durian', keywords: ['food', 'durian', 'tropical', 'king of fruits', 'strong aroma', 'unique']},
      {name: 'corn', keywords: ['food', 'ear corn', 'vegetable', 'staple', 'grain', 'farm']},
      {name: 'egg', keywords: ['food', 'egg', 'breakfast', 'protein', 'versatile', 'cook']},
      {
        name: 'sushi',
        keywords: ['food', 'Japanese', 'seafood', 'rice', 'nori'],
      },
      {name: 'pizza-slice', keywords: ['food', 'pizza slice', 'fast food', 'cheesy', 'popular', 'Italian']},
      {name: 'ice-cream', keywords: ['food', 'ice cream', 'dessert', 'summer', 'cold', 'scoop']},
      {name: 'kiwi', keywords: ['food', 'kiwi', 'fruit', 'exotic', 'vitamin C', 'green']},
      {name: 'lemon', keywords: ['food', 'lemon', 'citrus', 'flavor', 'refreshing', 'zesty']},
      {name: 'mango', keywords: ['food', 'mango', 'tropical', 'fruit salad', 'juicy', 'sweet']},
      {
        name: 'mangosteen',
        keywords: ['food', 'mangosteen', 'queen of fruits', 'tropical', 'antioxidant', 'purple'],
      },
      {name: 'melon', keywords: ['food', 'melon', 'refreshing', 'hydrating', 'summer fruit', 'sweet']},
      {name: 'milk', keywords: ['food', 'milk', 'dairy', 'calcium', 'beverage', 'drink']},
      {name: 'mug', keywords: ['food', 'mug', 'coffee', 'tea', 'drink', 'ceramic']},
      {name: 'orange-grapefruit', keywords: ['food', 'orange grapefruit', 'citrus', 'sour', 'juicy', 'refreshing']},
      {name: 'mandarin', keywords: ['food', 'mandarin', 'orange', 'citrus', 'snack', 'sweet']},
      {name: 'orange', keywords: ['food', 'orange', 'citrus', 'juicy', 'healthy', 'snack']},
      {name: 'papaya', keywords: ['food', 'papaya', 'tropical', 'digestive health', 'carotene', 'sweet']},
      {name: 'passion-fruit', keywords: ['food', 'passion fruit', 'exotic', 'tropical', 'juice', 'seedy']},
      {name: 'peach', keywords: ['food', 'peach', 'fruit', 'fuzzy', 'summer', 'juicy']},
      {name: 'pear', keywords: ['food', 'pear', 'fruit', 'juicy', 'sweet', 'snack']},
      {name: 'pepper', keywords: ['food', 'pepper', 'spice', 'cooking', 'fresh', 'flavor']},
      {name: 'persimmon', keywords: ['food', 'persimmon', 'fruit', 'autumn', 'sweet', 'fleshy']},
      {name: 'pineapple', keywords: ['food', 'pineapple', 'tropical', 'refreshing', 'sweet', 'juicy']},
      {name: 'plum', keywords: ['food', 'plum', 'fruit', 'stone fruit', 'purple', 'sweet']},
      {name: 'pomegranate', keywords: ['food', 'pomegranate', 'fruit', 'antioxidant', 'seedy', 'juicy']},
      {name: 'popcorn', keywords: ['food', 'popcorn', 'snack', 'movie night', 'buttery', 'salty']},
      {name: 'shell', keywords: ['food', 'shell', 'sea', 'beach', 'collectible', 'natural']},
      {name: 'spaghetti', keywords: ['food', 'spaghetti', 'pasta', 'dinner', 'Italian', 'tomato']},
      {name: 'sugar', keywords: ['food', 'sugar', 'sweet', 'baking', 'dessert', 'crystal']},
      {name: 'tea-cup', keywords: ['food', 'tea cup', 'beverage', 'drink', 'hot', 'relax']},
      {name: 'tea-kettle', keywords: ['food', 'tea kettle', 'boil', 'brew', 'morning', 'hot']},
      {name: 'tomato', keywords: ['food', 'tomato', 'vegetable', 'cooking', 'salad', 'sauce']},
      {name: 'watermelon', keywords: ['food', 'watermelon', 'summer', 'fruit', 'refreshing', 'picnic']},
      {
        name: 'mushroom-1',
        keywords: ['food', 'fungi', 'mushroom', 'foraging', 'forest', 'cooking', 'natural'],
      },
    ],
  },

  {
    name: 'sports',
    icons: [
      {name: 'football', keywords: ['sports', 'football', 'NFL', 'ball', 'team', 'helmet']},
      {name: 'football-helmet', keywords: ['sports', 'football helmet', 'protection', 'safety', 'gear', 'player']},
      {name: 'baseball-bat', keywords: ['sports', 'baseball bat', 'hitting', 'swing', 'wood', 'game']},
      {name: 'baseball', keywords: ['sports', 'baseball', 'MLB', 'pitch', 'ball', 'field']},
      {name: 'basketball', keywords: ['sports', 'basketball', 'NBA', 'hoop', 'ball', 'court']},
      {name: 'beach-ball', keywords: ['sports', 'beach', 'ball', 'summer', 'fun', 'play', 'colorful']},
      {name: 'beach-soccer', keywords: ['sports', 'beach soccer', 'sand', 'goal', 'kick', 'summer']},
      {name: 'beach-volleyball', keywords: ['sports', 'beach volleyball', 'sand', 'net', 'spike', 'serve']},
      {name: 'boomerang', keywords: ['sports', 'boomerang', 'throw', 'return', 'catch', 'flight']},
      {name: 'soccer-field', keywords: ['sports', 'soccer field', 'pitch', 'goal', 'grass', 'match']},
      {name: 'volleyball', keywords: ['sports', 'volleyball', 'serve', 'spike', 'ball', 'court']},
      {name: 'gym', keywords: ['sports', 'gym', 'fitness', 'workout', 'weights', 'training']},
      {name: 'kite', keywords: ['sports', 'kite', 'fly', 'wind', 'outdoor', 'sky']},
      {name: 'lockers', keywords: ['sports', 'lockers', 'storage', 'school', 'security', 'personal']},
      {name: 'pool-8-ball', keywords: ['sports', 'pool', 'ball', 'cue', 'billiards', 'shot', 'rack']},
      {name: 'pool', keywords: ['sports', 'pool', 'cue sports', 'billiards', 'table', 'balls']},
      {name: 'running-gym', keywords: ['sports', 'running gym', 'treadmill', 'cardio', 'fitness', 'exercise']},
      {name: 'shoe-soccer', keywords: ['sports', 'shoe soccer', 'cleats', 'pitch', 'kick', 'gear']},
      {name: 'soccer-ball', keywords: ['sports', 'soccer', 'ball', 'FIFA', 'kick', 'goal']},
      {name: 'sport-bag', keywords: ['sports', 'sport bag', 'storage', 'equipment', 'gear', 'carry']},
      {
        name: 'stationary-bike',
        keywords: ['sports', 'stationary bike', 'cycling', 'fitness', 'cardio', 'exercise'],
      },
      {
        name: 'ping-pong-racket',
        keywords: ['sports', 'table tennis racket and ball', 'ping pong', 'game', 'serve', 'volley'],
      },
      {
        name: 'ping-pong',
        keywords: ['sports', 'table tennis table', 'ping pong', 'match', 'net', 'rally'],
      },
      {name: 'tennis-ball', keywords: ['sports', 'tennis ball', 'court', 'serve', 'volley', 'match']},
      {name: 'tennis-racket', keywords: ['sports', 'tennis racket', 'swing', 'serve', 'match', 'court']},
      {name: 'trophy', keywords: ['sports', 'trophy', 'win', 'champion', 'award', 'victory']},
      {name: 'whistle', keywords: ['sports', 'whistle', 'referee', 'alert', 'signal', 'game']},
    ],
  },

  {
    name: 'kitchen',
    icons: [
      {name: 'apron-chef', keywords: ['kitchen', 'apron', 'chef', 'protective', 'cooking', 'uniform']},
      {name: 'blender', keywords: ['kitchen', 'blender', 'smoothies', 'mix', 'puree', 'appliance']},
      {name: 'bowl', keywords: ['kitchen', 'bowl', 'serve', 'mix', 'ceramic', 'salad']},
      {name: 'casserole', keywords: ['kitchen', 'casserole', 'bake', 'dish', 'oven', 'cookware']},
      {name: 'chef-knife', keywords: ['kitchen', 'chef knife', 'slice', 'chop', 'cut', 'blade']},
      {name: 'chef', keywords: ['kitchen', 'chef', 'cook', 'professional', 'culinary', 'food prep']},
      {name: 'cleaver-knife', keywords: ['kitchen', 'cleaver', 'chop', 'meat', 'butcher', 'heavy']},
      {name: 'coffee-shop', keywords: ['kitchen', 'coffee', 'shop', 'espresso', 'cafe', 'brew', 'social']},
      {name: 'cutlery', keywords: ['kitchen', 'cutlery', 'silverware', 'utensils', 'dine', 'set']},
      {name: 'extractor-hood', keywords: ['kitchen', 'extractor hood', 'vent', 'fume', 'cook', 'air']},
      {name: 'food-dome-cover', keywords: ['kitchen', 'food dome', 'cover', 'protect', 'serve', 'hygienic']},
      {name: 'food-processor', keywords: ['kitchen', 'food processor', 'chop', 'blend', 'dice', 'prep']},
      {name: 'grater', keywords: ['kitchen', 'grater', 'shred', 'cheese', 'carrot', 'zest']},
      {name: 'kitchen-board', keywords: ['kitchen', 'cutting board', 'chop', 'slice', 'wood', 'prep']},
      {name: 'masher-garlic', keywords: ['kitchen', 'garlic masher', 'crush', 'press', 'mince', 'tool']},
      {
        name: 'measuring-spoons',
        keywords: ['kitchen', 'measuring spoons', 'measure', 'bake', 'precise', 'ingredients'],
      },
      {name: 'microwave', keywords: ['kitchen', 'microwave', 'heat', 'cook', 'reheat', 'appliance']},
      {name: 'mixer-bowl', keywords: ['kitchen', 'mixer', 'bowl', 'blend', 'batter', 'dough']},
      {name: 'mixer-kitchenware', keywords: ['kitchen', 'mixer', 'whisk', 'beat', 'mix', 'baking']},
      {name: 'pot', keywords: ['kitchen', 'multipot', 'cook', 'steam', 'multicook', 'pressure']},
      {name: 'large-knife', keywords: ['kitchen', 'nakiri knife', 'vegetable', 'chop', 'slice', 'Japanese']},
      {name: 'oven', keywords: ['kitchen', 'oven', 'bake', 'roast', 'broil', 'cook']},
      {name: 'pepper-spice', keywords: ['kitchen', 'pepper', 'seasoning', 'spice', 'flavor', 'condiment']},
      {name: 'pepper-grinder', keywords: ['kitchen', 'pepper grinder', 'mill', 'season', 'grind', 'fresh']},
      {name: 'plate', keywords: ['kitchen', 'plate', 'serve', 'dish', 'eat', 'tableware']},
      {name: 'pot-pressure-cooker', keywords: ['kitchen', 'pressure cooker', 'steam', 'cook', 'fast', 'pot']},
      {name: 'rice-cooker', keywords: ['kitchen', 'rice cooker', 'cook', 'steam', 'grain', 'appliance']},
      {name: 'rolling-pin', keywords: ['kitchen', 'rolling pin', 'bake', 'pastry', 'dough', 'roll']},
      {
        name: 'sauce-pan',
        keywords: ['kitchen', 'sauce pan', 'cooking', 'boiling', 'sauce', 'stew', 'simmer', 'metal', 'handle'],
      },
      {
        name: 'soup-ladle',
        keywords: ['kitchen', 'soup ladle', 'serving', 'soup', 'stew', 'dip', 'scoop', 'pour', 'long handle'],
      },
      {
        name: 'soup-pot',
        keywords: ['kitchen', 'soup pot', 'cooking', 'large', 'stew', 'boiling', 'stock', 'broth', 'handle'],
      },
      {
        name: 'spatula',
        keywords: [
          'kitchen',
          'spatula',
          'cooking',
          'flipping',
          'frying',
          'serving',
          'utensil',
          'non-stick',
          'heat resistant',
        ],
      },
      {
        name: 'steam',
        keywords: ['kitchen', 'steam', 'cooking', 'heat', 'vapor', 'moist heat', 'boiling', 'evaporation', 'hot'],
      },
      {
        name: 'stock-pot',
        keywords: ['kitchen', 'stock pot', 'cooking', 'large', 'soup', 'boiling', 'broth', 'stew', 'handle'],
      },
      {
        name: 'stove',
        keywords: [
          'kitchen',
          'stove',
          'cooking',
          'heat',
          'burner',
          'appliance',
          'gas',
          'electric',
          'temperature control',
        ],
      },
      {
        name: 'strainer',
        keywords: [
          'kitchen',
          'strainer',
          'draining',
          'washing',
          'pasta',
          'vegetables',
          'mesh',
          'sieve',
          'liquid separation',
        ],
      },
      {
        name: 'tap-faucet',
        keywords: ['kitchen', 'tap faucet', 'water', 'sink', 'flow', 'plumbing', 'valve', 'wash', 'control flow'],
      },
    ],
  },

  {
    name: 'nature',
    icons: [
      {name: 'beach-umbrella', keywords: ['nature', 'beach', 'umbrella', 'shade', 'sun protection', 'vacation']},
      {name: 'beach', keywords: ['nature', 'beach', 'sand', 'ocean', 'waves', 'sunbathing']},
      {name: 'bush', keywords: ['nature', 'bush', 'shrub', 'greenery', 'plant', 'landscape']},
      {name: 'cactus', keywords: ['nature', 'cactus', 'desert', 'succulent', 'thorns', 'drought tolerant']},
      {
        name: 'christmas-decoration-pine',
        keywords: ['nature', 'christmas', 'pine', 'decoration', 'holiday', 'festive'],
      },
      {name: 'flower', keywords: ['nature', 'flowers', 'bloom', 'floral', 'garden', 'petals']},
      {name: 'rose', keywords: ['nature', 'rose', 'flower', 'thorns', 'fragrant', 'romantic']},
      {name: 'garden-1', keywords: ['nature', 'garden', 'flora', 'greenery', 'outdoors', 'landscape']},
      {name: 'garden-scenery', keywords: ['nature', 'garden', 'scenery', 'landscape', 'peaceful', 'green']},
      {name: 'garden', keywords: ['nature', 'garden', 'horticulture', 'plants', 'cultivation', 'green space']},
      {name: 'gardening-plant', keywords: ['nature', 'gardening', 'plant', 'grow', 'horticulture', 'care']},
      {name: 'earth', keywords: ['nature', 'earth', 'planet', 'environment', 'global', 'world']},
      {name: 'plant-garden-1', keywords: ['nature', 'plant', 'garden', 'horticulture', 'growth', 'flora']},
      {name: 'plant-garden', keywords: ['nature', 'plant', 'garden', 'botanical', 'greenery', 'cultivation']},
      {name: 'seed', keywords: ['nature', 'seed', 'growth', 'planting', 'germination', 'sow']},
      {name: 'seedling', keywords: ['nature', 'seedling', 'young plant', 'growth', 'sprout', 'green']},
      {name: 'snow-showers-at-night', keywords: ['nature', 'snow showers', 'night', 'winter', 'cold', 'weather']},
      {name: 'snow-showers', keywords: ['nature', 'snow showers', 'winter', 'flurries', 'cold', 'weather']},
      {name: 'sun-behind-cloud', keywords: ['nature', 'sun', 'cloud', 'partly sunny', 'weather', 'sky']},
      {name: 'sun-behind-large-cloud', keywords: ['nature', 'sun', 'cloud', 'mostly cloudy', 'weather', 'sky']},
      {name: 'sun-behind-rain-cloud', keywords: ['nature', 'sun', 'rain', 'cloud', 'weather', 'showers']},
      {name: 'sun-behind-small-cloud', keywords: ['nature', 'sun', 'cloud', 'partly cloudy', 'weather', 'sky']},
      {name: 'sun', keywords: ['nature', 'sun', 'sunny', 'bright', 'daylight', 'warm']},
      {name: 'tree-1', keywords: ['nature', 'tree', 'wood', 'leaves', 'shade', 'ecology']},
      {name: 'tree-2', keywords: ['nature', 'tree', 'forest', 'green', 'nature reserve', 'biodiversity']},
      {name: 'tree', keywords: ['nature', 'tree', 'plant', 'woodland', 'green', 'oxygen']},
      {name: 'vase', keywords: ['nature', 'vase', 'container', 'flowers', 'decor', 'porcelain']},
      {name: 'wave', keywords: ['nature', 'water', 'wave', 'ocean', 'sea', 'tide']},
      {name: 'watering-can-water', keywords: ['nature', 'watering can', 'water', 'irrigate', 'plants']},
      {
        name: 'mushroom-2',
        keywords: ['nature', 'fungi', 'mushroom', 'foraging', 'forest', 'cooking', 'natural'],
      },
    ],
  },

  {
    name: 'vehicles',
    icons: [
      {
        name: 'bicycle',
        keywords: [
          'vehicles',
          'bicycle',
          'cycle',
          'pedal',
          'ride',
          'transport',
          'eco-friendly',
          'two-wheeled',
          'outdoor',
        ],
      },
      {
        name: 'christmas-santa-sleigh',
        keywords: ['vehicles', 'santa', 'sledge', 'christmas', 'reindeer', 'holiday', 'winter', 'gifts', 'snow'],
      },
      {
        name: 'scooter',
        keywords: ['vehicles', 'transport', 'two-wheeled', 'urban', 'manual'],
      },
      {
        name: 'motorcycle',
        keywords: ['vehicles', 'motorcycle', 'bike', 'ride', 'two-wheeled', 'speed', 'motorbike', 'road', 'transport'],
      },
      {
        name: 'sailboat',
        keywords: ['vehicles', 'sailboat', 'boat', 'sailing', 'water', 'wind', 'nautical', 'sea', 'yacht'],
      },
      {
        name: 'train',
        keywords: ['vehicles', 'train', 'rail', 'transport', 'locomotive', 'tracks', 'commute', 'speed', 'journey'],
      },
      {
        name: 'tram',
        keywords: ['vehicles', 'tram', 'streetcar', 'urban', 'rail', 'city transport', 'electric', 'public', 'commute'],
      },
    ],
  },
  {
    name: 'instruments',
    icons: [
      {name: 'acoustic-guitar', keywords: ['instruments', 'guitar', 'acoustic', 'strings', 'melody']},
      {name: 'djembe', keywords: ['instruments', 'djembe', 'African', 'drum', 'rhythm']},
      {name: 'drum', keywords: ['instruments', 'drum', 'percussion', 'beat', 'rhythmic']},
      {name: 'drum-set', keywords: ['instruments', 'drum set', 'percussion', 'kit', 'rhythm']},
      {name: 'guitar-electric', keywords: ['instruments', 'guitar', 'electric', 'strings', 'rock']},
      {name: 'harmonica', keywords: ['instruments', 'harmonica', 'blues', 'wind', 'mouth organ']},
      {name: 'maracas', keywords: ['instruments', 'maracas', 'percussion', 'shakers', 'rhythm']},
      {name: 'music-desk-dj', keywords: ['instruments', 'music desk', 'DJ', 'mixing', 'party']},
      {
        name: 'jukebox',
        keywords: ['instruments', 'jukebox', 'music player', 'retro', 'audio'],
      },
      {name: 'music-player', keywords: ['instruments', 'music player', 'audio', 'media', 'sound']},
      {name: 'music', keywords: ['instruments', 'music', 'melody', 'sound', 'rhythm']},
      {name: 'piano-keyboard', keywords: ['instruments', 'piano', 'keyboard', 'keys', 'melodic']},
      {name: 'piano', keywords: ['instruments', 'piano', 'keys', 'classical', 'melody']},
    ],
  },
  {
    name: 'clothes',
    icons: [
      {name: 'baseball-cap', keywords: ['clothes', 'cap', 'baseball', 'sportswear', 'casual']},
      {name: 'bow-ribbon', keywords: ['clothes', 'bow', 'ribbon', 'decorative', 'fashion']},
      {name: 'dress-1', keywords: ['clothes', 'dress', 'fashion', 'elegant', 'apparel']},
      {name: 'dress-2', keywords: ['clothes', 'dress', 'stylish', 'casual', 'wear']},
      {name: 'dress-3', keywords: ['clothes', 'dress', 'formal', 'evening', 'attire']},
      {name: 'dress', keywords: ['clothes', 'dress', 'apparel', 'garment', 'fashion']},
      {name: 'handbag', keywords: ['clothes', 'handbag', 'accessory', 'fashion', 'carry']},
      {name: 'hat-birthday-1', keywords: ['clothes', 'hat', 'birthday', 'party', 'festive']},
      {name: 'hat', keywords: ['clothes', 'hat', 'headwear', 'fashion', 'accessory']},
      {name: 'mexican-hat-mariachi', keywords: ['clothes', 'mariachi', 'hat', 'Mexican', 'traditional']},
      {
        name: 'miner-hat',
        keywords: ['clothes', 'miner', 'hat', 'safety', 'lamp'],
      },
      {name: 'ring', keywords: ['clothes', 'ring', 'jewelry', 'accessory', 'fashion']},
      {name: 'scarf-1', keywords: ['clothes', 'scarf', 'warm', 'accessory', 'fashion']},
      {name: 'scarf', keywords: ['clothes', 'scarf', 'neckwear', 'stylish', 'warm']},
      {name: 'shirt-with-bow', keywords: ['clothes', 'shirt', 'bow', 'formal', 'fashion']},
      {name: 'shoe-1', keywords: ['clothes', 'shoe', 'footwear', 'casual', 'fashion']},
      {name: 'shoe-2', keywords: ['clothes', 'shoe', 'footwear', 'elegant', 'wear']},
      {name: 'shoe-3', keywords: ['clothes', 'shoe', 'sport', 'athletic', 'sneaker']},
      {name: 'shoe-4', keywords: ['clothes', 'shoe', 'dress', 'formal', 'footwear']},
      {name: 'shoe-shoes-1', keywords: ['clothes', 'shoes', 'footwear', 'pair', 'fashion']},
      {name: 'shoe-shoes', keywords: ['clothes', 'shoes', 'footwear', 'multiple', 'variety']},
      {name: 'shoe', keywords: ['clothes', 'shoe', 'footwear', 'wear', 'fashion']},
      {name: 'suitcase-portfolio', keywords: ['clothes', 'suitcase', 'travel', 'carry', 'portable']},
      {name: 'winter-hat-1', keywords: ['clothes', 'hat', 'winter', 'warm', 'knit']},
      {name: 'winter-hat', keywords: ['clothes', 'hat', 'winter', 'cold', 'headwear']},
      {name: 'towel', keywords: ['clothes', 'towel', 'absorbent', 'bath', 'dry']},
    ],
  },
];
