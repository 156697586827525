import {createEntityAdapter} from '@ngrx/entity';
import {SeasonModel, SkedTemplateModel, WeekModel} from './scheduler-dummy-data';
import {TeamSchedule} from '../../../team-schedule/shared/model/team-schedule';

export interface SchedulerState {
  currentSeason: SeasonModel;
  currentWeek: WeekModel;
  hasUnsavedChanges: boolean;
  saveInProgress: boolean;
  seasonWeeks: WeekModel[];
  skedTemplates: SkedTemplateModel[];
  teamSkeds: boolean;
  teamSchedule: TeamSchedule;
}

export const schedulerAdapter = createEntityAdapter<SchedulerState>();

export const initialSchedulerState: SchedulerState = schedulerAdapter.getInitialState({
  currentSeason: null, // dummyMonth,
  currentWeek: null, //dummyWeek,
  hasUnsavedChanges: false,
  saveInProgress: false,
  seasonWeeks: null,
  skedTemplates: null, // dummySkedTemplates,
  teamSkeds: false,
  teamSchedule: null,
});
