import {Sked} from 'src/app/current-sked/shared/model/sked';
import {CurrentSkedTabOptions} from 'src/app/current-sked/task-list/shared/current-sked-tab-options';
import {CurrentSkedTask} from '../../model/task';

export interface CurrentSkedState {
  currentSked: Sked;
  skeds: Sked[];
  task: CurrentSkedTask;
  selectedSkedType: 'current' | 'all';
  activeTab: CurrentSkedTabOptions;
  updatedTask: CurrentSkedTask;
  // When updating a boolean metric value in the current sked, the initial displayed value is null. only after a selection is made does the value show, thus keeping track of which values have been touched is needed
  metricUpdateValueChosenMap: Record<string, boolean>; // key is the metric id
}

export const initialCurrentSkedState: CurrentSkedState = {
  currentSked: null,
  skeds: [],
  task: null,
  selectedSkedType: 'current',
  activeTab: CurrentSkedTabOptions.Sked,
  updatedTask: {} as CurrentSkedTask,
  metricUpdateValueChosenMap: {},
};
