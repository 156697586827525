<div class="day-selector-container" [class.auto-sked]="autoSked">
  @for (day of weekData; track day; let i = $index) {
    <div
      class="day-block"
      [ngClass]="isChecked(day.value, i) ? 'selected' : 'unselected'"
      [class.error]="(isInvalid(day.value) && control.touched) || allInvalid"
      [class.disabled]="disabledIndexArr.includes(i)"
      (click)="changeValue(day.value, i)"
    >
      <span>{{ day.label }}</span>
    </div>
  }
</div>
