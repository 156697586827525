import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'projectViewEmptyMessage',
  standalone: true,
})
export class ProjectViewEmptyMessagePipe implements PipeTransform {
  transform(taskType: string): string {
    return `No ${taskType.toLowerCase()} ${(taskType !== 'Routines' ? 'tasks' : '')?.toLowerCase()}`;
  }
}
