import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'task-wizard-dialog-check-button',
  templateUrl: './task-wizard-dialog-check-button.component.html',
  styleUrls: ['./task-wizard-dialog-check-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskWizardDialogCheckButtonComponent {
  @Input() selected: boolean;
  @Input() width: string = '129px';
  @Input() flexGrow: boolean;
}
