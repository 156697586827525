import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {UserApiService} from '../../api/user-api.service';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {
  ActiveUserActionType,
  DeleteImageAction,
  DeleteImageSuccessAction,
  GetActiveUserAction,
  GetActiveUserSuccessAction,
  UploadImageAction,
} from './active-user.action';

@Injectable()
export class ActiveUserEffects {
  public getAll$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetActiveUserAction>(ActiveUserActionType.GET),
      mergeMap(action => {
        const {email, onSuccess, onFailure} = action.payload;

        return this.userApiService.getActiveUser(email).pipe(
          mergeMap(user => [new GetActiveUserSuccessAction({user}), ...createCallbackActions(onSuccess, user)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public uploadImg$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UploadImageAction>(ActiveUserActionType.UPLOAD_IMG),
      mergeMap(action => {
        const {image, onSuccess, onFailure} = action.payload;

        return this.userApiService.uploadImg(image).pipe(
          mergeMap(url => [new DeleteImageSuccessAction(), ...createCallbackActions(onSuccess, url)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public deleteImg$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteImageAction>(ActiveUserActionType.DELETE_IMG),
      mergeMap(action => {
        const {ids, onSuccess, onFailure} = action.payload;

        return this.userApiService.deleteImg(ids).pipe(
          mergeMap(user => [new DeleteImageSuccessAction(), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private userApiService: UserApiService
  ) {}
}
