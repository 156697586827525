import {ReportingObjectsParamsDto} from '../dto/reporting-objects-params.dto';
import {ReportingObjectsParams} from '../model/reporting-objects-params';

export function convertReportingObjectsParamsModelToDto(dto: ReportingObjectsParams): ReportingObjectsParamsDto {
  return {
    ...dto,
    startDate: dto.startDate.format('MM-DD-YYYY'),
    endDate: dto.endDate.format('MM-DD-YYYY'),
  };
}
