import {Injectable} from '@angular/core';
import {FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class TwdTimeValidationService {
  validateTimes(): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
      const startTime = group.get('eventStart');
      const endTime = group.get('eventEnd');
      if (!startTime?.value || !endTime?.value) {
        return null;
      }

      const startConversion = this.convertTo24HourFormat(startTime.value);
      const endConversion = this.convertTo24HourFormat(endTime.value);

      const start = moment().hour(startConversion.hours).minute(startConversion.minutes);
      const end = moment().hour(endConversion.hours).minute(endConversion.minutes);
      if (end?.format('hh:mm a') === '12:00 am') {
        end.add(1, 'day');
      }
      if (start?.isBefore(end)) {
        return null;
      }

      const message = `Start time should be before ${end?.format('h:mm A').replace(/:00/g, '')}.`;
      return {message};
    };
  }

  convertTo24HourFormat(timeString: string): {hours: number; minutes: number} {
    const [time, modifier] = timeString.split(' ');
    let [hoursStr, minutesStr] = time.split(':');
    let hours = parseInt(hoursStr, 10);
    let minutes = parseInt(minutesStr, 10);

    if (hours === 12) {
      hours = modifier.toUpperCase() === 'AM' ? 0 : 12;
    } else if (modifier.toUpperCase() === 'PM') {
      hours += 12;
    }

    return {hours, minutes};
  }
}
