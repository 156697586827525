import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'task-dialog-confirmation',
  templateUrl: './task-dialog-confirmation.component.html',
  styleUrls: ['./task-dialog-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskDialogConfirmationComponent {
  @Input() text: string;

  @Output() yes = new EventEmitter();
  @Output() no = new EventEmitter();

  loading$ = new BehaviorSubject<boolean>(false);

  onYes() {
    this.loading$.next(true);
    this.yes.emit();
  }
}
