<div class="header">
  <h1 class="title">Requirements</h1>
  <div class="header-right-container">
    <div class="button-group-container">
      @for (option of buttonOptions; track option) {
        <button
          class="toggle-button"
          [class.selected-toggle-button]="selectedOption === option"
          (click)="selectedOption = option"
        >
          {{ option | titlecase }}
        </button>
      }
    </div>
    <button (click)="onCancel()" class="icon-button">
      <img src="assets/img/icons/x-brown.svg" />
    </button>
  </div>
</div>
@if ({selectedRequirements: selectedRequirements$ | async}; as state) {
  <div class="item-container-wrapper oph-scroll-bar">
    <div class="item-container">
      @if (selectedOption === 'people') {
        @for (user of users$ | async; track user) {
          <task-dialog-requirement-item
            [display]="user.username"
            [selected]="usersControl.value?.includes(user._id)"
            [user]="user"
            type="people"
            (buttonClick)="onOption($event, user._id, 'users')"
          ></task-dialog-requirement-item>
        }
      }
      @if (selectedOption === 'teams') {
        @for (team of teams$ | async; track team) {
          <task-dialog-requirement-item
            [display]="team.name"
            [selected]="teamsControl.value?.includes(team.id)"
            type="teams"
            (buttonClick)="onOption($event, team.id, 'teams')"
          ></task-dialog-requirement-item>
        }
      }
      @if (selectedOption === 'skills') {
        @for (requirement of requirements$ | async; track requirement) {
          <task-dialog-requirement-item
            [display]="requirement.name"
            [selected]="requirementsControl.value?.includes(requirement._id)"
            type="skills"
            (buttonClick)="onOption($event, requirement._id, 'requirements')"
          ></task-dialog-requirement-item>
        }
      }
    </div>
  </div>
}

<task-dialog-panel-footer (cancel)="onCancel()" (ok)="back.emit()"></task-dialog-panel-footer>
