<h1>{{ text }}</h1>
<div class="button-container">
  <task-wizard-dialog-check-button width="300px" class="mb-10" (click)="onYes()">
    @if ((loading$ | async) === false) {
      Yes
    } @else {
      <mat-spinner diameter="25"></mat-spinner>
    }
  </task-wizard-dialog-check-button>
  <task-wizard-dialog-check-button width="300px" (click)="no.emit()">No</task-wizard-dialog-check-button>
</div>
