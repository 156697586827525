@if (validationErrors['triggersComplete']) {
  <span class="error">You have an incomplete Trigger.</span>
}
<div class="trigger-header">
  Trigger this task only if:
  @if (scheduleTypeControl.value === 'doNotRepeat' && !eventControl.value) {
    <mat-checkbox class="mat-checkbox" color="primary" [formControl]="autoResetControl">Auto reset task</mat-checkbox>
  }
</div>
@if (triggerFormArray?.value?.length) {
  @for (triggerFormGroup of triggerFormArray.controls; track control; let i = $index) {
    <task-trigger-selection
      class="mb-10"
      [triggerFormGroup]="triggerFormGroup"
      (removeItem)="onRemoveCondition(i)"
    ></task-trigger-selection>
  }
}
<button class="add-condition-button" (click)="onAddCondition()">
  <img src="assets/img/icons/plus-orange.svg" />
  A condition is met
</button>
