import {AsyncPipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {AbstractControl, FormControl, ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {RewardCoin} from 'src/app/core/model/reward';
import {selectCoinTypes, selectCoinTypesMap} from 'src/app/core/store/rewards/rewards.selector';
import {REWARD_COST_ERROR, REWARD_COST_MAX, REWARD_COST_MIN} from 'src/app/rewards/shared/rewards-constants';
import {RewardCoinIconPipe} from 'src/app/shared/pipes/reward-coin-icon.pipe';

@Component({
  selector: 'task-dialog-details-panel-rewards',
  standalone: true,
  imports: [MatIconModule, MatMenuModule, RewardCoinIconPipe, AsyncPipe, ReactiveFormsModule],
  templateUrl: './task-dialog-details-panel-rewards.component.html',
  styleUrl: './task-dialog-details-panel-rewards.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskDialogDetailsPanelRewardsComponent {
  @Input() form: FormControl;
  @Input() wizard: boolean;

  coinTypes$: Observable<RewardCoin[]> = this.store$.pipe(select(selectCoinTypes));
  coinTypesMap$: Observable<Record<string, RewardCoin>> = this.store$.pipe(select(selectCoinTypesMap));

  rewardCostMin = REWARD_COST_MIN;
  rewardCostMax = REWARD_COST_MAX;
  rewardCostError = REWARD_COST_ERROR;

  constructor(private store$: Store) {}

  onCoinType(coinId: string) {
    this.taskCoinTypeControl.setValue(coinId);
  }

  onRemoveDecimals(value: string) {
    this.taskCoinValueControl.setValue(value.replace(/\.\d*$/, ''));
  }

  get taskCoinTypeControl(): AbstractControl {
    return this.form.get('taskCoinType');
  }

  get taskCoinValueControl(): AbstractControl {
    return this.form.get('taskCoinValue');
  }
}
