import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'oph-radio-button',
  templateUrl: './oph-radio-button.component.html',
  styleUrls: ['./oph-radio-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OphRadioButtonComponent {
  @Input() selected: boolean;

  @Output() radioClick = new EventEmitter();
}
