import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {User} from 'src/app/core/model/user';
import {OphAvatarComponent} from 'src/app/shared/design/oph-avatar/oph-avatar.component';

@Component({
  selector: 'project-task-user',
  standalone: true,
  imports: [OphAvatarComponent],
  templateUrl: './project-task-user.component.html',
  styleUrl: './project-task-user.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectTaskUserComponent implements OnChanges {
  @Input() user: User;
  @Input() action: string;

  username = '';

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.action || changes.user) && this.action) {
      // If the task is todo or expired
      if (this.action === 'Assigned to' && !this.user) {
        this.username = 'Anyone';
      }
    }
  }
}
