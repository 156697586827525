<div class="button-container">
  <task-wizard-dialog-check-button
    width="100%"
    flexGrow="true"
    [selected]="dueWithinControl.value === 'project'"
    (click)="onButton('project')"
    >Project End</task-wizard-dialog-check-button
  >

  <task-wizard-dialog-check-button
    width="100%"
    flexGrow="true"
    [selected]="dueWithinControl.value === 'date'"
    (click)="onButton('date')"
    >Specific Date</task-wizard-dialog-check-button
  >

  <task-wizard-dialog-check-button
    width="100%"
    flexGrow="true"
    [selected]="dueWithinControl.value !== 'project' && dueWithinControl.value !== 'date'"
    (click)="onButton('1')"
    >Time Period</task-wizard-dialog-check-button
  >
</div>

@if (dueWithinControl.value === 'project') {
  <p>
    Task is due by the end of <br />
    the project
  </p>
}
@if (dueWithinControl.value === 'date') {
  <twd-calendar [control]="dueWithinDateControl" (dateChange)="onDateChange($event)"></twd-calendar>
}
@if (dueWithinControl.value !== 'project' && dueWithinControl.value !== 'date') {
  <div>
    <p>Task is due within</p>
    <div class="time-select-container">
      <task-wizard-dialog-number-input
        [min]="1"
        [max]="10000"
        [control]="dueWithinControl"
        [fontSize]="20"
      ></task-wizard-dialog-number-input>
      <button class="menu-button" [matMenuTriggerFor]="typeMenu">
        {{ dueWithinIntervalTypeControl.value | titlecase }}
        <img src="assets/img/icons/chevron-down-brown.svg" />
      </button>
      <mat-menu #typeMenu="matMenu" class="oph-select-menu">
        @for (type of repeatTypesArr; track type) {
          <button class="menu-item text" (click)="onType(type)">
            <span>{{ type | titlecase }}</span>
          </button>
        }
      </mat-menu>
    </div>
  </div>
}
