@if ({routineDetailMap: routineDetailMap$ | async, routineDetail: routineDetail$ | async}; as state) {
  <div class="header">
    <oph-back-button (buttonClick)="onBack()"></oph-back-button>
    <h2>Routine task list</h2>
    <div class="filter-buttons-container">
      @for (option of options; track option) {
        <button
          [class]="'filter-button ' + option.toLowerCase()"
          (click)="onFilterButton(option)"
          [class.selected]="(params$ | async).status === option.toLowerCase()"
        >
          @if ((loadingFilterButton$ | async) === option.toLowerCase()) {
            <mat-spinner class="custom-color" diameter="17"></mat-spinner>
          } @else {
            {{ option }}
          }
        </button>
      }
    </div>
  </div>
  <div class="routine-container">
    <div class="routine-container-header">
      <oph-icon
        class="task-icon"
        [name]="'color-icons/' + routine.icon"
        size="32"
        imageErrorName="color-icons/clock-time-watch"
      ></oph-icon>

      <span class="routine-title">{{ routine.label }}</span>
      <div class="color-bar" [style.background]="routinePriorityColor"></div>
      <div class="task-count-container">
        {{ state.routineDetail?.pagination?.total || 0 }}
        Task{{ state.routineDetail?.pagination?.total === 1 ? '' : 's' }}
        <img [src]="'assets/img/icons/repeat-green.svg'" />
      </div>
    </div>
    @if (!state.routineDetail) {
      <div class="loading-container">
        <oph-loading></oph-loading>
      </div>
    } @else {
      @if (state.routineDetail.routineTasks?.length) {
        @for (r of state.routineDetail.routineTasks; track routine) {
          <project-view-routine-item
            [routine]="r"
            (routineClick)="onRoutine(r._id)"
            [usersMap]="usersMap"
            (checkboxClick)="onCheckbox($event, r._id)"
          ></project-view-routine-item>
        }
      } @else {
        <div class="no-routines">No routines were found</div>
      }

      @if (state.routineDetail.pagination.totalPages > 1) {
        <oph-paginator
          [page]="state.routineDetail.pagination.page"
          [totalPages]="state.routineDetail.pagination.totalPages"
          preventRouting="true"
          (pageChange)="onPageChange($event)"
        ></oph-paginator>
      }
    }
  </div>
}
