<div class="button-container">
  <task-wizard-dialog-check-button
    width="100%"
    flexGrow="true"
    [selected]="scheduleOnDateControl.value === 'asap'"
    (click)="onButton('asap')"
    >As soon as possible</task-wizard-dialog-check-button
  >
  <task-wizard-dialog-check-button
    width="100%"
    flexGrow="true"
    [selected]="scheduleOnDateControl.value !== 'asap'"
    (click)="onButton('date')"
    >Select date</task-wizard-dialog-check-button
  >
</div>

<twd-calendar
  [control]="scheduleOnDateControl"
  [disabled]="scheduleOnDateControl.value === 'asap'"
  [focused]="scheduleOnDateControl.value !== 'asap'"
  (dateChange)="onDateChange($event)"
></twd-calendar>
