<button
  mat-icon-button
  [ngClass]="open ? 'menu-button-active' : 'menu-button'"
  [matMenuTriggerFor]="menu"
  (click)="onClick($event)"
  (menuOpened)="menuOpened()"
  (menuClosed)="menuClosed()"
>
  <oph-icon name="menu-horizontal" size="24"></oph-icon>
</button>

<mat-menu #menu="matMenu" xPosition="before" class="list-view-menu">
  @for (option of options; track option) {
    @if (option !== 'delete') {
      <button class="menu-item" (click)="action.next(option)" [class.wider-item]>
        <oph-icon [name]="option | listViewMenuIcon" size="20"></oph-icon>
        <span>{{ option | titlecase }}</span>
      </button>
    }
    @if (option === 'delete') {
      <button
        class="menu-item delete"
        (click)="action.next(option)"
        (mouseenter)="deleteHovered = true"
        (mouseleave)="deleteHovered = false"
      >
        <oph-icon [name]="deleteHovered ? 'delete-white' : 'delete-brown'" size="20"></oph-icon>
        <span>{{ option | titlecase }}</span>
      </button>
    }
  }
</mat-menu>
