import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {OphButtonModule} from 'src/app/shared/design/oph-button/oph-button.module';

@Component({
  selector: 'project-view-item-header',
  standalone: true,
  imports: [OphButtonModule],
  templateUrl: './project-view-item-header.component.html',
  styleUrl: './project-view-item-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectViewItemHeaderComponent {
  @Input() text: string;
  @Input() color: string;
  @Input() addNewButton: boolean;
  @Input() addNewButtonLoading: boolean;
  @Input() taskCount: number;

  @Output() buttonClick = new EventEmitter();

  buttonStyle = {
    borderRadius: '6px',
    height: '29px',
    width: '74px',
    minWidth: '74px',
    fontSize: '14px',
    background: 'var(--light)',
    border: '1px solid var(--border) !important',
  };
}
