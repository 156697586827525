import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Store, select} from '@ngrx/store';
import {BehaviorSubject, Observable, Subscription, filter, first, take} from 'rxjs';
import {selectRouterParam} from 'src/app/core/store/router/router.selector';
import {AuthenticationService} from '../auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordComponent implements OnInit {
  subscriptions = new Subscription();

  loadingReset = false;

  token$: Observable<string>;
  passwordInputType$ = new BehaviorSubject<string>('password');
  passwordConfirmInputType$ = new BehaviorSubject<string>('password');
  view$ = new BehaviorSubject<'valid' | 'invalid' | 'passwordChanged'>(null);
  loadingValid$ = new BehaviorSubject<boolean>(true);
  errorMessage$ = new BehaviorSubject<string>('');

  form = this.fb.group({
    password: ['', Validators.required],
    confirmPassword: ['', Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private store$: Store,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser) {
      this.router.navigate(['current-sked']);
    }
  }

  ngOnInit() {
    this.token$ = this.store$.pipe(select(selectRouterParam('token')));
    this.subscriptions.add(this.subscribeToToken());
  }

  subscribeToToken(): Subscription {
    return this.token$.pipe(filter(t => !!t)).subscribe(token => {
      // Success is added to url on success of password reset
      if (token === 'success') {
        return;
      }
      this.authenticationService
        .validateResetToken(token)
        .pipe(first())
        .subscribe({
          next: data => {
            this.errorMessage$.next('');
            this.view$.next(data.valid ? 'valid' : 'invalid');
            this.loadingValid$.next(false);
          },
          error: error => {
            this.errorMessage$.next(error);
            this.view$.next('invalid');
            this.loadingValid$.next(false);
          },
        });
    });
  }

  onTogglePassword(event: MouseEvent) {
    event.preventDefault();
    const type = this.passwordInputType$.value === 'password' ? 'text' : 'password';
    this.passwordInputType$.next(type);
  }

  onToggleConfirmPassword(event: MouseEvent) {
    event.preventDefault();
    const type = this.passwordConfirmInputType$.value === 'password' ? 'text' : 'password';
    this.passwordConfirmInputType$.next(type);
  }

  onGo() {
    if (this.passwordControl.value !== this.confirmPasswordControl.value) {
      this.errorMessage$.next('Passwords must match');
      return;
    }

    this.token$.pipe(take(1)).subscribe(token => {
      this.errorMessage$.next('');
      this.loadingReset = true;
      this.authenticationService.resetPassword(token, this.passwordControl.value).subscribe({
        next: data => {
          this.errorMessage$.next('');
          this.router.navigate(['reset-password', 'success']);
          this.view$.next('passwordChanged');
          this.loadingValid$.next(false);
          this.loadingReset = false;
        },
        error: error => {
          this.loadingValid$.next(false);
          this.loadingReset = false;
        },
      });
    });
  }

  onBack(event: PointerEvent) {
    event.preventDefault();
    this.router.navigate(['login']);
  }

  onRequestNewLink() {
    this.router.navigate(['login'], {queryParams: {view: 'forgotPassword'}, queryParamsHandling: 'merge'});
  }

  get passwordControl() {
    return this.form.get('password');
  }

  get confirmPasswordControl() {
    return this.form.get('confirmPassword');
  }
}
