import {AsyncPipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, combineLatest, map, Observable, take} from 'rxjs';
import {Reward, RewardCoin} from 'src/app/core/model/reward';
import {User} from 'src/app/core/model/user';
import {
  FulfillRewardAction,
  GetManageRewardsAction,
  UpdateManageRewardsCoinExpandedMapAction,
  UpdateManageRewardsRewardExpandedMapAction,
  UpdateRewardsHeaderLoading,
} from 'src/app/core/store/rewards/rewards.action';
import {
  selectCoinTypes,
  selectManageRewards,
  selectManageRewardsCoinExpandedMap,
  selectManageRewardsRewardExpandedMap,
} from 'src/app/core/store/rewards/rewards.selector';
import {selectAllUsers, selectUsersMap} from 'src/app/core/store/users/users.selector';
import {MessageService} from 'src/app/services/message.service';
import {OphButtonModule} from 'src/app/shared/design/oph-button/oph-button.module';
import {OphLoadingModule} from 'src/app/shared/design/oph-loading/oph-loading.module';
import {ListViewsModule} from 'src/app/shared/list-views/list-views.module';
import {IsExpiredPipe} from 'src/app/shared/pipes/is-expired.pipe';
import {PipesModule} from 'src/app/shared/pipes/pipes.module';
import {RewardsCoinExpansionPanelComponent} from '../shared/coin-expansion-panel/rewards-coin-expansion-panel.component';
import {RewardsDeleteConfirmationDialogComponent} from '../shared/delete-confirmation-dialog/rewards-delete-confirmation-dialog.component';
import {RewardsRewardHeaderItemComponent} from '../shared/header-item/rewards-reward-header-item.component';
import {RewardsProgressBarComponent} from '../shared/progress-bar/rewards-progress-bar.component';
import {RewardsRewardCostComponent} from '../shared/reward-cost/rewards-reward-cost.component';
import {RewardManageProgressPipe} from '../shared/reward-manage-progress.pipe';
import {RewardsActiveUserCountPipe} from '../shared/rewards-active-user-count.pipe';
import {RewardsCheckIfAvailableFulfillmentPipe} from '../shared/rewards-check-if-available-fulfillment.pipe';
import {MANAGE_REWARDS_DATA, rewardsDialogConfig} from '../shared/rewards-constants';
import {RewardsDialogComponent} from '../shared/rewards-dialog/rewards-dialog.component';
import {RewardsUserCountChevronComponent} from '../shared/user-count-chevron/rewards-user-count-chevron.component';
import {RewardUserItemComponent} from '../shared/user-item/reward-user-item.component';

@Component({
  selector: 'rewards-manage',
  standalone: true,
  imports: [
    RewardsCoinExpansionPanelComponent,
    OphLoadingModule,
    AsyncPipe,
    PipesModule,
    MatExpansionModule,
    RewardsUserCountChevronComponent,
    MatIconModule,
    IsExpiredPipe,
    OphButtonModule,
    ListViewsModule,
    RewardsRewardHeaderItemComponent,
    RewardsProgressBarComponent,
    RewardsRewardCostComponent,
    RewardUserItemComponent,
    RewardManageProgressPipe,
    RewardsActiveUserCountPipe,
    RewardsCheckIfAvailableFulfillmentPipe,
  ],
  templateUrl: './rewards-manage.component.html',
  styleUrl: './rewards-manage.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RewardsManageComponent implements OnInit {
  manageRewardsData = MANAGE_REWARDS_DATA;
  readonly dialog = inject(MatDialog);

  coinTypes$: Observable<RewardCoin[]> = this.store$.pipe(select(selectCoinTypes));
  users$: Observable<User[]> = this.store$.pipe(select(selectAllUsers));
  usersMap$: Observable<Record<string, User>> = this.store$.pipe(select(selectUsersMap));
  manageRewards$: Observable<RewardCoin[]> = this.store$.pipe(select(selectManageRewards));
  manageRewardsCoinExpandedMap$: Observable<Record<string, boolean>> = this.store$.pipe(
    select(selectManageRewardsCoinExpandedMap)
  );
  manageRewardsRewardExpandedMap$: Observable<Record<string, boolean>> = this.store$.pipe(
    select(selectManageRewardsRewardExpandedMap)
  );
  initialLoading$: Observable<boolean>;

  loading$ = new BehaviorSubject<boolean>(true);
  getError$ = new BehaviorSubject<boolean>(false);

  fulfillLoadingIndex = -1;

  constructor(
    private store$: Store,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.initialLoading$ = this.manageRewards$.pipe(map(rewards => !rewards));
    this.getManageRewards();
  }

  getManageRewards() {
    this.initialLoading$.pipe(take(1)).subscribe(loading => {
      if (loading) {
        return;
      }
      this.store$.dispatch(new UpdateRewardsHeaderLoading({headerLoading: true}));
    });

    this.store$.dispatch(
      new GetManageRewardsAction({
        onSuccess: () => {
          this.clearLoading();
        },
        onFailure: err => {
          this.clearLoading();
          this.messageService.add(err.message || 'There was a problem getting rewards.');
          this.getError$.next(true);
        },
      })
    );
  }

  clearLoading() {
    this.store$.dispatch(new UpdateRewardsHeaderLoading({headerLoading: false}));
    this.loading$.next(false);
    this.fulfillLoadingIndex = -1;
  }

  onCoinExpandedMapChange(name: string, open: boolean) {
    this.store$.dispatch(new UpdateManageRewardsCoinExpandedMapAction({name, open}));
  }

  onRewardExpandedMapChange(coinName: string, index: number, open: boolean) {
    const key = coinName + String(index);
    this.store$.dispatch(new UpdateManageRewardsRewardExpandedMapAction({key, open}));
  }

  onMenuAction(action: string, reward: Reward) {
    if (action === 'edit') {
      this.openRewardDialog(reward);
    }
    if (action === 'delete') {
      // Cannot delete reward that is not fulfilled
      const activeClaim = reward.assignedTo.some(a => a.status === 'claimed');
      if (activeClaim) {
        this.messageService.add('This reward is not completely fulfilled. Please fulfill before deleting.');
        return;
      }
      this.openDeleteDialog(reward);
    }
  }

  onFulfill(rewardId: string, assignedToArrayId: string, uIndex: number) {
    this.fulfillLoadingIndex = uIndex;
    this.store$.dispatch(
      new FulfillRewardAction({
        rewardId,
        assignedToArrayId,
        onSuccess: () => this.getManageRewards(),
        onFailure: err => this.onFulfillFailure(),
      })
    );
  }

  onFulfillFailure() {
    this.fulfillLoadingIndex = -1;
    this.messageService.add('There was a problem fulfilling the reward');
  }

  openDeleteDialog(reward: Reward) {
    this.dialog.open(RewardsDeleteConfirmationDialogComponent, {
      data: {
        message: 'Are you sure you want to delete this reward? This action cannot be undone.',
        page: 'manage',
        rewardId: reward._id,
      },
    });
  }

  openRewardDialog(reward: Reward): void {
    combineLatest([this.coinTypes$, this.users$, this.usersMap$])
      .pipe(take(1))
      .subscribe(([coinTypes, users, usersMap]) => {
        this.dialog.open(RewardsDialogComponent, {
          ...rewardsDialogConfig,
          data: {reward, coinTypes, users, usersMap, page: 'manage'},
        });
      });
  }
}
