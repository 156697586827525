import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {Observable, take} from 'rxjs';
import {AuthenticationService} from 'src/app/auth/auth.service';
import {ProductionEnvironmentService} from 'src/app/core/page/production-environment.service';
import {selectActiveUser} from '../../../../core/store/active-user/active-user.selector';
import {AppThemeService} from './../../../services/app-theme.service';

@Component({
  selector: 'user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuComponent implements OnInit {
  public readonly activeUser$ = this.store$.select(selectActiveUser);
  public skedsLegacy$: Observable<boolean>;
  appTheme = new FormControl('');

  constructor(
    private productionEnvironmentService: ProductionEnvironmentService,
    private store$: Store,
    private authService: AuthenticationService,
    private router: Router,
    private appThemeService: AppThemeService
  ) {}

  ngOnInit() {
    this.skedsLegacy$ = this.productionEnvironmentService.observeHostname();
    this.appTheme.setValue(this.appThemeService.getAppTheme());
  }

  onThemeChange(theme: string) {
    this.appThemeService.update(theme);
  }

  onLogout() {
    this.authService.logout();
  }

  onProfile() {
    this.activeUser$.pipe(take(1)).subscribe(user => {
      this.router.navigate(['users', user._id]);
    });
  }
}
