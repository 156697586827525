import {TaskCalendarHeaderComponent} from './header/task-calendar-header.component';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'task-calendar',
  templateUrl: './task-calendar.component.html',
  styleUrls: ['./task-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskCalendarComponent {
  @Input() control: FormControl;
  @Input() disabled: boolean;
  @Input() focused: boolean;
  @Input() hideBorder: boolean;

  @Output() dateChange = new EventEmitter<Date>();

  today = new Date();
  headerComponent = TaskCalendarHeaderComponent;

  onDateChange(date: Date) {
    this.dateChange.emit(date);
    this.control.setValue(date);
  }
}
