<div cdkDropList class="subtasks-list" [cdkDropListAutoScrollDisabled]="false" (cdkDropListDropped)="onDrop($event)">
  @if ({hoverIndex: hoverIndex$ | async, menuIndex: menuIndex$ | async}; as state) {
    @for (subtask of (subtasks$ | async) || []; track subtask; let i = $index) {
      <div class="subtask-container" cdkDrag (mouseenter)="onMouseEnter(i)" (mouseleave)="onMouseLeave(i)">
        @if (state.menuIndex !== i) {
          <div
            class="subtask-item"
            [class.subtask-item-light]="itemBackground === 'light'"
            [class.subtask-item-dark]="itemBackground === 'dark'"
          >
            <div class="subtask-item-title">
              {{ subtask.title || subtask.description || '(Edit to update subtask title)' }}
            </div>
            @if (i === state.hoverIndex) {
              <div cdkDragHandle class="drag-handle" (mousedown)="menuIndex$.next(null)">
                <div class="drag-handle-inner-container">
                  <div class="white-line white-line-top"></div>
                  <div class="white-line white-line-bottom"></div>
                  <img src="assets/img/icons/up-down-arrows.svg" />
                </div>
              </div>
            }
            <button aria-label="Menu" class="subtask-menu-button" (click)="onShowActions(i)">
              <img src="assets/img/icons/more-horizontal-brown.svg" />
            </button>
          </div>
        }
        @if (state.menuIndex === i) {
          <div class="menu-container">
            <div class="menu">
              <oph-inline-edit-menu
                (buttonClick)="handleEditButtonClick($event, i)"
                (closeMenu)="onHideActions(i)"
                [actions]="['duplicate', 'delete', 'edit']"
              ></oph-inline-edit-menu>
            </div>
          </div>
        }
      </div>
    }
  }
</div>
