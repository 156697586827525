<div class="desktop">
  <task-icon-picker-button [iconName]="iconControl.value" (buttonClick)="onSelectIcon()"></task-icon-picker-button>
  <oph-input-inside-label
    label="Task Name"
    [control]="labelControl"
    [invalid]="labelControl.invalid && labelControl.touched"
    defaultFocus="true"
  ></oph-input-inside-label>
  <task-duration-slider [control]="durationControl" durationDisplayType="manualTask"></task-duration-slider>
</div>

<div class="mobile">
  <div class="top">
    <task-icon-picker-button [iconName]="iconControl.value" (buttonClick)="onSelectIcon()"></task-icon-picker-button>
    <task-duration-slider [control]="durationControl" durationDisplayType="manualTask"></task-duration-slider>
  </div>
  <oph-input-inside-label
    label="Task Name"
    [control]="labelControl"
    [invalid]="labelControl.invalid && labelControl.touched"
    defaultFocus="true"
    backgroundColorHex="#FEFDF5"
  ></oph-input-inside-label>
</div>
