<div class="input-container">
  <oph-icon [name]="'list-views/search'" [size]="22"></oph-icon>
  <input
    type="text"
    class="example-input"
    [placeholder]="placeholder || ''"
    [matAutocomplete]="auto"
    [ngStyle]="style"
    [disabled]="inputDisabled"
    [value]="value"
    (input)="onInput($event.target.value)"
    (blur)="onBlur()"
    #input
  />
</div>

<mat-autocomplete #auto="matAutocomplete" (opened)="menuOpen = true" (closed)="menuOpen = false">
  @for (option of filteredOptions; track option; let i = $index) {
    <mat-option
      [value]="preventSelectedOption ? null : option"
      (click)="onOption(option, i)"
      [disabled]="option.disabled"
      >{{ option.name }}</mat-option
    >
  }
</mat-autocomplete>
