import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'trackerSortName',
  standalone: true,
})
export class TrackerSortNamePipe implements PipeTransform {
  transform(name: string): string {
    if (name === 'lastUpdatedOn') {
      return 'Updated On';
    }
    if (name === 'lastUpdatedBy') {
      return 'Updated By';
    }
    return name;
  }
}
