import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {QuillModule} from 'ngx-quill';
import {SharedCurrentSkedModule} from 'src/app/shared/current-sked/shared-current-sked.module';
import {OphBackButtonComponent} from 'src/app/shared/design/oph-back-button/oph-back-button.component';
import {OphButtonModule} from 'src/app/shared/design/oph-button/oph-button.module';
import {OphIconModule} from 'src/app/shared/design/oph-icon/oph-icon.module';
import {OphLoadingModule} from 'src/app/shared/design/oph-loading/oph-loading.module';
import {PipesModule} from 'src/app/shared/pipes/pipes.module';
import {CurrentSkedTaskDetailRoutingModule} from './current-sked-task-detail-routing.module';
import {CurrentSkedTaskDetailComponent} from './current-sked-task-detail.component';
import {CurrentSkedTaskDetailDubaiThemeModule} from './dubai-theme/current-sked-task-detail-dubai-theme.module';
import {CurrentSkedTaskDetailStatusButtonComponent} from './status-button/current-sked-task-detail-status-button.component';
import {CurrentSkedTaskDetailSubtaskMetricUpdateComponent} from './subtask-metric-update/current-sked-task-detail-subtask-metric-update.component';
import {CurrentSkedTaskDetailSubtaskComponent} from './subtask/current-sked-task-detail-subtask.component';
import {RewardCoinIconPipe} from 'src/app/shared/pipes/reward-coin-icon.pipe';

@NgModule({
  declarations: [CurrentSkedTaskDetailComponent],
  imports: [
    CommonModule,
    CurrentSkedTaskDetailRoutingModule,
    CurrentSkedTaskDetailDubaiThemeModule,
    OphLoadingModule,
    MatIconModule,
    CurrentSkedTaskDetailStatusButtonComponent,
    PipesModule,
    OphIconModule,
    MatExpansionModule,
    CurrentSkedTaskDetailSubtaskComponent,
    SharedCurrentSkedModule,
    OphBackButtonComponent,
    QuillModule.forRoot(),
    OphButtonModule,
    CurrentSkedTaskDetailSubtaskMetricUpdateComponent,
    RewardCoinIconPipe,
  ],
  exports: [CurrentSkedTaskDetailComponent],
})
export class CurrentSkedTaskDetailModule {}
