import {HttpErrorResponse} from '@angular/common/http';
import {Action} from '@ngrx/store';
import {Lens, LensDto} from 'src/app/lenses/shared/model/lens';
import {LensSetting} from 'src/app/lenses/shared/model/lens-setting';

export enum LensesActionType {
  GET = '[Lenses] Get',
  GET_SUCCESS = '[Lenses] Get :: Success',

  GET_SINGLE = '[Lenses] Get Single',
  GET_SINGLE_SUCCESS = '[Lenses] Get Single :: Success',

  CLEAR_SINGLE = '[Lenses] Clear Single',

  GET_USER_LENS = '[Lenses] Get User Lens',
  GET_USER_LENS_SUCCESS = '[Lenses] Get User Lens :: Success',

  GET_SETTINGS = '[Lenses] Get Settings',
  GET_SETTINGS_SUCCESS = '[Lenses] Get Settings :: Success',

  DELETE = '[Lenses] Delete',
  DELETE_SUCCESS = '[Lenses] Delete :: Success',

  CREATE = '[Lenses] Create',
  CREATE_SUCCESS = '[Lenses] Create :: Success',

  UPDATE = '[Lenses] Update',
  UPDATE_SUCCESS = '[Lenses] Update :: Success',
}

export class GetLensesAction implements Action {
  public readonly type = LensesActionType.GET;

  public constructor(
    public payload: {
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetLensesSuccessAction implements Action {
  public readonly type = LensesActionType.GET_SUCCESS;

  public constructor(public payload: {lenses: Lens[]}) {}
}

//--------------------------------------------------------

export class CreateNewLensAction implements Action {
  public readonly type = LensesActionType.CREATE;

  public constructor(
    public payload: {
      lens: LensDto;
      onSuccess?: (lens: Lens) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class CreateLensSuccessAction implements Action {
  public readonly type = LensesActionType.CREATE_SUCCESS;

  public constructor(public payload: {lens: Partial<Lens>}) {}
}

//--------------------------------------------------------
export class DeleteLensAction implements Action {
  public readonly type = LensesActionType.DELETE;

  public constructor(
    public payload: {
      id: string;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class DeleteLensSuccessAction implements Action {
  public readonly type = LensesActionType.DELETE_SUCCESS;
}

//--------------------------------------------------------

export class UpdateLensAction implements Action {
  public readonly type = LensesActionType.UPDATE;

  public constructor(
    public payload: {
      lens: LensDto;
      onSuccess?: (lens: Lens) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class UpdateLensSuccessAction implements Action {
  public readonly type = LensesActionType.UPDATE_SUCCESS;

  public constructor(public payload: {lens: Lens}) {}
}

//--------------------------------------------------------

export class GetSingleLensAction implements Action {
  public readonly type = LensesActionType.GET_SINGLE;

  public constructor(
    public payload: {
      id: string;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetSingleLensSuccessAction implements Action {
  public readonly type = LensesActionType.GET_SINGLE_SUCCESS;

  public constructor(public payload: {lens: Lens}) {}
}

export class ClearSingleLensSuccessAction implements Action {
  public readonly type = LensesActionType.CLEAR_SINGLE;
}

//--------------------------------------------------------

export class GetUserLensAction implements Action {
  public readonly type = LensesActionType.GET_USER_LENS;

  public constructor(
    public payload: {
      id: string;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetUserLensSuccessAction implements Action {
  public readonly type = LensesActionType.GET_USER_LENS_SUCCESS;

  public constructor(public payload: {userLens: Lens; userLensSettingsMap: Record<string, LensSetting>}) {}
}

//--------------------------------------------------------

export class GetLensSettingsAction implements Action {
  public readonly type = LensesActionType.GET_SETTINGS;

  public constructor(
    public payload: {
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class GetLensSettingsSuccessAction implements Action {
  public readonly type = LensesActionType.GET_SETTINGS_SUCCESS;

  public constructor(public payload: {settings: LensSetting[]; settingsMap: Record<string, LensSetting>}) {}
}

//--------------------------------------------------------

export type LensesAction =
  | GetLensesAction
  | GetLensesSuccessAction
  | GetSingleLensAction
  | GetSingleLensSuccessAction
  | ClearSingleLensSuccessAction
  | GetUserLensAction
  | GetUserLensSuccessAction
  | GetLensSettingsAction
  | GetLensSettingsSuccessAction
  | CreateNewLensAction
  | UpdateLensAction
  | DeleteLensAction
  | CreateLensSuccessAction
  | UpdateLensSuccessAction
  | DeleteLensSuccessAction;
