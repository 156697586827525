import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatMenuModule} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {OphIconModule} from 'src/app/shared/design/oph-icon/oph-icon.module';
import {PipesModule} from 'src/app/shared/pipes/pipes.module';
import {CurrentSkedDubaiThemeSkedNameDisplayComponent} from './sked-name-display/current-sked-dubai-theme-sked-name-display.component';
import {CurrentSkedDubaiThemeStatusActionMenuComponent} from './status-action-menu/current-sked-dubai-theme-status-action-menu.component';
import {CurrentSkedDubaiThemeTopBarComponent} from './top-bar/current-sked-dubai-theme-top-bar.component';
import {CurrentSkedDubaiThemeTopBarSkedSelectorComponent} from './legacy/sked-selector/current-sked-dubai-theme-top-bar-sked-selector.component';

@NgModule({
  declarations: [
    CurrentSkedDubaiThemeSkedNameDisplayComponent,
    CurrentSkedDubaiThemeTopBarComponent,
    CurrentSkedDubaiThemeStatusActionMenuComponent,
    CurrentSkedDubaiThemeTopBarSkedSelectorComponent,
  ],
  imports: [CommonModule, OphIconModule, MatMenuModule, PipesModule, MatTooltipModule],
  exports: [
    CurrentSkedDubaiThemeSkedNameDisplayComponent,
    CurrentSkedDubaiThemeTopBarComponent,
    CurrentSkedDubaiThemeStatusActionMenuComponent,
  ],
})
export class SharedDubaiThemeModule {}
