import {TeamDto} from '../dto/team.dto';
import {Team} from '../model/team';

export function convertTeamDtoToModel(dto: TeamDto): Team {
  return {
    id: dto._id,
    name: dto.name,
    description: dto.description,
  };
}
