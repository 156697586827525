import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {TaskDialogPanels} from '../../../shared/task-dialog-constants';

@Component({
  selector: 'task-dialog-header',
  templateUrl: './task-dialog-header.component.html',
  styleUrls: ['./task-dialog-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskDialogHeaderComponent {
  @Input() form: FormGroup;

  @Output() panelChange = new EventEmitter<TaskDialogPanels>();

  onSelectIcon() {
    this.panelChange.emit(TaskDialogPanels.IconPicker);
  }

  get iconControl(): AbstractControl {
    return this.form.get('icon');
  }

  get labelControl(): AbstractControl {
    return this.form.get('label');
  }

  get durationControl(): AbstractControl {
    return this.form.get('duration');
  }
}
