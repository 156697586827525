import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CurrentSkedTask} from 'src/app/core/model/task';
import {OutSideClickDirective} from 'src/app/shared/design/outside-click/outside-click.directive';
import {AvailableTaskActions} from '../../shared/model/available-task-actions';
import {TaskLoadingState} from '../../shared/model/task-loading-state';
import {TaskStatusActions} from '../../shared/task-status-actions';
import {TaskStatuses} from '../../shared/task-statuses';
import {MatDialog} from '@angular/material/dialog';
import {CurrentSkedStatusButtonInfoDialogComponent} from './info-dialog/current-sked-status-button-info-dialog.component';

interface TaskSetting {
  label: string;
  primeColor: string;
  secColor: string;
}

interface TaskStatus {
  toDo: TaskSetting;
  start: TaskSetting;
  inProgress: TaskSetting;
  hold: TaskSetting;
  complete: TaskSetting;
  cancelled: TaskSetting;
}

@Component({
  selector: 'current-sked-task-detail-status-button',
  standalone: true,
  imports: [MatIconModule, OutSideClickDirective, MatProgressSpinnerModule, MatTooltipModule],
  templateUrl: './current-sked-task-detail-status-button.component.html',
  styleUrl: './current-sked-task-detail-status-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentSkedTaskDetailStatusButtonComponent implements OnChanges {
  @Input() loading: TaskLoadingState;
  @Input() task: CurrentSkedTask;
  @Input() availableTaskActions: AvailableTaskActions;

  @Output() statusAction = new EventEmitter<TaskStatusActions>();

  taskStatuses = TaskStatuses;
  taskStatusActions = TaskStatusActions;
  showInfoButton: boolean;

  readonly dialog = inject(MatDialog);

  taskStatus: TaskStatus = {
    toDo: {
      label: 'To Do',
      primeColor: '#7BC143',
      secColor: '#66AD2E',
    },
    start: {
      label: 'Start Task',
      primeColor: '#7BC143',
      secColor: '#66AD2E',
    },
    inProgress: {
      label: 'In Progress',
      primeColor: '#55CBBD',
      secColor: '#36B1A2',
    },
    hold: {
      label: 'On Hold',
      primeColor: '#FF705C',
      secColor: '#E65845',
    },
    complete: {
      label: 'Complete',
      primeColor: '#A3A099',
      secColor: '#86847A',
    },
    cancelled: {
      label: 'Cancelled',
      primeColor: '#F2C469',
      secColor: '#D0A551',
    },
  };

  statusOptions = [
    {
      label: 'To Do',
      action: this.taskStatusActions.Release,
      primeColor: this.taskStatus.toDo.primeColor,
      secColor: this.taskStatus.toDo.secColor,
      status: TaskStatuses.Unclaimed,
    },
    {
      label: 'In Progress',
      action: this.taskStatusActions.Start,
      primeColor: this.taskStatus.inProgress.primeColor,
      secColor: this.taskStatus.inProgress.secColor,
      status: TaskStatuses.InProgress,
    },
    {
      label: 'On Hold',
      action: this.taskStatusActions.OnHold,
      primeColor: this.taskStatus.hold.primeColor,
      secColor: this.taskStatus.hold.secColor,
      status: TaskStatuses.OnHold,
    },
    {
      label: 'Complete',
      action: this.taskStatusActions.Complete,
      primeColor: this.taskStatus.complete.primeColor,
      secColor: this.taskStatus.complete.secColor,
      status: TaskStatuses.Completed,
    },
    {
      label: 'Cancelled',
      action: this.taskStatusActions.Cancel,
      primeColor: this.taskStatus.cancelled.primeColor,
      secColor: this.taskStatus.cancelled.secColor,
      status: TaskStatuses.Cancelled,
    },
  ];

  menuOpen: boolean = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.task || changes.availableTaskActions) {
      if (!this.task || !this.availableTaskActions) {
        return;
      }
      if (this.task.status === this.taskStatuses.Unclaimed) {
        this.showInfoButton = !this.task.authorizationObject.isAuthorized;
      }
      if (this.task.status === this.taskStatuses.InProgress) {
        this.showInfoButton =
          !this.task.authorizationObject.isAuthorized || this.availableTaskActions[TaskStatuses.Completed]?.disabled;
      }
    }
  }

  startTask(): void {
    if (this.task.status === this.taskStatuses.Unclaimed || this.task.status === this.taskStatuses.Claimed) {
      this.statusAction.emit(TaskStatusActions.Start);
    }
  }

  getTaskStatus(): TaskSetting {
    if (this.task?.status) {
      if (this.task.status === this.taskStatuses.Unclaimed) {
        return this.taskStatus.start;
      } else if (this.task.status === this.taskStatuses.Claimed) {
        return this.taskStatus.start;
      } else if (this.task.status === this.taskStatuses.InProgress) {
        return this.taskStatus.inProgress;
      } else if (this.task.status === this.taskStatuses.OnHold) {
        return this.taskStatus.hold;
      } else if (this.task.status === this.taskStatuses.Completed) {
        return this.taskStatus.complete;
      } else if (this.task.status === this.taskStatuses.Cancelled) {
        return this.taskStatus.cancelled;
      } else {
        return this.taskStatus.cancelled;
      }
    } else {
      return this.taskStatus.cancelled;
    }
  }

  setTaskStatus(status: TaskStatusActions): void {
    this.statusAction.emit(status);
    this.menuOpen = false;
  }

  toggleDropdownMenu(event: Event): void {
    event.stopPropagation();
    this.menuOpen = !this.menuOpen;
  }

  isTaskActive(status: string): boolean {
    return status === this.task?.status || false;
  }

  onInfo() {
    this.dialog.open(CurrentSkedStatusButtonInfoDialogComponent, {
      data: {auth: this.task.authorizationObject, taskActions: this.availableTaskActions, taskStatus: this.task.status},
    });
  }
}
