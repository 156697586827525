import {Routes} from '@angular/router';
import {RewardsHistoryComponent} from './history/rewards-history.component';
import {RewardsManageComponent} from './manage/rewards-manage.component';
import {RewardsMyRewardsComponent} from './my-rewards/rewards-my-rewards.component';
import {RewardsComponent} from './rewards.component';

export const rewardsRouting: Routes = [
  {
    path: '',
    component: RewardsComponent,
    children: [
      {path: 'manage', component: RewardsManageComponent},
      {path: 'my-rewards', component: RewardsMyRewardsComponent},
      {path: 'history', component: RewardsHistoryComponent},
    ],
  },
];
