export const LEGACY_TASK_ICONS = [
  'alarm-clock',
  'drink',
  'duck',
  'evergreen',
  'fish',
  'heart',
  'leaf',
  'mushroom',
  'speaker',
  'bucket',
  'grid',
  'mag',
  'palette',
  'thermometer',
  'walk',
];
