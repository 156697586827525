import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'current-sked-checkbox',
  templateUrl: './current-sked-checkbox.component.html',
  styleUrls: ['./current-sked-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentSkedCheckboxComponent implements OnChanges {
  @Input() checked: boolean;
  @Input() disabled: boolean;
  @Input() loading: boolean;

  @Output() checkboxClick = new EventEmitter<boolean>();

  checkImgSrc = 'assets/img/icons/check-light-grey.svg';

  ngOnChanges(changes: SimpleChanges) {
    if (changes.checked) {
      const color = this.checked ? 'white' : 'light-grey';
      this.checkImgSrc = `assets/img/icons/check-${color}.svg`;
    }
  }

  onButton(event: PointerEvent) {
    event.stopPropagation();
    this.checkboxClick.emit(!this.checked);
  }
}
