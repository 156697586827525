import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import * as Sentry from '@sentry/angular';
import {BehaviorSubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {WEBROOT} from '../app.constants';
import {LoginDto} from '../core/api/dto/login.dto';
import {SetActiveUser} from '../core/store/active-user/active-user.action';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private loggedIn = new BehaviorSubject<boolean>(false);

  get isLoggedIn() {
    if (localStorage.getItem('currentUser')) {
      this.loggedIn.next(true);
    }
    return this.loggedIn.asObservable();
  }

  constructor(
    private http: HttpClient,
    private store: Store,
    private router: Router
  ) {}

  login(email: string, password: string) {
    return this.http.post<LoginDto>(`${WEBROOT}/login`, {email, password: password}).pipe(
      map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.configureSentryUserScope(user.email);
          this.loggedIn.next(true);
        }
        return user;
      })
    );
  }

  logout(preventRoute?: boolean) {
    this.store.dispatch(new SetActiveUser({user: null}));

    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');

    this.configureSentryUserScope(null);
    this.loggedIn.next(false);
    if (!preventRoute) {
      this.router.navigate(['/login']);
    }
  }

  private configureSentryUserScope(username: string) {
    if (!environment.sentryDsn) {
      return;
    }

    Sentry.setUser(username ? {username} : null);
  }

  requestPasswordReset(email: string, host: string) {
    return this.http.post<string>(`${WEBROOT}/requestPasswordReset`, {email, host});
  }

  validateResetToken(token: string) {
    return this.http.post<{valid: boolean}>(`${WEBROOT}/validateResetToken`, {token});
  }

  resetPassword(token: string, password: string) {
    return this.http.post<string>(`${WEBROOT}/resetPassword`, {token, password});
  }
}
