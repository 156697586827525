import {createSelector} from '@ngrx/store';
import {AppState} from '../app-state';

export const selectSchedulerState = (state: AppState) => state.scheduler;

export const selectSchedulerSkedTemplate = createSelector(selectSchedulerState, state => state.skedTemplates);

export const selectSchedulerCurrentWeek = createSelector(selectSchedulerState, state => state.currentWeek);

export const selectSchedulerCurrentSeason = createSelector(selectSchedulerState, state => state.currentSeason);

export const selectSeasonWeeks = createSelector(selectSchedulerState, state => state.seasonWeeks);

export const selectSaveInProgress = createSelector(selectSchedulerState, state => state.saveInProgress);

export const selectHasUnsavedChanges = createSelector(selectSchedulerState, state => state.hasUnsavedChanges);

export const selectTeamSkeds = createSelector(selectSchedulerState, state => state.teamSkeds);

export const selectTeamSchedule = createSelector(selectSchedulerState, state => state.teamSchedule);
