<button class="tracker" (click)="trackerClick.emit()">
  <div class="row">
    <div class="item name">
      <label>NAME</label>
      <span class="bold">{{ tracker.name }}</span>
    </div>

    <div class="item projects">
      <label>PROJECTS</label>
      <list-views-project-display [projects]="tracker.projects"></list-views-project-display>
    </div>
  </div>

  <div class="row">
    <div class="item on">
      <label>UPDATED</label>
      <span
        >{{ tracker.lastUpdatedAt | convertUtcToTimezone: 'MMM DD YYYY' }}&nbsp;&nbsp;&nbsp;
        {{ tracker.lastUpdatedAt | convertUtcToTimezone: 'HH:mm' }}</span
      >
    </div>

    <div class="item by">
      <label>BY</label>
      <span>{{ tracker.lastUpdatedBy }}</span>
    </div>

    <div class="item metrics">
      <label>METRICS</label>
      <span>{{ tracker.metrics?.length || 0 }}</span>
    </div>
  </div>
</button>
