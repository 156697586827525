import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'task-dialog-schedule-button',
  templateUrl: './task-dialog-schedule-button.component.html',
  styleUrls: ['./task-dialog-schedule-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskDialogScheduleButtonComponent {
  @Input() showClear: boolean;
  @Input() iconType: 'date' | 'time' = 'date';

  @Output() buttonClick = new EventEmitter();
  @Output() clear = new EventEmitter<PointerEvent>();
}
