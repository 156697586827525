import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {ProjectViewRoutine} from 'src/app/core/model/project';
import {ProjectViewRoutineStatusColorPipe} from '../../pipes/project-view-routine-status-color.pipe';
import {ProjectViewIconContainerComponent} from '../../icon-container/project-view-icon-container.component';

@Component({
  selector: 'project-view-task-routine',
  standalone: true,
  imports: [ProjectViewRoutineStatusColorPipe, ProjectViewIconContainerComponent],
  templateUrl: './project-view-task-routine.component.html',
  styleUrl: './project-view-task-routine.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectViewTaskRoutineComponent {
  @Input() routine: ProjectViewRoutine;

  @Output() routineClick = new EventEmitter();
}
