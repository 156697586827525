<div class="header">Task requirements</div>

<!-- ----------------TASK HAS NOT STARTED---------------- -->
@if (taskStatus === taskStatuses.Unclaimed) {
  <div class="header-message">
    <span>You are missing the following requirements to <span class="bold">start</span> this task:</span>
  </div>

  @if (!auth.skillRequirementsMet) {
    <strong>Skills</strong>
    <p>You do not have the required skills to start this task.</p>
  }

  @if (!auth.teamRequirementMet) {
    <strong>Team</strong>
    <p>You do not have the team requirements to start this task.</p>
  }

  @if (!auth.timeRequirementMet) {
    <strong>Schedule</strong>
    <p>This task is not ready to start yet.</p>
  }

  @if (!auth.userRequirementMet) {
    <strong>User</strong>
    <p>You are not assigned to this task or it was started by someone else.</p>
  }
}

<!-- --------------------TASK IN PROGRESS---------------- -->
@if (taskStatus === taskStatuses.InProgress) {
  <div class="header-message">
    <span>You are missing the following requirements to <span class="bold">complete</span> this task:</span>
  </div>

  @if (taskActions[taskStatuses.Completed]?.disabled) {
    <strong>Lens setting</strong>
    <p>{{ taskActions[taskStatuses.Completed]?.message || 'You are missing the required Lens setting.' }}</p>
  }
}

<div class="button-container">
  <oph-button color="green" (buttonClick)="onOk()">OK</oph-button>
</div>
