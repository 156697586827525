@if (routine) {
  <div class="main-container" (click)="routineClick.emit()">
    <div class="left-container">
      <project-view-icon-container icon="repeat-green"></project-view-icon-container>
      <span>{{ routine?.task?.label }}</span>
    </div>

    <div class="status-container">
      @for (statusItem of routine?.taskStatusArray; track statusItem) {
        @if (statusItem?.status) {
          <div class="status" [style.background]="statusItem.status | projectViewRoutineStatusColor"></div>
        }
      }
    </div>
  </div>
}
