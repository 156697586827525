<button (click)="routineClick.emit(routine._id)">
  <div class="name-container">
    <current-sked-checkbox
      [checked]="routine.status === taskStatuses.Completed"
      [loading]="loading"
      (checkboxClick)="onCheckbox($event)"
    ></current-sked-checkbox>
    <span>{{ routine.startDateTime | convertUtcToTimezone: 'dddd, MMM D YYYY' }}</span>
  </div>

  <div class="right-container">
    <span class="updated-date">{{ updatedAt | convertUtcToTimezone: 'DD MMM YY' }}</span>
    <div
      class="status"
      [ngStyle]="{
        background: statusBackgroundColor,
        border: '1px solid ' + statusBorderColor,
        color: statusBorderColor
      }"
    >
      {{ statusDisplay }}
    </div>
    <project-task-user [user]="usersMap[routine.ownerId] || null" [action]="action"></project-task-user>
  </div>
</button>
