import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {UserApiService} from '../../api/user-api.service';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {
  CreateNewUserAction,
  CreateNewUserSuccessAction,
  DeleteUserAction,
  GetAllUsersAction,
  GetAllUsersSuccessAction,
  UpdateUserAction,
  UsersActionType,
} from './users.action';

@Injectable()
export class UsersEffects {
  public getAll$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAllUsersAction>(UsersActionType.GET_ALL),
      mergeMap(action => {
        const {onSuccess, onFailure} = action.payload;

        return this.userApiService.getAll().pipe(
          mergeMap(users => [new GetAllUsersSuccessAction({users}), ...createCallbackActions(onSuccess, users)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public createNew$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateNewUserAction>(UsersActionType.CREATE_NEW),
      mergeMap(action => {
        const {user, onSuccess, onFailure} = action.payload;

        return this.userApiService.createNewUser(user).pipe(
          mergeMap(user => [new CreateNewUserSuccessAction(), ...createCallbackActions(onSuccess, user)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public update$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateUserAction>(UsersActionType.UPDATE),
      mergeMap(action => {
        const {user, onSuccess, onFailure} = action.payload;

        return this.userApiService.updateUser(user).pipe(
          mergeMap(user => [new CreateNewUserSuccessAction(), ...createCallbackActions(onSuccess, user)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public delete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteUserAction>(UsersActionType.DELETE),
      mergeMap(action => {
        const {userId, onSuccess, onFailure} = action.payload;

        return this.userApiService.deleteUser(userId).pipe(
          mergeMap(user => [new CreateNewUserSuccessAction(), ...createCallbackActions(onSuccess, user)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private userApiService: UserApiService
  ) {}
}
