import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {FormArray} from '@angular/forms';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Subtask} from 'src/app/core/model/subtask';
import {TasksService} from 'src/app/shared/services/tasks.service';

@Component({
  selector: 'twd-subtasks',
  templateUrl: './twd-subtasks.component.html',
  styleUrls: ['./twd-subtasks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwdSubtasksComponent implements OnChanges, OnInit {
  @Input() formArray: FormArray;

  @Output() editSubtaskChange = new EventEmitter<{index: number; edit: boolean; subtask?: Subtask}>();

  existingSubtasks$ = new BehaviorSubject<boolean>(false);
  subscription: Subscription;

  constructor(private taskService: TasksService) {}

  ngOnInit() {
    this.subscription = this.formArray.valueChanges.subscribe(subtasks => {
      this.existingSubtasks$.next(subtasks?.length > 0);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.formArray && this.formArray) {
      this.existingSubtasks$.next(this.formArray.value?.length > 0);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onAddSubtask() {
    this.formArray.push(this.taskService.getSubtaskFormGroup());
    this.editSubtaskChange.next({index: this.formArray.length - 1, edit: true});
  }

  onEditSubtask(index: number) {
    this.editSubtaskChange.next({index, edit: true, subtask: this.formArray.value[index]});
  }
}
