import {Lens} from 'src/app/lenses/shared/model/lens';
import {LensSetting} from 'src/app/lenses/shared/model/lens-setting';

export interface LensesState {
  lenses: Lens[];
  lens: Lens;
  userLens: Lens;
  userLensSettingsMap: Record<string, LensSetting>;
  settings: LensSetting[];
  settingsMap: Record<string, LensSetting>;
}

export const initialLensesState: LensesState = {
  lenses: null,
  lens: null,
  userLens: null,
  userLensSettingsMap: {},
  settings: null,
  settingsMap: null,
};
