<h1>Privacy Policy</h1>

<h4>DIRECT DATA COLLECTION</h4>
<p>
  Personal data is only stored when voluntarily given by you for a determined purpose e.g. in context of a registration,
  a survey, a contest, or in performance of a contract. Your individual, identifiable, personal data will not be
  disclosed to third parties, without your consent.
</p>

<h4>PHONE NUMBERS</h4>
<p>
  Any phone numbers collected through our site, whether through webform or other means, will be used exclusively to
  communicate with you regarding our services and will never be shared or sold to third parties for promotional or
  marketing purposes.
</p>

<h4>TELEPHONE COMMUNICATIONS WITH YOU</h4>
<p>
  You agree that our company and its agents may call or text you at any phone number (landline or wireless) that you
  provide to us, using an automated dialing system and/or a prerecorded message, for marketing services and/or
  account-related purposes, such as appointment confirmations, service alerts, billing and collection issues or account
  recovery concerns. You can manage your contact preferences by emailing us, calling us, or replying to text messages
  with “HELP” for assistance or “STOP” to unsubscribe. Reply “START ” to resubscribe. Message and data rates may apply.
</p>

<div class="button-container">
  <oph-button color="green" (click)="onOk()">OK</oph-button>
</div>
