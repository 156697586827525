export enum CoinTypes {
  Golden = 'Golden Coins',
  Sapphire = 'Sapphire Coins',
  Amber = 'Amber Coins',
  Quartz = 'Quartz Coins',
  Emerald = 'Emerald Coins',
  Amethyst = 'Amethyst Coins',
}

export interface RewardsTabOption {
  pageTitle: string;
  tabName: string;
  url: string;
}

export const REWARDS_USER_OPTIONS: RewardsTabOption[] = [
  {
    pageTitle: 'My Rewards',
    tabName: 'My Rewards',
    url: 'my-rewards',
  },
  {
    pageTitle: 'Reward History',
    tabName: 'History',
    url: 'history',
  },
];

export const REWARDS_ADMIN_OPTIONS: RewardsTabOption[] = [
  {
    pageTitle: 'Manage Rewards',
    tabName: 'Manage',
    url: 'manage',
  },

  ...REWARDS_USER_OPTIONS,
];

export const MANAGE_REWARDS_DATA = {
  pageTitle: 'Manage Rewards',
  tabName: 'Manage',
  url: 'manage',
};

export const MY_REWARDS_DATA = {
  pageTitle: 'My Rewards',
  tabName: 'My Rewards',
  url: 'my-rewards',
};

export const REWARDS_HISTORY_DATA = {
  pageTitle: 'Reward History',
  tabName: 'History',
  url: 'history',
};

export const rewardsDialogConfig = {width: '100%', maxWidth: '720px', height: '100%', maxHeight: '702px'};

export const DEFAULT_MY_REWARDS_EXPANDED_MAP: Record<string, boolean> = {
  'Golden Coins': true,
  'Sapphire Coins': true,
  'Amber Coins': true,
  'Quartz Coins': true,
  'Emerald Coins': true,
  'Amethyst Coins': true,
};

export const REWARD_COST_MIN = 0;
export const REWARD_COST_MAX = 1_000_000_000_000; // if changed, error message below needs to change
export const REWARD_COST_ERROR = `min: ${REWARD_COST_MIN}, max: 1 trillion`;
export const REWARD_LIMIT_MIN = 1;
export const REWARD_LIMIT_MAX = 100;
export const REWARD_LIMIT_ERROR = `min: ${REWARD_LIMIT_MIN}, max: ${REWARD_LIMIT_MAX}`;
