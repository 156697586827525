import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'twd-finish',
  templateUrl: './twd-finish.component.html',
  styleUrls: ['./twd-finish.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwdFinishComponent {
  @Input() fromCurrentSked: boolean;

  @Output() allDone = new EventEmitter();
  @Output() createAnotherTask = new EventEmitter();
}
