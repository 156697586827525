<img class="fullsked-logo" src="assets/img/fullsked-logo.svg" />

@if ({passwordInputType: passwordInputType$ | async, view: view$ | async}; as state) {
  <form [formGroup]="loginForm">
    @if (!state.view) {
      <h2>Sign in</h2>
      <input
        formControlName="email"
        placeholder="email"
        class="mb-20"
        (keydown.enter)="handleKeyDown($event, state.view)"
      />
      <div class="password-input-container mb-20">
        <input
          formControlName="password"
          placeholder="password"
          [type]="state.passwordInputType"
          class="password-input"
          (keydown.enter)="handleKeyDown($event, state.view)"
        />
        <button class="show-password-button">
          <oph-icon
            [name]="state.passwordInputType === 'text' ? 'show-password' : 'hide-password'"
            class="show-password-icon"
            (click)="onTogglePassword($event)"
            matSuffix
          ></oph-icon>
        </button>
      </div>
      <div class="button-container">
        <oph-button appearance="text" color="orange" (buttonClick)="changeView('forgotPassword')"
          >Forgot password?</oph-button
        >
        <oph-button color="green" size="large" (click)="onGo(state.view)" [loading]="loading">Go</oph-button>
      </div>
    }
    @if (state.view === 'forgotPassword') {
      <h3>Request a password reset.</h3>
      <p>We'll send you an email with a password reset link.</p>
      <input
        formControlName="requestEmail"
        placeholder="email"
        class="mb-20"
        (keydown.enter)="handleKeyDown($event, state.view)"
      />
      <div class="button-container">
        <oph-button appearance="text" color="orange" (click)="changeView(null)">I remember now.</oph-button>
        <oph-button color="green" size="large" (click)="onRequestPasswordReset(state.view)" [loading]="loading"
          >Go</oph-button
        >
      </div>
    }
    @if (state.view === 'emailSent') {
      <h3 class="email-sent-message">
        {{ emailSentMessage || 'If an account with that email exists, a password reset link has been sent.' }}
      </h3>
      <div class="button-container center email-sent">
        <oph-button color="green" size="large" (click)="changeView(null)">OK</oph-button>
      </div>
    }
    @if (errorMsg) {
      <div class="error-msg">
        <small data-test="login-form-error">{{ errorMsg }}</small>
      </div>
    }

    <div class="space"></div>
    <div class="privacy-policy-container">
      <div class="top-container">
        <mat-checkbox></mat-checkbox>
        <span>I agree to receive text messages for this session.</span>
      </div>
      <button class="privacy-button" (click)="onPrivacyPolicy()">Privacy policy</button>
    </div>
  </form>
}
