import {createSelector} from '@ngrx/store';
import {AppState} from '../app-state';
import {User} from '../../model/user';

export const selectUsersState = (state: AppState) => state.users;

export const selectAllUsers = createSelector(selectUsersState, state => state.users);

export const selectUsersMap = createSelector(
  selectAllUsers,
  users => users?.reduce((usersMap: Record<string, User>, obj: User) => ((usersMap[obj._id] = obj), usersMap), {})
);

export const selectUserById = (id: string) => createSelector(selectUsersMap, usersMap => usersMap[id]);
