import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {catchError, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {SchedulerService} from '../../../services/scheduler.service';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {
  CreateSkedTemplateAction,
  CreateWeekAction,
  CreateWeekSuccessAction,
  DuplicateWeekAction,
  FetchCurrentSeasonAction,
  FetchCurrentSeasonSuccessAction,
  FetchCurrentWeekAction,
  FetchSkedTemplatesAction,
  FetchSkedTemplatesSuccessAction,
  FetchWeeksAction,
  GetTeamScheduleAction,
  GetTeamScheduleSuccessAction,
  RemoveSkedFromWeekAction,
  RemoveSkedTemplateAction,
  RemoveWeekAction,
  SaveCurrentWeekAction,
  SaveCurrentWeekSuccessAction,
  SchedulerActionType,
  UpdateCurrentWeekSkedByIdAction,
  UpdateCurrentWeekSkedsAction,
  UpdateCurrentWeekSkedsSuccessAction,
  UpdateSkedTemplateAction,
  UpdateWeekAction,
} from './scheduler.action';
import {selectSchedulerCurrentWeek} from './scheduler.selectors';

@Injectable()
export class SchedulerEffects {
  createSkedTemplate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateSkedTemplateAction>(SchedulerActionType.CREATE_SKED_TEMPLATE),
      mergeMap(action => {
        const {sked, onSuccess} = action.payload;
        return this.schedulerService
          .createSkedTemplate(sked)
          .pipe(mergeMap(() => [...createCallbackActions(onSuccess)]));
      })
    )
  );

  fetchSeason$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchCurrentSeasonAction>(SchedulerActionType.FETCH_CURRENT_SEASON),
      mergeMap(action => {
        return this.schedulerService.fetchSeason().pipe(
          mergeMap(result => {
            const {onSuccess} = action.payload;

            return [
              new FetchCurrentSeasonSuccessAction({season: result[0]}),
              ...createCallbackActions(onSuccess, result[0]),
            ];
          })
        );
      })
    )
  );

  createWeek$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateWeekAction>(SchedulerActionType.CREATE_WEEK),
      mergeMap(action => {
        const {onSuccess, startDate, teamSkeds} = action.payload;
        return this.schedulerService
          .createWeek({label: '', startDate}, teamSkeds)
          .pipe(
            mergeMap(result => [
              ...createCallbackActions(onSuccess, result),
              new CreateWeekSuccessAction({week: result}),
            ])
          );
      })
    )
  );

  fetchWeeks$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchWeeksAction>(SchedulerActionType.FETCH_WEEKS),
      mergeMap(action => {
        const {filter, onSuccess} = action.payload;
        return this.schedulerService
          .fetchWeeks({startDate: filter.startDate, numberOfWeeks: filter.numberOfWeeks, teamSkeds: filter.teamSkeds})
          .pipe(
            map(weeks => [
              ...weeks.map(w => ({
                ...w,
                skeds: w.skeds.sort((a, b) => {
                  if (a.startDateTime > b.startDateTime) {
                    return 1;
                  }
                  if (a.startDateTime < b.startDateTime) {
                    return -1;
                  }
                  return 0;
                }),
              })),
            ]),
            mergeMap(result => [...createCallbackActions(onSuccess, result)])
          );
      })
    )
  );

  fetchCurrentWeek$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchCurrentWeekAction>(SchedulerActionType.FETCH_CURRENT_WEEK),
      mergeMap(action => {
        return this.schedulerService.fetchWeek(action.payload.weekId).pipe(
          mergeMap(result => {
            const week = result[0];

            if (week) {
              return [
                ...createCallbackActions(action.payload.onSuccess || (() => null), week),
                new CreateWeekSuccessAction({week}),
              ];
            }

            return [...createCallbackActions(action.payload.onError)];
          })
        );
      })
    )
  );

  fetchSkedTemplates$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchSkedTemplatesAction>(SchedulerActionType.FETCH_SKED_TEMPLATES),
      mergeMap(action => {
        return this.schedulerService
          .fetchSkedTemplates(action.payload.search)
          .pipe(
            mergeMap(result => [
              new FetchSkedTemplatesSuccessAction(result),
              ...createCallbackActions(action.payload.onSuccess),
            ])
          );
      })
    )
  );

  updateWeek$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateWeekAction>(SchedulerActionType.UPDATE_WEEK),
      mergeMap(action => {
        const {onSuccess, week} = action.payload;
        return this.schedulerService
          .updateWeek(week)
          .pipe(mergeMap(result => [...createCallbackActions(onSuccess, result)]));
      })
    )
  );

  duplicateWeek$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DuplicateWeekAction>(SchedulerActionType.DUPLICATE_WEEK),
      mergeMap(action => {
        const {onSuccess, weekId} = action.payload;
        return this.schedulerService
          .duplicateWeek(weekId)
          .pipe(mergeMap(result => [...createCallbackActions(onSuccess, result)]));
      })
    )
  );

  removeWeek$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<RemoveWeekAction>(SchedulerActionType.REMOVE_WEEK),
      mergeMap(action => {
        const {id, onSuccess} = action.payload;
        return this.schedulerService.removeWeek(id).pipe(mergeMap(() => [...createCallbackActions(onSuccess)]));
      })
    )
  );

  saveCurrentWeek$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveCurrentWeekAction>(SchedulerActionType.SAVE_CURRENT_WEEK),
      withLatestFrom(this.store$.select(selectSchedulerCurrentWeek)),
      mergeMap(([action, week]) => {
        return this.schedulerService
          .updateWeek(week)
          .pipe(
            mergeMap(res => [new SaveCurrentWeekSuccessAction(res), ...createCallbackActions(action.payload.onSuccess)])
          );
      })
    )
  );

  updateCurrentWeekSkeds$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateCurrentWeekSkedsAction>(SchedulerActionType.UPDATE_CURRENT_WEEK_SKEDS),
      withLatestFrom(this.store$.select(selectSchedulerCurrentWeek)),
      mergeMap(([action, week]) => [
        new UpdateCurrentWeekSkedsSuccessAction({
          ...week,
          skeds: action.payload.skeds,
        }),
        ...createCallbackActions(action.payload.onSuccess),
      ])
    )
  );

  removeSkedFromWeek$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<RemoveSkedFromWeekAction>(SchedulerActionType.REMOVE_SKED_FROM_WEEK),
      withLatestFrom(this.store$.select(selectSchedulerCurrentWeek)),
      mergeMap(([action, week]) => [
        new UpdateCurrentWeekSkedsSuccessAction({
          ...week,
          skeds: week.skeds.filter(s => s._id !== action.payload.skedId),
        }),
        ...createCallbackActions(action.payload.onSuccess),
      ])
    )
  );

  removeSkedTemplate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<RemoveSkedTemplateAction>(SchedulerActionType.REMOVE_SKED_TEMPLATE),
      mergeMap(action => {
        return this.schedulerService
          .deleteSkedTemplate(action.payload.id)
          .pipe(mergeMap(() => [...createCallbackActions(action.payload.onSuccess)]));
      })
    )
  );

  updateSkedTemplate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateSkedTemplateAction>(SchedulerActionType.UPDATE_SKED_TEMPLATE),
      mergeMap(action => {
        return this.schedulerService
          .updateSkedTemplate(action.payload.sked)
          .pipe(mergeMap(() => [...createCallbackActions(action.payload.onSuccess)]));
      })
    )
  );

  updateCurrentWeekSkedById$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateCurrentWeekSkedByIdAction>(SchedulerActionType.UPDATE_CURRENT_WEEK_SKED_BY_ID),
      withLatestFrom(this.store$.select(selectSchedulerCurrentWeek)),
      mergeMap(([action, week]) => [
        new UpdateCurrentWeekSkedsSuccessAction({
          ...week,
          skeds: week.skeds.map(s => {
            if (s._id === action.payload.sked._id) {
              return action.payload.sked;
            }

            return s;
          }),
        }),
        ...createCallbackActions(action.payload.onSuccess),
      ])
    )
  );

  public getTeamSchedule$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetTeamScheduleAction>(SchedulerActionType.GET_TEAM_SCHEDULE),
      mergeMap(action => {
        const {teamId, params, onSuccess, onFailure} = action.payload;

        return this.schedulerService.getTeamSchedule(teamId, params).pipe(
          mergeMap(teamSchedule => [
            new GetTeamScheduleSuccessAction(teamSchedule),
            ...createCallbackActions(onSuccess),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private schedulerService: SchedulerService,
    private store$: Store
  ) {}
}
