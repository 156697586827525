export enum TaskDialogPanels {
  Default = 'default',
  Requirements = 'requirements',
  SubtaskEdit = 'subtaskEdit',
  DeleteTaskConfirmation = 'deleteTaskConfirmation',
  DeleteTemplateConfirmation = 'deleteTemplateConfirmation',
  IconPicker = 'iconPicker',
}

export enum TaskDialogTabs {
  Details = 'details',
  Schedule = 'schedule',
  Triggers = 'triggers',
  Subtasks = 'subtasks',
}

export enum TaskDialogScheduleTabs {
  Task = 'task',
  Event = 'event',
}

export const TASK_DIALOG_SETTINGS = {
  maxHeight: '90vh',
  height: '730px',
  panelClass: 'task-dialog',
  disableClose: true,
};
