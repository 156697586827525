import {createSelector} from '@ngrx/store';
import {AppState} from '../app-state';
import {Program} from 'src/app/programs/shared/model/program';

export const selectProgramsState = (state: AppState) => state.programs;

export const selectAllPrograms = createSelector(selectProgramsState, state => state.programs);

export const selectProgramsMap = createSelector(selectAllPrograms, programs =>
  programs.reduce(
    (programsMap: Record<string, Program>, obj: Program) => ((programsMap[obj.id] = obj), programsMap),
    {}
  )
);
