import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {convertProgramDtoToModel} from '../../../programs/shared/converters/convert-program-dto-to-model';
import {ProgramDto} from '../../../programs/shared/dto/program.dto';
import {ProgramsApiService} from '../../api/programs-api.service';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {
  CreateProgramAction,
  CreateProgramSuccessAction,
  DeleteProgramAction,
  DeleteProgramSuccessAction,
  GetProgramsAction,
  GetProgramsSuccessAction,
  ProgramsActionType,
  UpdateProgramAction,
  UpdateProgramSuccessAction,
} from './programs.action';

@Injectable()
export class ProgramsEffects {
  public get$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetProgramsAction>(ProgramsActionType.GET),
      mergeMap(action => {
        const {onSuccess, onFailure} = action.payload;

        return this.programsApiService.get().pipe(
          map((dtos: ProgramDto[]) => dtos.map(dto => convertProgramDtoToModel(dto))),
          mergeMap(programs => [new GetProgramsSuccessAction({programs}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public create$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateProgramAction>(ProgramsActionType.CREATE),
      mergeMap(action => {
        const {program, onSuccess, onFailure} = action.payload;

        return this.programsApiService.create(program).pipe(
          mergeMap(p => [new CreateProgramSuccessAction({program: p}), ...createCallbackActions(onSuccess, p)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public update$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateProgramAction>(ProgramsActionType.UPDATE),
      mergeMap(action => {
        const {program, onSuccess, onFailure} = action.payload;

        return this.programsApiService.update(program).pipe(
          mergeMap(p => [new UpdateProgramSuccessAction({program: p}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public delete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteProgramAction>(ProgramsActionType.DELETE),
      mergeMap(action => {
        const {id, onSuccess, onFailure} = action.payload;

        return this.programsApiService.delete(id).pipe(
          mergeMap(programId => [new DeleteProgramSuccessAction(), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private programsApiService: ProgramsApiService // private store$: Store<{}>
  ) {}
}
