@if (
  {
    user: user$ | async,
    sked: sked$ | async,
    task: task$ | async,
    projectsMap: projectsMap$ | async,
    locationsMap: locationsMap$ | async,
    combinedLensSettingsMap: combinedLensSettingsMap$ | async,
    ableToCompleteSubtaskIndex: ableToCompleteSubtaskIndex$ | async,
    skedTimeStatus: skedTimeStatus$ | async,
    availableTaskActions: availableTaskActions$ | async,
    loading: loading$ | async,
    selectedSubtask: selectedSubtask$ | async,
    selectedSubtaskIndex: selectedSubtaskIndex$ | async
  };
  as state
) {
  @if (state.loading.task) {
    <div class="loading-container">
      <oph-loading></oph-loading>
    </div>
  } @else {
    @if (!state.selectedSubtask) {
      <!-- ****************************************************************************** -->
      <!-- ****************************** TASK VIEW ************************************* -->
      <!-- ****************************************************************************** -->

      <!-- --------------------------------HEADER------------------------------------ -->
      <header>
        <div class="left-container">
          <button class="back-button" (click)="onBack()">
            <mat-icon svgIcon="chevron-left"></mat-icon>
            <span>{{ state.task.label || '' }}</span>
          </button>
        </div>

        <current-sked-task-detail-status-button
          [loading]="state.loading"
          [task]="state.task"
          (statusAction)="onUpdateTaskStatus($event)"
          [availableTaskActions]="state.availableTaskActions"
        ></current-sked-task-detail-status-button>
      </header>
      <!-- --------------------------------TASK INFO------------------------------------ -->
      <div class="subtask-list-container">
        <div class="info-container">
          <div class="project text-overflow" [style.background]="state.projectsMap[state.task.projectId]?.color">
            <span>{{ state.projectsMap[state.task.projectId]?.label }}</span>
          </div>
          @if (state.task.location.length) {
            <div class="info-container-item location text-overflow">
              <oph-icon name="pin-brown" size="18" class="location-icon"></oph-icon>
              <span class="info-value">{{ (state.locationsMap || {})[state.task.location]?.name }}</span>
            </div>
          }
          @if (state.task.duration) {
            <div class="info-container-item duration">
              <oph-icon name="clock-2-brown" size="19" class="location-icon"></oph-icon>
              <span class="info-value">{{ state.task.duration | minutesToHoursAndMinutes }}</span>
            </div>
          }
          @if (state.task.taskCoinValue && state.task.taskCoinValue > 0 && state.task.taskCoinType) {
            <div class="coin-container">
              <mat-icon [svgIcon]="(coinTypesMap$ | async)[state.task.taskCoinType]?.name | rewardCoinIcon"></mat-icon>
              {{ state.task.taskCoinValue }}
            </div>
          }
        </div>
        <!-- --------------------------------DESCRIPTION------------------------------------ -->
        @if (state.task.description) {
          <mat-expansion-panel hideToggle (opened)="descriptionOpen = true" (closed)="descriptionOpen = false">
            <mat-expansion-panel-header>
              <mat-panel-title>Description</mat-panel-title>
              <img [src]="'assets/img/icons/eye' + (!descriptionOpen ? '-off' : '') + '-brown.svg'" />
            </mat-expansion-panel-header>
            <p>{{ state.task.description }}</p>
          </mat-expansion-panel>
        }

        <!-- --------------------------------SUBTASK LIST------------------------------------ -->
        <div class="subtask-container">
          @for (subtask of state.task.subtasks; track subtask; let i = $index) {
            <current-sked-task-detail-subtask
              [task]="state.task"
              [subtask]="subtask"
              [subtaskIndex]="i"
              [expandedSubtaskIndex]="expandedSubtaskIndex$ | async"
              [ableToCompleteSubtaskIndex]="state.ableToCompleteSubtaskIndex"
              [loading]="state.loading"
              (expandedIndexChange)="onExpandedSubtaskIndexChange(i)"
              (subtaskClick)="onSubtask(subtask, i)"
              (updateTaskStatus)="onUpdateTaskStatus($event)"
              (updateSubtaskStatus)="onUpdateSubtaskStatus($event, subtask, i)"
              (updateMetricValue)="onUpdateMetricValue($event, subtask, i, state.task._id)"
            ></current-sked-task-detail-subtask>
          }
        </div>
      </div>
    } @else {
      <!-- ****************************************************************************** -->
      <!-- ****************************** SUBTASK VIEW ************************************* -->
      <!-- ****************************************************************************** -->

      <!-- --------------------------------HEADER------------------------------------ -->
      <header class="subtask-view-header">
        <oph-back-button (buttonClick)="onSubtaskBack()"></oph-back-button>
        <div class="subtask-view-header-inner-container">
          <current-sked-checkbox
            [checked]="state.selectedSubtask.complete"
            [loading]="state.loading.subtaskIndex"
            (checkboxClick)="onUpdateSubtaskStatus($event, state.selectedSubtask, 1)"
          ></current-sked-checkbox>
          <span class="subtask-view-name">{{ state.selectedSubtask.title }} </span>
        </div>
      </header>

      <div class="subtask-view-content-container">
        <div class="right">
          <div class="subtask-view-container info">
            @if (state.task?.projectId && state.projectsMap[state.task.projectId]) {
              <div
                class="project-detail"
                [style.background]="state.projectsMap[state.task.projectId].color || 'transparent'"
              >
                <span>{{ state.projectsMap[state.task.projectId].label || 'Unknown' }}</span>
              </div>
            }
            @if (state.task?.location && state.locationsMap[state.task.location]) {
              <div class="location">
                <img src="assets/img/icons/pin-brown.svg" alt="location" />
                <span>{{ state.locationsMap[state.task.location]?.name }}</span>
              </div>
            }
          </div>
          @if (state.selectedSubtask.metricId) {
            <div class="subtask-view-container update-tracker-container">
              <current-sked-task-detail-subtask-metric-update
                [loading]="state.loading"
                [subtask]="state.selectedSubtask"
                [subtaskIndex]="state.selectedSubtaskIndex"
                [ableToCompleteSubtaskIndex]="state.ableToCompleteSubtaskIndex"
                (updateMetricValue)="
                  onUpdateMetricValue($event, state.selectedSubtask, state.selectedSubtaskIndex, state.task._id)
                "
              ></current-sked-task-detail-subtask-metric-update>
            </div>
          }
        </div>

        <div class="subtask-view-container left current-sked-quill">
          @if (state.selectedSubtask.body) {
            <quill-view-html [content]="state.selectedSubtask.body" theme="snow"></quill-view-html>
          } @else {
            <div class="empty">This subtask has no instructions</div>
          }
        </div>
      </div>
    }
  }
}
