import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule, MatMenuTrigger} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {OphIconModule} from 'src/app/shared/design/oph-icon/oph-icon.module';

@Component({
  selector: 'tracker-detail-metric-select',
  standalone: true,
  imports: [MatMenuModule, OphIconModule, CommonModule, MatProgressSpinnerModule, MatIconModule],
  templateUrl: './tracker-detail-metric-select.component.html',
  styleUrl: './tracker-detail-metric-select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackerDetailMetricSelectComponent {
  @Input() buttonText: string;
  @Input() options: string[];
  @Input() buttonStyle: Object;
  @Input() disabled: boolean;
  @Input() loadingStatus: string; // loading, success, null

  @Output() optionChange = new EventEmitter<string>();

  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

  menuOpen: boolean = false;

  onMenu(open: boolean) {
    this.menuOpen = open;
  }
}
