import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ProjectViewMilestone} from 'src/app/core/model/project';

@Component({
  selector: 'project-view-milestone',
  standalone: true,
  imports: [],
  templateUrl: './project-view-milestone.component.html',
  styleUrl: './project-view-milestone.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectViewMilestoneComponent {
  @Input() milestone: ProjectViewMilestone;
}
