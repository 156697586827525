import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'project-view-icon-container',
  standalone: true,
  imports: [],
  templateUrl: './project-view-icon-container.component.html',
  styleUrl: './project-view-icon-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectViewIconContainerComponent {
  @Input() icon: string;
}
