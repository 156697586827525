import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {MENU_ITEMS, MenuItem} from 'src/app/app.constants';
import {User} from 'src/app/core/model/user';

@Component({
  selector: 'mobile-navbar',
  templateUrl: './mobile-navbar.component.html',
  styleUrls: ['./mobile-navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileNavbarComponent implements OnChanges {
  @Input() user: User;
  @Input() routerUrl: string;

  @Output() mobileNavItemClick = new EventEmitter();
  @Output() logout = new EventEmitter();

  selectedIndex$ = new BehaviorSubject<number>(null);

  menuItems: MenuItem[] = MENU_ITEMS;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.routerUrl && this.routerUrl) {
      const index = this.menuItems.findIndex(item => this.routerUrl.startsWith(item.url));
      this.selectedIndex$.next(index);
    }
  }
}
