import {TimezoneService} from './../../services/timezone.service';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'convertUtcToTimezone',
})
export class ConvertUtcToTimezonePipe implements PipeTransform {
  constructor(private timezoneService: TimezoneService) {}

  transform(value: string, format?: string): string {
    const date = this.timezoneService.convertUtcToTimezone(value, format || '');
    if (date === 'Invalid date') {
      return '';
    }
    return this.timezoneService.convertUtcToTimezone(value, format || '');
  }
}
