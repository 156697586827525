import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Store, select} from '@ngrx/store';
import {Observable} from 'rxjs';
import {selectAllLocations, selectLocationsMap} from 'src/app/core/store/locations/locations.selector';
import {Location} from 'src/app/locations/shared/model/location';

@Component({
  selector: 'task-location-selector',
  templateUrl: './task-location-selector.component.html',
  styleUrls: ['./task-location-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskLocationSelectorComponent implements OnInit {
  @Input() control: FormControl;
  @Input() lightBackground: boolean;

  locations$: Observable<Location[]>;
  locationsMap$: Observable<Record<string, Location>>;

  constructor(private store$: Store) {}

  ngOnInit() {
    this.locations$ = this.store$.pipe(select(selectAllLocations));
    this.locationsMap$ = this.store$.pipe(select(selectLocationsMap));
  }

  onLocation(id: string) {
    this.control.setValue(id);
  }
}
