@if (
  {selectedPanel: selectedPanel$ | async, selectedTab: selectedTab$ | async, triggerCount: triggerCount$ | async};
  as state
) {
  <form [formGroup]="form">
    @if (state.selectedPanel === TaskDialogPanels.Default) {
      <task-dialog-header
        class="header-margin"
        [form]="form"
        (panelChange)="onPanelChange($event)"
      ></task-dialog-header>
      <div class="body">
        <task-dialog-tabs
          [selectedTab]="state.selectedTab"
          [triggerCount]="state.triggerCount"
          [subtaskCount]="subtaskFormArray?.length"
          [template]="template"
          (tabChange)="onTabChange($event)"
        ></task-dialog-tabs>
        @if (state.selectedTab === TaskDialogTabs.Details) {
          <task-dialog-details-panel
            [form]="form"
            [template]="template"
            (panelChange)="onPanelChange(TaskDialogPanels.Requirements)"
          ></task-dialog-details-panel>
        }
        @if (state.selectedTab === TaskDialogTabs.Schedule) {
          <task-dialog-schedule-panel
            [form]="form"
            [validationErrors]="validationErrors$ | async"
            [triggerCount]="state.triggerCount"
          ></task-dialog-schedule-panel>
        }
        @if (state.selectedTab === TaskDialogTabs.Triggers) {
          <task-dialog-triggers-tab
            [form]="form"
            [validationErrors]="validationErrors$ | async"
          ></task-dialog-triggers-tab>
        }
        @if (state.selectedTab === TaskDialogTabs.Subtasks) {
          <task-dialog-subtasks-panel
            [formArray]="subtaskFormArray"
            (panelChange)="onPanelChange($event)"
          ></task-dialog-subtasks-panel>
        }
      </div>
      <task-dialog-footer
        (save)="onSave()"
        (cancel)="onCancel()"
        (delete)="onDelete()"
        (duplicate)="onSave(true)"
        (createTemplate)="onSaveTemplate(true)"
        [loading]="loadingSave$ | async"
        [hideMenu]="!task"
        [template]="template"
      ></task-dialog-footer>
    }
    <!-- ---------------FULLSCREEN PANELS------------------ -->
    @if (state.selectedPanel === TaskDialogPanels.Requirements) {
      <task-dialog-requirements
        [form]="form"
        (back)="onPanelChange(TaskDialogPanels.Default)"
      ></task-dialog-requirements>
    }
    @if (state.selectedPanel === TaskDialogPanels.SubtaskEdit) {
      <task-dialog-subtask-edit
        [formArray]="subtaskFormArray"
        [fromTemplate]="template"
        (panelChange)="onPanelChange($event)"
        (imagesToDeleteChange)="onImagesToDeleteChange($event)"
      ></task-dialog-subtask-edit>
    }
    @if (state.selectedPanel === TaskDialogPanels.DeleteTaskConfirmation) {
      <task-dialog-confirmation
        text="Are you sure you want to remove this task from the current project?"
        (yes)="onConfirmRemoveTask()"
        (no)="onConfirmationCancel()"
      >
      </task-dialog-confirmation>
    }
    @if (state.selectedPanel === TaskDialogPanels.DeleteTemplateConfirmation) {
      <task-dialog-confirmation
        text="Are you sure you want to delete this task template?"
        (yes)="onConfirmDeleteTemplate()"
        (no)="onConfirmationCancel()"
      >
      </task-dialog-confirmation>
    }
    @if (state.selectedPanel === TaskDialogPanels.IconPicker) {
      <task-dialog-icon-picker
        [iconControl]="iconControl"
        (panelChange)="onPanelChange($event)"
      ></task-dialog-icon-picker>
    }
  </form>
}
