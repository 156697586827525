import {ChangeDetectionStrategy, Component} from '@angular/core';
import {OphButtonModule} from '../../design/oph-button/oph-button.module';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'privacy-policy-dialog',
  standalone: true,
  imports: [OphButtonModule],
  templateUrl: './privacy-policy-dialog.component.html',
  styleUrl: './privacy-policy-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivacyPolicyDialogComponent {
  constructor(private dialogRef: MatDialogRef<PrivacyPolicyDialogComponent>) {}

  onOk() {
    this.dialogRef.close();
  }
}
