import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ReportingObjectsParamsDto} from 'src/app/reporting/objects/shared/dto/reporting-objects-params.dto';
import {environment} from '../../../environments/environment';
import {ReportingObjectEntryDto} from '../../reporting/objects/shared/dto/reporting-object-entry.dto';

@Injectable({
  providedIn: 'root',
})
export class ReportingApiService {
  public url = `${environment.apiUrl}/logs`;

  constructor(private http: HttpClient) {}

  public getReportingRecentAttributeUpdates(params: ReportingObjectsParamsDto): Observable<ReportingObjectEntryDto[]> {
    return this.http.get<ReportingObjectEntryDto[]>(`${this.url}/objects`, {
      params: JSON.parse(JSON.stringify(params)),
    });
  }

  public getReportingObjectParent(
    parentId: string,
    params: ReportingObjectsParamsDto
  ): Observable<ReportingObjectEntryDto[]> {
    return this.http.get<ReportingObjectEntryDto[]>(`${this.url}/objects/model/${parentId}`, {
      params: JSON.parse(JSON.stringify(params)),
    });
  }

  public getReportingObjectInstance(
    instanceId: string,
    params: ReportingObjectsParamsDto
  ): Observable<ReportingObjectEntryDto[]> {
    return this.http.get<ReportingObjectEntryDto[]>(`${this.url}/objects/${instanceId}`, {
      params: JSON.parse(JSON.stringify(params)),
    });
  }

  public getReportingObjectAttribute(
    instanceId: string,
    attributeId: string,
    params: ReportingObjectsParamsDto
  ): Observable<ReportingObjectEntryDto[]> {
    return this.http.get<ReportingObjectEntryDto[]>(`${this.url}/objects/${instanceId}/${attributeId}`, {
      params: JSON.parse(JSON.stringify(params)),
    });
  }
}
