import {ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {BehaviorSubject, Observable, Subscription, map} from 'rxjs';
import {Project} from 'src/app/core/model/project';
import {Subtask} from 'src/app/core/model/subtask';
import {ProjectTask} from 'src/app/core/model/task';
import {DeleteImageAction} from 'src/app/core/store/active-user/active-user.action';
import {GetProjectsAction, UpdateProjectTasksAction} from 'src/app/core/store/projects/projects.action';
import {GetAllTrackersAction} from 'src/app/core/store/trackers/trackers.action';
import {MessageService} from 'src/app/services/message.service';
import {ProjectsService} from '../../services/projects.service';
import {TasksService} from '../../services/tasks.service';
import {formatSelectedDay} from '../utils/format-selected-day';
import {TaskPanelItem} from './model/task-panel-item';
import {EVENT_PANEL_ARRAY, PANEL_DATA, TASK_PANEL_ARRAY, TaskWizardPanels} from './shared/task-wizard-constants';
import {RewardCoin} from 'src/app/core/model/reward';

@Component({
  selector: 'task-wizard-dialog',
  templateUrl: './task-wizard-dialog.component.html',
  styleUrls: ['./task-wizard-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskWizardDialogComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription();

  activePanel$: Observable<TaskPanelItem>;

  panelArray$ = new BehaviorSubject<TaskWizardPanels[]>(TASK_PANEL_ARRAY);
  activePanelIndex$ = new BehaviorSubject<number>(0);
  activePanelName$ = new BehaviorSubject<TaskWizardPanels>(null);
  confirmation$ = new BehaviorSubject<boolean>(false);
  hideHeaderAndFooter$ = new BehaviorSubject<boolean>(false);
  hideHeaderAndBorder$ = new BehaviorSubject<boolean>(false);
  hideFooter$ = new BehaviorSubject<boolean>(false);
  nextValid$ = new BehaviorSubject<boolean>(true);
  subtaskIndex$ = new BehaviorSubject<number>(null);
  storedSubtask$ = new BehaviorSubject<Subtask>(null);
  loadingSave$ = new BehaviorSubject<boolean>(false);
  triggerCount$ = new BehaviorSubject<number>(0);

  confirmationMessage = 'Are you sure you want to cancel?';
  TaskWizardPanels = TaskWizardPanels;
  form: FormGroup;
  project: Project;
  fromCurrentSked: boolean;
  tempImagesArray: string[] = [];
  imagesToDelete: string[] = [];
  coinTypes: RewardCoin[];

  constructor(
    private dialogRef: MatDialogRef<TaskWizardDialogComponent>,
    private tasksService: TasksService,
    private projectsService: ProjectsService,
    private messageService: MessageService,
    private store$: Store,
    @Inject(MAT_DIALOG_DATA) public data: {project: Project; fromCurrentSked: boolean; coinTypes: RewardCoin[]}
  ) {
    this.project = data.project;
    this.fromCurrentSked = data.fromCurrentSked;
    this.coinTypes = data.coinTypes;
  }

  ngOnInit() {
    this.store$.dispatch(new GetAllTrackersAction({params: {projectId: this.project._id, pageSize: 100}}));

    this.form = this.tasksService.getTaskForm(null, false, this.coinTypes[0]?._id);
    this.activePanel$ = this.observeIndex();
    this.subscriptions.add(this.subscribeToFormChanges());
    this.subscriptions.add(this.subscribeToEvent());
    this.subscriptions.add(this.subscribeToActivePanel());

    this.subscriptions.add(
      this.triggersFormArray.valueChanges.subscribe(triggerArray => {
        const filteredTriggers = triggerArray.filter(trigger => !!trigger.value);
        this.triggerCount$.next(filteredTriggers?.length || 0);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  subscribeToFormChanges(): Subscription {
    return this.form.valueChanges.subscribe(() => this.validateNextButton());
  }

  subscribeToEvent(): Subscription {
    return this.eventControls.valueChanges.subscribe(event => {
      let panelArray;
      let date;
      if (event) {
        panelArray = EVENT_PANEL_ARRAY;
        date = formatSelectedDay(date);
      } else {
        panelArray = TASK_PANEL_ARRAY;
        date = 'asap';
      }
      this.panelArray$.next(panelArray);
      this.scheduleOnDateControl.setValue(date);
    });
  }

  subscribeToActivePanel(): Subscription {
    return this.activePanel$.subscribe(() => this.validateNextButton());
  }

  observeIndex(): Observable<TaskPanelItem> {
    return this.activePanelIndex$.pipe(
      map(index => {
        const panelName = this.panelArray$.value[index];
        this.hideHeaderAndBorder$.next(panelName === TaskWizardPanels.SubtaskEdit);
        this.hideFooter$.next(panelName === TaskWizardPanels.Finish);
        this.activePanelName$.next(panelName);
        return PANEL_DATA[panelName];
      })
    );
  }

  onClose(preventDeleteImages?: boolean) {
    this.dialogRef.close(false);
    this.form.reset();
    if (!preventDeleteImages) {
      this.onDeleteSubtaskImages();
    }
  }

  onCloseManualEntry() {
    this.dialogRef.close(true);
    this.form.reset();
  }

  onCancel() {
    if (this.activePanelIndex$.value === this.panelArray$.value.length - 1) {
      this.onClose();
    }
    if (this.activePanelIndex$.value > 0) {
      this.confirmation$.next(true);
      return;
    }
    this.onClose();
  }

  onNext() {
    if (this.activePanelName$.value === TaskWizardPanels.SubtaskEdit) {
      this.removeImages(false);
      this.activePanelIndex$.next(this.activePanelIndex$.value - 1);
      return;
    }
    if (this.activePanelName$.value === TaskWizardPanels.SubtaskList) {
      this.finish();
      return;
    }
    this.activePanelIndex$.next(this.activePanelIndex$.value + 1);
  }

  onBack() {
    this.removeImages(true);

    if (this.activePanelName$.value === TaskWizardPanels.SubtaskEdit) {
      this.checkSubtaskForm();
    }
    this.activePanelIndex$.next(this.activePanelIndex$.value - 1);
  }

  checkSubtaskForm() {
    const storedSubtask = this.storedSubtask$.value;
    const subtaskIndex = this.subtaskIndex$.value;

    if (storedSubtask) {
      this.subtaskFormArray.at(subtaskIndex).setValue(storedSubtask);
    } else {
      this.subtasksFormArray.removeAt(this.subtasksFormArray.length - 1);
    }
  }

  get subtasksFormArray() {
    return this.form.controls['subtasks'] as FormArray;
  }

  onHideHeaderAndFooter(value: boolean) {
    this.hideHeaderAndFooter$.next(value);
  }

  onHideHeaderAndBorder(value: boolean) {
    this.hideHeaderAndBorder$.next(value);
  }

  onEditSubtaskChange(data: {index: number; edit: boolean; subtask?: Subtask}) {
    this.subtaskIndex$.next(data.index);
    this.storedSubtask$.next(data.subtask); // store form subtask in local variable
    this.tempImagesArray = this.tasksService.getImgArrayFromHTMLString(
      this.subtasksFormArray.at(data.index).value?.body || ''
    );

    if (this.activePanelName$.value === TaskWizardPanels.SubtaskList) {
      if (!data.edit) {
        this.activePanelIndex$.next(this.activePanelIndex$.value + 2);
      } else {
        this.activePanelIndex$.next(this.activePanelIndex$.value + 1);
      }
    }
  }

  validateNextButton() {
    this.nextValid$.next(this.getNextValid());
  }

  getNextValid(): boolean {
    const form = this.form.value;
    switch (this.activePanelName$.value) {
      case TaskWizardPanels.BasicInfo:
        return !!form.label && !!this.form.get('taskCoinValue').valid;
      case TaskWizardPanels.EventTime:
        return (
          form.schedule.eventTimes.eventStart && form.schedule.eventTimes.eventEnd && this.eventTimesControls.valid
        );
      case TaskWizardPanels.TaskRepeat:
      case TaskWizardPanels.EventRepeat:
        return (
          form.schedule.scheduleType === 'doNotRepeat' ||
          form.schedule.repeatType === 'day' ||
          (form.schedule.repeatType === 'week' && !!((form.schedule.repeatDays || []) as string[]).length)
        );
      case TaskWizardPanels.TaskTrigger:
      case TaskWizardPanels.EventTrigger:
        return !form.triggers?.length || this.tasksService.checkIfTriggerIsComplete(form.triggers[0]);
      case TaskWizardPanels.Completion:
        const targetCompletionRepeatData = this.tasksService.getRepeatTimeValidityData(
          this.scheduleControls,
          this.targetCompletionValueTypeControl.value,
          this.targetCompletionValueControl.value
        );
        const expirationRepeatData = this.tasksService.getRepeatTimeValidityData(
          this.scheduleControls,
          this.expirationValueTypeControl.value,
          this.expirationValueControl.value
        );
        return targetCompletionRepeatData.valid && expirationRepeatData.valid;
      case TaskWizardPanels.SubtaskEdit:
        return (
          !!(form.subtasks[this.subtaskIndex$.value] as Subtask)?.title &&
          this.tasksService.checkIfSubtaskIsComplete(form.subtasks)
        );
      default:
        return true;
    }
  }

  finish() {
    this.loadingSave$.next(true);
    const formattedTask = this.tasksService.formatProjectTaskFromForm(this.form);
    this.updateProject(formattedTask);
  }

  updateProject(task: ProjectTask, taskDeleted?: boolean) {
    const projectPartial = this.projectsService.getProjectFields(this.project);
    this.store$.dispatch(
      new UpdateProjectTasksAction({
        id: this.project._id,
        projectPartial,
        task: task || null,
        taskDeleted: taskDeleted || false,
        onSuccess: () => this.onUpdateProjectSuccess(this.project._id),
        onFailure: err => this.onUpdateProjectFailure(err),
      })
    );
  }

  onUpdateProjectSuccess(id: string) {
    this.store$.dispatch(new GetProjectsAction({}));
    this.loadingSave$.next(false);
    this.activePanelIndex$.next(this.activePanelIndex$.value + 2);
    this.onDeleteSubtaskImages();
  }

  onUpdateProjectFailure(err: Error) {
    this.messageService.add(err?.message);
    this.loadingSave$.next(false);
  }

  onCreateAnotherTask() {
    this.form.reset();
    (this.form.get('subtasks') as FormArray).clear();
    (this.form.get('triggers') as FormArray).clear();
    this.activePanelIndex$.next(0);
  }

  onImageAdded(image: string) {
    this.tempImagesArray.push(image);
  }

  removeImages(cancel: boolean) {
    let subtaskBody: string;
    if (cancel) {
      subtaskBody = this.storedSubtask$.value?.body || '';
    } else {
      subtaskBody = this.subtaskFormArray.at(this.subtaskIndex$.value).value?.body || '';
    }
    const currentImageArray = this.tasksService.getImgArrayFromHTMLString(subtaskBody);
    const imagesToDelete = this.tempImagesArray.filter(img => !currentImageArray.includes(img));
    this.imagesToDelete = [...new Set(this.imagesToDelete.concat(imagesToDelete))];
  }

  onDeleteSubtaskImages() {
    if (!this.imagesToDelete?.length) {
      return;
    }
    const idString = this.imagesToDelete.join(',');
    const ids = encodeURIComponent(idString);
    this.store$.dispatch(
      new DeleteImageAction({
        ids,
        onFailure: err => this.messageService.add(err.error),
      })
    );
  }

  get eventControls(): AbstractControl {
    return this.form.get('event');
  }

  get scheduleControls(): AbstractControl {
    return this.form.get('schedule');
  }

  get scheduleOnDateControl(): AbstractControl {
    return this.scheduleControls.get('scheduleOnDate');
  }

  get scheduleAtControl(): AbstractControl {
    return this.scheduleControls.get('scheduleAt');
  }

  get eventTimesControls(): AbstractControl {
    return this.scheduleControls.get('eventTimes');
  }

  get subtaskFormArray(): FormArray {
    return this.form.get('subtasks') as FormArray;
  }
  get triggersFormArray(): FormArray {
    return this.form.get('triggers') as FormArray;
  }

  get targetCompletionValueControl(): AbstractControl {
    return this.scheduleControls.get('targetCompletionValue');
  }

  get targetCompletionValueTypeControl(): AbstractControl {
    return this.scheduleControls.get('targetCompletionValueType');
  }

  get expirationValueControl(): AbstractControl {
    return this.scheduleControls.get('expirationValue');
  }

  get expirationValueTypeControl(): AbstractControl {
    return this.scheduleControls.get('expirationValueType');
  }
}
