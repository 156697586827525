import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Store, select} from '@ngrx/store';
import * as moment from 'moment';
import {Observable, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {User} from 'src/app/core/model/user';
import {UpdateScheduleQueryParams} from 'src/app/core/store/schedule/schedule.action';
import {selectScheduleQueryParams} from 'src/app/core/store/schedule/schedule.selector';
import {GetTeamsAction} from 'src/app/core/store/teams/teams.action';
import {selectAllTeams} from 'src/app/core/store/teams/teams.selector';
import {ScheduleQueryParams} from 'src/app/schedule/shared/model/schedule-query-params';
import {TimezoneService} from 'src/app/services/timezone.service';
import {Team} from 'src/app/teams/shared/model/team';

enum ToggleOptions {
  Auto = 'auto',
  Calendar = 'calendar',
}

enum WeekMonthOptions {
  Week = 'week',
  Month = 'month',
}

interface UserTeam {
  label: string;
  isTeam: boolean;
}

@Component({
  selector: 'scheduler-toolbar',
  templateUrl: './scheduler-toolbar.component.html',
  styleUrls: ['./scheduler-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchedulerToolbarComponent implements OnInit, OnDestroy {
  @Input() activeUser: User;

  public subscriptions: Subscription = new Subscription();

  public readonly queryParams$: Observable<ScheduleQueryParams> = this.store$.select(selectScheduleQueryParams);

  public toggleOptions = ToggleOptions;
  public weekMonthOptions = WeekMonthOptions;
  public toggleOption: string;
  public weekMonthOption: string;
  public userTeamOption: UserTeam;
  public timeZone: string;
  public startDate: string;
  public teams$: Observable<Team[]>;
  public userTeamList: UserTeam[] = [];

  constructor(
    private store$: Store,
    private router: Router,
    private timeZoneService: TimezoneService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.toggleOption = this.router.url.includes('auto') ? this.toggleOptions.Auto : this.toggleOptions.Calendar;
    this.weekMonthOption = this.router.url.includes('month') ? this.weekMonthOptions.Month : this.weekMonthOptions.Week;
    this.timeZone = this.timeZoneService.getTimeZone();
    this.startDate = moment().tz(this.timeZone).format();
    this.setUserTeam();
    this.getTeams();
    this.subscriptions.add(this.subscribeToRouterEvents());
    this.subscriptions.add(this.subscribeToQueryParams());
  }

  subscribeToRouterEvents(): Subscription {
    return this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.toggleOption = event.urlAfterRedirects.includes('auto')
          ? this.toggleOptions.Auto
          : this.toggleOptions.Calendar;
        this.weekMonthOption = event.urlAfterRedirects.includes('month')
          ? this.weekMonthOptions.Month
          : this.weekMonthOptions.Week;
        this.cdRef.detectChanges();
      }
    });
  }

  subscribeToQueryParams(): Subscription {
    return this.queryParams$.subscribe(params => {
      this.startDate = params.startDate;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  setUserTeam(): void {
    this.userTeamOption = {label: this.activeUser?.username, isTeam: false};
    this.updateQueryParams();
  }

  getTeams(): void {
    this.store$.dispatch(
      new GetTeamsAction({
        onSuccess: () => this.setTeams(),
      })
    );
  }

  setTeams(): void {
    this.teams$ = this.store$.pipe(select(selectAllTeams));
    this.teams$.pipe(take(1)).subscribe(teams => this.buildUserTeamList(teams));
  }

  buildUserTeamList(teams: Team[]): void {
    this.userTeamList.push({label: this.activeUser?.username, isTeam: false});
    this.activeUser.teams.forEach((teamId: string) =>
      this.userTeamList.push({label: teams.find((team: Team) => team.id === teamId)?.name || '', isTeam: true})
    );
  }

  displayStartDate(): string {
    if (this.weekMonthOption === this.weekMonthOptions.Week) {
      const endOfWeek = moment(this.startDate).endOf('week').add(1, 'day').format('MMM D');
      return moment(this.startDate).startOf('week').add(1, 'day').format(`MMM D [- ${endOfWeek}]`);
    } else if (this.weekMonthOption === this.weekMonthOptions.Month) {
      return moment(this.startDate).format('MMMM');
    }
  }

  subtract(): void {
    if (this.weekMonthOption === this.weekMonthOptions.Week) {
      this.startDate = moment(this.startDate).subtract(7, 'days').format();
    } else if (this.weekMonthOption === this.weekMonthOptions.Month) {
      this.startDate = moment(this.startDate).subtract(1, 'months').format();
    }
    this.updateQueryParams();
  }

  add(): void {
    if (this.weekMonthOption === this.weekMonthOptions.Week) {
      this.startDate = moment(this.startDate).add(7, 'days').format();
    } else if (this.weekMonthOption === this.weekMonthOptions.Month) {
      this.startDate = moment(this.startDate).add(1, 'months').format();
    }
    this.updateQueryParams();
  }

  changeOption(option: ToggleOptions): void {
    this.toggleOption = option;
    if (option === this.toggleOptions.Auto) {
      this.router.navigate(['/schedule/auto']);
    } else if (option === this.toggleOptions.Calendar) {
      this.router.navigate([`/schedule/${this.weekMonthOption}`]);
    }
  }

  changeWeekMonth(option: WeekMonthOptions): void {
    this.weekMonthOption = option;
    this.router.navigate([`/schedule/${option}`]);
  }

  changeUserTeam(menuOption: UserTeam): void {
    this.userTeamOption = menuOption;
    this.updateQueryParams();
  }

  updateQueryParams(): void {
    const scheduleQueryParams: ScheduleQueryParams = {
      startDate: this.startDate,
      teamSkeds: this.userTeamOption.isTeam,
      userId: this.activeUser?._id,
      teamId: this.activeUser?.teams[0] || '',
    };
    this.store$.dispatch(new UpdateScheduleQueryParams({params: scheduleQueryParams}));
  }
}
