@if ({metric: metric$ | async}; as state) {
  <mat-expansion-panel
    hideToggle
    #panel
    (click)="onSubtask($event)"
    [class.disabled]="ableToCompleteSubtaskIndex < subtaskIndex"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="subtask-header">
          <current-sked-checkbox
            [checked]="subtask.complete"
            [loading]="loading?.subtaskIndex === subtaskIndex"
            (checkboxClick)="onCheckboxClick($event, subtask, i)"
          ></current-sked-checkbox>
          <span>{{ subtask.title }}</span>
          @if (state.metric) {
            <button class="metric-button" (click)="onMetricToggle($event)">
              @if (expandedSubtaskIndex === subtaskIndex) {
                <mat-icon svgIcon="chevron-up"></mat-icon>
              }
              @if (expandedSubtaskIndex !== subtaskIndex) {
                <mat-icon svgIcon="tracker"></mat-icon>
              }
            </button>
          }
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <!-- ----------------------------------------METRIC CONTENT---------------------------------------- -->
    @if (state.metric) {
      <div class="subtask-metric-container">
        <current-sked-task-detail-value-display [metric]="state.metric"></current-sked-task-detail-value-display>

        <div class="value-update-container">
          <span class="metric-name">{{ state.metric?.name || '' }}</span>
          <tracker-metric-value-update
            [metric]="state.metric"
            [subtaskId]="subtask._id"
            [control]="subtaskValueControl"
            [options]="state.metric.options"
            [showNumericUpdateButton]="true"
            [loadingStatus]="loading?.metricId === state.metric._id ? loading.metricStatus : null"
            toggleWidth="178px"
            (click)="$event.stopPropagation()"
            (updateValue)="onUpdateMetricValue($event)"
          ></tracker-metric-value-update>
          <span class="metric-name suffix">{{ state.metric.uom || '' }}</span>
        </div>
      </div>
    }
  </mat-expansion-panel>
}
