import {AsyncPipe, TitleCasePipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {OphButtonModule} from 'src/app/shared/design/oph-button/oph-button.module';
import {OphCheckboxComponent} from 'src/app/shared/design/oph-checkbox/oph-checkbox.component';
import {OphIconModule} from 'src/app/shared/design/oph-icon/oph-icon.module';
import {OphInputOrangeComponent} from 'src/app/shared/design/oph-inputs/oph-input-orange/oph-input-orange.component';
import {OphXButtonComponent} from 'src/app/shared/design/oph-x-button/oph-x-button.component';
import {ListViewsModule} from 'src/app/shared/list-views/list-views.module';
import {PipesModule} from 'src/app/shared/pipes/pipes.module';
import {TrackerMetricValueUpdateComponent} from 'src/app/shared/trackers/tracker-metric-value-update/tracker-metric-value-update.component';
import {TrackerMetricTypeDisplayPipe} from 'src/app/trackers/shared/tracker-metric-type-display.pipe';
import {TrackerMetricTypes} from 'src/app/trackers/shared/tracker-metric-types';
import {TrackerSelectOptionsPipe} from 'src/app/trackers/shared/tracker-select-options.pipe';
import {TrackerDetailMetricSelectComponent} from './select/tracker-detail-metric-select.component';

@Component({
  selector: 'tracker-detail-metric',
  standalone: true,
  imports: [
    OphInputOrangeComponent,
    MatMenuModule,
    TitleCasePipe,
    TrackerDetailMetricSelectComponent,
    TrackerMetricTypeDisplayPipe,
    ListViewsModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    OphIconModule,
    OphButtonModule,
    MatIconModule,
    OphCheckboxComponent,
    OphXButtonComponent,
    TrackerSelectOptionsPipe,
    TrackerMetricValueUpdateComponent,
    PipesModule,
    AsyncPipe,
  ],
  templateUrl: './tracker-detail-metric.component.html',
  styleUrl: './tracker-detail-metric.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackerDetailMetricComponent {
  @Input() metricForm: FormGroup;
  @Input() editing: boolean;

  @Output() menuAction = new EventEmitter<string>();

  typeOptions = ['Number', 'List', 'Yes / No'];
  trackerMetricTypes = TrackerMetricTypes;

  onTypeChange(displayType: string) {
    const type = this.getType(displayType);
    if (type === this.trackerMetricTypes.Select) {
      this.metricForm.patchValue({type, value: '', defaultValue: ''});
      return;
    }
    this.metricForm.patchValue({type, value: '0', defaultValue: '0'});
  }

  getType(type: string): string {
    switch (type) {
      case 'Number':
        return 'numeric';
      case 'List':
        return 'select';
      case 'Yes / No':
        return 'boolean';
    }
  }

  onToggle(type: 'value' | 'defaultValue', checked: boolean) {
    this.metricForm.get(type).setValue(checked ? '1' : '0');
  }

  onSelectValueChange(value: string) {
    this.metricForm.get('value').setValue(value);
  }

  onSelectDefaultValueChange(optionIndex: number, checked: boolean) {
    const options = this.optionsFormArray.controls;
    options.forEach((formGroup: FormGroup, index: number) => {
      formGroup.patchValue({default: index === optionIndex ? checked : false});
    });
  }

  onRemoveOption(index: number) {
    this.optionsFormArray.removeAt(index);
  }

  onNewOption() {
    this.optionsFormArray.push(new FormGroup({value: new FormControl(''), default: new FormControl(false)}));
  }

  get typeControl(): AbstractControl {
    return this.metricForm.get('type');
  }

  get optionsFormArray() {
    return this.metricForm.get('options') as FormArray;
  }
}
