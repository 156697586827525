@if (
  {
    showCompletionCalendar: showCompletionCalendar$ | async,
    showExpirationCalendar: showExpirationCalendar$ | async,
    targetCompletionRepeatTimeInvalid: targetCompletionRepeatTimeInvalid$ | async,
    expirationRepeatTimeInvalid: expirationRepeatTimeInvalid$ | async
  };
  as state
) {
  @if (!state.showCompletionCalendar && !this.state.showExpirationCalendar) {
    <!-- ---------------------------TARGET COMPLETION----------------------------------- -->
    <span class="subtitle">This is the date you plan to complete the task by.</span>
    <div class="button-container">
      @if (scheduleTypeControl.value === 'doNotRepeat') {
        <task-wizard-dialog-check-button
          width="100%"
          flexGrow="true"
          [selected]="targetCompletionTypeControl.value === 'endOfProject'"
          (click)="onCompletion('endOfProject')"
          >End of project</task-wizard-dialog-check-button
        >
      }
      @if (scheduleTypeControl.value === 'repeats') {
        <task-wizard-dialog-check-button
          width="100%"
          flexGrow="true"
          [selected]="targetCompletionTypeControl.value === 'onRepeat'"
          (click)="onCompletion('onRepeat')"
          >On Repeat</task-wizard-dialog-check-button
        >
      }
      @if (scheduleTypeControl.value === 'doNotRepeat' && !triggerCount) {
        <task-wizard-dialog-check-button
          width="100%"
          flexGrow="true"
          [selected]="targetCompletionTypeControl.value === 'date'"
          (click)="onCompletion('date')"
          >{{
            targetCompletionDateControl.value
              ? (targetCompletionDateControl.value | convertUtcToTimezone: 'MMM DD, YYYY')
              : 'A specific date'
          }}</task-wizard-dialog-check-button
        >
      }
      @if (
        (scheduleTypeControl.value === 'repeats' || triggerCount) &&
        (targetCompletionTypeControl.value === 'endOfProject' || targetCompletionTypeControl.value === 'onRepeat')
      ) {
        <task-wizard-dialog-check-button
          width="100%"
          flexGrow="true"
          [selected]="targetCompletionTypeControl.value === 'date'"
          (click)="onCompletion('amountOfTime')"
          >Amount of time</task-wizard-dialog-check-button
        >
      }
      @if (
        (scheduleTypeControl.value === 'repeats' || triggerCount) &&
        targetCompletionTypeControl.value === 'amountOfTime'
      ) {
        <task-wizard-dialog-check-button width="100%" flexGrow="true" [selected]="true">
          <div class="empty-space"></div>
          <task-number-input
            [min]="1"
            [max]="10000"
            [control]="targetCompletionValueControl"
            [fontSize]="20"
            height="49"
            inputWidth="56"
          ></task-number-input>
          <button class="type-button" [matMenuTriggerFor]="completionMenu">
            {{ targetCompletionValueTypeControl.value | titlecase }}
            <img src="assets/img/icons/chevron-down-brown.svg" />
          </button>
          <mat-menu #completionMenu="matMenu" class="oph-select-menu">
            @for (type of repeatTypesArr; track type) {
              <button class="oph-select-menu-item" (click)="onCompletionType(type)">
                <span>{{ type | titlecase }}</span>
              </button>
            }
          </mat-menu></task-wizard-dialog-check-button
        >
      }
      @if (state.targetCompletionRepeatTimeInvalid) {
        <div class="time-validation-error-container">
          <error>Expiration time must be less than time in between repeated tasks.</error>
          @if (state.targetCompletionRepeatTimeInvalid.timeBeforeNextTaskDisplay) {
            <span>Max time allowed: {{ state.targetCompletionRepeatTimeInvalid.timeBeforeNextTaskDisplay }}</span>
          }
        </div>
      }
    </div>
    <!-- ---------------------------EXPIRATION----------------------------------- -->
    <div class="twd-panel-title">When does the task expire?</div>
    <span class="subtitle">The task is no longer available after the expiration.</span>
    <div class="button-container">
      @if (scheduleTypeControl.value === 'doNotRepeat') {
        <task-wizard-dialog-check-button
          width="100%"
          flexGrow="true"
          [selected]="expirationTypeControl.value === 'endOfProject'"
          (click)="onExpiration('endOfProject')"
          >End of project</task-wizard-dialog-check-button
        >
      }
      @if (scheduleTypeControl.value === 'repeats') {
        <task-wizard-dialog-check-button
          width="100%"
          flexGrow="true"
          [selected]="expirationTypeControl.value === 'onRepeat'"
          (click)="onExpiration('onRepeat')"
          >On Repeat</task-wizard-dialog-check-button
        >
      }
      @if (scheduleTypeControl.value === 'doNotRepeat' && !triggerCount) {
        <task-wizard-dialog-check-button
          width="100%"
          flexGrow="true"
          [selected]="expirationTypeControl.value === 'date'"
          (click)="onExpiration('date')"
          >{{
            expirationDateControl.value
              ? (expirationDateControl.value | convertUtcToTimezone: 'MMM DD, YYYY')
              : 'A specific date'
          }}</task-wizard-dialog-check-button
        >
      }
      @if (
        (scheduleTypeControl.value === 'repeats' || triggerCount) &&
        (expirationTypeControl.value === 'endOfProject' || expirationTypeControl.value === 'onRepeat')
      ) {
        <task-wizard-dialog-check-button
          width="100%"
          flexGrow="true"
          [selected]="expirationTypeControl.value === 'date'"
          (click)="onExpiration('amountOfTime')"
          >Amount of time</task-wizard-dialog-check-button
        >
      }
      @if (
        (scheduleTypeControl.value === 'repeats' || triggerCount) && expirationTypeControl.value === 'amountOfTime'
      ) {
        <task-wizard-dialog-check-button width="100%" flexGrow="true" [selected]="true">
          <div class="empty-space"></div>
          <task-number-input
            [min]="1"
            [max]="10000"
            [control]="expirationValueControl"
            [fontSize]="20"
            height="49"
            inputWidth="56"
          ></task-number-input>
          <button class="type-button" [matMenuTriggerFor]="expirationMenu">
            {{ expirationValueTypeControl.value | titlecase }}
            <img src="assets/img/icons/chevron-down-brown.svg" />
          </button>
          <mat-menu #expirationMenu="matMenu" class="oph-select-menu">
            @for (type of repeatTypesArr; track type) {
              <button class="oph-select-menu-item" (click)="onExpirationType(type)">
                <span>{{ type | titlecase }}</span>
              </button>
            }
          </mat-menu></task-wizard-dialog-check-button
        >
      }
      @if (state.expirationRepeatTimeInvalid) {
        <div class="time-validation-error-container">
          <error>Expiration time must be less than time in between repeated tasks.</error>
          @if (state.expirationRepeatTimeInvalid.timeBeforeNextTaskDisplay) {
            <span>Max time allowed: {{ state.expirationRepeatTimeInvalid.timeBeforeNextTaskDisplay }}</span>
          }
        </div>
      }
    </div>
  } @else {
    <ng-template [ngTemplateOutlet]="calendar"></ng-template>
  }
  @if (state.showCompletionCalendar) {
    <div class="calendar-container">
      <twd-calendar
        [control]="targetCompletionDateControl"
        (dateChange)="onCompletionDateChange($event)"
      ></twd-calendar>
      <div class="twd-calendar-buttons-container">
        <oph-button appearance="text" color="brown" (click)="onCompletionCalendarButton(true)">Cancel</oph-button>
        <oph-button
          appearance="solid"
          color="green"
          (click)="onCompletionCalendarButton(false)"
          [disabled]="!targetCompletionDateControl.value"
          >OK</oph-button
        >
      </div>
    </div>
  }
  @if (state.showExpirationCalendar) {
    <div class="calendar-container">
      <twd-calendar [control]="expirationDateControl" (dateChange)="onExpirationDateChange($event)"></twd-calendar>
      <div class="twd-calendar-buttons-container">
        <oph-button appearance="text" color="brown" (click)="onExpirationCalendarButton(true)">Cancel</oph-button>
        <oph-button
          appearance="solid"
          color="green"
          (click)="onExpirationCalendarButton(false)"
          [disabled]="!expirationDateControl.value"
          >OK</oph-button
        >
      </div>
    </div>
  }
}
