import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OphStringListComponent} from './oph-string-list.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
  imports: [CommonModule, MatIconModule, MatButtonModule],
  declarations: [OphStringListComponent],
  exports: [OphStringListComponent],
})
export class OphStringListModule {}
