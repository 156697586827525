import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'oph-inline-edit-menu',
  templateUrl: './oph-inline-edit-menu.component.html',
  styleUrls: ['./oph-inline-edit-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OphInlineEditMenuComponent {
  @Input() actions: string[] = ['duplicate', 'delete', 'edit'];

  @Output() buttonClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() closeMenu = new EventEmitter<void>();

  onButtonClick(action: string): void {
    this.buttonClick.emit(action);
  }

  onCloseMenu() {
    this.closeMenu.emit(null);
  }
}
