<div class="oph-expansion-panel rewards-expansion-panel">
  <mat-expansion-panel
    hideToggle
    [expanded]="expanded"
    (opened)="onTypeToggle(true)"
    (closed)="onTypeToggle(false)"
    [class.disabled]="disabled"
  >
    <mat-expansion-panel-header>
      <div class="coin-types-header">
        <div class="coin-types-header-lc">
          <mat-icon [svgIcon]="rewardCoin.name | rewardCoinIcon"></mat-icon>
          <span>{{ rewardCoin.name }}</span>
        </div>
        <div class="coin-types-header-rc">
          @if (userCoinCount || userCoinCount === 0) {
            <span class="coin-count">{{ userCoinCount | numberAbbreviation }}</span>
          } @else {
            <span class="rewards-count"
              >{{ rewardCoin.rewards?.length || 0 }} Reward{{ rewardCoin?.rewards?.legnth === 1 ? '' : 's' }}</span
            >
          }
          <mat-icon [svgIcon]="expanded ? 'chevron-down' : 'chevron-right'" [class.hidden]=""></mat-icon>
        </div>
      </div>
    </mat-expansion-panel-header>

    <ng-content></ng-content>
  </mat-expansion-panel>
</div>
