@if (
  {
    selectedTabOption: selectedTabOption$ | async,
    usersMap: usersMap$ | async,
    selectedUserId: selectedUserId$ | async,
    headerLoading: headerLoading$ | async
  };
  as state
) {
  <header>
    {{ state.selectedTabOption?.pageTitle || '' }}

    @if (state.headerLoading) {
      <div class="oph-orange-spinner">
        <mat-spinner [diameter]="26" [strokeWidth]="2"></mat-spinner>
      </div>
    }
  </header>
  <div class="toolbar">
    <oph-button-group
      [options]="buttonGroupOptions$ | async"
      [selectedOptionName]="(selectedTabOption$ | async)?.tabName"
      (optionClick)="onTabOption($event)"
    ></oph-button-group>

    @if ((userRole$ | async) === 'admin') {
      @if (state.selectedTabOption?.url === 'history') {
        <button class="user-selector-button" [matMenuTriggerFor]="menu">
          @if (state.usersMap[state.selectedUserId]) {
            <oph-avatar
              [user]="state.usersMap[state.selectedUserId]"
              [size]="24"
              background="#0085ff"
              class="mr-5"
            ></oph-avatar>
            <span>{{ state.usersMap[state.selectedUserId].username }}</span>
          } @else {
            <mat-icon svgIcon="user" class="user-icon"></mat-icon>
            <span>Select User</span>
          }
          <mat-icon svgIcon="chevron-down" class="chevron"></mat-icon>
        </button>
        <mat-menu #menu="matMenu" class="oph-select-menu">
          <button class="oph-select-menu-item" (click)="onUser(null)">
            <span>All users</span>
          </button>
          @for (user of users$ | async; track user) {
            <button class="oph-select-menu-item" (click)="onUser(user._id)">
              <oph-avatar [user]="user" [size]="24" background="#0085ff" class="mr-10"></oph-avatar>
              <span>{{ user.username }}</span>
            </button>
          }
        </mat-menu>
      }

      <oph-new-button
        [buttonStyle]="{
          borderRadius: '6px',
          border: '1px solid var(--orange)',
          background: 'transparent'
        }"
        (buttonClick)="openRewardDialog(null)"
        >New Reward</oph-new-button
      >
    }
  </div>

  <router-outlet></router-outlet>
}
