import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {User} from 'src/app/core/model/user';

@Component({
  selector: 'task-dialog-requirement-item',
  templateUrl: './task-dialog-requirement-item.component.html',
  styleUrls: ['./task-dialog-requirement-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskDialogRequirementItemComponent {
  @Input() selected: boolean;
  @Input() type: 'people' | 'teams' | 'skills';
  @Input() display: string;
  @Input() user: User;

  @Output() buttonClick = new EventEmitter<boolean>();
}
