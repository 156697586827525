@if (formArray.at(subtaskIndex)) {
  <div class="subtask-edit-container">
    <subtask-edit
      [controls]="formArray.at(subtaskIndex)"
      [fromTemplate]="fromTemplate"
      taskDialog="true"
      (isSubtaskComplete)="onIsSubtaskComplete($event)"
      (imageAdded)="onImageAdded($event)"
    ></subtask-edit>
  </div>
}

<task-dialog-panel-footer
  (cancel)="onCancel()"
  (ok)="onOk()"
  [disabled]="!formArray.at(subtaskIndex).value.title || (isSubtaskComplete$ | async) === false"
></task-dialog-panel-footer>
