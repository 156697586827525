<button [matMenuTriggerFor]="menu" class="location-button" [class.light-background]="lightBackground">
  <div class="button-left-container">
    <img class="location-icon" src="assets/img/icons/location-outline-gray.svg" />
    <span>{{ (locationsMap$ | async)[control?.value]?.name || 'Select location' }}</span>
  </div>
  <img class="chevron" src="assets/img/icons/chevron-down-brown.svg" />
</button>
<mat-menu #menu="matMenu" class="oph-select-menu">
  @for (location of locations$ | async; track location) {
    <button (click)="onLocation(location.id)" class="oph-select-menu-item">
      {{ location.name }}
    </button>
  }
</mat-menu>
