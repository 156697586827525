@if (project) {
  <!-- --------------TASK LIST---------------- -->
  <div
    cdkDropList
    #projectList="cdkDropList"
    [cdkDropListData]="project.tasks"
    [cdkDropListConnectedTo]="[templateList]"
    class="container task-list"
    (cdkDropListDropped)="taskDrop($event)"
  >
    <!-- --------------NEW TASK BUTTON---------------- -->
    <button class="create-new-task-button" (click)="onCreateTaskForProject(project)">
      <div class="create-new-task-text-container">
        <span class="create-new-task-text">Create a new task</span>
        <span class="create-new-task-subtext">Or drag a task over from the sidebar.</span>
      </div>
      <div class="create-new-task-icon-container">
        <mat-icon>add</mat-icon>
      </div>
    </button>
    @for (task of project.tasks; track task; let i = $index) {
      <projects-edit-task
        #taskContainer
        [task]="task"
        [requirementsMap]="requirementsMap$ | async"
        [locationsMap]="locationsMap$ | async"
        [loading]="(loadingTaskIndex$ | async) === i"
        (click)="openManualProjectTaskModal(i)"
      ></projects-edit-task>
    }
  </div>

  <div class="tabs-container">
    <oph-button-group
      [options]="tabOptions"
      [selectedOptionName]="selectedTab$ | async"
      (optionClick)="onTabChange($event)"
    ></oph-button-group>
    <oph-search-input
      [control]="search"
      [inputStyle]="{height: '42px'}"
      placeholder="Search Task Templates"
    ></oph-search-input>
    <oph-new-button (buttonClick)="openTaskTemplateDialog()">New Task Template</oph-new-button>
    <!-- --------------TASK TEMPLATE LIST---------------- -->
    <div
      cdkDropList
      #templateList="cdkDropList"
      [cdkDropListData]="taskTemplates"
      [cdkDropListConnectedTo]="[projectList]"
      cdkDropListSortingDisabled
      class="task-list"
    >
      @for (taskTemplate of taskTemplates; track taskTemplate) {
        <projects-edit-task
          [template]="true"
          [task]="taskTemplate"
          [requirementsMap]="requirementsMap$ | async"
          [locationsMap]="locationsMap$ | async"
          (click)="openTaskTemplateDialog(taskTemplate)"
        ></projects-edit-task>
      }
    </div>
  </div>
}
