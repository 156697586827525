import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'task-wizard-dialog-number-input',
  templateUrl: './task-wizard-dialog-number-input.component.html',
  styleUrls: ['./task-wizard-dialog-number-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskWizardDialogNumberInputComponent {
  @Input() placeholder: string;
  @Input() min: number;
  @Input() max: number;
  @Input() control: FormControl;
  @Input() fontSize: number;

  onArrowUp() {
    this.control.setValue(this.control.value + 1);
  }

  onArrowDown() {
    if (this.control.value > 1) {
      this.control.setValue(this.control.value - 1);
    }
  }

  onInputBlur(value: number) {
    if (!value) {
      this.control.setValue(1);
    }
  }
}
