import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {OphRadioComponent} from './oph-radio/oph-radio.component';
import {OphCheckboxComponent} from './oph-checkbox/oph-checkbox.component';
import {OphWeekSelectorComponent} from './oph-week-selector/oph-week-selector.component';
import {OphIconModule} from '../oph-icon/oph-icon.module';
import {OphInputInsideLabelComponent} from './oph-input-inside-label/oph-input-inside-label.component';
import {OphInputComponent} from './oph-input/oph-input.component';
import {OphInputOutlineComponent} from './oph-input-outline/oph-input-outline.component';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  declarations: [
    OphInputInsideLabelComponent,
    OphInputComponent,
    OphRadioComponent,
    OphCheckboxComponent,
    OphWeekSelectorComponent,
    OphInputOutlineComponent,
  ],
  imports: [CommonModule, ReactiveFormsModule, OphIconModule, MatIconModule],
  exports: [
    OphInputInsideLabelComponent,
    OphInputComponent,
    OphRadioComponent,
    OphCheckboxComponent,
    OphWeekSelectorComponent,
    OphInputOutlineComponent,
  ],
})
export class OphInputsModule {}
