import {createSelector} from '@ngrx/store';
import {AppState} from '../app-state';
import {Team} from 'src/app/teams/shared/model/team';

export const selectTeamsState = (state: AppState) => state.teams;

export const selectAllTeams = createSelector(selectTeamsState, state => state.teams);

export const selectTeamsMap = createSelector(selectAllTeams, teams =>
  teams.reduce((teamsMap: Record<string, Team>, obj: Team) => ((teamsMap[obj.id] = obj), teamsMap), {})
);

export const selectTeam = (id: string) => createSelector(selectTeamsMap, teamsMap => teamsMap[id] || ({} as Team));
