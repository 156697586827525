import {ChangeDetectionStrategy, Component, EventEmitter, Output} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';

@Component({
  selector: 'oph-back-button',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './oph-back-button.component.html',
  styleUrl: './oph-back-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OphBackButtonComponent {
  @Output() buttonClick = new EventEmitter();
}
