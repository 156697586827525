import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {ListViewOptions} from '../../list-view-options';
import {Router} from '@angular/router';

@Component({
  selector: 'list-view-header',
  templateUrl: './list-view-header.component.html',
  styleUrls: ['./list-view-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListViewHeaderComponent {
  @Input() name: string;
  @Input() icon: string;
  @Input() iconColor: string;
  @Input() view: ListViewOptions;
  @Input() searchValue: string;
  @Input() createButtonText: string;
  @Input() loadingCreateNew: boolean;
  @Input() hideSearch: boolean;

  @Output() viewChange = new EventEmitter<ListViewOptions>();
  @Output() createNew = new EventEmitter();

  readonly ListViewOptions = ListViewOptions;

  constructor(private router: Router) {}

  onView(view: ListViewOptions) {
    this.viewChange.emit(view);
  }

  onSearch(search: string) {
    this.router.navigate([], {queryParams: {search: search || null}, queryParamsHandling: 'merge'});
  }

  onCreateNew() {
    this.createNew.emit();
  }
}
