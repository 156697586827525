import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'operatorDisplay',
})
export class OperatorDisplayPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'eq':
        return '=';
      case 'lt':
        return '<';
      case 'lte':
        return '<=';
      case 'gt':
        return '>';
      case 'gte':
        return '>=';
      default:
        return value;
    }
  }
}
