import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ProgramDto} from 'src/app/programs/shared/dto/program.dto';
import {Program} from 'src/app/programs/shared/model/program';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProgramsApiService {
  public url = `${environment.apiUrl}/programs`;

  constructor(private http: HttpClient) {}

  public get(): Observable<ProgramDto[]> {
    return this.http.get<ProgramDto[]>(this.url);
  }

  public create(program: Partial<Program>): Observable<Program> {
    return this.http.post<Program>(`${this.url}`, program);
  }

  public update(program: Program): Observable<Program> {
    return this.http.put<Program>(`${this.url}/${program.id}`, program);
  }

  public delete(id: string): Observable<Object> {
    return this.http.delete(`${this.url}/${id}`);
  }
}
