import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'list-view-menu',
  templateUrl: './list-view-menu.component.html',
  styleUrls: ['./list-view-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListViewMenuComponent {
  @Input() options: string[];

  @Output() action = new EventEmitter<string>();

  public open = false;
  public deleteHovered = false;

  public menuOpened() {
    this.open = true;
  }

  public menuClosed() {
    this.open = false;
  }

  onClick(event: PointerEvent) {
    event.stopPropagation();
  }
}
