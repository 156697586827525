import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'task-wizard-dialog-panel-item',
  templateUrl: './task-wizard-dialog-panel-item.component.html',
  styleUrls: ['./task-wizard-dialog-panel-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskWizardDialogPanelItemComponent {
  @Input() title: string;
  @Input() subtaskEdit: boolean;
  @Input() noTitleMarginBottom: boolean;
}
