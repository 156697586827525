@if (
  {
    tracker: tracker$ | async,
    loadingTracker: loadingTracker$ | async,
    mobileActiveTab: mobileActiveTab$ | async,
    usersMap: usersMap$ | async,
    projectsMap: projectsMap$ | async
  };
  as state
) {
  @if (state.loadingTracker) {
    <div class="loading-container">
      <oph-loading></oph-loading>
    </div>
  } @else {
    <oph-back-button (buttonClick)="onBack()"></oph-back-button>

    <div class="main-container">
      <div class="main-header">
        <div class="main-header-container flex-grow">
          <mat-icon svgIcon="tracker"></mat-icon>
          <oph-input-orange
            [control]="nameControl"
            [orangeBorder]="nameControl?.value.startsWith('Tracker')"
            class="tracker-name-input"
          ></oph-input-orange>
        </div>
        <div class="main-header-container">
          <span class="header-text"
            >Updated On {{ state.tracker.lastUpdatedAt | convertUtcToTimezone: 'MMM DD YYYY' }} by
            {{ state.usersMap[state.tracker.lastUpdatedBy]?.username || 'Unknown' }}</span
          >
          <list-view-menu [options]="menuOptions" (action)="onMenuAction($event)"></list-view-menu>
        </div>
      </div>

      <div class="tracker-mobile-tabs">
        @for (tab of mobileTabs; track tab) {
          <button class="tab-button" [class.selected-tab]="state.mobileActiveTab === tab" (click)="onMobileTab(tab)">
            {{ tab }}
          </button>
        }
      </div>

      <div class="lower-container">
        <tracker-detail-metrics class="desktop-metrics" [tracker]="state.tracker"></tracker-detail-metrics>

        <tracker-detail-project-selection
          class="desktop-projects"
          [form]="form"
          [projects]="projects$ | async"
          [projectsMap]="state.projectsMap"
        ></tracker-detail-project-selection>

        @if (state.mobileActiveTab === 'metrics') {
          <tracker-detail-metrics class="mobile-metrics" [tracker]="state.tracker"></tracker-detail-metrics>
        }

        @if (state.mobileActiveTab === 'projects') {
          <tracker-detail-project-selection
            class="mobile-projects"
            [form]="form"
            [projects]="projects$ | async"
            [projectsMap]="state.projectsMap"
          ></tracker-detail-project-selection>
        }
      </div>
    </div>
  }
}
