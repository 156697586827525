@if ({user: activeUser$ | async}; as state) {
  <button [matMenuTriggerFor]="menu" class="menu-button">
    @if (state.user?.avatarUrl) {
      <oph-avatar [user]="state.user" size="36"></oph-avatar>
    } @else {
      <img [src]="'/assets/img/icons/user-brown.svg'" [height]="size" [width]="size" alt="user avatar" />
    }
  </button>
  <mat-menu #menu="matMenu" class="oph-select-menu">
    <div class="username">{{ state.user?.username }}</div>
    <button class="menu-item" (click)="onProfile()">
      <img src="assets/img/icons/user.svg" class="ml" />
      My Profile
    </button>
    <button class="menu-item" (click)="onLogout()">
      <img src="assets/img/icons/logout.svg" />
      Log Out
    </button>

    <!-- Theme toggle -->
    <!-- <div class="theme">
    <mat-button-toggle-group
      name="fontStyle"
      aria-label="Font Style"
      [formControl]="appTheme"
      (change)="onThemeChange($event.value)"
    >
      <mat-button-toggle value="light">Light</mat-button-toggle>
      <mat-button-toggle value="dark">Dark</mat-button-toggle>
    </mat-button-toggle-group>
  </div> -->
  </mat-menu>
}
