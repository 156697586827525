@if (!(showCalendar$ | async)) {
  <div class="button-container">
    @for (button of buttons; track button) {
      <task-wizard-dialog-check-button
        width="100%"
        flexGrow="true"
        [selected]="scheduleTypeControl.value === button.value"
        (click)="onButton(button.value)"
        >{{ button.display }}</task-wizard-dialog-check-button
      >
    }
  </div>
  <task-repetition
    [scheduleFormGroup]="scheduleControls"
    [disabledDays]="disabledDays$ | async"
    (showCalendar)="onCalendarOpen()"
  ></task-repetition>
} @else {
  <!-- <div class="date-display">{{ control?.value ? (control.value | convertUtcToTimezone: 'ddd, MMM DD, YYYY') : '' }}</div> -->
  <twd-calendar [control]="endsDateControl" (dateChange)="onDateChange($event)"></twd-calendar>
  <div class="twd-calendar-buttons-container">
    <oph-button appearance="text" color="brown" (click)="onCalendarButton(true)">Cancel</oph-button>
    <oph-button appearance="solid" color="green" (click)="onCalendarButton(false)" [disabled]="!endsDateControl.value"
      >OK</oph-button
    >
  </div>
}
