@if ({hoverBackground: hoverBackground$ | async}; as state) {
  <button
    [disabled]="disabled || loading"
    [class]="className"
    [style.border]="colorHex ? '2px solid ' + (state.hoverBackground || colorHex) : ''"
    [style.color]="colorHexText || null"
    [style.background]="appearance === 'outline' ? 'transparent' : colorHex ? colorHex : ''"
    [ngStyle]="style"
    (click)="buttonClick.emit()"
    (mouseenter)="colorHexHover ? onButtonHover() : null"
    (mouseleave)="colorHexHover ? onButtonLeave() : null"
  >
    @if (loading) {
      <mat-spinner class="custom-color" diameter="20"></mat-spinner>
    } @else {
      @if (buttonText) {
        <span [style.color]="color === 'gray' ? '#4A443D' : hexColorText">{{ buttonText }}</span>
      }
      @if (!buttonText) {
        <ng-content></ng-content>
      }
    }
  </button>
}
