import {ReportingObjectEntryDto} from '../dto/reporting-object-entry.dto';
import {ReportingObjectEntry} from '../model/reporting-object-entry';

export function convertReportingObjectEntryDtoToModel(dto: ReportingObjectEntryDto): ReportingObjectEntry {
  return {
    id: dto._id,
    loggedAt: dto.loggedAt,
    loggedByName: dto.loggedByName,
    type: dto.type,
    taskId: dto.taskId,
    taskTitle: dto.taskTitle,
    objectId: dto.objectId,
    objectTitle: dto.objectTitle,
    objectModelTitle: dto.objectModelTitle,
    objectModelId: dto.objectModelId,
    attributeId: dto.attributeId,
    attributeName: dto.attributeName,
    attributeType: dto.attributeType,
    value: dto.value,
    previousValue: dto.previousValue,
    defaultValue: dto.defaultValue || '',
  };
}
