import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Project, ProjectView, ProjectViewRoutineDetail, ProjectViewRoutineDetailParams} from '../model/project';
import {ProjectTask, TaskTemplate} from '../model/task';

@Injectable({
  providedIn: 'root',
})
export class ProjectsApiService {
  public url = `${environment.apiUrl}/projects`;
  public tasksUrl = `${environment.apiUrl}/tasks`;

  constructor(private http: HttpClient) {}

  get(): Observable<Project[]> {
    return this.http.get<Project[]>(`${this.url}/templates`);
  }

  getSingle(id: string): Observable<Project> {
    return this.http.get<Project>(`${this.url}/templates/${id}`);
  }

  create(project: Partial<Project>): Observable<Project> {
    return this.http.post<Project>(`${this.url}/templates`, project);
  }

  update(project: Project): Observable<Project> {
    return this.http.put<Project>(`${this.url}/templates/${project._id}`, project);
  }

  duplicate(id: string): Observable<Project> {
    return this.http.post<Project>(`${this.url}/templates/${id}`, null);
  }

  delete(id: string): Observable<Object> {
    return this.http.delete(`${this.url}/templates/${id}`);
  }

  updateProjectTasks(
    id: string,
    schedule: Partial<Project>,
    index: number,
    task: ProjectTask | null,
    taskDeleted: boolean
  ): Observable<Project> {
    const payload = {
      ...schedule,
      task,
      index,
    };
    return this.http.patch<Project>(`${this.url}/templates/${id}?taskDeleted=${String(taskDeleted)}`, payload);
  }

  getProjectView(projectId: string): Observable<ProjectView> {
    return this.http.get<ProjectView>(`${this.url}/viewer/${projectId}`);
  }

  getRoutineDetail(id: string, params: ProjectViewRoutineDetailParams): Observable<ProjectViewRoutineDetail> {
    return this.http.get<ProjectViewRoutineDetail>(`${this.url}/viewer/routines/${id}`, {params: {...params}});
  }

  getTaskTemplates(search?: string): Observable<TaskTemplate[]> {
    const params = search ? {label: search} : {};
    return this.http.get<TaskTemplate[]>(`${this.tasksUrl}/templates`, {params});
  }

  createTaskTemplate(task: TaskTemplate): Observable<ProjectTask> {
    return this.http.post<ProjectTask>(`${this.tasksUrl}/templates`, task);
  }

  updateTaskTemplate(task: TaskTemplate): Observable<ProjectTask> {
    return this.http.put<ProjectTask>(`${this.tasksUrl}/templates/${task._id}`, task);
  }

  deleteTaskTemplate(id: string) {
    return this.http.delete(`${this.tasksUrl}/templates/${id}`);
  }
}
