import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import * as moment from 'moment';
import {Project} from 'src/app/core/model/project';
import {TimezoneService} from 'src/app/services/timezone.service';
import {OphButtonModule} from 'src/app/shared/design/oph-button/oph-button.module';
import {PipesModule} from 'src/app/shared/pipes/pipes.module';

@Component({
  selector: 'project-selector',
  standalone: true,
  imports: [MatMenuModule, MatIconModule, OphButtonModule, PipesModule],
  templateUrl: './project-selector.component.html',
  styleUrl: './project-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectSelectorComponent implements OnChanges {
  @Input() selectedProject: Project;
  @Input() loading: boolean;

  buttonDisplay: string;
  buttonColor: string;
  colorHex: string;
  isProjectActive: boolean;

  constructor(private timezoneService: TimezoneService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedProject && this.selectedProject) {
      this.setButtonDisplay();
      this.isProjectActive = this.timezoneService.areDatesWithinRange(
        this.selectedProject.startDateTime,
        this.selectedProject.endDateTime
      );
    }
  }

  setButtonDisplay() {
    const now = moment().tz(this.timezoneService.getTimeZone()).startOf('day');
    const projectStart = moment(this.selectedProject.startDateTime).tz(this.timezoneService.getTimeZone());
    if (
      this.selectedProject.status === 'inactive' ||
      !this.selectedProject.startDateTime ||
      !this.selectedProject.endDateTime
    ) {
      this.buttonDisplay = 'Draft';
      this.colorHex = '#A3A099';
      this.buttonColor = '';
    } else if (this.selectedProject.status === 'active' && now.isBefore(projectStart)) {
      this.buttonDisplay = 'Scheduled';
      this.buttonColor = 'orange';
      this.colorHex = '';
    } else if (
      this.selectedProject.status === 'active' &&
      this.timezoneService.areDatesWithinRange(this.selectedProject.startDateTime, this.selectedProject.endDateTime)
    ) {
      this.buttonDisplay = 'Active';
      this.buttonColor = 'green';
      this.colorHex = '';
    } else if (
      this.selectedProject.status === 'active' &&
      !this.timezoneService.areDatesWithinRange(this.selectedProject.startDateTime, this.selectedProject.endDateTime)
    ) {
      this.buttonDisplay = 'Expired';
      this.buttonColor = 'red';
      this.colorHex = '';
    }
  }
}
