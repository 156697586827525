import {AsyncPipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {select, Store} from '@ngrx/store';
import {map, Observable} from 'rxjs';
import {TrackerMetric} from 'src/app/core/model/tracker';
import {UpdateCurrentSkedValueChosen} from 'src/app/core/store/current-sked/current-sked.action';
import {selectCurrentSkedValueChosenMap} from 'src/app/core/store/current-sked/current-sked.selector';
import {TrackerMetricTypes} from 'src/app/trackers/shared/tracker-metric-types';
import {TrackerSelectOptionsPipe} from 'src/app/trackers/shared/tracker-select-options.pipe';
import {TrackerDetailMetricSelectComponent} from 'src/app/trackers/tracker-detail/metrics/metric/select/tracker-detail-metric-select.component';
import {OphInputOrangeComponent} from '../../design/oph-inputs/oph-input-orange/oph-input-orange.component';
import {OphSlideToggleComponent} from '../../design/oph-slide-toggle/oph-slide-toggle.component';

@Component({
  selector: 'tracker-metric-value-update',
  standalone: true,
  imports: [
    OphInputOrangeComponent,
    TrackerSelectOptionsPipe,
    TrackerDetailMetricSelectComponent,
    OphSlideToggleComponent,
    MatIconModule,
    AsyncPipe,
  ],
  templateUrl: './tracker-metric-value-update.component.html',
  styleUrl: './tracker-metric-value-update.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackerMetricValueUpdateComponent {
  @Input() metric: TrackerMetric;
  @Input() subtaskId: string; // if there is a subtaskId, this is used on the current sked. if not, used in tracker detail
  @Input() control: FormControl;
  @Input() inputStyle: Object;
  @Input() options: string[];
  @Input() selectStyle: Object;
  @Input() toggleWidth: string;
  @Input() showNumericUpdateButton: boolean;
  @Input() loadingStatus: string; // loading, success, null
  @Input() initiallyEmpty: boolean;

  @Output() updateValue = new EventEmitter<string>();

  valueChosenMap$: Observable<Record<string, boolean>>;
  valueChosen$: Observable<boolean>;

  trackerMetricTypes = TrackerMetricTypes;
  booleanButtonGroupOptions = [
    {value: '0', display: 'No'},
    {value: '1', display: 'Yes'},
  ];

  constructor(private store$: Store) {
    this.valueChosenMap$ = this.store$.pipe(select(selectCurrentSkedValueChosenMap));
    this.valueChosen$ = this.observeChosenMap();
  }

  observeChosenMap(): Observable<boolean> {
    return this.valueChosenMap$.pipe(
      map(chosenMap => {
        if (this.initiallyEmpty && !chosenMap[this.subtaskId]) {
          this.control.setValue('');
        }
        if (this.subtaskId) {
          return chosenMap[this.subtaskId];
        } else {
          return true;
        }
      })
    );
  }

  onSlideToggle(checked: boolean) {
    const value = checked ? '1' : '0';
    this.control.setValue(value);
    this.updateValue.emit(value);
  }

  onUpdateValue(value: string) {
    this.control.setValue(value);
    this.updateValue.emit(value);
    this.store$.dispatch(new UpdateCurrentSkedValueChosen({id: this.subtaskId}));
  }
}
