import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {BehaviorSubject, Subscription, combineLatest, startWith} from 'rxjs';
import {minutesToHoursAndMinutes} from '../../utils/minutes-to-hours-and-mintes';

@Component({
  selector: 'task-duration-slider',
  templateUrl: './task-duration-slider.component.html',
  styleUrls: ['./task-duration-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskDurationSliderComponent implements OnInit {
  @Input() control: FormControl;
  @Input() durationDisplayType: 'manualTask' | 'taskWizard' = 'taskWizard';

  subscription: Subscription;

  durationOptions$ = new BehaviorSubject<number[]>(null);
  durationDisplay$ = new BehaviorSubject<string>('');
  sliderValue$ = new BehaviorSubject<number>(0);

  maxDurationHours = 20; // hours
  maxTimeWithIntervals = 10; // hours
  menuOpen = false;

  ngOnInit() {
    this.durationOptions$.next(this.createDurationOptions());

    this.subscription = combineLatest([
      this.control.valueChanges.pipe(startWith(this.control.value)),
      this.durationOptions$,
    ]).subscribe(([duration, options]) => {
      this.durationDisplay$.next(minutesToHoursAndMinutes(duration));
      const index = options.findIndex(option => option === this.control.value) || 0;
      this.sliderValue$.next(index);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  createDurationOptions(): number[] {
    const durationWithIntervals = [];
    const durationWithoutIntervals = [];
    const maxTimeWithIntervalsMinutes = this.maxTimeWithIntervals * 60;
    for (let x = 15; x < maxTimeWithIntervalsMinutes; x += 15) {
      durationWithIntervals.push(x);
    }
    for (let y = this.maxTimeWithIntervals; y <= this.maxDurationHours; y++) {
      durationWithoutIntervals.push(y * 60);
    }
    return durationWithIntervals.concat(durationWithoutIntervals);
  }

  onSliderChange(index: number) {
    this.control.setValue(this.durationOptions$.value[index]);
  }
}
