import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {OphIconModule} from '../../oph-icon/oph-icon.module';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'oph-search-input',
  standalone: true,
  imports: [OphIconModule, ReactiveFormsModule, CommonModule],
  templateUrl: './oph-search-input.component.html',
  styleUrl: './oph-search-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OphSearchInputComponent {
  @Input() control: FormControl;
  @Input() inputStyle: Object;
  @Input() placeholder: string;
}
