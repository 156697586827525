import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'task-priority-selector',
  templateUrl: './task-priority-selector.component.html',
  styleUrls: ['./task-priority-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskPrioritySelectorComponent {
  @Input() control: FormControl;
  @Input() priorities = ['low', 'normal', 'high', 'crucial'];
  @Input() lightBackground: boolean;

  onPriority(priority: number) {
    this.control.setValue(priority);
  }
}
