import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'rewards-progress-bar',
  standalone: true,
  imports: [],
  templateUrl: './rewards-progress-bar.component.html',
  styleUrl: './rewards-progress-bar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RewardsProgressBarComponent {
  @Input() percentage: number;
  @Input() disabled: boolean;
}
