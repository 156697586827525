import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'twd-basic-info',
  templateUrl: './twd-basic-info.component.html',
  styleUrls: ['./twd-basic-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwdBasicInfoComponent implements AfterViewInit {
  @Input() form: FormGroup;

  @Output() hideHeaderAndFooter = new EventEmitter<boolean>();

  @ViewChild('taskNameInput') taskNameInput: ElementRef;

  showIconPicker$ = new BehaviorSubject<boolean>(false);

  ngAfterViewInit() {
    setTimeout(() => {
      this.taskNameInput.nativeElement.focus();
    }, 100);
  }

  onShowIconPicker() {
    this.showIconPicker$.next(true);
    this.hideHeaderAndFooter.emit(true);
  }

  onIconChange(value: string) {
    if (value) {
      this.iconControl.setValue(value);
    }
    this.showIconPicker$.next(false);
    this.hideHeaderAndFooter.emit(false);
  }

  get iconControl(): AbstractControl {
    return this.form.get('icon');
  }

  get labelControl(): AbstractControl {
    return this.form.get('label');
  }

  get descriptionControl(): AbstractControl {
    return this.form.get('description');
  }

  get eventControl(): AbstractControl {
    return this.form.get('event');
  }

  get durationControl(): AbstractControl {
    return this.form.get('duration');
  }

  get priorityControl(): AbstractControl {
    return this.form.get('priority');
  }

  get locationControl(): AbstractControl {
    return this.form.get('location');
  }
}
