import {TitleCasePipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {ProjectActionOption} from 'src/app/core/model/project';

@Component({
  selector: 'project-selector-menu-action-button',
  standalone: true,
  imports: [TitleCasePipe, MatIconModule],
  templateUrl: './project-selector-menu-action-button.component.html',
  styleUrl: './project-selector-menu-action-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectSelectorMenuActionButtonComponent {
  @Input() option: ProjectActionOption;

  @Output() buttonClick = new EventEmitter();
}
