import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import * as Sentry from '@sentry/angular';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {AuthenticationService} from '../../../auth/auth.service';
import {MessageService} from 'src/app/services/message.service';

/* eslint-disable @typescript-eslint/no-explicit-any */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private messageService: MessageService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(error => {
        if (error.status === 0) {
          // Most likely indicates that the request was terminated in a way that wasn't standard (like CORS issues, network failures, etc.).
          this.messageService.add(
            "It looks like we're having trouble accessing some necessary resources to complete your request. Please try again later."
          );
        }
        if (error.status === 401) {
          // auto logout if 401 response returned from api
          this.authenticationService.logout();
        }

        if (environment.sentryDsn && ![400, 401, 404, 500].includes(error.status)) {
          this.sendErrorToSentry(error);
        }

        const errorMessage = error.error || error.error.message || error.statusText;
        return throwError(errorMessage);
      })
    );
  }

  private sendErrorToSentry(error: any): void {
    if (error instanceof Error || error instanceof ErrorEvent) {
      Sentry.captureException(error);
    }

    if (error instanceof HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        Sentry.captureException(error.error);
      }

      Sentry.captureException(new Error(`${error.status}: ${String(error.error)}`), {
        level: 'error',
      });
    }

    Sentry.captureMessage(error);
  }
}
