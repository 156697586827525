import {AsyncPipe} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, map, Observable, Subscription, take} from 'rxjs';
import {RewardCoin} from 'src/app/core/model/reward';
import {User} from 'src/app/core/model/user';
import {selectActiveUser} from 'src/app/core/store/active-user/active-user.selector';
import {
  ClaimRewardAction,
  GetMyRewardsAction,
  UpdateMyRewardsExpandedMapAction,
  UpdateRewardsHeaderLoading,
} from 'src/app/core/store/rewards/rewards.action';
import {selectMyRewards, selectMyRewardsExpandedMap} from 'src/app/core/store/rewards/rewards.selector';
import {MessageService} from 'src/app/services/message.service';
import {OphButtonModule} from 'src/app/shared/design/oph-button/oph-button.module';
import {RewardsCoinExpansionPanelComponent} from '../shared/coin-expansion-panel/rewards-coin-expansion-panel.component';
import {RewardsRewardHeaderItemComponent} from '../shared/header-item/rewards-reward-header-item.component';
import {RewardsProgressBarComponent} from '../shared/progress-bar/rewards-progress-bar.component';
import {RewardsRewardCostComponent} from '../shared/reward-cost/rewards-reward-cost.component';
import {RewardManageProgressPipe} from '../shared/reward-manage-progress.pipe';
import {MatIconModule} from '@angular/material/icon';
import {DaysUntilExpirationPipe} from 'src/app/shared/pipes/days-until-expiration.pipe';
import {GetActiveUserAction} from 'src/app/core/store/active-user/active-user.action';
import {OphLoadingModule} from 'src/app/shared/design/oph-loading/oph-loading.module';

@Component({
  selector: 'rewards-my-rewards',
  standalone: true,
  imports: [
    AsyncPipe,
    RewardsCoinExpansionPanelComponent,
    RewardsRewardHeaderItemComponent,
    RewardsProgressBarComponent,
    RewardsRewardCostComponent,
    OphButtonModule,
    RewardManageProgressPipe,
    MatIconModule,
    DaysUntilExpirationPipe,
    OphLoadingModule,
  ],
  templateUrl: './rewards-my-rewards.component.html',
  styleUrl: './rewards-my-rewards.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RewardsMyRewardsComponent implements OnInit {
  activeUser$: Observable<User> = this.store$.pipe(select(selectActiveUser));
  activeUserCoinsCountMap$: Observable<Record<string, number>>;
  initialLoading$: Observable<boolean>;
  myRewards$: Observable<RewardCoin[]> = this.store$.pipe(select(selectMyRewards));
  myRewardsExpandedMap$: Observable<Record<string, boolean>> = this.store$.pipe(select(selectMyRewardsExpandedMap));

  loading$ = new BehaviorSubject<boolean>(true);
  getError$ = new BehaviorSubject<boolean>(false);

  userSub: Subscription;
  claimLoadingIndex = -1;

  constructor(
    private store$: Store,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.initialLoading$ = this.myRewards$.pipe(map(rewards => !rewards));
    this.getMyRewards();

    this.activeUserCoinsCountMap$ = this.observeActiveUser();
  }

  observeActiveUser(): Observable<Record<string, number>> {
    return this.activeUser$.pipe(
      map(user => {
        return user.coins.reduce((acc, coin) => {
          acc[coin.coinType] = coin.coinCount;
          return acc;
        }, {});
      })
    );
  }

  getMyRewards() {
    this.initialLoading$.pipe(take(1)).subscribe(loading => {
      if (loading) {
        return;
      }
      this.store$.dispatch(new UpdateRewardsHeaderLoading({headerLoading: true}));
    });

    this.store$.dispatch(
      new GetMyRewardsAction({
        onSuccess: () => {
          this.clearLoading();
          this.claimLoadingIndex = -1;
        },
        onFailure: err => {
          this.clearLoading();
          this.messageService.add(err.message || 'There was a problem getting rewards.');
          this.getError$.next(true);
        },
      })
    );
  }

  clearLoading() {
    this.store$.dispatch(new UpdateRewardsHeaderLoading({headerLoading: false}));
    this.loading$.next(false);
  }

  onMyRewardsExpandedMapChange(key: string, open: boolean) {
    this.store$.dispatch(new UpdateMyRewardsExpandedMapAction({key, open}));
  }

  onClaim(rewardId: string, assignedToArrayId: string, index: number) {
    this.claimLoadingIndex = index;
    this.store$.dispatch(
      new ClaimRewardAction({
        rewardId,
        assignedToArrayId,
        onSuccess: () => this.onClaimSuccess(),
        onFailure: err => this.onClaimFailure(err),
      })
    );
  }

  onClaimSuccess() {
    this.getMyRewards();
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.store$.dispatch(
      new GetActiveUserAction({
        email: encodeURIComponent(currentUser.email),
        onSuccess: () => this.onGetActiveUserSuccess(),
        onFailure: () => this.onGetActiveUserFailure(),
      })
    );
  }

  onGetActiveUserSuccess() {
    this.claimLoadingIndex = -1;
  }

  onGetActiveUserFailure() {
    this.claimLoadingIndex = -1;
    this.messageService.add('There was a problem updating the user coins.');
  }

  onClaimFailure(err: HttpErrorResponse) {
    this.claimLoadingIndex = -1;
    this.messageService.add(err.error || 'There was a problem claiming reward.');
  }
}
