import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Project} from 'src/app/core/model/project';

@Component({
  selector: 'project-selector-menu-project-button',
  standalone: true,
  imports: [],
  templateUrl: './project-selector-menu-project-button.component.html',
  styleUrl: './project-selector-menu-project-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectSelectorMenuProjectButtonComponent {
  @Input() project: Project;
  @Input() isSelected: boolean;

  @Output() buttonClick = new EventEmitter();
}
