import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {projectsRouting} from './projects/projects.routing';
import {reportingRouting} from './reporting-new/reporting.routing';
import {trackersRouting} from './trackers/trackers.routing';
import {rewardsRouting} from './rewards/rewards.routing';

const routes: Routes = [
  {
    path: 'current-sked',
    loadChildren: () => import('./current-sked/current-sked.module').then(m => m.CurrentSkedModule),
  },
  {
    path: 'goals',
    loadChildren: () => import('./goals/goals.module').then(m => m.GoalsModule),
  },
  {
    path: 'skills',
    loadChildren: () => import('./skills/skills.module').then(m => m.SkillsModule),
    // canLoad: [CanActivateGuard], //this and following canLoads will be added back when permissions are revisited
    data: {
      privileges: ['Can view Skills'],
      bottomNavShown: true,
    },
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
  },
  {
    path: 'roles',
    loadChildren: () => import('./roles/roles.module').then(m => m.RolesModule),
    // canLoad: [CanActivateGuard],
    data: {
      privileges: ['Can view Roles'],
      bottomNavShown: true,
    },
  },
  {
    path: 'lenses',
    loadChildren: () => import('./lenses/lenses.module').then(m => m.LensesModule),
    data: {
      bottomNavShown: true,
    },
  },
  {
    path: 'privileges',
    loadChildren: () => import('./privileges/privileges.module').then(m => m.PrivilegesModule),
    // canLoad: [CanActivateGuard],
    data: {
      privileges: ['Can view Privileges'],
      bottomNavShown: true,
    },
  },
  {
    path: 'programs',
    loadChildren: () => import('./programs/programs.module').then(m => m.ProgramsModule),
    data: {
      bottomNavShown: true,
    },
  },
  {
    path: 'locations',
    loadChildren: () => import('./locations/locations.module').then(m => m.LocationsModule),
    data: {
      bottomNavShown: true,
    },
  },
  {
    path: 'actions',
    loadChildren: () => import('./actions/actions.module').then(m => m.ActionsModule),
    data: {
      bottomNavShown: true,
    },
  },
  {
    path: 'teams',
    loadChildren: () => import('./teams/teams.module').then(m => m.TeamsModule),
    data: {
      bottomNavShown: true,
    },
  },
  {
    path: 'team-schedule',
    loadChildren: () => import('./team-schedule/team-schedule.module').then(m => m.TeamScheduleModule),
    data: {
      bottomNavShown: true,
    },
  },
  {
    path: 'projects',
    children: projectsRouting,
  },
  {
    path: 'reporting-old',
    loadChildren: () => import('./reporting/reporting.module').then(m => m.ReportingModule),
  },
  {
    path: 'reporting',
    children: reportingRouting,
  },
  {
    path: 'schedule',
    loadChildren: () => import('./schedule/schedule.module').then(m => m.ScheduleModule),
  },
  {
    path: 'trackers',
    children: trackersRouting,
  },
  {
    path: 'rewards',
    children: rewardsRouting,
  },
  {path: 'user/:id', redirectTo: '/users/:id'},
  {path: '', redirectTo: '/current-sked', pathMatch: 'full'},

  // Wildcard route
  {
    path: '**',
    redirectTo: '/current-sked',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
