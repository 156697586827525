import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {Subtask} from 'src/app/core/model/task';
import {TrackerMetricValueUpdateComponent} from 'src/app/shared/trackers/tracker-metric-value-update/tracker-metric-value-update.component';
import {TaskLoadingState} from '../../shared/model/task-loading-state';
import {CurrentSkedTaskDetailValueDisplayComponent} from '../value-display/current-sked-task-detail-value-display.component';

@Component({
  selector: 'current-sked-task-detail-subtask-metric-update',
  standalone: true,
  imports: [MatIconModule, TrackerMetricValueUpdateComponent, CurrentSkedTaskDetailValueDisplayComponent],
  templateUrl: './current-sked-task-detail-subtask-metric-update.component.html',
  styleUrl: './current-sked-task-detail-subtask-metric-update.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentSkedTaskDetailSubtaskMetricUpdateComponent implements OnChanges {
  @Input() loading: TaskLoadingState;
  @Input() subtask: Subtask;
  @Input() subtaskIndex: number;
  @Input() ableToCompleteSubtaskIndex: number;

  @Output() updateMetricValue = new EventEmitter<string>();

  subtaskValueControl = new FormControl('');

  ngOnChanges(changes: SimpleChanges) {
    if (changes.subtask && this.subtask) {
      this.subtaskValueControl.setValue(
        String(this.subtask.tracker?.metric?.value || this.subtask.tracker?.metric?.defaultValue || '')
      );
    }
  }

  onUpdateMetricValue(value: string) {
    this.updateMetricValue.emit(value);
  }
}
