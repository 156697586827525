<div class="date-display">
  {{ scheduleOnDateControl?.value ? (scheduleOnDateControl.value | convertUtcToTimezone: 'ddd, MMM DD, YYYY') : '' }}
  &nbsp;
  <span class="from">from</span>
</div>

<div [class.hide]="showTimepicker$ | async" class="input-container">
  <div #startTime>
    <input
      placeholder="Start time"
      readonly
      type="text"
      class="timepicker-ui-input time-input"
      [class.error]="eventStartControl.invalid && eventStartControl.value"
      [value]="eventStartControl.value"
    />
  </div>
  <span class="to">to</span>
  <div #endTime>
    <input
      placeholder="Stop time"
      readonly
      type="text"
      class="timepicker-ui-input time-input"
      [class.error]="eventEndControl.invalid && eventEndControl.value"
      [value]="eventEndControl.value"
    />
  </div>
</div>
@if (!eventTimesControls.valid && eventStartControl.value && eventEndControl.value) {
  <span class="to error">Start time should be before stop time.</span>
}

<div class="task-wizard-timepicker"></div>
