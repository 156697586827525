<div class="action-menu">
  @for (action of actions; track action) {
    <button class="action-button" (click)="onButtonClick(action)">
      @if (action === 'duplicate') {
        <div class="item-wrapper">
          <oph-icon name="duplicate-purple"></oph-icon>
          <span>DUPLICATE</span>
        </div>
      }
      @if (action === 'delete') {
        <div class="item-wrapper">
          <oph-icon name="trash-red"></oph-icon>
          <span>DELETE</span>
        </div>
      }
      @if (action === 'edit') {
        <div class="item-wrapper">
          <oph-icon name="edit-orange"></oph-icon>
          <span>EDIT</span>
        </div>
      }
    </button>
  }
</div>
<button mat-icon-button aria-label="Menu" class="hide-button" (click)="onCloseMenu()">
  <oph-icon class="desktop" name="line-brown"></oph-icon>
  <oph-icon class="mobile" name="line-brown" size="16"></oph-icon>
</button>
