import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {TaskDialogPanels} from '../../shared/task-dialog-constants';

@Component({
  selector: 'task-dialog-icon-picker',
  templateUrl: './task-dialog-icon-picker.component.html',
  styleUrls: ['./task-dialog-icon-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskDialogIconPickerComponent {
  @Input() iconControl: AbstractControl;

  @Output() panelChange = new EventEmitter<TaskDialogPanels>();

  onIconChange(icon: string) {
    if (icon) {
      this.iconControl.setValue(icon);
    }
    this.panelChange.emit(TaskDialogPanels.Default);
  }
}
