import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {NumberAbbreviationPipe} from 'src/app/shared/pipes/number-abbreviation.pipe';
import {PipesModule} from 'src/app/shared/pipes/pipes.module';
import {RewardCoinIconPipe} from 'src/app/shared/pipes/reward-coin-icon.pipe';

@Component({
  selector: 'rewards-reward-cost',
  standalone: true,
  imports: [MatIconModule, PipesModule, NumberAbbreviationPipe, RewardCoinIconPipe],
  templateUrl: './rewards-reward-cost.component.html',
  styleUrl: './rewards-reward-cost.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RewardsRewardCostComponent {
  @Input() coinName: string;
  @Input() rewardCost: number;
  @Input() silverCoin: boolean;
}
