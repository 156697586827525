<div class="oph-button-group" [ngStyle]="style">
  @for (option of options; track option) {
    <button
      [ngStyle]="buttonStyle"
      [class.selected]="option.name === selectedOptionName"
      [class.disabled]="option.disabled"
      (click)="optionClick.emit(option.name)"
    >
      {{ option.name | titlecase }}
    </button>
  }
</div>
