import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
import {AutoSked} from 'src/app/schedule/shared/model/autosked';

@Pipe({
  name: 'autoskedTimeDisplay',
  standalone: true,
})
export class AutoskedTimeDisplayPipe implements PipeTransform {
  transform(autoSked: AutoSked): unknown {
    return `${moment(autoSked.start, 'HH:mm').format('h:mm A')} - ${moment(autoSked.end, 'HH:mm').format(
      'h:mm A'
    )}`.replace(/:00/g, '');
  }
}
