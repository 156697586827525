import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {convertLocationDtoToModel} from '../../../locations/shared/converters/convert-location-dto-to-model';
import {LocationDto} from '../../../locations/shared/dto/location.dto';
import {LocationsApiService} from '../../api/locations-api.service';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {
  CreateLocationAction,
  CreateLocationSuccessAction,
  DeleteLocationAction,
  DeleteLocationSuccessAction,
  GetLocationsAction,
  GetLocationsSuccessAction,
  LocationsActionType,
  UpdateLocationAction,
  UpdateLocationSuccessAction,
} from './locations.action';

@Injectable()
export class LocationsEffects {
  public get$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetLocationsAction>(LocationsActionType.GET),
      mergeMap(action => {
        const {onSuccess, onFailure} = action.payload;

        return this.locationsApiService.get().pipe(
          map((dtos: LocationDto[]) => dtos.map(dto => convertLocationDtoToModel(dto))),
          mergeMap(locations => [new GetLocationsSuccessAction({locations}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public create$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateLocationAction>(LocationsActionType.CREATE),
      mergeMap(action => {
        const {location, onSuccess, onFailure} = action.payload;

        return this.locationsApiService.create(location).pipe(
          mergeMap(l => [new CreateLocationSuccessAction({location: l}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public update$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateLocationAction>(LocationsActionType.UPDATE),
      mergeMap(action => {
        const {location, onSuccess, onFailure} = action.payload;

        return this.locationsApiService.update(location).pipe(
          mergeMap(l => [new UpdateLocationSuccessAction({location: l}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public delete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteLocationAction>(LocationsActionType.DELETE),
      mergeMap(action => {
        const {id, onSuccess, onFailure} = action.payload;

        return this.locationsApiService.delete(id).pipe(
          mergeMap(programId => [new DeleteLocationSuccessAction(), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private locationsApiService: LocationsApiService // private store$: Store<{}>
  ) {}
}
