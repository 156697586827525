import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OphLoadingComponent} from './oph-loading.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@NgModule({
  declarations: [OphLoadingComponent],
  imports: [CommonModule, MatProgressSpinnerModule],
  exports: [OphLoadingComponent],
})
export class OphLoadingModule {}
