import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Goal, Target} from '../model/goal';

@Injectable({
  providedIn: 'root',
})
export class GoalsApiService {
  public url = `${environment.apiUrl}/goals`;

  constructor(private http: HttpClient) {}

  get(): Observable<Goal[]> {
    return this.http.get<Goal[]>(this.url);
  }

  getSingle(id: string): Observable<Goal> {
    return this.http.get<Goal>(`${this.url}/${id}`);
  }

  create(goal: Goal): Observable<{message: string; goal: Goal}> {
    return this.http.post<{message: string; goal: Goal}>(`${this.url}`, goal);
  }

  update(goal: Goal): Observable<Goal> {
    return this.http.put<Goal>(`${this.url}/${goal._id}`, goal);
  }

  delete(id: string): Observable<string> {
    return this.http.delete<string>(`${this.url}/${id}`);
  }

  connectTarget(goalId: string, objectiveId: string, target: Partial<Target>): Observable<Goal> {
    return this.http.patch<Goal>(`${this.url}/connectTarget`, {
      goalId,
      objectiveId,
      target,
    });
  }

  disconnectTarget(goalId: string, objectiveId: string, target: Partial<Target>): Observable<Goal> {
    return this.http.patch<Goal>(`${this.url}/removeConnection`, {
      goalId,
      objectiveId,
      target,
    });
  }

  toggleTarget(goalId: string, objectiveId: string, targetId: string): Observable<Goal> {
    return this.http.patch<Goal>(`${this.url}/toggleComplete`, {
      goalId,
      objectiveId,
      targetId,
    });
  }
}
