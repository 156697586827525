<button cdkDrag [class.template]="template" [class.disabled]="loading">
  @if (loading) {
    <div class="loading-container">
      <mat-spinner diameter="36"></mat-spinner>
    </div>
  }
  <div class="icon-container">
    <oph-icon
      class="task-icon"
      [name]="'color-icons/' + task.icon"
      imageErrorName="color-icons/clock-time-watch"
      size="44"
    ></oph-icon>
    @if (template) {
      <oph-icon class="drag-icon" name="arrow-left" size="25" cdkDragHandle></oph-icon>
    } @else {
      <oph-icon class="drag-icon" name="3-line-handle" size="25" cdkDragHandle></oph-icon>
    }
  </div>

  <div class="text-container">
    <span class="task-title">{{ task.label }}</span>
    <div class="duration-container">
      <span class="duration">{{ task.duration | minutesToHoursAndMinutes }}</span>
      @if (!template) {
        <div class="priority" [style.background-color]="priorityColor"></div>
        <div class="skills-container">
          @for (requirement of shortenedSkillsArray; track requirement; let i = $index) {
            @if (requirementsMap[requirement]) {
              <div class="skill">
                <oph-icon name="star-brown" size="10"></oph-icon>
                <span class="skill-text">{{ requirementsMap[requirement].name }}</span>
              </div>
            }
          }
          @if (task.skills?.length > 2) {
            <div class="skill more">
              <oph-icon name="plus-brown" size="10"></oph-icon>
              <span class="more-text"> {{ task.requirements.length - 2 }}</span>
            </div>
          }
        </div>
      }
    </div>
  </div>

  <div class="subtask-container">
    @if (task.location?.length && !template) {
      <img src="assets/img/icons/location.svg" [matTooltip]="locationsMap[task.location]?.name || ''" />
    }
    @if (task.event && !template) {
      <img src="assets/img/icons/clock-light.svg" class="clock-icon" [matTooltip]="(eventTime | uppercase) || ''" />
    }
    @if (task.schedule?.scheduleType === 'repeats' && !template) {
      <img src="assets/img/icons/repeat-green.svg" />
    }
    <div class="subtask-count">
      {{ task.subtasks?.length || 0 }}
    </div>
  </div>
  @if (!template) {
    <div class="task-placeholder" *cdkDragPlaceholder></div>
  } @else {
    <div class="template-placeholder" *cdkDragPlaceholder></div>
  }
</button>
