import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@Component({
  selector: 'project-view-progress',
  standalone: true,
  imports: [MatProgressSpinnerModule],
  templateUrl: './project-view-progress.component.html',
  styleUrl: './project-view-progress.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectViewProgressComponent {
  @Input() percentComplete: string;

  strokeWidth = 7;
  diameter = 85;
}
