<div class="title-container">
  @if (icon) {
    <mat-icon [svgIcon]="icon" [style.color]="iconColor"></mat-icon>
  }
  <label>{{ name }}</label>
</div>

<div class="right-container">
  @if (view === ListViewOptions.Table) {
    <oph-icon
      class="view-icon"
      (click)="onView(ListViewOptions.Grid)"
      [name]="'list-views/grid-view'"
      [size]="27"
    ></oph-icon>
  }
  @if (view === ListViewOptions.Grid) {
    <oph-icon
      class="view-icon"
      (click)="onView(ListViewOptions.Table)"
      [name]="'list-views/table-view'"
      [size]="27"
    ></oph-icon>
  }

  @if (!hideSearch) {
    <div class="input-container">
      <oph-icon [name]="'list-views/search'" [size]="22"></oph-icon>
      <input placeholder="Search" [value]="searchValue" (input)="onSearch($event.target.value)" />
    </div>
  }

  <oph-button
    color="green"
    (buttonClick)="onCreateNew()"
    [style]="{'font-size': '14px', height: '34px', padding: '0 10px', gap: '8px', 'min-width': '126px'}"
    [loading]="loadingCreateNew"
    [disabled]="loadingCreateNew"
  >
    <mat-icon svgIcon="plus" class="plus-icon"></mat-icon>
    {{ createButtonText || 'Create new' }}
  </oph-button>
</div>
