import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'task-dialog-panel-footer',
  templateUrl: './task-dialog-panel-footer.component.html',
  styleUrls: ['./task-dialog-panel-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskDialogPanelFooterComponent {
  @Input() disabled: boolean;
  @Input() marginBottom: number;

  @Output() cancel = new EventEmitter();
  @Output() ok = new EventEmitter();
}
