import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'projectViewRoutineStatusColor',
  standalone: true,
})
export class ProjectViewRoutineStatusColorPipe implements PipeTransform {
  transform(value: string): string {
    if (value === 'notComplete') {
      return '#F2C469';
    }
    if (value === 'complete') {
      return '#7BC143';
    }
    return '#D8D3C6';
  }
}
