import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ActionDto} from '../../actions/shared/dto/action.dto';
import {Action} from '../../actions/shared/model/action';

@Injectable({
  providedIn: 'root',
})
export class ActionsApiService {
  public url = `${environment.apiUrl}/actions`;

  constructor(private http: HttpClient) {}

  public get(): Observable<ActionDto[]> {
    return this.http.get<ActionDto[]>(this.url);
  }

  public create(action: Action): Observable<Action> {
    return this.http.post<Action>(`${this.url}`, action);
  }

  public update(action: Action): Observable<Action> {
    return this.http.put<Action>(`${this.url}/${action.id}`, action);
  }

  public delete(id: string): Observable<Object> {
    return this.http.delete(`${this.url}/${id}`);
  }
}
