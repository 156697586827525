<button class="project-selector-button" [matMenuTriggerFor]="projectSelectorMenu">
  <div class="project-color" [style.background-color]="selectedProject?.color || '#E5E3DC'"></div>
  <span class="project-name">{{ selectedProject.label }}</span>
  <div class="project-selector-button-exp-container">
    @if (selectedProject?.status !== 'inactive' && isProjectActive) {
      <span class="expiration">exp {{ selectedProject.endDateTime | convertUtcToTimezone: 'MMM D, YYYY' }}</span>
    }
    <mat-icon svgIcon="chevron-down"></mat-icon>
  </div>
</button>
<mat-menu #projectSelectorMenu="matMenu" class="oph-select-menu">
  <ng-content select=".project-selector"> </ng-content>
</mat-menu>

<oph-button
  [matMenuTriggerFor]="projecActiontMenu"
  appearance="solid"
  [color]="buttonColor"
  [colorHex]="colorHex"
  [style]="{width: '125px', justifyContent: 'space-between', color: 'white', height: '30px', borderRadius: '6px'}"
  [loading]="loading"
>
  {{ buttonDisplay }}
  <img src="assets/img/icons/chevron-down-white.svg" />
</oph-button>
<mat-menu #projecActiontMenu="matMenu" class="oph-select-menu">
  <ng-content select=".project-menu"> </ng-content>
</mat-menu>
