import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NewTaskButtonComponent} from './new-task-button.component';
import {MatIconModule} from '@angular/material/icon';
import {OphIconModule} from '../../design/oph-icon/oph-icon.module';

@NgModule({
  declarations: [NewTaskButtonComponent],
  imports: [CommonModule, MatIconModule, OphIconModule],
  exports: [NewTaskButtonComponent],
})
export class NewTaskButtonModule {}
