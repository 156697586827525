import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'trackerSelectOptions',
  standalone: true,
})
export class TrackerSelectOptionsPipe implements PipeTransform {
  transform(value: {value: string}[]): string[] {
    return value?.reduce((acc, v) => {
      if (v.value) {
        acc.push(v.value);
      }
      return acc;
    }, [] as string[]);
  }
}
