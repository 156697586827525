import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {CurrentSkedTask} from 'src/app/core/model/task';
import {User} from 'src/app/core/model/user';
import {ProjectTaskUserComponent} from '../../shared/project-task-user/project-task-user.component';
import {PipesModule} from 'src/app/shared/pipes/pipes.module';
import {OphIconModule} from 'src/app/shared/design/oph-icon/oph-icon.module';

@Component({
  selector: 'project-view-task',
  standalone: true,
  imports: [ProjectTaskUserComponent, PipesModule, OphIconModule],
  templateUrl: './project-view-task.component.html',
  styleUrl: './project-view-task.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectViewTaskComponent implements OnChanges {
  @Input() task: CurrentSkedTask;
  @Input() type: string;
  @Input() user: User;

  @Output() taskClick = new EventEmitter();

  completeSubtaskCount: number;
  userSubtext: string;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.task && this.task) {
      this.completeSubtaskCount = this.task.subtasks.filter(s => s.complete)?.length || 0;
      this.userSubtext = this.getUserSubtext();
    }
  }

  getUserSubtext(): string {
    switch (this.type) {
      case 'todoTasks':
        return 'Assigned to';
      case 'inProgressTasks':
        return 'In-progress by';
      case 'expiredTasks':
        return this.task.status === 'inProgress' ? 'In-progress by' : 'Assigned to';
      case 'cancelledTasks':
        return 'Cancelled by';
      case 'completedTasks':
        return 'Completed by';
      case 'onHoldTasks':
        return 'On hold by';
      default:
        return '';
    }
  }

  onTask() {
    this.taskClick.emit();
  }
}
