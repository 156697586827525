import {LocationsAction, LocationsActionType} from './locations.action';
import {initialLocationsState, LocationsState} from './locations.state';

export function locationsReducer(
  state: LocationsState = initialLocationsState,
  action: LocationsAction
): LocationsState {
  switch (action.type) {
    case LocationsActionType.GET_SUCCESS:
      return {...state, locations: action.payload.locations};
    default:
      return state;
  }
}
