import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MatExpansionModule} from '@angular/material/expansion';
import {Tracker} from 'src/app/core/model/tracker';
import {OphIconModule} from 'src/app/shared/design/oph-icon/oph-icon.module';
import {PipesModule} from 'src/app/shared/pipes/pipes.module';
import {ProjectViewIconContainerComponent} from '../icon-container/project-view-icon-container.component';
import {TrackerMetricTypes} from 'src/app/trackers/shared/tracker-metric-types';

@Component({
  selector: 'project-view-tracker',
  standalone: true,
  imports: [MatExpansionModule, ProjectViewIconContainerComponent, PipesModule, OphIconModule],
  templateUrl: './project-view-tracker.component.html',
  styleUrl: './project-view-tracker.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectViewTrackerComponent implements OnChanges {
  @Input() tracker: Tracker;
  @Input() mostRecentlyUpdatedMetricIndex: number;

  expanded: boolean = false;
  // This is for display purposes
  evenMetricCount: boolean;
  trackerMetricTypes = TrackerMetricTypes;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tracker && this.tracker) {
      this.evenMetricCount = this.tracker?.metrics?.length % 2 === 0;
    }
  }

  onTracker(opened: boolean) {
    this.expanded = opened;
  }
}
