import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ScheduleProjectLegendComponent} from './project-legend/schedule-project-legend.component';
import {OphChipModule} from 'src/app/shared/design/oph-chip/oph-chip.module';
import {EditSkedComponent} from './edit-sked/edit-sked.component';
import {ProjectSelectComponent} from './edit-sked/project-select/project-select.component';
import {OphButtonModule} from 'src/app/shared/design/oph-button/oph-button.module';
import {MatIconModule} from '@angular/material/icon';
import {OphInputsModule} from 'src/app/shared/design/oph-inputs/oph-inputs.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SchedulerToolbarComponent} from './scheduler-toolbar/scheduler-toolbar.component';
import {MatMenuModule} from '@angular/material/menu';

@NgModule({
  declarations: [ScheduleProjectLegendComponent, EditSkedComponent, ProjectSelectComponent, SchedulerToolbarComponent],
  imports: [
    CommonModule,
    OphChipModule,
    OphButtonModule,
    MatIconModule,
    OphInputsModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
  ],
  exports: [ScheduleProjectLegendComponent, EditSkedComponent, SchedulerToolbarComponent, ProjectSelectComponent],
})
export class ScheduleSharedModule {}
