import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  OnInit,
} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

/**
 *
 * If you want to add an icon, you need to add the .icon class to the <img> or whatever element you provide as a child.
 * For example: <oph-button><span class="icon-remove icon"></span></oph-button>
 *
 * If you decide not to use the predefined colors provided below, you can use colorHex (background) and textColorHex
 * (both must be provided together in that case).
 *
 * Often, the icon itself is not vertically aligned. Applying flex (both align and justify) to the icon (e.g span) helps.
 *
 */

@Component({
  selector: 'oph-button',
  templateUrl: './oph-button.component.html',
  styleUrls: ['./oph-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OphButtonComponent implements OnInit, OnChanges {
  @Input() appearance: 'solid' | 'outline' | 'text' = 'solid';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() color: 'orange' | 'green' | 'brown' | 'red' | 'gray' | 'yellow' | 'blue' | string;
  @Input() colorHex: string;
  @Input() colorHexHover: string;
  @Input() colorHexText: string;
  @Input() disabled: boolean = false;
  @Input() loading: boolean;
  @Input() style: Object;

  @Output() buttonClick = new EventEmitter();

  hoverBackground$ = new BehaviorSubject<string>('');

  className: string;
  hexColorText: string;

  ngOnInit() {
    this.setClassName();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.appearance || changes.color || changes.size || changes.disabled) {
      this.setClassName();
    }
    if (changes.appearance || changes.color || changes.disabled) {
      // this.hexColor = this.getColorHex();
      this.hexColorText = this.getColorHexText();
    }
  }

  setClassName() {
    this.className = `${this.appearance} ${this.color || ''} ${this.size} ${this.disabled ? 'disabled' : ''}`;
  }

  onButtonHover() {
    this.hoverBackground$.next(this.colorHexHover || this.colorHex);
  }

  onButtonLeave() {
    this.hoverBackground$.next('');
  }

  getColorHex(): string {
    if (this.disabled) {
      return '#A3A099';
    }
    if (this.colorHex) {
      return this.colorHex;
    }
    return this.getHexValue();
  }

  getColorHexText(): string {
    if (this.colorHexText) {
      return this.colorHexText;
    }
    if (this.appearance === 'solid') {
      return 'white';
    }
    return this.getHexValue();
  }

  getHexValue(): string {
    switch (this.color) {
      case 'orange':
        return '#F57B4E';
      case 'green':
        return '#7BC143';
      case 'brown':
        return '#7c655f';
      case 'red':
        return '#d95050';
      case 'gray':
        return '#E5E3DC';
      case 'yellow':
        return '#DDB731';
      case 'blue':
        return '#55CBBD';
      default:
        return '#7a746f';
    }
  }
}
