import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {OphButtonModule} from '../../design/oph-button/oph-button.module';

@Component({
  selector: 'oph-delete-dialog-content',
  standalone: true,
  imports: [OphButtonModule],
  templateUrl: './oph-delete-dialog-content.component.html',
  styleUrl: './oph-delete-dialog-content.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OphDeleteDialogContentComponent {
  // This component is used inside of a feature delete dialog as the dialog content
  @Input() message: string;
  @Input() loading: boolean;

  @Output() close = new EventEmitter<boolean>();
}
