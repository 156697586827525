import {ErrorHandler, Injectable} from '@angular/core';
import * as Sentry from '@sentry/angular';
import {environment} from '../../../environments/environment';

if (environment.sentryDsn) {
  Sentry.init({
    dsn: environment.sentryDsn,
    environment: environment.envName || '',
    ignoreErrors: ['event.data.split is not a function'],
  });
}

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private lastError: string | null = null;
  private debounceTime = 1000; // 1 second debounce time
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private errorTimeout: any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public handleError(error: any): void {
    // Avoid duplicate errors by implementing a debounce mechanism
    const errorMessage = error.originalError?.message || error.message || error.toString();

    if (this.lastError === errorMessage) {
      return;
    }

    this.lastError = errorMessage;

    clearTimeout(this.errorTimeout);
    this.errorTimeout = setTimeout(() => {
      this.lastError = null;
    }, this.debounceTime);

    if (/Loading chunk [\d]+ failed/.test(errorMessage)) {
      window.location.reload();
      return;
    }

    if (environment.sentryDsn) {
      Sentry.captureException(error.originalError || error);
    }

    console.error(error);
  }
}
