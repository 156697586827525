import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CurrentSkedAuthorizationObject} from 'src/app/core/model/task';
import {AvailableTaskActions} from 'src/app/current-sked/shared/model/available-task-actions';
import {TaskStatuses} from 'src/app/current-sked/shared/task-statuses';
import {OphButtonModule} from 'src/app/shared/design/oph-button/oph-button.module';

export interface DialogData {
  auth: CurrentSkedAuthorizationObject;
  taskActions: AvailableTaskActions;
  taskStatus: TaskStatuses;
}

@Component({
  selector: 'current-sked-status-button-info-dialog',
  standalone: true,
  imports: [OphButtonModule],
  templateUrl: './current-sked-status-button-info-dialog.component.html',
  styleUrl: './current-sked-status-button-info-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentSkedStatusButtonInfoDialogComponent {
  readonly dialogRef = inject(MatDialogRef<CurrentSkedStatusButtonInfoDialogComponent>);
  readonly data = inject<DialogData>(MAT_DIALOG_DATA);
  readonly auth = this.data.auth;
  readonly taskActions = this.data.taskActions;
  readonly taskStatus = this.data.taskStatus;
  taskStatuses = TaskStatuses;

  onOk(): void {
    this.dialogRef.close();
  }
}
