export interface TaskLoadingState {
  task: boolean;
  action: boolean;
  subtaskIndex: number;
  metricId: string;
  metricStatus: string; // loading, success, ''
}

export const EMPTY_TASK_LOADING_STATE: TaskLoadingState = {
  task: true,
  action: false,
  subtaskIndex: null,
  metricId: '',
  metricStatus: '',
};
