import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'task-icon-picker-button',
  templateUrl: './task-icon-picker-button.component.html',
  styleUrls: ['./task-icon-picker-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskIconPickerButtonComponent {
  @Input() iconName: string;
  @Input() lightBackground: boolean;

  @Output() buttonClick = new EventEmitter();
}
