import {ActionsAction, ActionsActionType} from './actions.action';
import {ActionsState, initialActionsState} from './actions.state';

export function actionsReducer(state: ActionsState = initialActionsState, action: ActionsAction): ActionsState {
  switch (action.type) {
    case ActionsActionType.GET_SUCCESS:
      return {...state, actions: action.payload.actions};
    default:
      return state;
  }
}
