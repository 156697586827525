import {createSelector} from '@ngrx/store';
import {LensSettings} from 'src/app/lenses/lens-settings';
import {AppState} from '../app-state';
import {Lens} from 'src/app/lenses/shared/model/lens';

export const selectLensesState = (state: AppState) => state.lenses;

export const selectLenses = createSelector(selectLensesState, state => state.lenses);

export const selectLensesMap = createSelector(
  selectLenses,
  lenses => lenses?.reduce((lensesMap: Record<string, Lens>, obj: Lens) => ((lensesMap[obj._id] = obj), lensesMap), {})
);

export const selectLensSettings = createSelector(selectLensesState, state => state.settings);

export const selectSingleLens = createSelector(selectLensesState, state => state.lens);

export const selectUserLens = createSelector(selectLensesState, state => state.userLens);

export const selectUserLensSettingsMap = createSelector(selectLensesState, state => state.userLensSettingsMap);

export const selectLensSettingsMap = createSelector(selectLensesState, state => state.settingsMap);

export const selectCombinedLensSettingsMap = createSelector(
  selectLensSettingsMap,
  selectUserLensSettingsMap,
  (settingsMap, userSettingsMap) => {
    if (settingsMap && userSettingsMap) {
      const settings = Object.values(LensSettings);
      const combinedSettings = {};
      settings.forEach(setting => {
        combinedSettings[setting] = userSettingsMap[setting]?.value
          ? userSettingsMap[setting].value
          : settingsMap[setting]?.defaultValue || 0;
      });
      return combinedSettings as Record<LensSettings, string>;
    }
  }
);
