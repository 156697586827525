import {Injectable} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';

@Injectable({
  providedIn: 'root',
})
export class IconRegistryService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}

  icons = [
    {name: 'activity', path: '../assets/img/icons/universal/activity.svg'},
    {name: 'alert-triangle', path: '../assets/img/icons/universal/alert-triangle.svg'},
    {name: 'auto-sked', path: '../assets/img/icons/universal/auto-sked.svg'},
    {name: 'basketball', path: '../assets/img/icons/universal/basketball.svg'},
    {name: 'book', path: '../assets/img/icons/universal/book.svg'},
    {name: 'book-open', path: '../assets/img/icons/universal/book-open.svg'},
    {name: 'bolt', path: '../assets/img/icons/universal/bolt.svg'},
    {name: 'box', path: '../assets/img/icons/universal/box.svg'},
    {name: 'briefcase', path: '../assets/img/icons/universal/briefcase.svg'},
    {name: 'bucket', path: '../assets/img/icons/builder/project-builder/task-icons/bucket.svg'},
    {name: 'calendar', path: '../assets/img/icons/universal/calendar.svg'},
    {name: 'check', path: '../assets/img/icons/universal/check.svg'},
    {name: 'checkbox-check', path: '../assets/img/icons/universal/checkbox-check.svg'},
    {name: 'check-square', path: '../assets/img/icons/universal/check-square.svg'},
    {name: 'chevron-down', path: '../assets/img/icons/universal/chevron-down.svg'},
    {name: 'chevron-left', path: '../assets/img/icons/universal/chevron-left.svg'},
    {name: 'chevron-right', path: '../assets/img/icons/universal/chevron-right.svg'},
    {name: 'chevron-up', path: '../assets/img/icons/universal/chevron-up.svg'},
    {name: 'clock', path: '../assets/img/icons/universal/clock.svg'},
    {name: 'clock-thin', path: '../assets/img/icons/universal/clock-thin.svg'},
    {name: 'clock-fill', path: '../assets/img/icons/universal/icon-clock-fill.svg'},
    {name: 'close', path: '../assets/img/icons/universal/close.svg'},
    {name: 'coin-amber', path: '../assets/img/icons/coins/coin-amber.svg'},
    {name: 'coin-amethyst', path: '../assets/img/icons/coins/coin-amethyst.svg'},
    {name: 'coin-emerald', path: '../assets/img/icons/coins/coin-emerald.svg'},
    {name: 'coin-golden', path: '../assets/img/icons/coins/coin-golden.svg'},
    {name: 'coin-silver', path: '../assets/img/icons/coins/coin-silver.svg'},
    {name: 'coin-quartz', path: '../assets/img/icons/coins/coin-quartz.svg'},
    {name: 'coin-sapphire', path: '../assets/img/icons/coins/coin-sapphire.svg'},
    {name: 'columns', path: '../assets/img/icons/universal/columns.svg'},
    {name: 'dashboard', path: '../assets/img/icons/universal/dashboard.svg'},
    {name: 'dollar-sign', path: '../assets/img/icons/universal/dollar-sign.svg'},
    {name: 'family', path: '../assets/img/icons/universal/family.svg'},
    {name: 'feather', path: '../assets/img/icons/universal/feather.svg'},
    {name: 'fish', path: '../assets/img/icons/builder/project-builder/task-icons/fish.svg'},
    {name: 'grid', path: '../assets/img/icons/builder/project-builder/task-icons/grid.svg'},
    {name: 'heart', path: '../assets/img/icons/universal/heart.svg'},
    {name: 'house', path: '../assets/img/icons/universal/house.svg'},
    {name: 'info', path: '../assets/img/icons/universal/info.svg'},
    {name: 'leaf', path: '../assets/img/icons/builder/project-builder/task-icons/leaf.svg'},
    {name: 'mag', path: '../assets/img/icons/builder/project-builder/task-icons/mag.svg'},
    {name: 'map-pin', path: '../assets/img/icons/universal/map-pin.svg'},
    {name: 'map-pin-fill', path: '../assets/img/icons/universal/icon-pin-fill.svg'},
    {name: 'minus', path: '../assets/img/icons/universal/minus.svg'},
    {name: 'mission', path: '../assets/img/icons/mission.svg'},
    {name: 'move', path: '../assets/img/icons/universal/move.svg'},
    {name: 'palette', path: '../assets/img/icons/builder/project-builder/task-icons/palette.svg'},
    {name: 'pencil', path: '../assets/img/icons/builder/project-builder/task-icons/pencil.svg'},
    {name: 'pencil-outline', path: '../assets/img/icons/universal/pencil.svg'},
    {name: 'plant', path: '../assets/img/icons/universal/plant.svg'},
    {name: 'plus', path: '../assets/img/icons/universal/plus.svg'},
    {name: 'project', path: '../assets/img/icons/universal/project.svg'},
    {name: 'project-viewer', path: '../assets/img/icons/universal/project-viewer.svg'},
    {name: 'rabbit', path: '../assets/img/icons/universal/rabbit.svg'},
    {name: 'reporting', path: '../assets/img/icons/universal/reporting.svg'},
    {name: 'rewards', path: '../assets/img/icons/universal/rewards.svg'},
    {name: 'search', path: '../assets/img/icons/universal/search.svg'},
    {name: 'settings', path: '../assets/img/icons/universal/settings.svg'},
    {name: 'sked-list', path: '../assets/img/icons/universal/sked.svg'},
    {name: 'sort-arrow', path: '../assets/img/icons/universal/sort-arrow.svg'},
    {name: 'star', path: '../assets/img/icons/universal/star.svg'},
    {name: 'target', path: '../assets/img/icons/universal/target.svg'},
    {name: 'thermometer', path: '../assets/img/icons/builder/project-builder/task-icons/thermometer.svg'},
    {name: 'tracker', path: '../assets/img/icons/universal/tracker.svg'},
    {name: 'user', path: '../assets/img/icons/universal/user.svg'},
    {name: 'users', path: '../assets/img/icons/universal/users.svg'},
    {name: 'walk', path: '../assets/img/icons/builder/project-builder/task-icons/walk.svg'},
    {name: 'x-circle', path: '../assets/img/icons/universal/x-circle.svg'},
  ];

  registerIcons() {
    this.icons.forEach(icon => {
      this.matIconRegistry.addSvgIcon(icon.name, this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path));
    });
  }
}
