@if ({selectedPane: selectedPane$ | async}; as state) {
  <div class="main-container">
    @if (state.selectedPane === pane.Start) {
      <div class="body oph-scroll-bar">
        <div class="main-header header">
          <div class="left-container">
            <div class="color-container" (click)="changeToPane(pane.Color)">
              <div class="colored-thing" [style.background-color]="project.color"></div>
              <mat-icon class="arrow-icon" svgIcon="chevron-down"></mat-icon>
            </div>
            <oph-input-inside-label
              label="Project name"
              inputType="text"
              [value]="project.label"
              fontSize="20"
              (valueChange)="onProjectNameChange($event)"
            ></oph-input-inside-label>
          </div>
          <div>
            <mat-icon class="close-icon" svgIcon="plus" (click)="onClose()"></mat-icon>
          </div>
        </div>
        <div class="mobile-header">
          <div class="top-container">
            <div class="color-container" (click)="changeToPane(pane.Color)">
              <div class="colored-thing" [style.background-color]="project.color"></div>
              <mat-icon class="arrow-icon" svgIcon="chevron-down"></mat-icon>
            </div>
            <div>
              <mat-icon class="close-icon" svgIcon="plus" (click)="onClose()"></mat-icon>
            </div>
          </div>
          <oph-input-inside-label
            label="Project name"
            inputType="text"
            [value]="project.label"
            fontSize="20"
            (valueChange)="onProjectNameChange($event)"
          ></oph-input-inside-label>
        </div>
        <div
          class="add-program-button"
          [class.centered]="project.program.length === 0"
          (click)="changeToPane(pane.Program)"
        >
          @if (project.program.length === 0) {
            <div class="add-program-text">
              <mat-icon>add</mat-icon>
              <span class="btn-label">Add to Program</span>
            </div>
          }
          @if (project.program.length > 0) {
            <div class="display-programs">
              @for (program of project.program; track program) {
                <div class="program-name">
                  <mat-icon svgIcon="book"></mat-icon>
                  <span>{{ getProgramName(program) }}</span>
                </div>
              }
              <div class="add-button-container">
                <mat-icon>add</mat-icon>
              </div>
            </div>
          }
        </div>
        <div class="project-options-container">
          <div class="inner-container">
            <div
              class="option-item"
              [class.selected]="selectedOption === options.Schedule"
              (click)="onOptionSelect(options.Schedule)"
            >
              <mat-icon svgIcon="calendar"></mat-icon>
              <p class="title-text">Schedule</p>
              <p class="sub-text">Select a date range</p>
            </div>
            <div
              class="option-item"
              [class.selected]="selectedOption === options.Draft"
              (click)="onOptionSelect(options.Draft)"
            >
              <mat-icon svgIcon="feather" class="feather-icon"></mat-icon>
              <p class="title-text">Draft</p>
              <p class="sub-text">Build a project for later</p>
            </div>
          </div>
        </div>
        <div class="message-container">
          @if (modeText()) {
            <span class="mode-text">{{ modeText() }}</span>
          }
          @if (helpText()) {
            <span class="help-text">{{ helpText() }}</span>
          }
          @if (dateText()) {
            <div class="date-text" (click)="nextPane(state.selectedPane)">
              <span>{{ dateText() }}</span>
              <oph-icon class="pencil pencil-light" name="pencil-light"></oph-icon>
              <oph-icon class="pencil pencil-brown" name="pencil-brown"></oph-icon>
            </div>
          }
        </div>
      </div>
    }
    @if (state.selectedPane === pane.Color) {
      <div class="body">
        <div class="header">
          <span class="color-select-text">Select a color</span>
          <div>
            <mat-icon class="close-icon" svgIcon="plus" (click)="changeToPane(pane.Start)"></mat-icon>
          </div>
        </div>
        <div class="color-select-container">
          <div class="inner-container oph-scroll-bar">
            @for (color of colors; track color) {
              <div class="color-block" [style.background-color]="color" (click)="onColorSelect(color)"></div>
            }
          </div>
        </div>
        <div class="cancel-button-container">
          <button class="cancel-button" (click)="changeToPane(pane.Start)">cancel</button>
        </div>
      </div>
    }
    @if (state.selectedPane === pane.Program) {
      <div class="add-project-body">
        <div class="header">
          <span class="add-to-program-text">Add to program</span>
          <div>
            <mat-icon class="close-icon" svgIcon="plus" (click)="changeToPane(pane.Start)"></mat-icon>
          </div>
        </div>
        <span class="subtext">Programs let you group projects.</span>
        <div class="program-list-container oph-scroll-bar">
          @for (program of programs$ | async; track program) {
            <div class="program-list-item" (click)="toggleProgram(program)">
              <div class="program-name">
                <mat-icon svgIcon="book"></mat-icon>
                <span>{{ program.name }}</span>
              </div>
              @if (!hasProgram(program.id)) {
                <mat-icon class="plus-icon">add</mat-icon>
              }
              @if (hasProgram(program.id)) {
                <mat-icon class="check-icon">check</mat-icon>
              }
            </div>
          }
        </div>
        <div class="add-program-button centered no-border" (click)="changeToPane(pane.CreateProgram)">
          <span class="add-program-text">
            <mat-icon>add</mat-icon>
            <span class="btn-label">Create new program</span>
          </span>
        </div>
      </div>
    }
    @if (state.selectedPane === pane.CreateProgram) {
      <div class="body">
        <div class="header">
          <span class="color-select-text">New program</span>
          <div>
            <mat-icon class="close-icon" svgIcon="plus" (click)="changeToPane(pane.Program)"></mat-icon>
          </div>
        </div>
        <div class="program-name-container">
          <span class="program-label">Name your program</span>
          <oph-input-orange [control]="newProgramName"></oph-input-orange>
        </div>
        <div class="bottom-container end">
          <div class="buttons-container">
            <oph-button
              (buttonClick)="changeToPane(pane.Program)"
              appearance="outline"
              [borderWidth]="1"
              [borderRadius]="12"
              color="brown"
              >Cancel</oph-button
            >
            <oph-button (buttonClick)="saveProgram()" [disabled]="disableNewProgramButton()" color="green"
              >Next</oph-button
            >
          </div>
        </div>
      </div>
    }
    @if (state.selectedPane === pane.Calendar) {
      <div class="body">
        <div class="calendar-header header">
          <div class="back-btn" (click)="toStart()">
            <mat-icon svgIcon="chevron-left"></mat-icon>
            <span>Back</span>
          </div>
          <div>
            <mat-icon class="close-icon" svgIcon="plus" (click)="onClose()"></mat-icon>
          </div>
        </div>
        <div class="calendar-container">
          <div class="calendar-text">
            {{ selectedDateRange?.start ? (selectedDateRange?.start | date: 'MMMM d, y') : 'Start date' }} -
            {{ selectedDateRange?.end ? (selectedDateRange?.end | date: 'MMMM d, y') : 'End date' }}
          </div>
          <mat-calendar
            (selectedChange)="onSelectedChange($event)"
            [headerComponent]="customHeader"
            [selected]="selectedDateRange"
          ></mat-calendar>
        </div>
        <span class="calendar-date-display">{{ calendarStartDateDisplay }}{{ calendarEndDateDisplay }}</span>
      </div>
    }
    @if (displayBottomContainer(state.selectedPane)) {
      <div class="bottom-container">
        <div class="checkbox-container">
          <span class="private-text">Make this project private</span>
          <span class="mobile-private-text">Private</span>
          <mat-checkbox [(ngModel)]="project.private"></mat-checkbox>
        </div>
        <div class="buttons-container">
          <oph-button
            (buttonClick)="onClose()"
            appearance="outline"
            [borderWidth]="1"
            [borderRadius]="12"
            color="gray"
            [width]="75"
            borderRadius="12"
            >Cancel</oph-button
          >
          @if (showNextButton(state.selectedPane)) {
            <div>
              <oph-button
                (buttonClick)="nextPane(state.selectedPane)"
                [disabled]="disableNextButton(state.selectedPane)"
                [width]="68"
                borderRadius="12"
                color="green"
                >Next</oph-button
              >
            </div>
          }
          @if (showFinishButton(state.selectedPane)) {
            <div>
              <oph-button
                (buttonClick)="onSave()"
                [disabled]="!selectedOption"
                color="green"
                [width]="68"
                borderRadius="12"
                >Finish</oph-button
              >
            </div>
          }
        </div>
      </div>
    }
  </div>
}
