export enum SkedTimeStatuses {
  Current = 'current',
  Past = 'past',
  Future = 'future',
}

export function getSkedTimeStatus(startDateTime: string, endDateTime: string): SkedTimeStatuses {
  const now = new Date();
  const start = new Date(startDateTime);
  const end = new Date(endDateTime);
  if (now < start) {
    return SkedTimeStatuses.Future;
  } else if (start < now && now < end) {
    return SkedTimeStatuses.Current;
  } else if (end < now) {
    return SkedTimeStatuses.Past;
  }
}
