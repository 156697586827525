import {AsyncPipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {Store} from '@ngrx/store';
import {BehaviorSubject, Observable, Subscription, timer} from 'rxjs';
import {GetAutoSkeds, UpdateAutoSked} from 'src/app/core/store/schedule/schedule.action';
import {selectScheduleAutoSkeds} from 'src/app/core/store/schedule/schedule.selector';
import {AutoSked} from 'src/app/schedule/shared/model/autosked';
import {MessageService} from 'src/app/services/message.service';
import {OphCheckboxComponent} from 'src/app/shared/design/oph-checkbox/oph-checkbox.component';
import {OphXButtonComponent} from 'src/app/shared/design/oph-x-button/oph-x-button.component';
import {AutoskedTimeDisplayPipe} from 'src/app/shared/pipes/autosked-time-display.pipe';

interface DialogData {
  projectId: string;
}

@Component({
  selector: 'projects-sked-selector-dialog',
  standalone: true,
  imports: [
    OphXButtonComponent,
    OphCheckboxComponent,
    AsyncPipe,
    MatIconModule,
    AutoskedTimeDisplayPipe,
    MatProgressSpinnerModule,
  ],
  templateUrl: './projects-sked-selector-dialog.component.html',
  styleUrl: './projects-sked-selector-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectsSkedSelectorDialogComponent implements OnDestroy {
  autoSkedSubscription: Subscription;

  autoSkeds$: Observable<AutoSked[]> = this.store$.select(selectScheduleAutoSkeds);

  autoSkedCheckedMap$ = new BehaviorSubject<Record<string, boolean>>({});
  skedLoadingIndex$ = new BehaviorSubject<number>(null);

  readonly dialogRef = inject(MatDialogRef<ProjectsSkedSelectorDialogComponent>);
  readonly data = inject<DialogData>(MAT_DIALOG_DATA);

  constructor(
    private store$: Store,
    private messageService: MessageService
  ) {
    this.store$.dispatch(new GetAutoSkeds({}));
    this.autoSkedSubscription = this.autoSkeds$.subscribe(autoSkeds => {
      this.autoSkedCheckedMap$.next(
        autoSkeds.reduce((acc, autoSked) => {
          acc[autoSked._id] = autoSked.projectIds.includes(this.data.projectId);
          return acc;
        }, {})
      );
    });
  }

  ngOnDestroy() {
    this.autoSkedSubscription.unsubscribe();
  }

  onClose() {
    this.dialogRef.close();
  }

  onSked(sked: AutoSked, skedIndex: number) {
    this.skedLoadingIndex$.next(skedIndex);

    // Remove or add projectId to autoSked.projectIds
    const newAutoSked = {
      ...sked,
      projectIds: this.autoSkedCheckedMap$.value[sked._id]
        ? sked.projectIds.filter(id => id !== this.data.projectId)
        : [...sked.projectIds, this.data.projectId],
    };

    this.store$.dispatch(
      new UpdateAutoSked({
        autoSked: newAutoSked,
        onSuccess: () => this.onUpdateAutoSkedSuccess(),
        onFailure: err => this.onUpdateAutoSkedFailure(err),
      })
    );
  }

  onUpdateAutoSkedSuccess() {
    this.store$.dispatch(new GetAutoSkeds({onSuccess: () => this.onGetAutoSkedSuccess()}));
  }

  onUpdateAutoSkedFailure(err: Error) {
    this.messageService.add(err?.message || 'There was a problem updating the Auto Sked.');
  }

  onGetAutoSkedSuccess() {
    this.skedLoadingIndex$.next(null);
    timer(500).subscribe(() => {
      this.dialogRef.close();
    });
  }
}
