import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from '@angular/forms';

interface Day {
  label: string;
  value: number;
}

@Component({
  selector: 'oph-week-selector',
  templateUrl: './oph-week-selector.component.html',
  styleUrls: ['./oph-week-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OphWeekSelectorComponent {
  @Input() control: FormControl;
  @Input() autoSked: boolean;
  @Input() invalidDays: number[] = [];
  @Input() disabledIndexArr: number[] = [];
  @Input() allInvalid: boolean;

  @Output() runValidations = new EventEmitter();
  @Output() valueChange = new EventEmitter();

  public readonly weekData: Day[] = [
    {
      label: 'M',
      value: 0,
    },
    {
      label: 'T',
      value: 1,
    },
    {
      label: 'W',
      value: 2,
    },
    {
      label: 'T',
      value: 3,
    },
    {
      label: 'F',
      value: 4,
    },
    {
      label: 'S',
      value: 5,
    },
    {
      label: 'S',
      value: 6,
    },
  ];

  changeValue(value: number, index: number): void {
    if (this.isChecked(value, index)) {
      const values = this.control.value.filter(v => v !== value);
      this.control.patchValue(values.sort());
    } else {
      const values = this.control.value;
      values.push(value);
      this.control.patchValue(values.sort());
    }
    this.control.markAsTouched();
    this.runValidations.emit();
    this.valueChange.emit();
  }

  isChecked(value, index: number): boolean {
    return this.control.value.includes(value) || this.disabledIndexArr.includes(index);
  }

  isInvalid(value): boolean {
    return this.invalidDays.includes(value);
  }
}
