<div class="header">
  <h2 class="title">Icons</h2>
  <button (click)="onSelectIcon('')" class="close-button">
    <img src="assets/img/icons/x-brown.svg" />
  </button>
</div>

<div class="input-container">
  <oph-icon [name]="'list-views/search'" [size]="24"></oph-icon>
  <input placeholder="Search" [value]="searchValue$ | async" (input)="onSearch($event.target.value)" #input />
</div>

@if ({filteredIcons: filteredIcons$ | async}; as state) {
  <div class="main-container oph-scroll-bar">
    @if (state.filteredIcons) {
      @for (category of displayedCategories; track trackByCategory($index, category)) {
        <div class="icon-section-container">
          @if (state.filteredIcons[category]?.icons?.length) {
            <h3>{{ category | uppercase }}</h3>
            <div class="icons-container">
              @for (icon of state.filteredIcons[category]?.icons || []; track trackByIcon($index, icon)) {
                <div class="icon-button-container">
                  <button class="icon-button" (click)="onSelectIcon(icon.name)">
                    <img [src]="'assets/img/icons/color-icons/' + icon.name + '.svg'" loading="lazy" />
                  </button>
                </div>
              }
            </div>
          }
        </div>
      }
      @if (noIconsFound$ | async) {
        <div class="empty">No icons found</div>
      }
    }
  </div>
}
