import {AsyncPipe} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, map, Observable, Subscription, take} from 'rxjs';
import {ProjectViewRoutineDetail, ProjectViewRoutineDetailParams, SelectedRoutine} from 'src/app/core/model/project';
import {User} from 'src/app/core/model/user';
import {CurrentSkedChangeTaskStatusAction} from 'src/app/core/store/current-sked/current-sked.action';
import {
  GetProjectViewRoutineDetailAction,
  UpdateProjectViewTaskId,
  UpdateRoutineDetailParamsAction,
} from 'src/app/core/store/projects/projects.action';
import {
  selectProjectViewRoutineDetailMap,
  selectProjectViewRoutineDetailParams,
} from 'src/app/core/store/projects/projects.selector';
import {TaskStatusActions} from 'src/app/current-sked/shared/task-status-actions';
import {MessageService} from 'src/app/services/message.service';
import {OphBackButtonComponent} from 'src/app/shared/design/oph-back-button/oph-back-button.component';
import {OphIconModule} from 'src/app/shared/design/oph-icon/oph-icon.module';
import {OphPaginatorComponent} from 'src/app/shared/design/oph-paginator/oph-paginator.component';
import {PipesModule} from 'src/app/shared/pipes/pipes.module';
import {OphLoadingModule} from '../../../shared/design/oph-loading/oph-loading.module';
import {ProjectViewRoutineItemComponent} from './item/project-view-routine-item.component';
import {EMPTY_ROUTINE_DETAIL_PARAMS} from '../../shared/projects-constants';

@Component({
  selector: 'project-view-routine',
  standalone: true,
  imports: [
    ProjectViewRoutineItemComponent,
    OphBackButtonComponent,
    AsyncPipe,
    OphIconModule,
    PipesModule,
    OphPaginatorComponent,
    OphLoadingModule,
    MatProgressSpinner,
  ],
  templateUrl: './project-view-routine.component.html',
  styleUrl: './project-view-routine.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectViewRoutineComponent implements OnInit, OnChanges, OnDestroy {
  @Input() routine: SelectedRoutine;
  @Input() usersMap: Record<string, User>;

  @Output() back = new EventEmitter();

  paramsSub: Subscription;

  routineDetailMap$: Observable<Record<string, ProjectViewRoutineDetail>> = this.store$.pipe(
    select(selectProjectViewRoutineDetailMap)
  );
  routineDetail$: Observable<ProjectViewRoutineDetail>;
  params$: Observable<ProjectViewRoutineDetailParams> = this.store$.pipe(select(selectProjectViewRoutineDetailParams));

  loadingFilterButton$ = new BehaviorSubject<string>('');

  options = ['All', 'Expired', 'Completed'];
  routinePriorityColor: string;

  constructor(
    private store$: Store,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.paramsSub = this.subscribeToParams();
    this.routineDetail$ = this.routineDetailMap$.pipe(map(detailMap => detailMap[this.routine.taskInstanceId]));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.routine && this.routine) {
      this.routinePriorityColor = this.getRoutinePriorityColor(this.routine);
    }
  }

  ngOnDestroy(): void {
    this.paramsSub.unsubscribe();
  }

  subscribeToParams(): Subscription {
    return this.params$.subscribe(() => {
      this.getRoutineDetail();
    });
  }

  getRoutinePriorityColor(routine: SelectedRoutine): string {
    switch (routine.priority) {
      case 1:
        return '#5C95E9';
      case 2:
        return '#5FE8AF';
      case 3:
        return '#FF9F2D';
      case 4:
        return '#FC5555';
      default:
        return '#7C655F';
    }
  }

  getRoutineDetail() {
    this.params$.pipe(take(1)).subscribe(params => {
      this.store$.dispatch(
        new GetProjectViewRoutineDetailAction({
          taskInstanceId: this.routine.taskInstanceId,
          params,
          onSuccess: () => this.loadingFilterButton$.next(''),
          onFailure: err => this.onGetRoutineDetailFailure(err),
        })
      );
    });
  }

  onGetRoutineDetailFailure(err) {
    this.loadingFilterButton$.next('');
    this.messageService.add(err.message || 'There was a problem getting routine details.');
  }

  onFilterButton(option: string) {
    this.params$.pipe(take(1)).subscribe(currentParams => {
      this.loadingFilterButton$.next(option.toLowerCase());
      const params = {...currentParams, status: option.toLowerCase(), page: 1};
      this.store$.dispatch(new UpdateRoutineDetailParamsAction({params}));
    });
  }

  onRoutine(id?: string) {
    this.store$.dispatch(new UpdateProjectViewTaskId({id}));
  }

  onPageChange(page: number) {
    this.params$.pipe(take(1)).subscribe(currentParams => {
      const params = {...currentParams, page};
      this.store$.dispatch(new UpdateRoutineDetailParamsAction({params}));
    });
  }

  onCheckbox(checked: boolean, id: string) {
    const actionType = checked ? TaskStatusActions.Complete : TaskStatusActions.Release;
    this.store$.dispatch(
      new CurrentSkedChangeTaskStatusAction({
        id,
        actionType,
        skedId: '',
        onSuccess: task => {
          this.onActionSuccess(actionType);
        },
        onFailure: () => this.onActionFailure(actionType),
      })
    );
  }

  onActionSuccess(actionType: TaskStatusActions) {
    this.getRoutineDetail();
  }

  onActionFailure(actionType: TaskStatusActions) {
    this.getRoutineDetail();
    this.messageService.add(`There was a problem changing the task status.`);
  }

  onBack() {
    this.store$.dispatch(new UpdateRoutineDetailParamsAction({params: EMPTY_ROUTINE_DETAIL_PARAMS}));
    this.back.emit();
  }
}
