import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {GoalsApiService} from '../../api/goals-api.service';
import {createCallbackActions, emitErrorActions} from '../store.utils';
import {
  ConnectTargetAction,
  ConnectTargetSuccessAction,
  CreateGoalAction,
  CreateGoalSuccessAction,
  DeleteGoalAction,
  DeleteGoalSuccessAction,
  DisconnectTargetAction,
  GetAllGoalsAction,
  GetAllGoalsSuccessAction,
  GetSingleGoalAction,
  GetSingleGoalSuccessAction,
  GoalsActionType,
  ToggleTargetAction,
  ToggleTargetSuccessAction,
  UpdateGoalAction,
  UpdateGoalSuccessAction,
} from './goals.action';

@Injectable()
export class GoalsEffects {
  public get$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAllGoalsAction>(GoalsActionType.GET),
      mergeMap(action => {
        const {onSuccess, onFailure} = action.payload;

        return this.goalsApiService.get().pipe(
          mergeMap(goals => [new GetAllGoalsSuccessAction({goals}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public getSingle$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<GetSingleGoalAction>(GoalsActionType.GET_SINGLE),
      mergeMap(action => {
        const {id, onSuccess, onFailure} = action.payload;

        return this.goalsApiService.getSingle(id).pipe(
          mergeMap(goal => [new GetSingleGoalSuccessAction({goal}), ...createCallbackActions(onSuccess, goal)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public create$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateGoalAction>(GoalsActionType.CREATE),
      mergeMap(action => {
        const {goal, onSuccess, onFailure} = action.payload;

        return this.goalsApiService.create(goal).pipe(
          mergeMap(response => [
            new CreateGoalSuccessAction({goal: response.goal}),
            ...createCallbackActions(onSuccess, response.goal),
          ]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public update$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateGoalAction>(GoalsActionType.UPDATE),
      mergeMap(action => {
        const {goal, onSuccess, onFailure} = action.payload;

        return this.goalsApiService.update(goal).pipe(
          mergeMap(goal => [new UpdateGoalSuccessAction({goal}), ...createCallbackActions(onSuccess, goal)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public delete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteGoalAction>(GoalsActionType.DELETE),
      mergeMap(action => {
        const {id, onSuccess, onFailure} = action.payload;

        return this.goalsApiService.delete(id).pipe(
          mergeMap(() => [new DeleteGoalSuccessAction(), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public connectTarget$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<ConnectTargetAction>(GoalsActionType.CONNECT_TARGET),
      mergeMap(action => {
        const {goalId, objectiveId, target, onSuccess, onFailure} = action.payload;

        return this.goalsApiService.connectTarget(goalId, objectiveId, target).pipe(
          mergeMap(goal => [new ConnectTargetSuccessAction({goal}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public disConnectTarget$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DisconnectTargetAction>(GoalsActionType.DISCONNECT_TARGET),
      mergeMap(action => {
        const {goalId, objectiveId, target, onSuccess, onFailure} = action.payload;

        return this.goalsApiService.disconnectTarget(goalId, objectiveId, target).pipe(
          mergeMap(goal => [new ConnectTargetSuccessAction({goal}), ...createCallbackActions(onSuccess)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  public toggleTarget$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<ToggleTargetAction>(GoalsActionType.TOGGLE_TARGET),
      mergeMap(action => {
        const {goalId, objectiveId, targetId, onSuccess, onFailure} = action.payload;

        return this.goalsApiService.toggleTarget(goalId, objectiveId, targetId).pipe(
          mergeMap(goal => [new ToggleTargetSuccessAction({goal}), ...createCallbackActions(onSuccess, goal)]),
          catchError(error => emitErrorActions(error, onFailure))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private goalsApiService: GoalsApiService
  ) {}
}
