import {Pipe, PipeTransform} from '@angular/core';

function formatWithOptionalDecimals(value: number) {
  return value % 1 === 0 ? value.toFixed(0) : value.toFixed(2);
}

@Pipe({
  name: 'numberAbbreviation',
  standalone: true,
})
export class NumberAbbreviationPipe implements PipeTransform {
  transform(num: number): unknown {
    if (num >= 1_000_000_000_000) {
      // Abbreviate trillions
      return formatWithOptionalDecimals(num / 1_000_000_000_000) + 'T';
    } else if (num >= 1_000_000_000) {
      // Abbreviate billions
      return formatWithOptionalDecimals(num / 1_000_000_000) + 'B';
    } else if (num >= 1_000_000) {
      // Abbreviate millions
      return formatWithOptionalDecimals(num / 1_000_000) + 'M';
    } else if (num >= 100_000) {
      // Abbreviate hundreds of thousands
      return formatWithOptionalDecimals(num / 1_000) + 'K';
    } else {
      // Format with commas for numbers below 100,000
      return num.toLocaleString();
    }
  }
}
