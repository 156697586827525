import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {ListSearchParams} from 'src/app/core/model/list-search-params';
import {
  DeleteRewardAction,
  GetManageRewardsAction,
  GetRewardsAdminHistoryAction,
} from 'src/app/core/store/rewards/rewards.action';
import {MessageService} from 'src/app/services/message.service';
import {OphDeleteDialogContentComponent} from 'src/app/shared/dialog/oph-delete-dialog-content/oph-delete-dialog-content.component';

interface DialogData {
  rewardId: string;
  page: string;
  params: ListSearchParams;
}

@Component({
  selector: 'rewards-delete-confirmation-dialog',
  standalone: true,
  imports: [OphDeleteDialogContentComponent],
  templateUrl: './rewards-delete-confirmation-dialog.component.html',
  styleUrl: './rewards-delete-confirmation-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RewardsDeleteConfirmationDialogComponent {
  readonly dialogRef = inject(MatDialogRef<RewardsDeleteConfirmationDialogComponent>);
  readonly data = inject<DialogData>(MAT_DIALOG_DATA);
  rewardId = this.data.rewardId;
  page = this.data.page;
  params = this.data.params;
  message = 'Are you sure you want to delete this reward? This action cannot be undone.';
  loading: boolean = false;

  constructor(
    private store$: Store,
    private messageService: MessageService
  ) {}

  onClose(deleteReward: boolean) {
    if (deleteReward) {
      this.deleteReward();
    } else {
      this.dialogRef.close();
    }
  }

  deleteReward() {
    this.loading = true;
    this.store$.dispatch(
      new DeleteRewardAction({
        id: this.rewardId,
        onSuccess: () => this.onDeleteSuccess(),
        onFailure: err => this.onDeleteFailure(err),
      })
    );
  }

  onDeleteSuccess() {
    if (this.page === 'manage') {
      this.getManageRewards();
    }
    if (this.page === 'history') {
      this.getAdminHistory();
    }
  }

  onDeleteFailure(err: HttpErrorResponse) {
    this.messageService.add(err.error || 'There was a problem deleting the reward.');
  }

  getManageRewards() {
    this.store$.dispatch(
      new GetManageRewardsAction({
        onSuccess: () => this.onSuccess(),
        onFailure: err => this.onFailure(err),
      })
    );
  }

  getAdminHistory() {
    this.store$.dispatch(
      new GetRewardsAdminHistoryAction({
        params: this.params,
        onSuccess: () => this.onSuccess(),
        onFailure: err => this.onFailure(err),
      })
    );
  }

  onSuccess() {
    this.dialogRef.close();
    this.loading = false;
  }

  onFailure(err: HttpErrorResponse) {
    this.messageService.add(err.error || 'There was a problem retrieving rewards');
    this.loading = false;
  }
}
