import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {SkillModel} from 'src/app/core/model/skill';
import {User} from 'src/app/core/model/user';
import {Team} from 'src/app/teams/shared/model/team';
import {TaskDialogPanels} from '../task-dialog/shared/task-dialog-constants';

@Component({
  selector: 'provisions-selector',
  templateUrl: './provisions-selector.component.html',
  styleUrls: ['./provisions-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProvisionsSelectorComponent {
  @Input() form: FormGroup;
  @Input() teamsMap: Record<string, Team>;
  @Input() usersMap: Record<string, User>;
  @Input() requirementsMap: Record<string, SkillModel>;

  @Output() panelChange = new EventEmitter<TaskDialogPanels>();

  onAddRequirement() {
    this.panelChange.emit(TaskDialogPanels.Requirements);
  }

  onRemoveTeam(teamId: string) {
    const teams = this.teamsControl.value.filter(team => team !== teamId);
    this.teamsControl.setValue(teams);
  }

  onRemoveUser(userId: string) {
    const users = this.usersControl.value.filter(user => user !== userId);
    this.usersControl.setValue(users);
  }

  onRemoveRequirement(requirementId: string) {
    const requirements = this.requirementsControl.value.filter(requirement => requirement !== requirementId);
    this.requirementsControl.setValue(requirements);
  }

  get teamsControl(): AbstractControl {
    return this.form.get('teams');
  }

  get usersControl(): AbstractControl {
    return this.form.get('users');
  }

  get requirementsControl(): AbstractControl {
    return this.form.get('requirements');
  }
}
