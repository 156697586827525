<span>Reward</span>
<button class="coin-menu-button" [matMenuTriggerFor]="menu" [class.wizard]="wizard">
  <mat-icon
    [svgIcon]="(coinTypesMap$ | async)?.[taskCoinTypeControl?.value]?.name || '' | rewardCoinIcon"
    class="mr-6"
  ></mat-icon>
  <mat-icon svgIcon="chevron-down" class="orange"></mat-icon>
</button>
<mat-menu #menu="matMenu" class="oph-select-menu">
  @for (coinType of coinTypes$ | async; track coinType) {
    <button class="oph-select-menu-item" (click)="onCoinType(coinType._id)">
      <mat-icon [svgIcon]="coinType.name | rewardCoinIcon" class="mr-10"></mat-icon>
      {{ coinType.name }}
    </button>
  }
</mat-menu>

<div class="input-container">
  <input
    [formControl]="taskCoinValueControl"
    [class.short]="wizard"
    [class.wizard]="wizard"
    [min]="rewardCostMin"
    [max]="rewardCostMax"
    [class.red-border]="taskCoinValueControl.invalid"
    (input)="onRemoveDecimals($event.target.value)"
    type="number"
  />

  @if (taskCoinValueControl.invalid) {
    <div class="error-text">{{ rewardCostError }}</div>
  }
</div>
