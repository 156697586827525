import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'navbar-item',
  templateUrl: './navbar-item.component.html',
  styleUrls: ['./navbar-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarItemComponent {
  @Input()
  public icon: string;

  @Input()
  public display: string;

  @Input() selected: boolean;
}
