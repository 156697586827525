import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OphGeneralDialogComponent} from './oph-general-dialog.component';
import {MatButtonModule} from '@angular/material/button';
import {DialogLayoutModule} from '../layout/dialog-layout.module';
import {OphButtonModule} from '../../design/oph-button/oph-button.module';

@NgModule({
  declarations: [OphGeneralDialogComponent],
  imports: [CommonModule, DialogLayoutModule, MatButtonModule, OphButtonModule],
  exports: [OphGeneralDialogComponent],
})
export class OphGeneralDialogModule {}
