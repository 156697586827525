import {Action} from '@ngrx/store';
import {User} from '../../model/user';

export enum UsersActionType {
  GET_ALL = '[Users] Get All',
  GET_ALL_SUCCESS = '[Users] Get All :: Success',

  CREATE_NEW = '[Users] Create New',
  CREATE_NEW_SUCCESS = '[Users] Create New :: Success',

  UPDATE = '[Users] Update',
  UPDATE_SUCCESS = '[Users] Update :: Success',

  DELETE = '[Users] Delete',
  DELETE_SUCCESS = '[Users] Delete :: Success',

  CLEAR = '[Users] Clear',
}

export class GetAllUsersAction implements Action {
  public readonly type = UsersActionType.GET_ALL;

  public constructor(
    public payload: {
      onSuccess?: (users: User[]) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class GetAllUsersSuccessAction implements Action {
  public readonly type = UsersActionType.GET_ALL_SUCCESS;

  public constructor(public payload: {users: User[]}) {}
}

export class CreateNewUserAction implements Action {
  public readonly type = UsersActionType.CREATE_NEW;

  public constructor(
    public payload: {
      user: User;
      onSuccess?: (user: User) => void;
      onFailure?: (error: string) => void;
    }
  ) {}
}

export class CreateNewUserSuccessAction implements Action {
  public readonly type = UsersActionType.CREATE_NEW_SUCCESS;
}

export class UpdateUserAction implements Action {
  public readonly type = UsersActionType.UPDATE;

  public constructor(
    public payload: {
      user: User;
      onSuccess?: (user: User) => void;
      onFailure?: (error: string) => void;
    }
  ) {}
}

export class UpdateUserSuccessAction implements Action {
  public readonly type = UsersActionType.UPDATE_SUCCESS;

  public constructor(public payload: {user: User}) {}
}

export class DeleteUserAction implements Action {
  public readonly type = UsersActionType.DELETE;

  public constructor(
    public payload: {
      userId: string;
      onSuccess?: (user: User) => void;
      onFailure?: (error: string) => void;
    }
  ) {}
}

export class DeleteUserSuccessAction implements Action {
  public readonly type = UsersActionType.DELETE_SUCCESS;

  public constructor(public payload: {user: User}) {}
}

export class ClearUsersAction implements Action {
  public readonly type = UsersActionType.CLEAR;
}

export type UsersAction =
  | ClearUsersAction
  | GetAllUsersAction
  | GetAllUsersSuccessAction
  | CreateNewUserAction
  | CreateNewUserSuccessAction
  | UpdateUserAction
  | UpdateUserSuccessAction
  | DeleteUserAction
  | DeleteUserSuccessAction;
