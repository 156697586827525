import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {BehaviorSubject, Subscription} from 'rxjs';

@Component({
  selector: 'task-repetition',
  templateUrl: './task-repetition.component.html',
  styleUrls: ['./task-repetition.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskRepetitionComponent implements OnInit, OnDestroy {
  @Input() scheduleFormGroup: FormGroup;
  @Input() disabledDays: number[] = [];
  @Input() error: boolean;
  @Input() noDaySelected: boolean;

  @Output() showCalendar = new EventEmitter();

  subscriptions = new Subscription();

  scheduleType$ = new BehaviorSubject<'doNotRepeat' | 'repeat'>('doNotRepeat');
  endsDate$ = new BehaviorSubject<string>('');

  numberOptionsArr = new Array(10);
  repeatTypesArr = ['week', 'day'];
  previousDate: string;

  ngOnInit() {
    this.subscriptions.add(this.subscribeToScheduleChanges());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.scheduleFormGroup && this.scheduleFormGroup) {
      this.endsDate$.next(this.endsDateControl.value);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  subscribeToScheduleChanges(): Subscription {
    return this.scheduleFormGroup.valueChanges.subscribe(scheduleForm => {
      this.scheduleType$.next(scheduleForm.scheduleType);
      this.endsDate$.next(scheduleForm.endsDate);
    });
  }

  onRadioButton(value: string) {
    this.endsTypeControl.setValue(value);
  }

  onEndsDate() {
    this.previousDate = this.endsDateControl.value;
    this.showCalendar.emit();
  }

  onClear(event: PointerEvent) {
    event.stopPropagation();
    this.endsTypeControl.setValue('never');
    this.endsDateControl.setValue('');
  }

  onOccurrencesBlur(value: number) {
    if (value < 1) {
      this.endsOccurrencesControl.setValue(1);
    }
  }

  onNumber(value: number) {
    this.scheduleFormGroup.patchValue({repeatEvery: value, expirationType: 'onRepeat'});
  }

  onType(value: string) {
    this.scheduleFormGroup.patchValue({repeatType: value, expirationType: 'onRepeat'});
  }

  clearExpiration() {
    this.expirationTypeControl.setValue('onRepeat');
  }

  get scheduleTypeControl(): AbstractControl {
    return this.scheduleFormGroup.get('scheduleType');
  }

  get endsTypeControl(): AbstractControl {
    return this.scheduleFormGroup.get('endsType');
  }

  get endsDateControl(): AbstractControl {
    return this.scheduleFormGroup.get('endsDate');
  }

  get endsOccurrencesControl(): AbstractControl {
    return this.scheduleFormGroup.get('endsOccurrences');
  }

  get repeatEveryControl(): AbstractControl {
    return this.scheduleFormGroup.get('repeatEvery');
  }

  get repeatTypeControl(): AbstractControl {
    return this.scheduleFormGroup.get('repeatType');
  }

  get repeatDaysControl(): AbstractControl {
    return this.scheduleFormGroup.get('repeatDays');
  }

  get expirationTypeControl(): AbstractControl {
    return this.scheduleFormGroup.get('expirationType');
  }
}
