import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CurrentSkedSelectorComponent} from './selector/current-sked-selector.component';
import {MatMenuModule} from '@angular/material/menu';
import {PipesModule} from '../pipes/pipes.module';
import {OphIconModule} from '../design/oph-icon/oph-icon.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {CurrentSkedTaskProgressComponent} from './task-progress/current-sked-task-progress.component';
import {CurrentSkedCheckboxComponent} from './checkbox/current-sked-checkbox.component';

@NgModule({
  declarations: [CurrentSkedSelectorComponent, CurrentSkedTaskProgressComponent, CurrentSkedCheckboxComponent],
  imports: [CommonModule, MatMenuModule, PipesModule, OphIconModule, MatProgressSpinnerModule],
  exports: [CurrentSkedSelectorComponent, CurrentSkedTaskProgressComponent, CurrentSkedCheckboxComponent],
})
export class SharedCurrentSkedModule {}
