import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {TimezoneService} from 'src/app/services/timezone.service';
import {TASK_REPEAT_TYPE_OPTIONS} from '../../../task-constants';

@Component({
  selector: 'twd-task-due-by',
  templateUrl: './twd-task-due-by.component.html',
  styleUrls: ['./twd-task-due-by.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwdTaskDueByComponent {
  @Input() scheduleControls: FormGroup;

  constructor(private timezoneService: TimezoneService) {}

  repeatTypesArr = TASK_REPEAT_TYPE_OPTIONS;

  onButton(value: 'project' | 'date' | '1' | number) {
    if (value === '1') {
      value = +value;
    }
    this.dueWithinControl.setValue(value);
  }

  onDateChange(date: Date) {
    this.dueWithinDateControl.setValue(this.timezoneService.convertDateToUtcString(date));
  }

  onType(type: string) {
    this.dueWithinIntervalTypeControl.setValue(type);
  }

  get dueWithinControl(): AbstractControl {
    return this.scheduleControls.get('dueWithin');
  }

  get dueWithinDateControl(): AbstractControl {
    return this.scheduleControls.get('dueWithinDate');
  }

  get dueWithinIntervalTypeControl(): AbstractControl {
    return this.scheduleControls.get('dueWithinIntervalType');
  }
}
