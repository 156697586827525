import {AsyncPipe, TitleCasePipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {select, Store} from '@ngrx/store';
import {combineLatest, map, Observable, Subscription, take} from 'rxjs';
import {ListSearchParams} from 'src/app/core/model/list-search-params';
import {Pagination} from 'src/app/core/model/pagination';
import {Reward, RewardAssignment, RewardCoin} from 'src/app/core/model/reward';
import {User} from 'src/app/core/model/user';
import {selectActiveUserRole} from 'src/app/core/store/active-user/active-user.selector';
import {GetRewardsUserHistoryAction, UpdateRewardsHeaderLoading} from 'src/app/core/store/rewards/rewards.action';
import {
  selectCoinTypes,
  selectCoinTypesMap,
  selectRewardHistoryExpandedMap,
  selectRewardsAdminHistory,
  selectRewardsAdminHistoryParams,
  selectRewardsHistoryPagination,
  selectRewardsUserHistory,
} from 'src/app/core/store/rewards/rewards.selector';
import {selectAllUsers, selectUsersMap} from 'src/app/core/store/users/users.selector';
import {OphLoadingModule} from 'src/app/shared/design/oph-loading/oph-loading.module';
import {OphPaginatorComponent} from 'src/app/shared/design/oph-paginator/oph-paginator.component';
import {ListViewsModule} from 'src/app/shared/list-views/list-views.module';
import {PipesModule} from 'src/app/shared/pipes/pipes.module';
import {RewardsDeleteConfirmationDialogComponent} from '../shared/delete-confirmation-dialog/rewards-delete-confirmation-dialog.component';
import {RewardsRewardHeaderItemComponent} from '../shared/header-item/rewards-reward-header-item.component';
import {RewardsRewardCostComponent} from '../shared/reward-cost/rewards-reward-cost.component';
import {rewardsDialogConfig} from '../shared/rewards-constants';
import {RewardsDialogComponent} from '../shared/rewards-dialog/rewards-dialog.component';
import {RewardsUserCountChevronComponent} from '../shared/user-count-chevron/rewards-user-count-chevron.component';
import {RewardUserItemComponent} from '../shared/user-item/reward-user-item.component';
import {
  DeleteRewardAction,
  GetRewardsAdminHistoryAction,
  UpdateRewardHistoryExpandedMapAction,
} from './../../core/store/rewards/rewards.action';

@Component({
  selector: 'rewards-history',
  standalone: true,
  imports: [
    MatExpansionModule,
    AsyncPipe,
    RewardsRewardHeaderItemComponent,
    RewardsUserCountChevronComponent,
    PipesModule,
    RewardsRewardCostComponent,
    ListViewsModule,
    RewardUserItemComponent,
    OphPaginatorComponent,
    OphLoadingModule,
    TitleCasePipe,
  ],
  templateUrl: './rewards-history.component.html',
  styleUrl: './rewards-history.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RewardsHistoryComponent implements OnInit, OnDestroy {
  userRole$: Observable<string> = this.store$.pipe(select(selectActiveUserRole)).pipe(map(role => role?.toLowerCase()));
  rewardHistoryExpandedMap$: Observable<Record<string, boolean>> = this.store$.pipe(
    select(selectRewardHistoryExpandedMap)
  );
  params$: Observable<ListSearchParams> = this.store$.pipe(select(selectRewardsAdminHistoryParams));
  userHistory$: Observable<RewardAssignment[]> = this.store$.pipe(select(selectRewardsUserHistory));
  adminHistory$: Observable<Reward[]> = this.store$.pipe(select(selectRewardsAdminHistory));
  users$: Observable<User[]> = this.store$.pipe(select(selectAllUsers));
  usersMap$: Observable<Record<string, User>> = this.store$.pipe(select(selectUsersMap));
  coinTypes$: Observable<RewardCoin[]> = this.store$.pipe(select(selectCoinTypes));
  coinTypesMap$: Observable<Record<string, RewardCoin>> = this.store$.pipe(select(selectCoinTypesMap));
  pagination$: Observable<Pagination> = this.store$.pipe(select(selectRewardsHistoryPagination));
  initialLoading$: Observable<boolean>;

  subscriptions = new Subscription();
  pageSize = 25;
  readonly dialog = inject(MatDialog);

  constructor(private store$: Store) {}

  ngOnInit() {
    this.subscriptions.add(this.subscribeToPageAndUserRole());
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  subscribeToPageAndUserRole() {
    return combineLatest([this.params$, this.userRole$]).subscribe(([p, role]) => {
      const params = {...p, pageSize: this.pageSize};
      if (!params.search) {
        delete params.search;
      }

      if (role === 'admin') {
        this.initialLoading$ = this.adminHistory$.pipe(map(rewards => !rewards));
        this.getAdminHistory(params);
      } else {
        this.initialLoading$ = this.userHistory$.pipe(map(rewards => !rewards));
        this.getUserHistory(params);
      }
    });
  }

  getAdminHistory(params: ListSearchParams) {
    this.headerLoading();
    this.store$.dispatch(
      new GetRewardsAdminHistoryAction({
        params,
        onSuccess: () => this.clearLoading(),
        onFailure: () => this.clearLoading(),
      })
    );
  }

  getUserHistory(params: ListSearchParams) {
    this.headerLoading();

    this.store$.dispatch(
      new GetRewardsUserHistoryAction({
        params,
        onSuccess: () => this.clearLoading(),
        onFailure: () => this.clearLoading(),
      })
    );
  }

  headerLoading() {
    this.initialLoading$.pipe(take(1)).subscribe(loading => {
      if (!loading) {
        setTimeout(() => {
          this.store$.dispatch(new UpdateRewardsHeaderLoading({headerLoading: true}));
        }, 0);
      }
    });
  }

  clearLoading() {
    this.store$.dispatch(new UpdateRewardsHeaderLoading({headerLoading: false}));
  }

  onRewardHistoryExpandedMapChange(key: string, open: boolean) {
    this.store$.dispatch(new UpdateRewardHistoryExpandedMapAction({key, open}));
  }

  onMenuAction(action: string, reward: Reward) {
    if (action === 'edit') {
      this.openRewardDialog(reward);
    }
    if (action === 'delete') {
      this.openDeleteDialog(reward);
    }
  }

  openDeleteDialog(reward: Reward) {
    this.dialog.open(RewardsDeleteConfirmationDialogComponent, {
      data: {
        message: 'Are you sure you want to delete this reward? This action cannot be undone.',
        page: 'history',
        rewardId: reward._id,
      },
    });
  }

  deleteReward(reward: Reward) {
    this.store$.dispatch(new DeleteRewardAction({id: reward._id}));
  }

  openRewardDialog(reward: Reward): void {
    combineLatest([this.coinTypes$, this.users$, this.usersMap$])
      .pipe(take(1))
      .subscribe(([coinTypes, users, usersMap]) => {
        this.dialog.open(RewardsDialogComponent, {
          ...rewardsDialogConfig,
          data: {reward, coinTypes, users, usersMap, page: 'history'},
        });
      });
  }
}
