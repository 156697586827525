@if (
  {
    myRewardsExpandedMap: myRewardsExpandedMap$ | async,
    myRewards: myRewards$ | async,
    activeUserCoinsCountMap: activeUserCoinsCountMap$ | async
  };
  as state
) {
  @if ((initialLoading$ | async) && (getError$ | async) === false) {
    <div class="loading-container">
      <oph-loading> </oph-loading>
    </div>
  } @else {
    @if (!state.myRewards?.length) {
      <div class="empty">There are no rewards to display.</div>
    } @else {
      <div class="coin-types-container">
        @for (rewardCoin of myRewards$ | async; track $index; let coinIndex = $index) {
          <rewards-coin-expansion-panel
            [rewardCoin]="rewardCoin"
            [expandedMap]="state.myRewardsExpandedMap"
            [expanded]="state.myRewardsExpandedMap[rewardCoin.name]"
            [userCoinCount]="state.activeUserCoinsCountMap[rewardCoin._id] || 0"
            [disabled]="rewardCoin.rewards?.length === 0"
            (expandedChange)="onMyRewardsExpandedMapChange(rewardCoin.name, $event)"
          >
            <div class="rewards-container">
              @for (reward of rewardCoin.rewards; track $index; let rewardIndex = $index) {
                @for (u of reward.assignedTo; track $index; let uIndex = $index) {
                  <div class="reward">
                    <rewards-reward-header-item [name]="reward.name" [description]="reward.description">
                      @if ((reward.expirationDate | daysUntilExpiration) <= 7) {
                        <div class="expiration-container">
                          <mat-icon svgIcon="info"></mat-icon>
                          {{ reward.expirationDate | daysUntilExpiration }} Days Remaining!
                        </div>
                      }
                      <rewards-progress-bar
                        [percentage]="activeUser$ | async | rewardManageProgress: rewardCoin._id : reward.rewardCost"
                        [disabled]="u.status === 'claimed'"
                      ></rewards-progress-bar>
                      <rewards-reward-cost
                        [coinName]="rewardCoin.name"
                        [rewardCost]="reward.rewardCost"
                        [silverCoin]="u.status === 'claimed'"
                      ></rewards-reward-cost>
                      <div class="button-container">
                        @if (
                          u.status === 'unclaimed' &&
                          state.activeUserCoinsCountMap[rewardCoin._id] >= reward.rewardCost &&
                          !u.inactive &&
                          !reward.claimLimitReached
                        ) {
                          <oph-button
                            color="orange"
                            [style]="{
                              fontSize: '14px',
                              fontWeight: 600,
                              letterSpacing: '0.7px',
                              borderRadius: '6px'
                            }"
                            [loading]="claimLoadingIndex === coinIndex + ' ' + rewardIndex + ' ' + uIndex"
                            [class.disabled]="claimLoadingIndex >= 0"
                            (buttonClick)="onClaim(reward._id, u._id, coinIndex + ' ' + rewardIndex + ' ' + uIndex)"
                            >CLAIM!</oph-button
                          >
                        }
                        @if (u.status === 'claimed') {
                          <div class="due">DUE</div>
                        }
                        @if (reward.claimCount === reward.claimLimit && !reward.unlimited && u.status !== 'claimed') {
                          <apn class="limit-reached">LIMIT REACHED</apn>
                        }
                      </div>
                    </rewards-reward-header-item>
                  </div>
                }
              }
            </div>
          </rewards-coin-expansion-panel>
        }
      </div>
    }
  }
}
