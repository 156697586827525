@if (subtask?.tracker?.metric) {
  <div class="top-container">
    <div class="top-item">
      <mat-icon svgIcon="tracker"></mat-icon>
      <span class="update-tracker">Update Tracker</span>
    </div>
    <div class="top-item ml-15">
      <current-sked-task-detail-value-display
        [metric]="subtask?.tracker?.metric"
      ></current-sked-task-detail-value-display>
    </div>
  </div>
  <div class="bottom-container">
    <span class="metric-name">{{ subtask.tracker.metric?.name || '' }}</span>
    <tracker-metric-value-update
      [metric]="subtask.tracker.metric"
      [subtaskId]="subtask._id"
      [control]="subtaskValueControl"
      [options]="subtask.tracker.metric.options"
      [showNumericUpdateButton]="true"
      [loadingStatus]="loading?.metricId === subtask.metricId ? loading.metricStatus : null"
      toggleWidth="178px"
      (updateValue)="onUpdateMetricValue($event)"
    ></tracker-metric-value-update>
  </div>
} @else {
  <div class="empty">The metric was not found.</div>
}
