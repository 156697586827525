import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';
import {formatSelectedDay} from '../../../utils/format-selected-day';
// import { formatSelectedDay } from '../../shared/utils/format-selected-day';

@Component({
  selector: 'twd-task-date',
  templateUrl: './twd-task-date.component.html',
  styleUrls: ['./twd-task-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwdTaskDateComponent {
  @Input() scheduleOnDateControl: FormControl;

  onButton(value: 'asap' | 'date') {
    if (value === 'asap') {
      this.scheduleOnDateControl.setValue('asap');
    } else {
      this.scheduleOnDateControl.setValue(formatSelectedDay(new Date()));
    }
  }

  onDateChange(date: Date) {
    this.scheduleOnDateControl.setValue(formatSelectedDay(date));
  }
}
